import React from "react";
import PropTypes from 'prop-types';

const Radiobutton = ({ name, label, onChange, disabled, checked }) => {
  return (
    <div className="radio" onClick={onChange}>
      <input type="radio" name={name} checked={checked} onChange={() => {}} disabled={disabled}/>
      <label className="radio-label">{label}</label>
    </div>
  );
};

Radiobutton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

Radiobutton.defaultProps = {
  checked: false,
  disabled: false,
};

export default Radiobutton;
