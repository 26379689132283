import React from "react";
import PropTypes from 'prop-types';
import SvgIcon from "../icon/SvgIcon";
import Textbox from "./Textbox";

class Select extends React.Component {
  constructor() {
    super();

    this.state = {
      isOtherSelected: false
    };


    this.changeOtherSelected = this.changeOtherSelected.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    if(event.target.value === "-2") {
      event.target.value = this.props.value;
      this.changeOtherSelected(true);
    } else {
      this.changeOtherSelected(false);
    }
    this.props.onChange(event);
  }

  changeOtherSelected(flag){
    this.setState({isOtherSelected: flag});
  }

  render() {
    let wrapperClass = "field select " + this.props.wrapperClassName;
    let doesOptionsContainCustomOther = this.props.value ? this.props.options.filter(ele => this.props.value === ele.value).length === 0 : false;

    let highlightSelectClasses = this.props.isSuccess
      ? " success-outline"
      : (this.props.isError ? " error-outline" : "");
    
    return (
      <React.Fragment>
      <label htmlFor={this.props.id} style={this.props.hideLabel ? {display: 'none'} : {}}>{this.props.label}</label>
      <div className={wrapperClass}>
        <select id={this.props.id}
                ref={this.props.inputRef}
                className={"select" + highlightSelectClasses}
                name={this.props.id}
                value={this.props.value}
                defaultValue={this.props.defaultValue}
                onChange={this.onChange}
                disabled={this.props.disabled}>

          {
            !this.props.hideDefault &&
            <option value="">{this.props.defaultOptionText}</option>
          }

          {
            this.props.options.map(opt => <option key={opt.value} value={opt.value}>{opt.text}</option>)
          }

          {
            doesOptionsContainCustomOther &&
            <option value={this.props.value} key={this.props.value}>{this.props.value}</option>
          }

          {
            this.props.showOther &&
            <option key={"-2"} value={"-2"} onChange={this.changeOtherSelected}>Other</option>
          }
        </select>
        {
          this.state.isOtherSelected &&
          <Textbox id={this.props.id} label={this.props.label} showLabel value={doesOptionsContainCustomOther ? this.props.value : ""} onChange={this.props.onChange} />
        }
        <span className={"dropdown" + highlightSelectClasses}>
          <SvgIcon icon="chevron-dropdown" color="blue"/>
        </span>
      </div>
      </React.Fragment>
    );
  }
}

Select.propTypes = {
  inputRef: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
  })),
  showOther: PropTypes.bool,
  customOtherOption: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  hideDefaultOption: PropTypes.bool,
  defaultOptionText: PropTypes.string,
  hideDefault: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
};

Select.defaultProps = {
  value: "",
  hideLabel: false,
  hideDefault: false,
  defaultOptionText: "Select an Option",
  disabled: false,
  wrapperClassName: '',
  isSuccess: false,
  isError: false,
};

export default Select;
