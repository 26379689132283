import React from "react";
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from "../../constants/routeConfig";

import LandingPageContainer from "./LandingPageContainer";

import Login from '../user/Login';
import ResetPasswordPage from "../user/ResetPasswordPage";
import ConfirmResetPasswordPage from "../user/ConfirmResetPasswordPage";
import CreateAccount from "../user/CreateAccount";
import SetupGuestForm from "../user/SetupGuestForm";
import UserContainer from "./UserContainer";

import ErrorPage from '../errors/ErrorPage';
import ErrorBoundary from "../errors/ErrorBoundary";

import CourseViewPage from '../courses/CourseViewPage';
import CourseUnenrollPage from "../courses/CourseUnenrollPage";
import CourseListPage from "../courses/CourseListPage";
import CourseOfferingDetailPageContainer from "../containers/CourseOfferingDetailPageContainer";
import ViewWebinarPage from "../courses/ViewWebinarPage";

import ShoppingCartPageContainer from "./ShoppingCartPageContainer";

import ResourcesPage from "../resources/ResourcesPage";
import SlipTutorialsPage from "../resources/SlipTutorialsPage";
import BulletinsPage from "../resources/BulletinsPage";
import FaqsPage from "../resources/FaqsPage";
import FaqGroupPage from "../resources/FaqGroupPage";
import BulletinsSeriesPage from "../resources/BulletinsSeriesPage";
import NoticesPage from "../resources/NoticesPage";

import BrokerGuidesContentPage from "../brokerGuides/BrokerGuidesContentPage";

import TermsOfUseAndPrivacyPolicy from "../layout/TermsOfUseAndPrivacyPolicy";
import CECreditReferencePage from "../references/CECreditReferencePage";

class RootContainer extends React.Component {

  render() {

    return (
      <React.Fragment>
        <ErrorBoundary>
          <Switch>

            {/* User account pages */}
            <Route exact path={ROUTES.CONFIRM_RESET_PASSWORD} component={ConfirmResetPasswordPage} />
            <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Route exact path={ROUTES.CREATE_ACCOUNT} component={CreateAccount} />
            <Route exact path={ROUTES.SETUP_GUEST} component={SetupGuestForm} />
            <Route exact path={ROUTES.MY_PROFILE_PAGE} component={UserContainer} />
            <Route exact path={ROUTES.MY_COURSES_PAGE} component={UserContainer} />

            {/* Course pages */}
            <Route exact path={ROUTES.COURSE_LIST} component={CourseListPage} />
            <Route exact path={ROUTES.COURSE_DETAIL_PAGE} component={CourseOfferingDetailPageContainer} />
            <Route exact path={ROUTES.COURSE_DETAIL_PAGE_ARCHIVE_ROUTE} component={CourseOfferingDetailPageContainer} />
            <Route exact path={ROUTES.COURSE_VIEW_PAGE} component={CourseViewPage} />
            <Route exact path={ROUTES.COURSE_UNENROLL_PAGE} component={CourseUnenrollPage} />
            <Route exact path={ROUTES.VIEW_WEBINAR_REDIRECT} component={ViewWebinarPage} />

            {/* Shopping cart pages */}
            <Route path={ROUTES.SHOPPING_CART} component={ShoppingCartPageContainer} />

            {/* Resources section pages */}
            <Route exact path={ROUTES.RESOURCES_PAGE} component={ResourcesPage} />
            <Route exact path={ROUTES.TUTORIALS_PAGE} component={SlipTutorialsPage} />
            <Route exact path={ROUTES.BULLETINS_PAGE} component={BulletinsPage} />
            <Route exact path={ROUTES.BULLETINS_SERIES_PAGE} component={BulletinsSeriesPage} />
            <Route exact path={ROUTES.FAQS_PAGE} component={FaqsPage} />
            <Route exact path={ROUTES.FAQS_FAQ_PAGE} component={FaqGroupPage} />
            <Route exact path={ROUTES.NOTICES_PAGE} component={NoticesPage} />
            <Route exact path={ROUTES.NOTICES_PAGE_EXPANDED} component={NoticesPage} />

            {/* Broker Guides pages */}
            <Route exact path={`${ROUTES.FILING_REQUIREMENTS_PROCEDURES_PAGE}/:childPagePath`} component={BrokerGuidesContentPage} />

            {/* Other pages */}
            <Route exact path={ROUTES.TERMS_OF_USE_AND_PRIVACY_POLICY} component={TermsOfUseAndPrivacyPolicy} />

            <Route exact path={ROUTES.LANDING_PAGE} component={LandingPageContainer} />

            <Route exact path={ROUTES.CECREDITREFERENCE_PAGE} component={CECreditReferencePage} />

            <Route component={ErrorPage} />
          </Switch>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default RootContainer;
