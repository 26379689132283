import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {ROUTES} from "../../constants/routeConfig";
import landingBg from "../../assets/Carousel-1.png";
import landingBgMobile from "../../assets/Carousel-1-mobile.png";
import CourseSearchButton from '../courses/CourseSearchButton';
import CreateAccountButton from '../inputs/CreateAccountButton';
import CarouselSlide from './CarouselSlide';

class LandingPageMainSlide extends React.Component {
  render() {
    return(
      <CarouselSlide backgroundUrl={landingBg}
                     mobileBackgroundUrl={landingBgMobile}
                     active={this.props.active}>
        <div className={this.props.active ? '' : 'row'}>
          <div className={this.props.active ? '' : 'col offset-md-5'}>
            <h1><span>Welcome to the</span><span>SLA Learning Center</span></h1>
            <p className="landing-subtitle">
              {!this.props.isLoggedIn ?
                <React.Fragment>
                  The Learning Center includes a number of features designed to streamline your CE
                  experience: you can enroll in one or more CE courses, view your SLA course history, access your CE
                  certificates, and track the number of CE credits earned towards your license renewal. To get
                  started, click on the Create Account button or log in if you already have an account.
                </React.Fragment>
                :
                <React.Fragment>
                  Welcome back! Navigate to My Courses to check on your progress toward accumulating the
                  required CE credits to renew your license, review your completed courses, and download your
                  certificates.
                </React.Fragment>
              }
            </p>
            <div className="slide-buttons">
              {!this.props.isLoggedIn &&
                  <CreateAccountButton
                    primary={true}
                    blue={false}
                    createAccountRedirect={ROUTES.LANDING_PAGE}
                    createAccountFormRoute={ROUTES.CREATE_ACCOUNT} />
              }
              <CourseSearchButton />
            </div>
          </div>
        </div>
      </CarouselSlide>
    );
  }
}

LandingPageMainSlide.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  active: PropTypes.bool,
}

LandingPageMainSlide.defaults = {
  active: false,
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
}

export default connect(mapStateToProps)(LandingPageMainSlide);
