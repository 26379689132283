import React from "react";
import PropTypes from "prop-types";
import courseOffering from "../../../proptypes/courses/courseOffering";
import ProgressBar from "../../inputs/ProgressBar";

import CourseOfferingDetailsRow from "./CourseOfferingDetailsRow";
import { connect } from "react-redux";
import Checkbox from "./../../inputs/Checkbox";
import {
  calculateProgress,
} from "../../../utilities/courseUtilities";
import registeredCourse from "../../../proptypes/users/registeredCourse";

import * as courseSelectors from "../../../selectors/courseSelectors";

function determineSectionCompletion(sectionData) {
  if (sectionData.childKnowledgeChecks.length == 0) {
    return sectionData.section.isComplete;
  }

  return sectionData.childKnowledgeChecks.every(
    (childKnowledgeCheck) => childKnowledgeCheck.section.isComplete
  );
}

function getOutlineSectionData(courseRegistration) {
  let sectionDataList;

  if (courseRegistration.progress != null) {
    sectionDataList = courseRegistration.progress
      .slice(1)
      .map((section, index) => {
        return {
          index: index,
          section: section,
          isKnowledgeCheck: section.title.includes("Knowledge Check"),
          childKnowledgeChecks: [],
          parentItem: null,
        };
      });

    let lastNonKnowledgeCheck;
    sectionDataList.forEach((sectionData) => {
      if (sectionData.isKnowledgeCheck) {
        sectionData.parentItem = lastNonKnowledgeCheck;
        lastNonKnowledgeCheck.childKnowledgeChecks.push(sectionData);
      } else {
        lastNonKnowledgeCheck = sectionData;
      }
    });
  }

  return sectionDataList;
}

const OnDemandCourseOfferingDetails = ({
  courseOffering,
  outlineSectionData,
  courseRegistration,
}) => (
  <React.Fragment>
    <hr />
    {courseRegistration && (
      <React.Fragment>
        <CourseOfferingDetailsRow
          label="Progress"
          data={
            <ProgressBar
              min={0}
              max={100}
              current={calculateProgress(courseRegistration.progress)}
            />
          }
        />
        <hr />
      </React.Fragment>
    )}
    {courseRegistration && courseRegistration.progress
      ? outlineSectionData.map((section, index) => {
          if (!section.section.title.includes("Knowledge Check")) {
            return (
              <CourseOfferingDetailsRow
                key={index}
                label={index == 0 ? "Outline" : ""}
                className="compact"
                data={
                  <Checkbox
                    id={"id" + index}
                    label={`${section.section.title} ${
                      section.section.score &&
                      section.section.title.toLowerCase().includes("quiz")
                        ? "(Score: " + section.section.score + "%)"
                        : ""
                    }`}
                    checked={determineSectionCompletion(section)}
                    onChange={() => {}}
                  />
                }
              />
            );
          }
        })
      : courseOffering.lmsOutline.slice(1).map((section, index) => {
          if (!section.includes("Knowledge Check")) {
            return (
              <CourseOfferingDetailsRow
                key={section}
                label={index == 0 ? "Outline" : ""}
                data={section}
              />
            );
          }
        })}
  </React.Fragment>
);

OnDemandCourseOfferingDetails.propTypes = {
  courseOffering: PropTypes.shape(courseOffering),
  loading: PropTypes.bool,
  outlineSectionData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  courseRegistration: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(registeredCourse)]),
};

function mapStateToProps(state) {
  const courseRegistration = courseSelectors.selectCourseRegistration(state);
  return {
    courseOffering: courseSelectors.selectCurrentCourseOffering(state),
    courseRegistration,
    outlineSectionData: courseRegistration
      ? getOutlineSectionData(courseRegistration)
      : null,
  };
}

export default connect(mapStateToProps)(OnDemandCourseOfferingDetails);
