import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "../icon/SvgIcon";
import moment from 'moment';
import { DatePickerDateFormats } from '../../constants/datePicker';

class StandardDatePicker extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      hasError: false,
    };

    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e) {
    let regex = /((\d|\d{2}|\d{4})[/|-]\d\d?[/|-](\d|\d{2}|\d{4})|\d{8})$/;
    
    if (regex.test(e.currentTarget.value)) {
      let date = moment(e.currentTarget.value, DatePickerDateFormats, true);
      
      if (!date.isValid()) {
        this.setState({
          hasError: true,
        });
      } else {
        this.setState({
          hasError: false,
        });
      }
    } else if (e.currentTarget.value === '') {
      this.setState({
        hasError: false,
      });
    } else {
      this.setState({
        hasError: true,
      })
    }
  }

  render() {
    return (
      <div className={`date-picker ${this.props.inputClassName || ""}`}>
        <div className="field">
          <input type='text' className={"textbox" + (this.state.hasError ? " error" : "")}
                  value={this.props.value} placeholder={this.props.label}
                  onChange={this.props.onChange} onKeyDown={this.props.onKeyDown} onFocus={this.props.onFocus} onBlur={this.props.onBlur}
                  onInput={(e) => {this.handleInput(e)}} onClick={this.props.onClick}/>
        </div>
        <SvgIcon icon={"group"} onClick={this.props.onClick} color={"gray-light"}/>
      </div>
    )
  }
}

StandardDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onTextChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  inputClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default StandardDatePicker;
