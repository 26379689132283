import * as locationApi from "../api/locationApi";

export const actionTypes = Object.freeze({
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS"
});

export function GetStates() {
  return dispatch => {
    return locationApi.GetStates()
      .then((states) => {
        dispatch({ type: actionTypes.GET_STATES_SUCCESS, data: states});
      })
      .catch(error => {
        throw error;
      });
  };
}
