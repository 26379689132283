import React from 'react';
import PropTypes from 'prop-types';
import Textbox from "../inputs/Textbox";
import * as formUtilities from "../../utilities/formUtilities";

const PasswordField = ({ className, wrapperClassName, icon, hideLabel, inputRef, password, passwordLabel, change, id, confirmPassword, isConfirmField, passwordSuccess, passwordError }) => {
  let eightCharsLong = password.length >= 8 ? (null) : (<p>Password must be at least 8 characters long. *</p>);
  let numericalDigit = /\d/.test(password) ? (null) : (<p>Password must contain one numerical digit. *</p>);
  let oneUppercaseLetter = /[A-Z]/.test(password) ? (null) : (<p>Password must contain one uppercase letter. *</p>);
  let oneLowercaseLetter = /[a-z]/.test(password) ? (null) : (<p>Password must contain one lowercase letter. *</p>);
  let passwordsMatch = confirmPassword ? confirmPassword !== "" ? password === confirmPassword : false : false;
  let passwordsDontMatch = isConfirmField ? passwordsMatch ? (null) : (<p>Passwords must match</p>) : null;
  let errorText =
    (
      <div className={"field-error-text float-up"}>
        {oneUppercaseLetter}
        {oneLowercaseLetter}
        {numericalDigit}
        {eightCharsLong}
        {passwordsDontMatch}
      </div>
    );

  return (<Textbox
    className={className}
    wrapperClassName={wrapperClassName}
    hideLabel={hideLabel}
    icon={icon}
    inputRef={inputRef}
    id={id}
    label={passwordLabel}
    value={password}
    maskInput
    onChange={change}
    errorText={errorText}
    isError={passwordError}
    isSuccess={passwordSuccess && !formUtilities.formElementHasError(password) || (isConfirmField && passwordsMatch && formUtilities.validatePassword(password))} />);
}

PasswordField.propTypes = {
  icon: PropTypes.string,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  inputRef: PropTypes.func,
  id: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  passwordLabel: PropTypes.string.isRequired,
  password: PropTypes.string,
  passwordSuccess: PropTypes.bool,
  passwordError: PropTypes.bool,
  confirmPassword: PropTypes.string,
  isConfirmField: PropTypes.bool
};

PasswordField.defaultProps = {
  password: "",
  icon: "",
  hideLabel: false,
  className: "",
  wrapperClassName: "",
  confirmPassword: "",
  isConfirmField: false
}

export default PasswordField;
