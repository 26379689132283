import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DOCUMENT_PUBLISH_OPTIONS } from '../../../constants/courses';
import courseOffering from '../../../proptypes/courses/courseOffering';
import courseOfferingMethod from '../../../proptypes/courses/courseOfferingMethod';
import { getCurrentRegistration } from "../../../utilities/courseUtilities";

import * as courseSelectors from "../../../selectors/courseSelectors";

import CourseOfferingDetails from './CourseOfferingDetails';
import PresenterList from '../presenter/PresenterList';
import CourseDocumentList from './CourseDocumentList';

function filterCourseDocuments(currentOffering, registeredCourses) {
  let courseRegistration = registeredCourses
    ? getCurrentRegistration(registeredCourses, currentOffering.uniqueId)
    : false;

  let filteredCourseDocuments = currentOffering.documents.filter(document =>
    document.documentPublishOption === DOCUMENT_PUBLISH_OPTIONS.IMMEDIATE
    || (document.documentPublishOption === DOCUMENT_PUBLISH_OPTIONS.UPON_REGISTRATION && courseRegistration)
    || (document.documentPublishOption === DOCUMENT_PUBLISH_OPTIONS.UPON_COMPLETION && courseRegistration && courseRegistration.attended)
  );

  return filteredCourseDocuments;
}

const CourseOfferingDetailBody = ({
  currentOffering,
  registeredCourses,
  previouslyLoadedCourse,
}) => {
  let isOnDemand = currentOffering.method === courseOfferingMethod.ON_DEMAND;
  let hasOutline =
    currentOffering.lmsOutline && currentOffering.lmsOutline.length > 1;
  const pendingCDIApprovalInd = courseOffering.course.pendingCDIApprovalInd;

  let showCourseDetails =
    !previouslyLoadedCourse ||
    !isOnDemand ||
    (isOnDemand && (pendingCDIApprovalInd || hasOutline));

  const hasPresenters = currentOffering.presenters && currentOffering.presenters.length > 0;
  const hasDocuments = currentOffering.documents && currentOffering.documents.length > 0;

  if (!showCourseDetails && !hasPresenters && !hasDocuments) {
    return null;
  } else {
    return (
      <div role="main" className="detail-body-wrapper">
        <div className="row detail-body">
          {showCourseDetails && (
            <div className="col-md-12 col-lg-5 col-xl-4 course-details">
              <CourseOfferingDetails />
            </div>
          )}
          <div className="col-md-12 col-lg-7 col-xl-8 course-extras">
            {hasPresenters && (
              <PresenterList presenters={currentOffering.presenters} />
            )}

            {hasDocuments && (
              <CourseDocumentList
                documents={filterCourseDocuments(currentOffering, registeredCourses)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

CourseOfferingDetailBody.propTypes = {
  currentOffering: PropTypes.shape(courseOffering),
  registeredCourses: PropTypes.array,
  previouslyLoadedCourse: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    currentOffering: courseSelectors.selectCurrentCourseOffering(state),
    registeredCourses: state.auth.currentUser.registeredCourses,
    previouslyLoadedCourse:
      courseSelectors.selectWasCurrentCoursePreviouslyLoaded(state),
  };
}

export default connect(mapStateToProps)(CourseOfferingDetailBody);
