import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import objectAssign from 'object-assign';
import { bindActionCreators } from 'redux';
import * as courseActions from '../../actions/courseActions';
import { getCourseFormatFromRouteParam } from '../../constants/routeConfig';
import DocumentTitle from './DocumentTitle';
import TopNavBar from './../layout/TopNavBar';
import CourseOfferingDetailHeader from '../courses/details/CourseOfferingDetailHeader';
import CourseOfferingDetailBody from '../courses/details/CourseOfferingDetailBody';
import CourseOfferingImage from '../courses/CourseOfferingImage';
import Footer from './../layout/Footer';
import * as courseUtilities from '../../utilities/courseUtilities';
import CourseOfferingMethod from './../../proptypes/courses/courseOfferingMethod';

import * as courseSelectors from "../../selectors/courseSelectors";
import * as authSelectors from "../../selectors/authSelectors";

class CourseOfferingDetailPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullDescription: false,
    };

    this.fullDescriptionClick = this.fullDescriptionClick.bind(this);
  }

  componentWillMount() {
    const courseId = parseInt(this.props.match.params.courseOfferingId);
    const format = getCourseFormatFromRouteParam(this.props.match.params.format);

    const uniqueId = courseUtilities.generateUniqueId({ id: courseId, format });

    // If the course is not already loaded or a course is loaded that is
    // different than the course being requested then:
    if (!this.props.currentOffering.id || (uniqueId !== this.props.currentOffering.uniqueId)) {
      if (this.props.registeredCourses) {
        let courseRegistration = courseUtilities.getCurrentRegistration(this.props.registeredCourses, uniqueId);
        if (courseRegistration) {
          this.props.actions.getLmsCourseRegistration(courseRegistration.id);
        }
      }

      this.props.actions.getCourse(courseId, format);
    }
  }

  fullDescriptionClick() {
    this.setState({
      fullDescription: !this.state.fullDescription,
    })
  }

  render() {
    const offeringMethodClassname = this.props.currentOffering.method === CourseOfferingMethod.LIVE
      ? " live"
      : " on-demand";

    return (
        <div className="page-wrapper detail-wrapper">
          <DocumentTitle title="Course Details">
            <div className="detail-head">
              <div className={"detail-head-background" + offeringMethodClassname}>
                <div
                  className={
                    "detail-hero" + (this.state.fullDescription ? " full" : "")
                  }
                >
                  {this.props.courseIsLoaded && (
                    <CourseOfferingImage
                      courseOffering={this.props.currentOffering}
                    />
                  )}
                </div>
              </div>
            <div className={"detail-head-content" + offeringMethodClassname}>
                    <TopNavBar showSearchBar={true} />
                    <div className="detail-header">
                      <CourseOfferingDetailHeader
                        fullDescription={this.state.fullDescription}
                        fullDescriptionClick={this.fullDescriptionClick}
                      />
                    </div>
                  </div>
            </div>
                <CourseOfferingDetailBody />
            <Footer />
          </DocumentTitle>
        </div>
    );
  }
}

CourseOfferingDetailPageContainer.propTypes = {
  currentOffering: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  registeredCourses: PropTypes.array,
  courseIsLoaded: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    currentOffering: courseSelectors.selectCurrentCourseOffering(state),
    registeredCourses: authSelectors.selectRegisteredCourses(state),
    courseIsLoaded:
      courseSelectors.selectWasCurrentCoursePreviouslyLoaded(state),
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseOfferingDetailPageContainer);
