import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "../icon/SvgIcon";

class MyRenewalCoursesDatePickerCustomInput extends React.Component {

  render () {
    return (
      <React.Fragment>
        <span className={"my-renewal-courses-date-picker"}>
          {this.props.value ? this.props.value : "--/--/----"}
          <SvgIcon icon={"group"} onClick={this.props.onClick} color={"white"} />
        </span>
      </React.Fragment>
    )
  }
}

MyRenewalCoursesDatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

MyRenewalCoursesDatePickerCustomInput.defaultProps = {
};

export default MyRenewalCoursesDatePickerCustomInput;
