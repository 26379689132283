import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ceType: PropTypes.string,
  cdiCourseCode: PropTypes.string,
  ceHours: PropTypes.number,
  activeInd: PropTypes.string,
  pendingCDIApprovalInd: PropTypes.bool,
  notes: PropTypes.string,
  seriesId: PropTypes.number,
}
