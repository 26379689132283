import React from 'react';
import Button from './Button';
import PropTypes from 'prop-types';
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import SvgIcon from './../icon/SvgIcon';

/**
 * Render the certificate download button.
 * 
 * 
 * @param {*} param0 
 */
const CertificateButton = ({large, iconOnly, certificateUrl}) => {

  var tooltipText = certificateUrl
    ? "Download Certificate"
    : "Certificate is not available for download";

  if (iconOnly) {
    return(
      <SvgIcon title={tooltipText}
               onClick={certificateUrl ? () => {window.open(certificateUrl)} : () => {} }
               icon="certificate-table"
               color={certificateUrl ? "blue" : "gray-medium"}/>
    );
  } else {
    return(
      <Button onClick={() => {window.open(certificateUrl)}}
              label="Certificate"
              disabled={!certificateUrl}
              title={tooltipText}
              className='cert'
              svgIcon
              icon="certificate-table"
              iconColor="blue"
              hoverIconColor={"white"}
              buttonType={buttonTypes.PRIMARY_OUTLINE}
              buttonSize={large ? buttonSizes.LARGE : '' }
      />
    );
  }
};

CertificateButton.propTypes = {
  certificateUrl: PropTypes.string,
  large: PropTypes.bool,
  iconOnly: PropTypes.bool,
};

CertificateButton.defaults = {
  large: false,
  iconOnly: false,
};

export default CertificateButton;
