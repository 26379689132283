export const PRIMARY = "primary";
export const PRIMARY_OUTLINE = "outline-primary";
export const SUCCESS = "success";
export const DANGER = "danger";
export const WARNING = "warning";
export const INFO = "info";
export const LINK = "link";
export const WHITE = "white";
export const WHITE_OUTLINE = "outline-white";
export const DANGER_OUTLINE = "outline-danger";
export const SUCCESS_OUTLINE = "outline-success";
