import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";
import { ToastContainer, toast } from 'react-toastify';
import RootContainer from "./containers/RootContainer";
import LoadingScreen from "./LoadingScreen";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as courseActions from "../actions/courseActions";
import * as modalActions from "../actions/modalActions";
import * as UIActions from "../actions/UIActions";
import { ROUTES } from "../constants/routeConfig";
import initialState from "../store/initialState";
import Modal from "./layout/Modal";
import objectAssign from "object-assign";
import SvgIcon from './icon/SvgIcon';
import EnvironmentInfo from './layout/EnvironmentInfo';
import loadingStates from "../constants/loadingStates";

// Email images
require('../assets/email-footer.png');
require('../assets/email-footer-logo.png');
require('../assets/email-header.png');
require('../assets/email-header-logo.png');

class App extends React.Component {

  componentWillMount() {
    if (this.props.isLoggedIn && this.props.isLoadingRegisteredCourses) {
      // Rehydrated state in the middle of waiting for a request.
      // If the app is initializing and somehow the state indicates that we're already
      // loading registered courses, it is probably the case that the app was
      // interrupted while in the middle of loading registered courses. So
      // let's initiate the load again.
      this.props.actions.getRegisteredCourses();
    } else if (!this.props.isLoggedIn && this.props.isLoadingRegisteredCourses) {
      this.props.actions.getRegisteredCoursesFailure();
    }
  }

  componentWillUpdate(nextProps) {
    //reset search input when navigating anywhere but the search page
    if (this.props.location.pathname !== nextProps.location.pathname && nextProps.location.pathname !== ROUTES.COURSE_LIST) {
      this.props.actions.changeCourseSearchInput(initialState.course.searchInput);

      //close any open modals when navigating
      this.props.actions.closeModal();
    }
    //reset active nav
    this.props.actions.clearActiveNav();
  }

  render() {
    return (
      <React.Fragment>

        <LoadingScreen />

        <RootContainer />

        <Modal />

        <ToastContainer
          position={toast.POSITION.BOTTOM_CENTER}
          autoClose={6000}
          hideProgressBar={false}
          closeOnClick
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
          className='lc-toast-container'
          toastClassName='lc-toast'
          closeButton={<SvgIcon icon="circle-x" color='white' />}
          progressClassName='lc-progress-bar'
        />

        <EnvironmentInfo />

      </React.Fragment>
    );
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  searchInput: PropTypes.string.isRequired,
  children: PropTypes.element,
  isLoadingRegisteredCourses: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};


function mapStateToProps(state) {
  return {
    searchInput: state.course.searchInput,
    isLoadingRegisteredCourses: state.auth.isLoadingRegisteredCourses === loadingStates.LOADING,
    isLoggedIn: state.auth.isLoggedIn
  }
}


function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions, modalActions, UIActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default hot(module)(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
