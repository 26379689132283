import React, { Component } from 'react';
import PropTypes from 'prop-types';
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { CONFIGURATION } from "../../constants/config";
import * as courseActions from "../../actions/courseActions";

class CourseSearchField extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event);
    } else {
      let value = event.target.value;
      this.props.actions.changeCourseSearchInput(value);
    }
  }

  render() {
    let placeholder = CONFIGURATION.isMobileOrTablet() ? "Search" : "What course(s) do you want to search for?";

    return (
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          aria-label="Search"
          value={this.props.searchInput}
          onChange={this.onChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              this.props.onSubmit();
            }
          }} />

        {this.props.searchInput !== '' &&
          <span className="svg-icon remove blue" onClick={() => this.props.actions.changeCourseSearchInput('')} />
        }

        <span
          className="svg-icon search blue"
          onClick={this.props.onSearchClick} />
      </div>
    );
  }
}

CourseSearchField.propTypes = {
  actions: PropTypes.object.isRequired,
  searchInput: PropTypes.string,
  onChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

CourseSearchField.defaultProps = {
  onSearchClick: function () { },
  onSubmit: function () { },
}

function mapStateToProps(state) {
  return {
    searchInput: state.course.searchInput,
  }
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseSearchField));