import React from 'react'
import { PropTypes } from 'prop-types';
import LandingBackground from './LandingBackground';

class CarouselSlide extends React.Component {
  render() {
    return(
      <div className='slide-content'>
        <div>
          <LandingBackground backgroundUrl={this.props.backgroundUrl}
                             mobileBackgroundUrl={this.props.mobileBackgroundUrl}
                             playVideo={this.props.playVideo}/>
        </div>
        <div className="landing-text">
          <div className="slide-content-wrapper">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

CarouselSlide.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.object,
  backgroundUrl: PropTypes.string,
  mobileBackgroundUrl: PropTypes.string,
  playVideo: PropTypes.func,
}

export default CarouselSlide;
