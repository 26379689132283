import React from "react";
import {TextBlock} from 'react-placeholder/lib/placeholders'

const CourseDetailsHeaderPlaceHolder = (
  <div>
    <div className="row">
        <div className="col-md-10">
            <h1><TextBlock color='lightgray' rows={1}/></h1>
        </div>
    </div>
    <TextBlock color='lightgray' rows={6}/>
  </div>
);

export default CourseDetailsHeaderPlaceHolder;
