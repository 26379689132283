import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import courseOffering from '../../../proptypes/courses/courseOffering';
import AddRemoveCartButton from '../../shopping/AddRemoveCartButton';
import courseOfferingMethod from '../../../proptypes/courses/courseOfferingMethod';
import courseOfferingFormat from '../../../proptypes/courses/courseOfferingFormat';
import DropCourseButton from '../../inputs/DropCourseButton';
import SvgIcon from '../../icon/SvgIcon';
import PlayButton from '../../inputs/PlayButton';
import { connect } from 'react-redux';
import { formatDuration, formatPresenterString, shouldShowAsCompleted } from '../../../utilities/courseUtilities';
import CourseOfferingDescription from './CourseOfferingDescription';
import CalendarIcon from './CalendarIcon';
import { COURSE_SERIES } from "../../../constants/courseSeriesLookup";
import ReactPlaceholder from 'react-placeholder';

import * as courseSelectors from "../../../selectors/courseSelectors";
import registeredCourse from '../../../proptypes/users/registeredCourse';
import CourseDetailsHeaderPlaceHolder from '../../resources/placeholders/CourseDetailsHeaderPlaceHolder';
import { defaultCourseOffering } from '../../../store/initialState';
import LoadingSpinnerOverlay from '../../LoadingSpinnerOverlay';
import loadingStates from '../../../constants/loadingStates';

function formatDateTime(date) {
  let mdate = moment(date);
  return mdate.format('ddd, MMM Do, YYYY');
}

class CourseOfferingDetailHeader extends React.Component {
  render() {
    let { currentOffering, courseRegistration, history, loading,
      fullDescription, fullDescriptionClick, isLoadingRegistration } = this.props;
    let isLive = !loading && currentOffering.method !== courseOfferingMethod.ON_DEMAND;
    let hasLocation = currentOffering.location ? true : false;
    let isComplianceCourse = currentOffering.course.seriesId === COURSE_SERIES.COMPLIANCE_CONNECTIONS;
    let isSLAUniversityCourse = currentOffering.course.seriesId === COURSE_SERIES.SLA_UNIVERSITY;
    let isCourseCompleted = courseRegistration ? shouldShowAsCompleted(courseRegistration) : false;

    const showPlaceholder = loading && currentOffering === defaultCourseOffering;

    return (
      <div className='detail-header-text'>
        <div className="header-left-col">
          <div className="row align-items-end">

            <div className="col-12 col-sm-8">

              <ReactPlaceholder showLoadingAnimation ready={!showPlaceholder} customPlaceholder={CourseDetailsHeaderPlaceHolder}>
                <div className='back-to-search'>
                  <span onClick={() => history.goBack()}>
                    <span className='svg-icon chevron-left'></span>
                    Return
                  </span>
                </div>

                {isLive &&
                  <div className='cal-wrapper d-block d-sm-none'>
                    <CalendarIcon date={currentOffering.date} />
                  </div>
                }

                <h1 className='course-title'>
                  {currentOffering.course.name}
                </h1>

                <div className='row course-info'>

                  {isLive && currentOffering.format == courseOfferingFormat.WEBINAR &&
                    <div className='col-12 col-lg-auto'>
                      <div className='svg-icon webinar-sm' />
                      <span>Webinar</span>
                    </div>
                  }

                  {isLive && currentOffering.format == courseOfferingFormat.IN_PERSON &&
                    <div className='col-12 col-lg-auto'>
                      <div className='svg-icon pin-sm' />
                      <span>In-Person</span>
                    </div>
                  }

                  {isLive && currentOffering.format == courseOfferingFormat.IN_PERSON && hasLocation && currentOffering.location.address &&
                    <div className='col-12 col-lg-auto'>
                      <div className='svg-icon pin-sm' />
                      <span>{currentOffering.location.address.city}</span>
                    </div>
                  }

                  {isLive &&
                    <div className='col-12 col-lg-auto'>
                      <div className='svg-icon cal' />
                      <span>{formatDateTime(currentOffering.date)}</span>
                    </div>
                  }

                  <div className='col-12 col-lg-auto'>
                    <div className='svg-icon course-number-sm' />
                    <span>Course # {currentOffering.course.cdiCourseCode}</span>
                  </div>

                  {!!currentOffering.duration &&
                    <div className='col-12 col-lg-auto'>
                      <SvgIcon icon="clock-sm" color="white" />
                      <span className="detail">{formatDuration(currentOffering.duration)}</span>
                    </div>
                  }
                </div>

                {currentOffering.presenters && currentOffering.presenters.length > 0 &&
                  <React.Fragment>
                    <span className='presenter-with'>with</span>
                    <div className='presenter-names'>{formatPresenterString(currentOffering.presenters)}</div>
                  </React.Fragment>
                }
                <CourseOfferingDescription description={currentOffering.description} fullDescription={fullDescription} fullDescriptionClick={fullDescriptionClick} />
              </ReactPlaceholder>
            </div>

            <div className="col-12 col-sm-4 text-right">

              {isLive &&
                <div className='cal-wrapper d-none d-sm-inline-block'>
                  <CalendarIcon date={currentOffering.date} />
                </div>
              }

              {!isLive &&
                <div className='cal-wrapper d-none d-sm-inline-block'>
                  <div className="cal"><SvgIcon icon="play-screen" color='white' /></div>
                </div>
              }

            </div>
          </div>
        </div>

        <div className="detail-info">
          <div className='detail-info-items'>
            {courseRegistration ?
              <React.Fragment>
                {currentOffering.method === courseOfferingMethod.ON_DEMAND ?
                  <div className='detail-info-item'>
                    <PlayButton courseRegistration={courseRegistration} />
                  </div>
                  :
                  ''
                }
                {!isCourseCompleted &&
                  <div className='detail-info-item'>
                    <DropCourseButton courseRegistrationId={courseRegistration.id} />
                  </div>
                }
              </React.Fragment>
              :
                <LoadingSpinnerOverlay loading={isLoadingRegistration}>
                  <div className='detail-info-item'>
                    <AddRemoveCartButton courseOffering={currentOffering} />
                  </div>
                </LoadingSpinnerOverlay>
            }
            {isComplianceCourse &&
              <div className='detail-info-item'>
                <span className='detail-info-icon-container'>
                  <span className='svg-icon white compliance-connections'></span>
                </span>
              </div>
            }
            {isSLAUniversityCourse &&
              <div className='detail-info-item'>
                <span className='detail-info-icon-container'>
                  <span className='svg-icon white sla-university'></span>
                </span>
              </div>
            }
            {currentOffering.course.ceHours > 0 &&
              <div className='detail-info-item ce-credits'>
                <span className="credits-number">{currentOffering.course.ceHours}</span>
                Credits
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

CourseOfferingDetailHeader.propTypes = {
  loading: PropTypes.bool,
  isLoadingRegistration: PropTypes.bool,
  history: PropTypes.object.isRequired,
  currentOffering: PropTypes.shape(courseOffering),
  courseRegistration: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(registeredCourse)]),
  fullDescription: PropTypes.bool.isRequired,
  fullDescriptionClick: PropTypes.func.isRequired,
};

CourseOfferingDetailHeader.default = {
  fullDescription: false,
};

function mapStateToProps(state) {
  return {
    currentOffering: courseSelectors.selectCurrentCourseOffering(state),
    courseRegistration: courseSelectors.selectCourseRegistration(state),
    loading: courseSelectors.selectIsLoadingCourse(state),
    isLoadingRegistration: state.auth.isLoadingRegisteredCourses === loadingStates.LOADING,
  }
}

export default withRouter(connect(mapStateToProps)(CourseOfferingDetailHeader));
