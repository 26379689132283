import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';

import * as buttonTypes from "../../../constants/buttonTypes";
import * as buttonSizes from "../../../constants/buttonSizes";

import courseOffering from "../../../proptypes/courses/courseOffering";
import courseOfferingMethod from "../../../proptypes/courses/courseOfferingMethod";
import courseOfferingFormat from "../../../proptypes/courses/courseOfferingFormat";
import * as shoppingActions from "../../../actions/shoppingActions";
import * as courseActions from "../../../actions/courseActions";
import * as modalActions from "../../../actions/modalActions";
import { decode as decodeStatus } from "../../../proptypes/courses/courseOfferingStatus";
import ICalButton from "../../inputs/ICalButton";
import CourseOfferingDetailsRow from "./CourseOfferingDetailsRow";
import { connect } from "react-redux";
import {
  displayUrl,
  navigateUrl,
  courseOfferingFormatsVary
} from "../../../utilities/courseUtilities";
import StaticMap from "./StaticMap";

import * as courseSelectors from "../../../selectors/courseSelectors";
import * as authSelectors from "../../../selectors/authSelectors";
import registeredCourse from "../../../proptypes/users/registeredCourse";
import Button from "../../inputs/Button";
import { ROUTES } from '../../../constants/routeConfig';
import { toast } from "react-toastify";

function formatDate(date) {
  let mdate = moment(date);
  return mdate.format("L");
}

function formatTime(date) {
  let mdate = moment(date);
  return mdate.format("LT");
}

const changeCourseFormat =  (courseOffering, courseOfferings, actions, courseRegistration, history) => {
  let otherCourses = courseOfferings.filter(c =>
      c.method === courseOfferingMethod.LIVE
      && c.course.id === courseOffering.course.id
      && moment(c.date).isSame(courseOffering.date, 'day')
      && c.uniqueId !== courseOffering.uniqueId);
  let currentFormat = courseOffering.format == courseOfferingFormat.WEBINAR ? "Webinar" : "In-Person";
  let newFormat = otherCourses[0].format == courseOfferingFormat.WEBINAR ? "Webinar" : "In-Person";

  const course = {
    id: otherCourses[0].id,
    format: otherCourses[0].format
  };

  actions.showConfirmModal(
    <p className="text-center" key={courseRegistration.id}>Are you sure that you want to change course format from {currentFormat} to {newFormat}?</p>,
    "Caution: Change Course Format",
    () => {
      actions.closeModal();
      actions.dropCourse(courseRegistration.id)
         .then(() => otherCourses.length > 0 ? actions.updateShoppingCart([]) : null)
         .then(() => actions.addToCart(course))
         .then(() => actions.registerForCourses([course]))
         .then(() => history.push(ROUTES.SHOPPING_CART_COMPLETE))
         .then(() => toast.success("Format of course successfully changed to " + newFormat))
         .catch((error) => toast.error(error.message));
    }
  );
};

class LiveCourseOfferingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableChangeFormatButton: false,
      isHybridCourse: false,
    };
    this.computeIsHybridCourse = this.computeIsHybridCourse.bind(this);
  }

  computeIsHybridCourse()  {
    const { courseOffering, courseOfferings } = this.props;
    let today = new moment();
    let difference = new moment(courseOffering.date).diff(today.startOf('day'), 'days');
    let enableChangeFormatButton = difference > 5;

    if (enableChangeFormatButton !== this.state.enableChangeFormatButton) {
      this.setState(() => ({ enableChangeFormatButton }));
    }

    let isHybridCourse = false;
    let liveCourses = courseOfferings.filter(c =>
      c.method === courseOfferingMethod.LIVE
      && c.course.id === courseOffering.course.id
      && moment(c.date).isSame(courseOffering.date, 'day')
    );

    isHybridCourse = liveCourses.length > 1 && courseOfferingFormatsVary(liveCourses);

    if (isHybridCourse !== this.state.isHybridCourse) {
      this.setState(() => ({ isHybridCourse }));
    }
  }

  componentWillMount() {
    const { shouldLoadFutureCourses, actions } = this.props;
    if (shouldLoadFutureCourses) {
      actions.getFutureCourses();
    }
    this.computeIsHybridCourse();
  }

  componentDidUpdate() {
    this.computeIsHybridCourse();
  }

  render() {
    const {
      actions,
      courseRegistration,
      courseOffering,
      courseOfferings,
      history
    } = this.props;
    const { enableChangeFormatButton, isHybridCourse } = this.state;

    let tooltipText = enableChangeFormatButton ? "Change Course Format" : "Format cannot be changed within 5 business days of event. Please reach out to seminars@slacal.com for more information.";

    return (
      <React.Fragment>
        <CourseOfferingDetailsRow
          label="Status"
          data={decodeStatus(courseOffering.status)}
        />
        <hr />
        <CourseOfferingDetailsRow
          label="Date"
          data={
            <React.Fragment>
              {formatDate(courseOffering.date)}
              {courseRegistration && (
                <ICalButton id={courseOffering.id} iconOnly />
              )}
            </React.Fragment>
          }
        />

        {courseOffering.registrationTime && (
          <React.Fragment>
            <hr />
            <CourseOfferingDetailsRow
              label="Registration"
              data={formatTime(courseOffering.registrationTime)}
            />
          </React.Fragment>
        )}
        <hr />
        <CourseOfferingDetailsRow
          label="Class"
          data={
            formatTime(courseOffering.date) +
            " - " +
            formatTime(courseOffering.endDate) +
            " PT"
          }
        />

        {courseOffering.format == courseOfferingFormat.WEBINAR && (
          <React.Fragment>
            <hr />
            <CourseOfferingDetailsRow
              label="Webinar URL"
              data={
                courseRegistration ? (
                  <a
                    href={navigateUrl(courseOffering.webinarUrl)}
                    target="_blank"
                  >
                    {displayUrl("Click here")}
                  </a>
                ) : (
                  "To be provided upon registration"
                )
              }
            />
          </React.Fragment>
        )}

        {courseOffering.format == courseOfferingFormat.IN_PERSON && (
          <React.Fragment>
            <hr />
            <CourseOfferingDetailsRow
              label="Location"
              data={
                courseOffering.location.address && (
                  <address>
                    {courseOffering.locationName}
                    <br />
                    {courseOffering.location.address.address1}
                    {courseOffering.location.address.address2 &&
                      "<br/>" + courseOffering.location.address.address2}
                    <br />
                    {courseOffering.location.address.city +
                      ", " +
                      courseOffering.location.address.state +
                      " " +
                      courseOffering.location.address.zip}
                  </address>
                )
              }
            />
          </React.Fragment>
        )}
        {courseOffering.format == courseOfferingFormat.IN_PERSON && (
          <div className="row map-row">
            <div className="col map-wrapper">
              <StaticMap address={courseOffering.location.address} />
            </div>
          </div>
        )}

        {isHybridCourse && courseRegistration && (
          <React.Fragment>
            <hr />
            <div className="row">
              <div className="col">
                <Button buttonSize={buttonSizes.LARGE}
                          buttonType={buttonTypes.PRIMARY}
                          disabled={!enableChangeFormatButton}
                          title={tooltipText}
                          displayBlock={true}
                          onClick= {() => changeCourseFormat(courseOffering, courseOfferings, actions, courseRegistration, history)}
                          svgIcon
                          icon="change-course-format"
                          iconColor={"white"}
                          label="Change Format" />
              </div>
            </div>
          </React.Fragment>
        )}

      </React.Fragment>
    );
  }
}

LiveCourseOfferingDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  courseOffering: PropTypes.shape(courseOffering),
  courseRegistration: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape(registeredCourse)]),
  registeredCourses: PropTypes.array,
  courseOfferings: PropTypes.arrayOf(PropTypes.shape(courseOffering)),
  shouldLoadFutureCourses: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    courseOfferings: courseSelectors.selectCourseOfferings(state),
    shouldLoadFutureCourses: courseSelectors.selectShouldLoadFutureCourses(state),
    courseOffering: courseSelectors.selectCurrentCourseOffering(state),
    courseRegistration: courseSelectors.selectCourseRegistration(state),
    registeredCourses: authSelectors.selectRegisteredCourses(state),
    isLoggedIn: state.auth.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, shoppingActions, courseActions, modalActions);
  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveCourseOfferingDetails));
