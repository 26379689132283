import objectAssign from 'object-assign';
import initialState from '../initialState';
import { actionTypes } from '../../actions/modalActions';
import * as modalTypes from '../../constants/modalTypes';
import * as buttonTypes from '../../constants/buttonTypes';

export default function modalReducer(state = initialState.modal, action) {
  switch (action.type) {

    case actionTypes.SHOW_DEFAULT_MODAL:
      return objectAssign({}, state, {
        acceptCond: false,
        children: action.data.children,
        closeLabel: action.data.closeLabel,
        hideButtons: action.data.hideButtons,
        hideIcon: action.data.hideIcon,
        bodyCssClass: action.data.bodyCssClass,
        icon: action.data.icon,
        modalActionLabel: action.data.modalActionLabel,
        modalLabel: action.data.label,
        modalType: modalTypes.DEFAULT,
        showModal: true,
      });

    case actionTypes.SHOW_DANGER_MODAL:
      return objectAssign({}, state, {
        buttonType: buttonTypes.DANGER,
        buttonTypeOutline: buttonTypes.DANGER_OUTLINE,
        children: action.data.children,
        closeLabel: action.data.closeLabel,
        fontColor: "danger",
        hideButtons: action.data.hideButtons,
        hideIcon: action.data.hideIcon,
        icon: "alert",
        iconColor: "red",
        modalActionLabel: action.data.modalActionLabel,
        modalLabel: action.data.label,
        modalType: modalTypes.DANGER,
        showModal: true,
      });

    case actionTypes.SHOW_SUCCESS_MODAL:
      return objectAssign({}, state, {
        buttonType: buttonTypes.SUCCESS,
        buttonTypeOutline: buttonTypes.SUCCESS_OUTLINE,
        children: action.data.children,
        closeLabel: action.data.closeLabel,
        fontColor: "success",
        hideButtons: action.data.hideButtons,
        hideIcon: action.data.hideIcon,
        icon: "check-circle",
        iconColor: "green",
        modalActionLabel: action.data.modalActionLabel,
        modalLabel: action.data.label,
        modalType: modalTypes.SUCCESS,
        showModal: true,
      });

    case actionTypes.SHOW_CONFIRM_MODAL:
      return objectAssign({}, state, {
        children: action.data.children,
        closeLabel: action.data.closeLabel,
        hideButtons: action.data.hideButtons,
        hideIcon: action.data.hideIcon,
        icon: "alert",
        modalAction: action.data.modalAction,
        modalActionLabel: action.data.modalActionLabel,
        modalLabel: action.data.label,
        modalType: modalTypes.ALERT,
        onCancel: action.data.onCancel ? action.data.onCancel : function () { },
        showModal: true,
      });

    case actionTypes.SHOW_RETAKE_MODAL:
      return objectAssign({}, state, {
        closeLabel: action.data.closeLabel,
        courseOffering: action.data.courseOffering,
        courseRegistrationId: action.data.courseRegistrationId,
        hideIcon: true,
        modalActionLabel: action.data.modalActionLabel,
        modalType: modalTypes.RETAKE,
        showModal: true,
      });

    case actionTypes.SHOW_RENEWAL_CALCULATOR_MODAL:
      return objectAssign({}, state, {
        modalType: modalTypes.RENEWAL_CALCULATOR,
        showModal: true,
      });

    case actionTypes.SHOW_COMPLETED_COURSES_FILTERS_MODAL:
      return objectAssign({}, state, {
        icon: "filters",
        modalLabel: "Filters",
        modalType: modalTypes.COMPLETED_COURSES_FILTERS,
        showModal: true,
      });

    case actionTypes.SHOW_COURSE_LIST_FILTERS_MODAL:
      return objectAssign({}, state, {
        buttonType: buttonTypes.WHITE,
        buttonTypeOutline: buttonTypes.WHITE_OUTLINE,
        fontColor: "white",
        icon: "filters",
        iconColor: "white",
        modalAction: action.data.modalAction,
        modalActionLabel: "Reset Filters",
        modalClassName: "modal-blue font-weight-light form-blue",
        modalLabel: "Filters",
        modalType: modalTypes.COURSE_LIST_FILTERS,
        showModal: true,
      });

    case actionTypes.CLOSE_MODAL:
      return objectAssign({}, state, initialState.modal);

    default:
      return state;
  }

}
