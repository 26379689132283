import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import moment from "moment";
import { withRouter, Link } from 'react-router-dom';
import MyRenewalCoursesDatePickerCustomInput from "../inputs/MyRenewalCoursesDatePickerCustomInput";
import ceCreditStatuses from "../../proptypes/courses/ceCreditStatuses";
import courseOfferingMethod from "../../proptypes/courses/courseOfferingMethod";
import { connect } from "react-redux";
import * as UIActions from "../../actions/UIActions";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import * as lcUserActions from "../../actions/lcUserActions";
import { ROUTES } from "../../constants/routeConfig";
import { trackUserEvent } from '../../utilities/analyticsUtilities';

class MyCoursesRenewalCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCal: false,
      licenseRenewalDate: "",
      registered: 0,
      awarded: 0,
      remaining: 0,
      calculatedInitialRenewalValues: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeLicenseRenewalDate = this.changeLicenseRenewalDate.bind(this);
    this.sendGoogleAnalyticsEvent = this.sendGoogleAnalyticsEvent.bind(this);
    this.calculateRenewalValues = this.calculateRenewalValues.bind(this);
    this.openCalendar = this.openCalendar.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn && this.props.userDataLoaded) {
      this.calculateRenewalValues(this.props.licenseRenewalDate);
      this.setState({ calculatedInitialRenewalValues: true });
    }
  }

  componentDidUpdate() {
    if (!this.state.calculatedInitialRenewalValues && this.props.userDataLoaded) {
      this.calculateRenewalValues(this.props.licenseRenewalDate);
      this.setState({ calculatedInitialRenewalValues: true });
    }
  }

  openCalendar(e) {
    e && e.preventDefault();
    this.setState({ openCal: !this.state.openCal });
  }

  refreshData() {
    this.setState({ registered: 0, awarded: 0, remaining: 0 });
  }

  sendGoogleAnalyticsEvent() {
    trackUserEvent({
      action: 'Date Change',
      category: 'Renewal Calculator',
      label: 'Renew By Date'
    });
  }

  changeLicenseRenewalDate(date) {
    this.refreshData();
    this.props.actions.setLicenseRenewalDate(date);
    this.calculateRenewalValues(date);
    this.openCalendar();
  }

  handleChange(date) {
    this.sendGoogleAnalyticsEvent();
    this.changeLicenseRenewalDate(date);
  }

  calculateRenewalValues(date) {
    let totalCreditsRequired = 24;
    let TwoYearPrecedingDate = moment(date).subtract(2, 'years');
    let awarded = 0;
    let registered = 0;

    if (this.props.registeredCourses) {
      this.props.registeredCourses.forEach((courseRegistration) => {
        let courseOffering = courseRegistration.courseOffering;
        let courseDateIsInBetweenTwoYearPreceding = courseOffering.method === courseOfferingMethod.ON_DEMAND ?
          moment(courseRegistration.completionDate).isBetween(TwoYearPrecedingDate, date)
          :
          moment(courseOffering.date).isBetween(TwoYearPrecedingDate, date);

        let ceHours = courseOffering.course.ceHours;
        if (ceHours) {
          if (courseDateIsInBetweenTwoYearPreceding) {
            switch (courseRegistration.ceCreditStatus) {
              case ceCreditStatuses.AWARDED:
                awarded += ceHours;
                break;

              case ceCreditStatuses.PENDING:
                registered += ceHours;
                break;
            }
          } else if (courseOffering.method === courseOfferingMethod.ON_DEMAND && !courseRegistration.completionDate) {
            registered += ceHours;
          }
        }
      });
    }

    this.setState({
      awarded: awarded,
      registered: registered,
      remaining: totalCreditsRequired - (registered + awarded)
    });
  }

  render() {
    return (<React.Fragment>
      {!this.props.isLoggedIn ?
        <React.Fragment>
          <p>
            <Link to={ROUTES.LOGIN} onClick={() => {
              this.props.actions.setLoginRedirect(ROUTES.MY_COURSES_PAGE);
            }}>Log In</Link>
              &nbsp;or&nbsp;
              <Link to={ROUTES.CREATE_ACCOUNT} onClick={() => {
              this.props.actions.setCreateAccountRedirect(ROUTES.MY_COURSES_PAGE);
            }}>Create an Account</Link>&nbsp;to access this feature.
              </p>
        </React.Fragment>
        :
        <React.Fragment>
          <div className={"renewal-calculator-subtitle"}>
            <p className="text-center">
              Continuing Education credits are calculated for the 2 year period preceding the Renew By Date.
            </p>
          </div>
          <div className={"row"}>
            <div className={"col-md-6 col-lg-12"}>
              <div className={"row"}>
                <p className={"renewal-text col"}>RENEW BY DATE: </p>
                <div className={"renewal-value col"}>
                  <DatePicker
                    customInput={<MyRenewalCoursesDatePickerCustomInput />}
                    calendarClassName={"react-datepicker-z-index-heavy"}
                    minDate={moment()}
                    selected={
                      this.props.licenseRenewalDate
                        ? moment(this.props.licenseRenewalDate)
                        : this.props.licenseRenewalDate
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <hr className={"my-courses-hr"} />
              <div className={"row"}>
                <p className={"renewal-text col"}>AWARDED: </p>
                <p className={"renewal-value col"}>{this.state.awarded} CREDITS</p>
              </div>
              <hr className={"my-courses-hr"} />
              <div className={"row"}>
                <p className={"renewal-text col"}>REGISTERED: </p>
                <p className={"renewal-value col"}>{this.state.registered} CREDITS</p>
              </div>
              <hr className={"my-courses-hr"} />
            </div>
            <div className={"mb-5 col-md-6 col-lg-12"}>
              <div className={"row"}>
                <p className={"renewal-text col"}>TOTAL: </p>
                <p className={"renewal-value col"}>{this.state.registered + this.state.awarded} CREDITS</p>
              </div>
              <hr className={"my-courses-hr"} />
              <div className={"row"}>
                <p className={"renewal-text col"}>REQUIRED: </p>
                <p className={"renewal-value col"}>24 CREDITS</p>
              </div>
              <hr className={"my-courses-hr"} />
              <div className={"row"}>
                <p className={"renewal-text col"}>REMAINING: </p>
                <p className={"renewal-value col"}>{this.state.remaining} CREDITS</p>
              </div>
              <hr className={"my-courses-hr d-none d-md-block d-lg-none"} />
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>);
  }
}

function mapStateToProps(state) {
  return {
    licenseRenewalDate: state.ui.licenseRenewalDate,
    isLoggedIn: state.auth.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, lcUserActions, UIActions);
  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

MyCoursesRenewalCalculator.propTypes = {
  licenseRenewalNumber: PropTypes.string,
  registeredCourses: PropTypes.array,
  history: PropTypes.object,
  actions: PropTypes.object.isRequired,
  licenseRenewalDate: PropTypes.any,
  isLoggedIn: PropTypes.bool.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyCoursesRenewalCalculator)
);
