import React from "react";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import { ROUTES } from "../../constants/routeConfig";

import * as shoppingActions from '../../actions/shoppingActions';
import { generateUniqueId, sortCoursesByMethodAndDate } from '../../utilities/courseUtilities';

import CourseOfferingCard from "../courses/CourseOfferingCard";
import Button from '../inputs/Button';
import { selectCourseOfferingsById } from "../../selectors/courseSelectors";

class ShoppingCartListPage extends React.Component {

  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
  }

  next() {
    this.props.history.push(this.props.isLoggedIn ? ROUTES.SHOPPING_CART_CONFIRM_INFO : ROUTES.SHOPPING_CART_LOGIN);
  }

  render() {

    let cartEmpty = this.props.cart.length <= 0;
    let sortedCart = sortCoursesByMethodAndDate(
      this.props.cart.map(
        course => this.props.courseOfferingsById[generateUniqueId(course)]));

    return (
      <React.Fragment>

        <h1 className="content-heading">Review Selected Courses</h1>

        <div className="white-body">
          <div>

            {!cartEmpty ?
              <React.Fragment>
                <div className="mb-5">
                  {sortedCart.map(courseOffering =>
                    <CourseOfferingCard key={courseOffering.id} courseOffering={courseOffering} reviewing />
                  )}
                </div>

                <Button onClick={this.next}
                  label="Next"
                  className="btn-block-xs"
                  buttonSize={buttonSizes.LARGE}
                  buttonType={buttonTypes.PRIMARY}
                  svgIcon
                  icon="chevron-right"
                  iconColor="white"
                  iconRight
                  disabled={cartEmpty} />
              </React.Fragment>
              :
              <p>You have no courses in your basket.</p>
            }

          </div>
        </div>

      </React.Fragment>
    );
  }
}

ShoppingCartListPage.propTypes = {
  actions: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  courseOfferingsById: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    cart: state.shopping.cart,
    isLoggedIn: state.auth.isLoggedIn,
    courseOfferingsById: selectCourseOfferingsById(state),
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, shoppingActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCartListPage));
