import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import Button from './Button';
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import { calculateProgress, watchCourse } from '../../utilities/courseUtilities';
import * as courseActions from "../../actions/courseActions";
import * as modalActions from "../../actions/modalActions";
import SvgIcon from '../icon/SvgIcon';
import user from '../../proptypes/users/User';

class PlayButton extends React.Component {

  constructor(props) {
    super(props);
    this.launchCourse = this.launchCourse.bind(this);
  }

  launchCourse = () => {
    if (this.props.courseRegistration.attended
      && this.props.courseRegistration.courseOffering.course.ceHours > 0
      && this.props.user.licenseNumber != null) {
        this.props.actions.showRetakeModal(this.props.courseRegistration.courseOffering, this.props.courseRegistration.id);
    }
    else if (this.props.courseRegistration.attended
      && !this.props.courseRegistration.courseOffering.rewatchEnabled) {
        this.props.actions.retakeCourse(
          this.props.user,
          this.props.courseRegistration.courseOffering.id,
          this.props.courseRegistration.courseOffering.format,
          )
    }
    else {
      watchCourse(this.props.courseRegistration.id, true);
    }
  };

  render() {

    let label = "Play";
    let icon = "play-arrow";

    let hoverIconColorBase = "green";
    let buttonType = buttonTypes.SUCCESS;

    if (this.props.courseRegistration) {
      let progress = calculateProgress(this.props.courseRegistration.progress);

      if (progress > 0 && progress < 100) {
        label = "Resume";
        icon = "resume";
        hoverIconColorBase = "yellow"
        buttonType = buttonTypes.WARNING
      }

      if (this.props.courseRegistration.attended || progress == 100) {
        label = "Re-Watch";
        icon = "rewatch";
        hoverIconColorBase = "yellow"
        buttonType = buttonTypes.WARNING
      }
    }

    if (this.props.iconOnly) {
      return (
        <SvgIcon onClick={this.launchCourse}
          icon={icon}
          title={`${label} Course`}
          color="blue"
          className={this.props.className} />
      );
    } else {
      return (
        <Button onClick={this.launchCourse}
          label={label} disabled={false} svgIcon
          icon={icon}
          title={`${label} Course`}
          iconColor="white"
          hoverIconColor={this.props.large ? "white" : hoverIconColorBase}
          buttonSize={this.props.large ? buttonSizes.LARGE : ""}
          buttonType={buttonType} />
      );
    }
  }
}

PlayButton.propTypes = {
  courseRegistration: PropTypes.object.isRequired,
  large: PropTypes.bool,
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  actions: PropTypes.object.isRequired,
  user: PropTypes.shape(user).isRequired,
};

PlayButton.defaults = {
  large: false,
  iconOnly: false,
};

function mapStateToProps(state) {
  return {
    user: state.lcUser.user,
  }
}


function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, modalActions, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayButton);
