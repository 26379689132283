import React from "react";
import ContentPageLayout from "../layout/ContentPageLayout";

const CECreditReferencePage = () => {
  return (
    <ContentPageLayout
      documentTitle="CE Credits for California Non-Resident Licensees"
      headerTitle="CE Credits for California Non-Resident Licensees"
      headerContent={
        <React.Fragment>
          Continuing Education (CE) courses are required by every state, but the
          requirements for each state are unique
        </React.Fragment>
      }
    >
      <div className={"container"}>
        <div className={"white-body"}>
          <div className={"row"}>
            <p>
              All CE courses offered by the SLA are approved by the California
              Department of Insurance (CDI) for California-licensed Property
              &amp; Casualty agents. You will not receive CE credits for other
              states as our courses are not approved for CE credits in other
              states.
            </p>
            <p>
              With a membership covering all 50 states, the SLA is looking to
              expand our services. We are currently working on getting our
              courses approved in your state. As a non-resident licensee who
              attended a CE course offered by the SLA, you will receive a
              certificate indicating that you have completed the course but have
              not received credit.
            </p>
            <p>
              Please reach out to your state&apos;s Department of Insurance for
              more information on whether the SLA&apos;s courses count towards
              your state&apos;s continuing education courses. You can contact us
              at <a href="mailto:seminars@slacal.com">seminars@slacal.com</a>
              for course details and any other inquiries.
            </p>
          </div>
        </div>
      </div>
    </ContentPageLayout>
  );
};

export default CECreditReferencePage;
