import * as courseApi from '../api/courseApi';
import {throwError} from './errorActions';
import {blockUI} from "./UIActions";
import { push } from 'react-router-redux';
import { courseDetailRoute } from "../constants/routeConfig";

import * as courseUtilities from "../utilities/courseUtilities";
import { loadingCourseKey, loadingFutureCoursesKey } from '../store/initialState';
import courseOfferingMethod from '../proptypes/courses/courseOfferingMethod';
import { selectCourseOfferings } from '../selectors/courseSelectors';

export const actionTypes = Object.freeze({
  GET_FUTURE_COURSES: 'GET_FUTURE_COURSES',
  GET_FUTURE_COURSES_SUCCESS: 'GET_FUTURE_COURSES_SUCCESS',
  GET_FUTURE_COURSES_FAILURE: 'GET_FUTURE_COURSES_FAILURE',
  GET_COURSE: 'GET_COURSE',
  GET_COURSE_SUCCESS: 'GET_COURSE_SUCCESS',
  GET_COURSE_FAILURE: 'GET_COURSE_FAILURE',
  GET_REGISTERED_COURSES: 'GET_REGISTERED_COURSES',
  GET_REGISTERED_COURSES_SUCCESS: 'GET_REGISTERED_COURSES_SUCCESS',
  GET_REGISTERED_COURSES_FAILURE: 'GET_REGISTERED_COURSES_FAILURE',
  GET_LMS_OUTLINES: 'GET_LMS_OUTLINES',
  GET_LMS_OUTLINES_SUCCESS: 'GET_LMS_OUTLINES_SUCCESS',
  GET_LMS_OUTLINES_FAILURE: 'GET_LMS_OUTLINES_FAILURE',
  CHANGE_COURSE_SEARCH_INPUT: 'CHANGE_COURSE_SEARCH_INPUT',
  TOGGLE_LIVE_SORT: 'TOGGLE_LIVE_SORT',
  TOGGLE_ON_DEMAND_SORT: 'TOGGLE_ON_DEMAND_SORT',
  CHANGE_METHOD_FILTER: 'CHANGE_METHOD_FILTER',
  CHANGE_CE_CREDITS_FILTER: 'CHANGE_CE_CREDITS_FILTER',
  CHANGE_CREDITS_LOW_FILTER: 'CHANGE_CREDITS_LOW_FILTER',
  CHANGE_CREDITS_HIGH_FILTER: 'CHANGE_CREDITS_HIGH_FILTER',
  CHANGE_LIVE_DATES_FROM_FILTER: 'CHANGE_LIVE_DATES_FROM_FILTER',
  CHANGE_LIVE_DATES_TO_FILTER: 'CHANGE_LIVE_DATES_TO_FILTER',
  REGISTER_FOR_COURSES_SUCCESS: 'REGISTER_FOR_COURSES_SUCCESS',
  DROP_COURSE_SUCCESS: 'DROP_COURSE_SUCCESS',
  RETAKE_COURSE_SUCCESS: 'RETAKE_COURSE_SUCCESS',
  CHANGE_REGISTERED_STATUS_FILTER: 'CHANGE_REGISTERED_STATUS_FILTER',
  RESET_COURSE_SEARCH_FILTERS: 'RESET_COURSE_SEARCH_FILTERS',
  SAVE_COURSE_SEARCH_TO_HISTORY: 'SAVE_COURSE_SEARCH_TO_HISTORY',
  CHANGE_RECENTLY_PUBLISHED_FILTER: "CHANGE_RECENTLY_PUBLISHED_FILTER",
  GET_LMS_COURSE_REGISTRATION_SUCCESS: 'GET_LMS_COURSE_REGISTRATION_SUCCESS',
  GET_COURSE_COMPLETED_SUCCESS: 'GET_COURSE_COMPLETED_SUCCESS',
  CHANGE_CITY_FILTER: 'CHANGE_CITY_FILTER',
  TOGGLE_COMPLIANCE_CONNECTIONS: 'TOGGLE_COMPLIANCE_CONNECTIONS',
  TOGGLE_SLA_UNIVERSITY: 'TOGGLE_SLA_UNIVERSITY'
});

export function changeCourseSearchInput(value) {
  return {type: actionTypes.CHANGE_COURSE_SEARCH_INPUT, data: value}
}

export function saveCourseSearchToHistory(value) {
  return {type: actionTypes.SAVE_COURSE_SEARCH_TO_HISTORY, data: value}
}

export function toggleLiveSort(property) {
  return {type: actionTypes.TOGGLE_LIVE_SORT, data: property}
}

export function toggleOnDemandSort(property) {
  return {type: actionTypes.TOGGLE_ON_DEMAND_SORT, data: property}
}

export function changeRecentlyPublishedFilter() {
  return {type: actionTypes.CHANGE_RECENTLY_PUBLISHED_FILTER}
}

export function changeMethodFilter(method) {
  return {type: actionTypes.CHANGE_METHOD_FILTER, data: method}
}

export function changeCECreditsFilter() {
  return {type: actionTypes.CHANGE_CE_CREDITS_FILTER}
}

export function changeCreditsLowFilter(value) {
  return {type: actionTypes.CHANGE_CREDITS_LOW_FILTER, data: value}
}

export function changeCreditsHighFilter(value) {
  return {type: actionTypes.CHANGE_CREDITS_HIGH_FILTER, data: value}
}

export function changeLiveDatesFromFilter(value) {
  value = value !== null ? value.toISOString() : '';
  return {type: actionTypes.CHANGE_LIVE_DATES_FROM_FILTER, data: value}
}

export function changeLiveDatesToFilter(value) {
  value = value !== null ? value.toISOString() : '';
  return {type: actionTypes.CHANGE_LIVE_DATES_TO_FILTER, data: value}
}

export function changeRegisteredStatusFilter(value) {
  return {type: actionTypes.CHANGE_REGISTERED_STATUS_FILTER, data: value}
}

export function changeCityFilter(value) {
  return {type: actionTypes.CHANGE_CITY_FILTER, data: value}
}

export function resetCourseSearchFilters() {
  return {type: actionTypes.RESET_COURSE_SEARCH_FILTERS}
}

export function toggleComplianceConnections() {
  return {type: actionTypes.TOGGLE_COMPLIANCE_CONNECTIONS}
}

export function toggleSlaUniversity() {
  return {type: actionTypes.TOGGLE_SLA_UNIVERSITY}
}

export function getFutureCourses() {
  return (dispatch, getState) => {

    return courseApi
      .getFutureCourses()
      .then((courses) => {
        dispatch({
          type: actionTypes.GET_FUTURE_COURSES_SUCCESS,
          data: courses,
        });

        const ids = courseUtilities.getUniqueLmsCourseIds(courses);

        const updatedCourses = selectCourseOfferings(getState());

        if (ids.length) {
          dispatch(
            getCourseOutlines(
              ids,
              loadingFutureCoursesKey,
              updatedCourses.map((c) => c.uniqueId)
            )
          );
        }

        return courses;
      })
      .catch((error) =>
        dispatch(
          throwError(error, { source: "courseActions.getFutureCourses" })
        )
      );
  };
}

export function getRegisteredCoursesFailure() {
  return dispatch => dispatch({
    type: actionTypes.GET_REGISTERED_COURSES_FAILURE,
  })
}

export function getRegisteredCourses() {
  return dispatch => {
    dispatch({
      type: actionTypes.GET_REGISTERED_COURSES,
    })
    return courseApi
      .getUserRegisteredCourses()
      .then(registeredCourses => {
        dispatch({
          type: actionTypes.GET_REGISTERED_COURSES_SUCCESS,
          data: registeredCourses,
        })
      })
      .catch(error => {
        dispatch(
          throwError(error, { source: "courseActions.getRegisteredCourses" })
        )
        dispatch({
          type: actionTypes.GET_REGISTERED_COURSES_FAILURE,
        })
      });
  }
}

export function getCourseOutlines(
  lmsCourseIds,
  loadingKey = "",
  courseIds = []
) {
  if (
    !Array.isArray(lmsCourseIds) ||
    !lmsCourseIds.every((id) => typeof id === "number")
  ) {
    throw new Error(
      `Expected courses to be an array. Got: ${typeof lmsCourseIds}`
    );
  }
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_LMS_OUTLINES,
      data: lmsCourseIds,
    });

    return courseApi
      .getLmsCourseOutlines(lmsCourseIds)
      .then((courseOutlines) => {
        dispatch({
          type: actionTypes.GET_LMS_OUTLINES_SUCCESS,
          data: { courseOutlines, courseIds, loadingKey },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_LMS_OUTLINES_FAILURE,
          data: error,
        });
        dispatch(
          throwError(error, {
            source: "courseActions.getCourseOutlines",
          })
        );
      });
  };
}

export function getCourse(id, format) {
  return (dispatch) => {
    const uniqueId = courseUtilities.generateUniqueId({ id, format });
    dispatch({
      type: actionTypes.GET_COURSE,
      data: uniqueId,
    });

    return courseApi
      .getCourse(id, format)
      .then((courseOffering) => {

        // If the course was requested without the format specified (possibly using a
        // bookmarked or emailed URL), then this will be the first point at which
        // we know the format of the course we're displaying.
        if (!format) {
          const newUniqueId = courseUtilities.generateUniqueId({ id, format: courseOffering.format });
          dispatch({
            type: actionTypes.GET_COURSE,
            data: newUniqueId,
          })
        }

        dispatch({
          type: actionTypes.GET_COURSE_SUCCESS,
          data: {
            courseOffering,
            finishedLoading: !courseOffering.lmsCourseId,
          },
        });

        if (
          courseOffering.lmsCourseId &&
          courseOffering.method === courseOfferingMethod.ON_DEMAND
        ) {
          dispatch(
            getCourseOutlines([courseOffering.lmsCourseId], loadingCourseKey, [
              courseOffering.uniqueId,
            ])
          );
        }
      })
      .catch((error) => {
        dispatch(
          throwError(error, { source: "courseActions.getCourse", id: uniqueId })
        );
        throw error;
      });
  };
}

export function registerForCourses(courses, guest = null) {

  return dispatch => {
  
    dispatch(blockUI(true));
    const guestId = guest ? guest.id : null;

    return courseApi.registerForCourses(courses, guestId )
      .then((registeredCourses) => {
        dispatch(blockUI(false));
        const data = { registeredCourses };
        if (guest) data.user = guest;
        dispatch({
          type: actionTypes.REGISTER_FOR_COURSES_SUCCESS,
          data,
        });
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  }
}

export function dropCourse(courseRegistrationId) {
  return dispatch => {
    dispatch(blockUI(true));

    return courseApi.dropCourse(courseRegistrationId)
      .then(() => {
        dispatch(blockUI(false));
        dispatch({
          type: actionTypes.DROP_COURSE_SUCCESS, data: courseRegistrationId
        });
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  }
}

export function dropCourseFromEmail(courseRegistrationGuid) {
  return dispatch => {

    dispatch(blockUI(true));

    return courseApi.dropCourseFromEmail(courseRegistrationGuid)
      .then(() => {
        dispatch(blockUI(false));
        dispatch({
          type: actionTypes.DROP_COURSE_SUCCESS, data: courseRegistrationGuid
        });
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  }
}

export function retakeCourse(user, courseOfferingId, format) {
  return dispatch => {
    dispatch(blockUI(true));
    return courseApi.retakeCourse(user, courseOfferingId)
      .then((registeredCourse) => {
        dispatch(blockUI(false));
        dispatch({
          type: actionTypes.RETAKE_COURSE_SUCCESS, data: { registeredCourse: registeredCourse }
        });
        let courseUrl = courseDetailRoute(courseOfferingId, format);
        dispatch(push(courseUrl));
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  }
}

export function getLmsCourseRegistration(courseRegistrationId) {
  return dispatch => courseApi.getLmsCourseRegistration(courseRegistrationId)
    .then((courseRegistration) => {
      dispatch({
        type: actionTypes.GET_LMS_COURSE_REGISTRATION_SUCCESS, data: courseRegistration
      });
    })
    .catch(error => {
      dispatch(throwError(error, { source: 'courseActions.getLmsCourseRegistration', courseRegistrationId: courseRegistrationId }));
    });
}

export function hasUserCompletedCourseInOfferingsGroup(courseId) {
  return dispatch => {
    dispatch(blockUI(true));
    return courseApi.hasUserCompletedCourseInOfferingsGroup(courseId)

      .then((courseCompleted) => {
        dispatch({
          type: actionTypes.GET_COURSE_COMPLETED_SUCCESS, data: courseCompleted
        });

        return courseCompleted;
      })
      .catch(error => {
        dispatch(throwError(error, { source: 'courseActions.hasUserCompletedCourseInOfferingsGroup', courseId: courseId }));

        return false;
      })
      .finally((result) => { 
        dispatch(blockUI(false)); 
        return result; 
      });
  }
}