import React from 'react';
import { ROUTES} from "../../constants/routeConfig";
import {NavLink} from "react-router-dom";

const CopyrightFooter = () => {

  return (
    <div className="copyright-footer no-print">
    <span>The Surplus Line Association of California</span>
    <span className={"d-none d-md-inline-block"}>|</span>
    <span>Copyright &copy; {new Date().getFullYear()}</span>
    <span className={"d-none d-md-inline-block"}>|</span>
    <span><NavLink to={ROUTES.TERMS_OF_USE_AND_PRIVACY_POLICY}>Terms of Use &amp; Privacy Policy</NavLink></span>
  </div>
  );
};

export default CopyrightFooter;
