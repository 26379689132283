import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import DocumentTitle from "../containers/DocumentTitle";
import TopNavBar from "../layout/TopNavBar";
import Header from '../layout/Header';
import CopyrightFooter from "../layout/CopyrightFooter";

class SimpleContentPageLayout extends React.Component {
  render() {

    return (
      <div className="page-wrapper">
        <div role="main" className="content-wrapper simple-content-wrapper">

          <TopNavBar
            showNav={false}
            showSearchBar={false}
            showBackButton={this.props.showBackButton}
            backButtonRoute={this.props.backButtonRoute} />

          <DocumentTitle title={this.props.documentTitle}>

            <Header title={this.props.headerTitle}>{this.props.headerContent}</Header>

            <div className={"simple-page-content"}>
              {this.props.children}
            </div>

          </DocumentTitle>
        </div>
        <CopyrightFooter />
      </div>
    )
  }
}

SimpleContentPageLayout.propTypes = {
  children: PropTypes.any.isRequired,
  documentTitle: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired,
  headerContent: PropTypes.any,
  showBackButton: PropTypes.bool.isRequired,
  backButtonRoute: PropTypes.string,
  location: PropTypes.object.isRequired,
};

SimpleContentPageLayout.defaultProps = {
  headerContent: null,
  showBackButton: false,
};

export default withRouter(SimpleContentPageLayout);
