export const actionTypes = Object.freeze({
  CHANGE_FROMDATE_FILTER: 'CHANGE_FROMDATE_FILTER',
  CHANGE_TODATE_FILTER: 'CHANGE_TODATE_FILTER',
  CHANGE_CECREDITSONLY_FILTER: 'CHANGE_CECREDITSONLY_FILTER',
  CHANGE_TITLE_FILTER: 'CHANGE_TITLE_FILTER',
});

export function changeFromDate(value) { return { type: actionTypes.CHANGE_FROMDATE_FILTER, data: value } }
export function changeToDate(value) { return { type: actionTypes.CHANGE_TODATE_FILTER, data: value } }
export function changeCeCreditsOnly(value) { return { type: actionTypes.CHANGE_CECREDITSONLY_FILTER, data: value } }
export function changeTitle(value) { return { type: actionTypes.CHANGE_TITLE_FILTER, data: value } }