import React from 'react';
import PropTypes from 'prop-types';

const SvgIcon = ({className, icon, color, onClick, title}) => {
  return (
    <span className={`svg-icon ${icon} ${color || ""} ${className ? className : ""}`} title={title} onClick={onClick}/>
  );
};

SvgIcon.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string
};


export default SvgIcon;
