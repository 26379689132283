import React from "react";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as courseActions from "../../actions/courseActions";
import { COURSE_FILTERS } from "../../constants/courseFilters";

class LandingPageCourseFilters extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      activeNestedDropdown: false,
      toggleActiveMobile: false,
    };

    this.filterCourses = this.filterCourses.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.toggleActiveMobile = this.toggleActiveMobile.bind(this);
    this.toggleActiveNestedDropdown = this.toggleActiveNestedDropdown.bind(this);
  }

  filterCourses(filterType) {

    this.toggleActiveMobile();

    this.props.setCoursesForDisplay(filterType);
  }

  toggleActive() {
    const active = this.state.active;
    this.setState({ active: !active });
  }

  toggleActiveMobile() {
    const active = this.state.activeMobile;
    this.setState({ activeMobile: !active });
  }

  toggleActiveNestedDropdown() {
    const active = this.state.activeNestedDropdown;
    this.setState({ activeNestedDropdown: !active});
  }

  render() {
    return (
      <React.Fragment>
      <div className='landing-page-course-filters'>
        {this.props.displayRegisteredCourses &&
        <div className={'btn-text ' + (this.props.selectedFilter == COURSE_FILTERS.REGISTERED_COURSES ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.REGISTERED_COURSES)}>
          <span className='svg-icon blue my-courses' />
          <div>
            Registered Courses
          </div>
        </div>
        }
        <div className={'btn-text ' + (this.props.selectedFilter == COURSE_FILTERS.ALL_COURSES ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.ALL_COURSES)}>
          <span className='svg-icon blue all-courses' />
          <div>
            All Courses
          </div>
        </div>
        <div className={'btn-text ce-courses-dropdown ' + ((this.props.selectedFilter == COURSE_FILTERS.LIVE || this.props.selectedFilter == COURSE_FILTERS.ON_DEMAND) ? 'selected' : '')}>
          <span className='svg-icon blue ce-courses' />
          <div className='ce-courses'>
            CE Courses
          </div>
          <span className='svg-icon blue chevron-carousel-right' />
          <div className='dropdown-options'>
            <div className={'btn-text dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.LIVE ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.LIVE)}>
              <span className='svg-icon white live' />
              Live
            </div>
            <div className={'btn-text dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.ON_DEMAND ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.ON_DEMAND)}>
              <span className='svg-icon white on-demand' />  
              On Demand
            </div>
          </div>
        </div>
        <div className={'btn-text ' + (this.props.selectedFilter == COURSE_FILTERS.INTRO ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.INTRO)}>
          <span className='svg-icon blue two-hour-course' />
          <div>
            CA SL Regulation:
            <br /> 
            Required Training
          </div>
        </div>
        <div className={'btn-text ' + (this.props.selectedFilter == COURSE_FILTERS.COMPLIANCE_CONNECTIONS ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.COMPLIANCE_CONNECTIONS)}>
          <span className='svg-icon blue compliance-connections' />
          <div>
            Compliance 
            <br />
            Connections
          </div>
        </div>
        {/* <div className={'btn-text ' + (this.props.selectedFilter == COURSE_FILTERS.SLA_UNIVERSITY ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.SLA_UNIVERSITY)}>
          <span className='svg-icon blue sla-university' />
          <div>
            SLA 
            <br />
            University
          </div>
        </div> */}
        <div className={'btn-text ' + (this.props.selectedFilter == COURSE_FILTERS.TUTORIALS ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.TUTORIALS)}>
          <span className='svg-icon blue video-call' />
          <div>
            Tutorials
          </div>
        </div>
      </div>
      <div className='landing-page-course-filters-mobile-container'>
        <div className={'landing-page-course-filters-mobile ' + (this.state.activeMobile ? 'active' : '')} onClick={this.toggleActiveMobile}>
          <span className='svg-icon filters blue' />
          <div className='btn-text'>
            Filter Courses
          </div>
          <span className='svg-icon go blue' />
        </div>
        <div className={'course-filters-dropdown ' + (this.state.activeMobile ? 'active' : '')}>
          <div className={'dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.ALL_COURSES ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.ALL_COURSES)}>
            <span className='svg-icon blue all-courses' />
            <div className='btn-text'>
              All Courses
            </div>
          </div>
          <div className={'dropdown-option ' + (this.state.activeNestedDropdown ? 'active' : '')} onClick={this.toggleActiveNestedDropdown}>
            <span className='svg-icon blue ce-courses' />
            <div className='btn-text'>
              CE Courses
            </div>
            <span className='svg-icon blue chevron-carousel-right' />
          </div>
          <div className={'nested-dropdown ' + (this.state.activeNestedDropdown ? 'active' : '')}>
            <div className={'dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.LIVE ? 'selected' : '')}  onClick={() => this.filterCourses(COURSE_FILTERS.LIVE)}>
              <span className='svg-icon blue live' />
              <div className='btn-text'>
                Live
              </div>
            </div>
            <div className={'dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.ON_DEMAND ? 'selected' : '')}  onClick={() => this.filterCourses(COURSE_FILTERS.ON_DEMAND)}>
              <span className='svg-icon blue on-demand' />
              <div className='btn-text'>
                On Demand
              </div>
            </div>
          </div>
          <div className={'dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.INTRO ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.INTRO)}>
            <span className='svg-icon blue two-hour-course' />
            <div className='btn-text'>
              CA SL Regulation: Required Training
            </div>
          </div>
          <div className={'dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.COMPLIANCE_CONNECTIONS ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.COMPLIANCE_CONNECTIONS)}>
            <span className='svg-icon blue compliance-connections' />
            <div className='btn-text'>
              Compliance Connections
            </div>
          </div>
          {/* <div className={'dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.SLA_UNIVERSITY ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.SLA_UNIVERSITY)}>
            <span className='svg-icon blue sla-university' />
            <div className='btn-text'>
              SLA University
            </div>
          </div> */}
          <div className={'dropdown-option ' + (this.props.selectedFilter == COURSE_FILTERS.TUTORIALS ? 'selected' : '')} onClick={() => this.filterCourses(COURSE_FILTERS.TUTORIALS)}>
            <span className='svg-icon blue video-call' />
            <div className='btn-text'>
              Tutorials
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

LandingPageCourseFilters.propTypes = {
  actions: PropTypes.object.isRequired,
  selectedFilter: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  displayRegisteredCourses: PropTypes.bool,
  setCoursesForDisplay: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPageCourseFilters));