import objectAssign from 'object-assign';
import initialState from '../initialState';
import {actionTypes} from "../../actions/locationActions";

export default function locationReducer(state = initialState.location, action) {

  switch (action.type) {

    case actionTypes.GET_STATES_SUCCESS:
      return objectAssign({}, state, {
        states: [...action.data]
      });

    default:
      return state;
  }
}
