import moment from 'moment';

export function sortSeries(series) {
  return [...series].sort((a, b) => {
    return a.ordinal > b.ordinal ? -1 : 1;
  });
}

export function sortBulletins(bulletins) {
  return [...bulletins].sort((a, b) => {
    return moment(a.bulletinDate).isAfter(b.bulletinDate) ? -1 : 1;
  });
}

export function sortNotices(notices) {
  return [...notices].sort((a, b) => {
    return moment(a.publishDate).isAfter(b.publishDate) ? -1 : 1;
  });
}
