import React from 'react';
import {RectShape} from 'react-placeholder/lib/placeholders'

const BulletinsPlaceHolder = (
  <div className="w-100">
    <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
    <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
    <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
  </div>
);

export default BulletinsPlaceHolder;
