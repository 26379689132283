import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { CONFIGURATION } from "../../constants/config";
import { cdiWebsiteLinkForLicenseRenewalRoute } from "../../constants/routeConfig";
import user from '../../proptypes/users/User';
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";

import Button from "../inputs/Button";

const CDICheckLicenseStatus = ({ className, buttonType, currentUser, isLoggedIn }) => {

  return (
    <div className={"cdi-check-license-status " + (className || "")}>

      {isLoggedIn &&
        <p>The CE credit count reported includes only credits obtained through the SLA.</p>
      }

      <Button
        buttonSize={buttonSizes.LARGE}
        buttonType={buttonType || buttonTypes.PRIMARY_OUTLINE}
        onClick={() => window.open(cdiWebsiteLinkForLicenseRenewalRoute(currentUser.licenseNumber,isLoggedIn), '_blank')}
        label={CONFIGURATION.isMobileDevice() ? "VIEW CDI RECORD" : "VIEW CDI RECORD OF CE CREDIT"}
        svgIcon
        icon="capital"
        iconColor="blue"
        hoverIconColor="white"
        disabled={false} />
    </div>
  )
}

CDICheckLicenseStatus.propTypes = {
  className: PropTypes.string,
  buttonType: PropTypes.string,
  currentUser: PropTypes.shape(user).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    isLoggedIn: state.auth.isLoggedIn,
  }
}

export default connect(mapStateToProps, null)(CDICheckLicenseStatus);
