export const actionTypes = Object.freeze({
  SHOW_DANGER_MODAL: "SHOW_DANGER_MODAL",
  SHOW_SUCCESS_MODAL: "SHOW_SUCCESS_MODAL",
  SHOW_CONFIRM_MODAL: "SHOW_CONFIRM_MODAL",
  CHANGE_ACCEPTED_COND: "CHANGE_ACCEPTED_COND",
  SHOW_DEFAULT_MODAL: "SHOW_DEFAULT_MODAL",
  SHOW_RETAKE_MODAL: "SHOW_RETAKE_MODAL",
  SHOW_RENEWAL_CALCULATOR_MODAL: "SHOW_RENEWAL_CALCULATOR_MODAL",
  SHOW_COMPLETED_COURSES_FILTERS_MODAL: "SHOW_COMPLETED_COURSES_FILTERS_MODAL",
  SHOW_COURSE_LIST_FILTERS_MODAL: "SHOW_COURSE_LIST_FILTERS_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL"
});

export function closeModal() {
  return { type: actionTypes.CLOSE_MODAL }
}

export function showDefaultModal(children, label, hideIcon = false, hideButtons = false, bodyCssClass = "") {
  return { type: actionTypes.SHOW_DEFAULT_MODAL, data: { children, label, hideIcon, hideButtons, bodyCssClass, icon: 'alert-simple', modalActionLabel: "Submit", closeLabel: "Close" } }
}

export function showConfirmModal(children, label, modalAction, hideIcon = false, hideButtons = false, modalActionLabel = "Submit", closeLabel = "Close", onCancel = null) {
  return { type: actionTypes.SHOW_CONFIRM_MODAL, data: { children, label, modalAction, hideIcon, hideButtons, modalActionLabel, closeLabel, onCancel } }
}

export function showDangerModal(children, label, hideIcon = false, hideButtons = false) {
  return { type: actionTypes.SHOW_DANGER_MODAL, data: { children, label, hideIcon, hideButtons, modalActionLabel: "Submit", closeLabel: "Close" } }
}

export function showSuccessModal(children, label, hideIcon = false, hideButtons = false) {
  return { type: actionTypes.SHOW_SUCCESS_MODAL, data: { children, label, hideIcon, hideButtons, modalActionLabel: "Submit", closeLabel: "Close" } }
}

export function showRetakeModal(courseOffering, courseRegistrationId) {
  return { type: actionTypes.SHOW_RETAKE_MODAL, data: { courseOffering, courseRegistrationId, modalActionLabel: "Submit", closeLabel: "Close" } }
}

export function showRenewalCalculatorModal() {
  return { type: actionTypes.SHOW_RENEWAL_CALCULATOR_MODAL, data: {} }
}

export function showCompletedCoursesFiltersModal() {
  return { type: actionTypes.SHOW_COMPLETED_COURSES_FILTERS_MODAL, data: {} }
}

export function showCourseListFiltersModal(resetAction) {
  return { type: actionTypes.SHOW_COURSE_LIST_FILTERS_MODAL, data: { modalAction: resetAction } }
}