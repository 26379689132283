import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {ROUTES} from "../../constants/routeConfig";
import {mapWebsiteLinkPathtoLCLinkPath} from "../../utilities/routeUtilities";
import CourseSearchBar from '../courses/CourseSearchBar';
import PropTypes from 'prop-types';
import pageNode from "../../proptypes/brokerGuides/pageNode";
import {connect} from "react-redux";
import * as lcUserActions from '../../actions/lcUserActions';
import * as brokerGuidesActions from '../../actions/brokerGuidesActions';
import {bindActionCreators} from "redux";
import objectAssign from "object-assign";
import MediaQuery from 'react-responsive';
import {CONFIGURATION} from "../../constants/config";
import navTabs from '../../constants/navTabs';
import NoticesBulletinMenuDropdown from '../landing/NoticesBulletinMenuDropdown';

class TopNavBar extends React.Component {

  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    if (this.props.pageNodes.length === 0
      || !this.props.sectionPageNode) {
      this.props.actions.getSection("FilingRequirementsAndProcedures");
    }
  }

  logout() {
    this.props.actions.logout();
    this.props.history.push(ROUTES.LANDING_PAGE);
  }

  render() {

    return (
      <nav className="top-nav no-print">
        <MediaQuery maxWidth={CONFIGURATION.MAX_MD_WIDTH}>
          {(matches) => {
            if (!matches) {
              return(
                <div className='brand-wrapper h-100'>
                  <NavLink target="_blank" className='sla-logo-wrapper d-flex align-items-center justify-content-center flex-column' to={ROUTES.SLACAL_WEBSITE}>
                    <div className="sla-logo" />
                    <div className='sla-link'>
                      <div className="svg-icon left-arrow mr-2" />
                      SLACAL.COM
                    </div>
                  </NavLink>
                  <NavLink className=" lc-logo-wrapper d-flex align-items-center" to={ROUTES.LANDING_PAGE}>
                      <div className="crossbar" />
                      <div className="lc-logo" />
                  </NavLink>
                </div>
              );
            } else {
              return(
                <NavLink className="brand-wrapper" to={ROUTES.LANDING_PAGE}>
                  <div className="ml-3 lc-logo"/>
                </NavLink>);
            }
          }}
        </MediaQuery>

        <div className='top-nav-items-wrapper'>

          <NoticesBulletinMenuDropdown />

          {
            this.props.showBackButton &&
            <ul className={"top-nav-items"}>
              <li className="top-nav-item back-item" onClick={() => this.props.history.push(this.props.backButtonRoute)}>
                <div className="svg-icon back mr-2"/>
                  <a>
                    Back
                  </a>
              </li>
            </ul>
          }

          <MediaQuery maxWidth={CONFIGURATION.MAX_MD_WIDTH}>
            {(matches) => {
              if (matches) {
                return(
                  this.props.showNav &&
                  <ul className="top-nav-items">
                    {!this.props.isLoggedIn ?
                      <li className="top-nav-item drop-down">
                          <div className="svg-icon menu"/>
                        <ul className={"drop-down-menu shadow"}>
                          {this.props.showSearchItem &&
                          <li className="top-nav-item drop-down-item">
                            <NavLink activeClassName="active" to={ROUTES.COURSE_LIST}>
                              <div className="svg-icon search mr-2"/>
                              search
                            </NavLink>
                          </li>
                          }

                          <li className="top-nav-item drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.RESOURCES_PAGE}>
                              <div className="svg-icon resources mr-2"/>
                              Resources
                            </NavLink>
                          </li>
                          <li className="top-nav-item drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.MY_COURSES_PAGE}>
                              <div className="svg-icon calculator mr-2"/>
                              Renewal Calc
                            </NavLink>
                          </li>
                          <li className="top-nav-item drop-down-item">
                            <NavLink activeClassName="active" to={ROUTES.CREATE_ACCOUNT}>
                              <div className="svg-icon create-account mr-2"/>
                              Create Account
                            </NavLink>
                          </li>
                          <li className="top-nav-item drop-down-item">
                            <NavLink activeClassName="active" to={ROUTES.LOGIN}>
                              <div className="svg-icon signin mr-2"/>
                              Log In
                            </NavLink>
                          </li>
                          <li className="top-nav-item drop-down-item">
                            <NavLink target="_blank" activeClassName="active" to={ROUTES.SLACAL_WEBSITE}>
                              <div className="svg-icon right-arrow mr-2"/>
                              SLACAL.COM
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      :
                      <li className="top-nav-item drop-down">
                          <div className="svg-icon menu"/>

                        <ul className="drop-down-menu shadow">

                          {this.props.showSearchItem &&
                            <li className="top-nav-item drop-down-item">
                              <NavLink activeClassName="active" to={ROUTES.COURSE_LIST}>
                                <div className="svg-icon search mb-2 mr-2"/>
                                search
                              </NavLink>
                            </li>
                          }

                          <li className="top-nav-item drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.RESOURCES_PAGE}>
                              <div className="svg-icon resources mr-2"/>
                              Resources
                            </NavLink>
                          </li>

                          <li className="top-nav-item drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.MY_COURSES_PAGE}>
                              <div className="svg-icon calculator mr-2"/>
                              Renewal Calc
                            </NavLink>
                          </li>

                          <li className="drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.MY_PROFILE_PAGE}>
                              <div className="svg-icon profile-settings mr-2"/>
                              My Profile
                            </NavLink>
                          </li>
                          <li className="drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.MY_COURSES_PAGE}>
                              <div className="svg-icon my-courses mr-2"/>
                              My Courses
                            </NavLink>
                          </li>
                          {this.props.isLoggedIn &&
                          <li className="drop-down-item">
                            <a onClick={this.logout}>
                              <div className="svg-icon signin mr-2"/>
                              Log Out
                            </a>
                          </li>
                          }
                          </ul>

                      </li>
                    }
                  </ul>);
              } else {
                return(
                  this.props.showNav &&
                  <ul className="top-nav-items">

                    {this.props.showSearchItem &&
                      <li className={"top-nav-item" + (this.props.activeNav === navTabs.SEARCH ? ' active' : '')}>
                        <NavLink activeClassName="active" to={ROUTES.COURSE_LIST}>
                          <div className="svg-icon search mb-2"/>
                          {navTabs.SEARCH}
                        </NavLink>
                        {this.props.showSearchBar &&
                        <CourseSearchBar/>
                        }
                      </li>
                    }

                    <li className={"top-nav-item drop-down" + (this.props.activeNav === navTabs.RESOURCES ? ' active' : '')}>
                      <NavLink className="" activeClassName="active" to={ROUTES.RESOURCES_PAGE}>
                        <div className="svg-icon resources mb-1"/>
                        {navTabs.RESOURCES}
                      </NavLink>

                      <ul className={"drop-down-menu shadow"} style={{"width":425 + 'px'}}>
                        <li className="drop-down-item">
                          <NavLink className="" activeClassName="active" to={ROUTES.NOTICES_PAGE}>
                            <div className="svg-icon info mr-2"/>
                            Notices
                          </NavLink>
                        </li>
                        <li className="drop-down-item">
                          <NavLink className="" activeClassName="active" to={ROUTES.TUTORIALS_PAGE}>
                            <div className="svg-icon video-call mr-2"/>
                            Tutorials
                          </NavLink>
                        </li>
                        <li className="drop-down-item">
                          <NavLink className="" activeClassName="active" to={ROUTES.BULLETINS_PAGE}>
                            <div className="svg-icon pin mr-2"/>
                            Bulletins
                          </NavLink>
                        </li>
                        <li className="drop-down-item">
                          <NavLink className="" activeClassName="active" to={ROUTES.FAQS_PAGE}>
                            <div className="svg-icon faqs mr-2"/>
                            FAQS
                          </NavLink>
                        </li>
                        <li className="drop-down-item">
                          {this.props.pageNodes.length > 0 &&
                            this.props.sectionPageNode &&
                            <NavLink activeClassName="active" to={mapWebsiteLinkPathtoLCLinkPath(this.props.pageNodes[0].relativeUrlPath)}>
                              <span className="svg-icon video-call mr-2"></span>
                              {this.props.sectionPageNode.title}
                            </NavLink>
                          }
                        </li>
                      </ul>
                    </li>

                    <li className={"top-nav-item drop-down-item" + (this.props.activeNav === navTabs.RENEWAL_CALC ? ' active' : '')}>
                      <NavLink className="" activeClassName="active" to={ROUTES.MY_COURSES_PAGE}>
                        <div className="svg-icon calculator mb-2"/>
                        Renewal Calc
                      </NavLink>
                    </li>
                    {!this.props.isLoggedIn ?
                      <li className="top-nav-item">
                        <NavLink activeClassName="active" to={ROUTES.CREATE_ACCOUNT}>
                          <div className="svg-icon create-account mb-2"/>
                          {navTabs.CREATE_ACCOUNT}
                        </NavLink>
                      </li>
                      :
                      <li className="top-nav-item drop-down">
                        <NavLink activeClassName="active" to={ROUTES.MY_PROFILE_PAGE}>
                          <div className="svg-icon account mb-2"/>
                          {this.props.currentUser.firstName + " " + this.props.currentUser.lastName}
                        </NavLink>

                        <ul className="drop-down-menu shadow">
                          <li className="drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.MY_PROFILE_PAGE}>
                              <div className="svg-icon profile-settings mr-2"/>
                              My Profile
                            </NavLink>
                          </li>
                          <li className="drop-down-item">
                            <NavLink className="" activeClassName="active" to={ROUTES.MY_COURSES_PAGE}>
                              <div className="svg-icon my-courses mr-2"/>
                              My Courses
                            </NavLink>
                          </li>
                          {this.props.isLoggedIn &&
                          <li className="drop-down-item">
                            <a onClick={this.logout}>
                              <div className="svg-icon signin mr-2"/>
                              Log Out
                            </a>
                          </li>
                          }
                        </ul>

                      </li>
                    }

                    {!this.props.isLoggedIn &&
                    <li className="top-nav-item">
                      <NavLink activeClassName="active" to={ROUTES.LOGIN}>
                        <div className="svg-icon signin mb-2"/>
                        {navTabs.LOG_IN}
                      </NavLink>
                    </li>
                    }

                  </ul>);
              }
            }
            }
          </MediaQuery>
        </div>
      </nav>
    )
      ;
  }
}

TopNavBar.propTypes = {
  backButtonRoute: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  showSearchItem: PropTypes.bool.isRequired,
  showSearchBar: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  showNav: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  activeNav: PropTypes.string,
  pageNodes: PropTypes.arrayOf(PropTypes.shape(pageNode)),
  sectionPageNode: PropTypes.shape(pageNode),
};

TopNavBar.defaultProps = {
  backButtonRoute: "",
  showBackButton: false,
  showSearchItem: true,
  showSearchBar: false,
  showNav: true
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser,
    pageNodes: state.brokerGuides.pageNodes,
    sectionPageNode: state.brokerGuides.sectionPageNode,
  }
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({},
    lcUserActions,
    brokerGuidesActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNavBar));
