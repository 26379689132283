import * as fetchCreators from './fetchCreators';
import { CONFIGURATION } from '../constants/config';

const brokerGuidesApiUrl = CONFIGURATION.SERVER_API_URL + "/brokerguides";

export function getSection(sectionName) {
  return fetchCreators.get(`${brokerGuidesApiUrl}/getsection/${sectionName}`)
}


export function getPageContentForUrl(url) {
  return fetchCreators.get(`${brokerGuidesApiUrl}/getpagecontent/`, { url: url })
}