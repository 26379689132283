import React from 'react';
import { PropTypes } from 'prop-types';

const CourseOfferingDetailsRow = ({label, data, className}) => {
  return (
    <div className={`row ${className || ''}`}>
      <div className='detail-label'>
        {label &&
          <span>{label}:</span>
        }
      </div>
      <div className='detail-item'>
        {data}
      </div>
    </div>
  );
};

CourseOfferingDetailsRow.propTypes = {
  label: PropTypes.string,
  data: PropTypes.node,
  className: PropTypes.string,
};

export default CourseOfferingDetailsRow
