import React from 'react'
import { CONFIGURATION } from './../../../constants/config';
import { PropTypes } from 'prop-types';

function addressSearchValue(address = {}) {
  return `${address.address1} ${address.address2}, ${address.city}, ${address.state}`
}

function googleMapsUrl(address) {
  return `https://www.google.com/maps/place/${addressSearchValue(address)}`
}

function mapUrl(address) {
  const mapStyles = [
    'feature:administrative|element:labels.text.fill|color:0x444444',
    'feature:landscape|color:0xf2f2f2',
    'feature:poi|visibility:off',
    'feature:road|saturation:-100|lightness:45',
    'feature:road.highway|visibility:simplified',
    'feature:road.arterial|element:labels.icon|visibility:off',
    'feature:transit|visibility:off',
    'feature:water|color:0x01adfa|visibility:on',
  ];

  let params = '?key=' + CONFIGURATION.GOOGLE_MAP_API_KEY
    + '&size=650x325'
    + '&markers=' + 'color:red|' + addressSearchValue(address)
    + '&style=' + mapStyles.join('&style=');

  return 'https://maps.googleapis.com/maps/api/staticmap' + encodeURI(params);
}

class StaticMap extends React.Component {
  constructor() {
    super();
  }

  render() {
    let {address} = this.props;
    return(
      <a href={googleMapsUrl(address)} target="_blank">
        <img className='map' src={mapUrl(address)} style={{height: '100%', width: '100%'}} />
      </a>
    );
  }
}

StaticMap.propTypes = {
  address: PropTypes.object.isRequired,
}

export default StaticMap;
