import PropTypes from 'prop-types';
import courseOffering from '../courses/courseOffering';

export default {

  id: PropTypes.number.isRequired,
  attended: PropTypes.bool.isRequired,
  ceCreditStatus: PropTypes.string,
  ceCreditNotAwardedReason: PropTypes.string,
  certificateStatus: PropTypes.string,
  courseCompletionDate: PropTypes.string,
  courseOffering: PropTypes.shape(courseOffering),
  certificateUrl: PropTypes.string,
}
