import React from 'react'
import PropTypes from 'prop-types';
import notice from "../../proptypes/resource/notice";
import bulletin from '../../proptypes/resource/bulletin';
import NoticesBulletinControls from "./NoticesBulletinControls";
import CarouselIndicators from "./CarouselIndicators";

class NoticeBulletinSlideContent extends React.Component {
  render() {

    return (
      <div className={this.props.className}>

          <React.Fragment>
            <div className='notices-bulletin-title-col'>
              <div className='notices-bulletin-ribbon-type'>
                <span className='notice-icon svg-icon info-lg white'></span>
                {this.props.type == "Notice" ? <React.Fragment>Notice</React.Fragment> : <React.Fragment>Bulletin</React.Fragment>}
              </div>
            </div>
            <div className="notices-bulletins-nav-items-col">
              <div className='notices-bulletins-nav-items-wrapper'>
                <NoticesBulletinControls nextSlide={this.props.nextSlide} prevSlide={this.props.prevSlide} />
                <CarouselIndicators slides={this.props.slides} currentSlide={this.props.currentSlide} setSlide={this.props.setSlide} />
              </div>
            </div>
            <div className='ribbon-title-container'>
              <div className='notices-bulletin-ribbon-title'>
                <div className="text-truncate">
                  <a href={(this.props.type == "Notice") ? ("/resources/notices/" + this.props.notice.id) : (this.props.bulletin.fileUrl)}>
                    {this.props.type == "Notice" ? this.props.notice.title : this.props.bulletin.title}
                  </a>
                </div>
              </div>
            </div>
            <div className='notices-bulletin-go-button'>
              <a href={(this.props.type == "Notice") ? ("/resources/notices/" + this.props.notice.id) : (this.props.bulletin.fileUrl)}>
                <span className='svg-icon go white' />
              </a>
            </div>
          </React.Fragment>
      </div>

    );
  }
}

NoticeBulletinSlideContent.propTypes = {
  className: PropTypes.string,
  notice: PropTypes.shape(notice),
  bulletin: PropTypes.shape(bulletin),
  active: PropTypes.bool,
  prevSlide: PropTypes.func.isRequired,
  nextSlide: PropTypes.func.isRequired,
  slides: PropTypes.array,
  currentSlide: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  setSlide: PropTypes.func.isRequired,
}

export default NoticeBulletinSlideContent;
