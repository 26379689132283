// import loadingStates from "../constants/loadingStates";
import initialState from "../store/initialState";

const selectDomain = (state) => state.lcUser || initialState.lcUser;

export const selectIsLoadingLcUser = (state) =>
  selectDomain(state).isLoadingLcUser;

export const selectPreviouslyLoadedLcUser = (state) =>
  selectDomain(state).previouslyLoadedLcUser;
