import React from 'react';
import slideBg from '../../assets/Carousel-2.png';
import slideBgMobile from "../../assets/Carousel-2-mobile.png";
import ResourcesButton from '../resources/ResourcesButton';
import CarouselSlide from './CarouselSlide';
import { PropTypes } from 'prop-types';

class LandingPageResourcesSlide extends React.Component {
  render() {
    return(
      <CarouselSlide backgroundUrl={slideBg}
                     mobileBackgroundUrl={slideBgMobile}
                     active={this.props.active}>
        <div className={this.props.active ? '' : 'row'}>
          <div className={this.props.active ? '' : 'col offset-md-4'}>
            <h1><span>Resources to Help</span><span>with Compliance</span></h1>
            <p className="landing-subtitle">
              Our Resources section provides quick and easy access to reference materials, 
              such as bulletins, frequently asked questions, and tutorials on basic filing procedures and our electronic portal SLIP.
            </p>
            <div className="slide-buttons">
                <ResourcesButton/>
            </div>
          </div>
        </div>
      </CarouselSlide>
    );
  }
}

LandingPageResourcesSlide.propTypes = {
  active: PropTypes.bool,
}

LandingPageResourcesSlide.defaults = {
  active: false,
}

export default LandingPageResourcesSlide;