export const userCompanyType = Object.freeze({
  EXCESS_SURPLUS_LINES: 'Excess/Surplus Lines',
  RETAIL_AGENCY: 'Retail Agency',
  MGA: 'MGA',
  BROKERAGE: 'Brokerage',
  INSURANCE_COMPANY: 'Insurance Company',
  ADJUSTING: 'Adjusting'
});

export const userCompanyTypes = [
  userCompanyType.EXCESS_SURPLUS_LINES,
  userCompanyType.RETAIL_AGENCY ,
  userCompanyType.MGA,
  userCompanyType.BROKERAGE,
  userCompanyType.INSURANCE_COMPANY,
  userCompanyType.ADJUSTING
];

export const userCompanyTypeSelectOptions = userCompanyTypes.map(t => {
  return {
    text: t,
    value: t,
    selected: false,
    disabled: false
  }
});
