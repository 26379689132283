import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from "react-collapsible";
import moment from 'moment';
import notice from '../../proptypes/resource/notice';
import { trackUserEvent } from '../../utilities/analyticsUtilities';

class Notice extends React.Component {

  constructor(props) {
    super(props);

    this.captureAnalytics = this.captureAnalytics.bind(this);
  }

  captureAnalytics(noticeTitle) {
    trackUserEvent({
      category: 'Notices',
      action: 'Expand',
      label: noticeTitle
    });
  }

  render() {

    return (
      <React.Fragment>
        <Collapsible className={"collapsible-enhanced"}
        openedClassName={"collapsible-enhanced"}
        onOpening={() => this.captureAnalytics(this.props.notice.title)} open={this.props.open} trigger={(
          <React.Fragment>
            <span>{this.props.notice.title}</span>
            <small className="collapsible-trigger-meta">{moment(this.props.notice.publishDate).format("MMMM DD, YYYY")}</small>
          </React.Fragment>
        )}
        >
          <div className='notice-body generic-content' dangerouslySetInnerHTML={{ __html: this.props.notice.description }}>
          </div>
        </Collapsible>
      </React.Fragment>
    )
  }
}

Notice.propTypes = {
  notice: PropTypes.shape(notice).isRequired,
  open: PropTypes.bool
};

export default Notice;