import React from "react";
import ReactHtmlParser, { processNodes } from "react-html-parser";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import Collapsible from "react-collapsible";
import Faq from "../resources/Faq";
import { mapWebsiteLinkPathtoLCLinkPath, isLCPath } from "../../utilities/routeUtilities";

function transform(node) {
  // Convert a tag to Link tag and map to broker Guides path.
  if (node.name === 'a'
    && node.attribs
    && node.attribs.href
    && !node.attribs.href.startsWith("#")) {
    let lcLinkPath = mapWebsiteLinkPathtoLCLinkPath(node.attribs.href);
    return <Link
      key={node.attribs.href}
      target={isLCPath(lcLinkPath) ? undefined : "_blank"}
      to={lcLinkPath} >
      {node.children[0].data}
    </Link >
  }

  // Convert FAQ layouts to FAQ component.
  if (node.name === 'div' && node.attribs.class === 'faq') {
    let question;
    let answer;

    node.children.map((childNode) => {
      if (childNode.name === 'div' && (/.*faq-question.*/).test(childNode.attribs.class)) {
        question = childNode.children[0].data;
      }
      if (childNode.name === 'div' && (/.*faq-answer.*/).test(childNode.attribs.class)) {
        answer = processNodes(childNode.children, transform);
      }
    });

    return (
      <Faq question={question} answer={answer} key={question} open={false} />
    );
  }

  // Convert collapsible layouts to a Collapsible component.
  if (node.name === 'div' && node.attribs.class === 'collapsible') {
    let collapsibleHeaderText;
    let collapsibleContent;
    let collapsibleId;

    node.children.map((childNode) => {
      if (childNode.name === 'div' && (/.*collapsible-header.*/).test(childNode.attribs.class)) {
        collapsibleHeaderText = childNode.children[0].data;
        collapsibleId = childNode.attribs.id;
      }
      if (childNode.name === 'div' && (/.*collapsible-content.*/).test(childNode.attribs.class)) {
        collapsibleContent = processNodes(childNode.children, transform);
      }
    });

    return (
      <Collapsible key={collapsibleId} trigger={collapsibleHeaderText} contentInnerClassName="hoist-first-item">
        {collapsibleContent}
      </Collapsible>
    );
  }

  // Apply CSS classes to tables for proper styling.
  if (node.name === 'table' && (!node.attribs.class || node.attribs.class.indexOf('table') < 0)) {
    node.attribs.class = (node.attribs.class || '') + " table table-bordered";
  }

  // Apply proper styling for images.
  if (node.name === 'img') {
    node.attribs.style = (node.attribs.style || '') + " max-width: 100%;";
  }

  // Apply responsive display to embedded YouTube videos
  //
  // Sitefinity generates markup with the following pattern:
  // <div data-sf-ec-immutable='' style='...'>
  //   <div data-sf-disable-link-event="">
  //     <div sf-youtube-url="...">
  //       <iframe allowfullscreen="1" src="..." width="640" height="360" frameborder="0"></iframe>
  //     </div>
  //   </div>
  // </div>
  if (node.name === 'div'
    && node.attribs
    && node.attribs['data-sf-ec-immutable'] != undefined
    && node.children.length > 0
    && node.children[0].children.length > 0
    && node.children[0].children[0].attribs
    && node.children[0].children[0].attribs["sf-youtube-url"]
    && node.children[0].children[0].children.length > 0
    && node.children[0].children[0].children[0].name === 'iframe') {

    let frameContainer = node.children[0].children[0];
    let frame = frameContainer.children[0];

    frameContainer.attribs.class = (node.attribs.class || '') + " embed-responsive embed-responsive-16by9";
    frame.attribs.width = '';
    frame.attribs.height = '';
    frame.attribs.class = (node.attribs.class || '') + " embed-responsive-item";

    let embeddedFrameContent = processNodes([frameContainer], transform);
    let youtubeUrl = frameContainer.attribs["sf-youtube-url"]

    return (
      <React.Fragment key={youtubeUrl}>
        {embeddedFrameContent}
      </React.Fragment>
    );
  }
}

const options = {
  decodeEntities: true,
  transform
};

const TransformElement = props => {
  return <div className="ConvertedContent">{ReactHtmlParser(props.element, options)}</div>;
};

TransformElement.propTypes = {
  element: PropTypes.string,
};

export default withRouter(TransformElement);
