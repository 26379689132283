import React from "react";
import SvgIcon from "../icon/SvgIcon";
import PropTypes from "prop-types";
import { getSeriesPage } from "../../constants/routeConfig";
import { withRouter, NavLink } from "react-router-dom";
import series from "../../proptypes/resource/series";

class SeriesCard extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <NavLink className={"series-card"} to={getSeriesPage(this.props.series.title, this.props.series.id)}>
        <div className={"top-right-triangle"}>
          <SvgIcon icon={"pin"} color={"white"} />
        </div>
        <div className={"series-text"}>{this.props.series.title} Series</div>
        <div className={"series-date"}>{this.props.series.description}</div>
      </NavLink>
    );
  }
}

SeriesCard.propTypes = {
  series: PropTypes.shape(series).isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(SeriesCard);
