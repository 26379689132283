import { BROKER_GUIDE_SECTION_NAMES } from "../constants/brokerGuides";
import { ROUTES, EXTERNAL_ROUTES } from "../constants/routeConfig";
import { CONFIGURATION } from '../constants/config';

function truncateHostname(path, hostname) {
  let result;

  if (path.startsWith(`${hostname}`)
    || path.startsWith(`http://${hostname}`)
    || path.startsWith(`https://${hostname}`)
    || path.startsWith(`//${hostname}`)) {

    result = path.substring(path.indexOf(hostname) + hostname.length)
  } else {
    result = path;
  }

  return result;
}

/**
 * Converts a path for a link defined in website content and transforms
 * it into an appropriate path for use in a link in the Learning Center.
 * @param {string} websiteLinkPath - The website path
 * @returns The path as it should be rendered in the LC
 */
export function mapWebsiteLinkPathtoLCLinkPath(websiteLinkPath) {

  let mappedPath;
  if (websiteLinkPath.startsWith(EXTERNAL_ROUTES.WEBSITE_FILING_REQUIREMENTS_PROCEDURES_CONTENT_ROOT)) {
    // The path is to a broker guide page, so map it to the corresponding LC broker
    // guide page path
    mappedPath = websiteLinkPath.replace(
      EXTERNAL_ROUTES.WEBSITE_FILING_REQUIREMENTS_PROCEDURES_CONTENT_ROOT,
      ROUTES.FILING_REQUIREMENTS_PROCEDURES_PAGE);
  } else if (websiteLinkPath.startsWith("/")) {
    // The path is a relative path to a website page outside of the broker guides,
    // so convert the relative path to an absolute path to ensure the user is
    // navigated ot the website instead of an LC path
    mappedPath = CONFIGURATION.WEBSITE_URL + websiteLinkPath;
  } else if (isLCPath(websiteLinkPath)) {
    // The path is an LC page path, so we need to remove the domain if present.
    // If we don't, then then <Link> component will append the path to the
    // current URL instead of replacing it.
    mappedPath = truncateHostname(websiteLinkPath, CONFIGURATION.LC_HOST);
  } else {
    mappedPath = websiteLinkPath;
  }

  return mappedPath;
}

export function isLCPath(path) {
  let isLCPath = path.startsWith(`${CONFIGURATION.LC_HOST}`)
    || path.startsWith(`http://${CONFIGURATION.LC_HOST}`)
    || path.startsWith(`https://${CONFIGURATION.LC_HOST}`)
    || (path.startsWith(`/`) && !path.startsWith(`//`))
    || path.startsWith(`//${CONFIGURATION.LC_HOST}`)
    || path.startsWith(`#`);

  return isLCPath;
}

export function mapLCBrokerGuidePathToWebsitePath(childPagePath) {
  if (childPagePath.startsWith(ROUTES.FILING_REQUIREMENTS_PROCEDURES_PAGE)) {
    return childPagePath.replace(
      ROUTES.FILING_REQUIREMENTS_PROCEDURES_PAGE,
      EXTERNAL_ROUTES.WEBSITE_FILING_REQUIREMENTS_PROCEDURES_CONTENT_ROOT);
  }

  return '';
}

export function mapLCBrokerGuidePathToSection(childPagePath) {
  if (childPagePath.startsWith(ROUTES.FILING_REQUIREMENTS_PROCEDURES_PAGE)) {
    return BROKER_GUIDE_SECTION_NAMES.FILING_REQUIREMENTS_PROCEDURE;
  }

  return '';
}
