import React from 'react';
import { PropTypes } from 'prop-types';

import { watchCourse } from './../../utilities/courseUtilities';
import { getUserCourseRegistration } from '../../api/courseApi';

import SimpleContentPageLayout from "../layout/SimpleContentPageLayout";

class CourseViewPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      courseCompleted: false,
      notRegistered: false
    };
  }

  componentDidMount() {
    getUserCourseRegistration(this.props.match.params.courseRegistrationId)
      .then((courseRegistration) => {
        if (courseRegistration == null) {
          this.setState({
            notRegistered: true
          });
        }
        else if (courseRegistration.attended == true) {
          this.setState({
            courseCompleted: true
          });
        }
        else {
          watchCourse(this.props.match.params.courseRegistrationId);
        }
      });
  }

  render() {
    return (
      <SimpleContentPageLayout
        documentTitle="Watch Course"
        headerTitle="Watch Course">

        <div className='course-view'>
          {this.state.courseCompleted &&
            <h5>You have already completed this course.</h5>
          }
          {this.state.notRegistered &&
            <h5>You are not currently registered for this course.</h5>
          }
          {!this.state.courseCompleted && !this.state.notRegistered &&
            <h5>Redirecting to the Course Player...</h5>
          }
        </div>

      </SimpleContentPageLayout>
    );
  }
}

CourseViewPage.propTypes = {
  match: PropTypes.object.isRequired,
}

export default CourseViewPage;