import initialState from "../store/initialState";
import * as brokerGuidesApi from '../api/brokerGuidesApi';
import { throwError, postError } from './errorActions';

export const actionTypes = Object.freeze({
  GET_SECTION: 'GET_SECTION',
  GET_PAGE_CONTENT: 'GET_PAGE_CONTENT',
});

export function getSection(sectionName) {
  return dispatch => {
    return brokerGuidesApi.getSection(sectionName)
      .then(result => {
        dispatch({
          type: actionTypes.GET_SECTION,
          data: {
            sectionName: sectionName,
            sectionPageNode: result.sectionPage,
            pageNodes: result.childPages,
          },
        });
        return { ok: true }
      })
      .catch((error) => {

        let errorMessage = `Error loading section ${sectionName}`;

        if (error) {
          errorMessage = `${errorMessage}\n${error.toString()}\n${JSON.stringify(error)}`;
        }
        
        dispatch(postError(
          errorMessage,
          {
            user: {
              userName: ""
            },
            route: "",
            environment: {
              appCodeName: window.navigator.appCodeName,
              appName: window.navigator.appName,
              appVersion: window.navigator.appVersion,
              cookieEnabled: window.navigator.cookieEnabled,
              language: window.navigator.language,
              platform: window.navigator.platform,
              product: window.navigator.product,
              productSub: window.navigator.productSub,
              userAgent: window.navigator.userAgent,
              vendor: window.navigator.vendor,
              vendorSub: window.navigator.vendorSub,
            },
          },
          error ? error.stack : ''
        ))
        
        return { ok: false }
      });
  };
}

export function clearSelectedPage() {
  return {
    type: actionTypes.GET_PAGE_CONTENT,
    data: initialState.brokerGuides.pageContent,
  }
}

export function getPageContentForUrl({ url, shouldThrowError = false } = {}) {
  return dispatch => {
    return brokerGuidesApi.getPageContentForUrl(url)
      .then(pageContent => {
        dispatch({
          type: actionTypes.GET_PAGE_CONTENT,
          data: pageContent,
        });
        return { ok: true }
      })
      .catch(error => {
        if (shouldThrowError) {
          dispatch(throwError(error, { source: 'brokerGuidesActions.getPageContentForUrl', url }))
        }
        return { ok: false }
      });
  };
}
