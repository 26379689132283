
const courseOfferingMethod = Object.freeze({
  ALL: 'A',
  LIVE: 'L',
  ON_DEMAND: 'O'
});

export const CourseOfferingMethods = [
  courseOfferingMethod.ALL,
  courseOfferingMethod.LIVE,
  courseOfferingMethod.ON_DEMAND
];

export default courseOfferingMethod;
