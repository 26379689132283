import React from "react";
import PropTypes from 'prop-types';

const ProgressBar = ({ min, max, current, showLabel }) => {
  return (
    <div className='progress'>
      <div className='progress-bar' role='progressbar' style={{width: (current/max) * 100 + '%'}} aria-valuenow={current} aria-valuemin={min} aria-valuemax={max}>
        {showLabel &&
            (current/max) * 100 + '%'
        }
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    showLabel: PropTypes.bool,
};

ProgressBar.defaultProps = {
  showLabel: false,
};

export default ProgressBar;