import React from 'react'
import { PropTypes } from 'prop-types';
import CreditStatusTag from './CreditStatusTag';
import CertificateStatusTag from './CertificateStatusTag';

class CompletedCourseOfferingTags extends React.Component {
  render() {
    let {courseRegistration} = this.props;

    return(
      <div>
        <CreditStatusTag courseRegistration={courseRegistration} />
        <CertificateStatusTag courseRegistration={courseRegistration} />
      </div>
    );
  }
}

CompletedCourseOfferingTags.propTypes = {
  courseRegistration: PropTypes.object,
}

export default CompletedCourseOfferingTags;