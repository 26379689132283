import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ROUTES } from "../../constants/routeConfig";

import SimpleContentPageLayout from "../layout/SimpleContentPageLayout";
import LoginForm from "./LoginForm";

class Login extends React.Component {
  constructor(props) {
    super(props);
    if (props.isLoggedIn) {
      props.history.push(ROUTES.LANDING_PAGE);
    }
  }

  render() {
    return (
      <SimpleContentPageLayout
        documentTitle="Sign In"
        headerTitle="Sign In">

        <div className="row">
          <div className="col offset-sm-2 col-sm-8 offset-md-1 col-md-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6">
            <LoginForm blue createAccountRedirect={ROUTES.LANDING_PAGE} />
          </div>
        </div>

      </SimpleContentPageLayout>
    );
  }
}

Login.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.object
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn
  };
}

export default connect(mapStateToProps, null)(Login);
