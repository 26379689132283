import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";

import * as lcUserActions from '../../actions/lcUserActions';
import * as locationActions from '../../actions/locationActions';
import * as formUtilities from "../../utilities/formUtilities";
import { userCompanyTypeSelectOptions } from "../../proptypes/users/userCompanyTypes";
import initialState from "../../store/initialState";

import Textbox from "../inputs/Textbox";
import Button from "../inputs/Button";
import Select from "../inputs/Select";
import SvgIcon from '../icon/SvgIcon';
import Checkbox from '../inputs/Checkbox';

import LicenseDatePicker from "../inputs/LicenseDatePicker";
import * as courseActions from '../../actions/courseActions';
import PasswordField from "./PasswordField";
import { ROUTES } from "../../constants/routeConfig";
import moment from 'moment';
import ReactTooltip from "react-tooltip";

class CreateAccountForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      confirmPassword: "",
      confirmPasswordSuccess: false,
      passwordSuccess: false,
      usernameSuccess: false,
      firstNameSuccess: this.props.user.firstName ? !formUtilities.formElementHasError(this.props.user.firstName) : false,
      lastNameSuccess: this.props.user.lastName ? !formUtilities.formElementHasError(this.props.user.lastName) : false,
      addressOneSuccess: this.props.user.addressOne ? !formUtilities.formElementHasError(this.props.user.addressOne) : false,
      emailAddressSuccess: this.props.user.emailAddress ?
        !formUtilities.formElementHasError(this.props.user.emailAddress) && formUtilities.validateEmail(this.props.user.emailAddress)
        :
        false,
      cityAdrSuccess: this.props.user.city ? !formUtilities.formElementHasError(this.props.user.city) : false,
      stateSuccess: false,
      zipSuccess: this.props.user.zip ? !formUtilities.formElementHasError(this.props.user.zip) : false,
      phoneNumberSuccess: this.props.user.phoneNumber ? !formUtilities.formElementHasError(this.props.user.phoneNumber) : false,
      licenseNumberSuccess: false,
      licenseJurisdictionSuccess: false,

      usernameError: false,
      passwordError: false,
      confirmPasswordError: false,
      firstNameError: this.props.user.firstName ? formUtilities.formElementHasError(this.props.user.firstName) : false,
      lastNameError: this.props.user.firstName ? formUtilities.formElementHasError(this.props.user.firstName) : false,
      addressOneError: this.props.user.addressOne ? formUtilities.formElementHasError(this.props.user.addressOne) : false,
      cityAdrError: this.props.user.city ? formUtilities.formElementHasError(this.props.user.city) : false,
      stateError: false,
      zipError: this.props.user.zip ? formUtilities.formElementHasError(this.props.user.zip) : false,
      emailAddressError: this.props.user.emailAddress ?
        formUtilities.formElementHasError(this.props.user.emailAddress) || !formUtilities.validateEmail(this.props.user.emailAddress)
        :
        false,
      phoneNumberError: this.props.user.phoneNumber ? formUtilities.formElementHasError(this.props.user.phoneNumber) : false,
      licenseNumberError: false,
      licenseJurisdictionError: false,

      agreedToTerms: false,
      guestAcknowledgment: false,
      licenseFlag: false,
      licenseNumberWarning: "",
      showJurisdictionConfirm: false,
      showLicenseEffectiveDateConfirm: false,
      entityLicenseEntered: false,
    };

    this.changeForm = this.changeForm.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.toggleLicenseField = this.toggleLicenseField.bind(this);
    this.handleEffDateChange = this.handleEffDateChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.props.actions.GetStates();
  }

  toggleLicenseField() {
    this.setState({ licenseFlag: !this.state.licenseFlag });
    
    let updatedUser = objectAssign({}, this.props.user);
    updatedUser.licenseNumber = "";
    updatedUser.licenseJurisdiction = "";
    updatedUser.licenseEffDate = null;
    updatedUser.nonResidentInd = null;
    this.props.actions.changeLcUser(updatedUser);

    if (this.state.licenseFlag) {
      let error = !formUtilities.validLicenseNumber(this.props.user.licenseNumber);
      this.setState({ licenseNumberError: error, licenseNumberSuccess: !error, licenseNumberWarning: "",
                      licenseJurisdictionError: true, licenseJurisdictionSuccess: false,
                      licenseEffDateError: error, licenseEffDateSuccess: !error,
                      showJurisdictionConfirm: false, showLicenseEffectiveDateConfirm: false });

    }
    else {
      this.setState({ licenseNumberError: false, licenseNumberSuccess: true,
                      licenseJurisdictionError: false, licenseJurisdictionSuccess: true ,
                      licenseEffDateError: false, licenseEffDateSuccess: true,
                      showJurisdictionConfirm: false, showLicenseEffectiveDateConfirm: false });
    }
  }

  changePassword(event) {

    if (event.target.id === "password") {

      if (!formUtilities.formElementHasError(event.target.value)) {
        let formError = formUtilities.validatePassword(event.target.value) && event.target.value === this.state.confirmPassword;
        this.setState({
          passwordError: !formError,
          passwordSuccess: formError,
          confirmPasswordError: this.state.confirmPassword !== "" && event.target.value !== this.state.confirmPassword,
          confirmPasswordSuccess: this.state.confirmPassword !== "" && event.target.value === this.state.confirmPassword
        });
      } else {
        this.setState({
          passwordError: true,
          passwordSuccess: false,
        });
      }

      let changedEnrollForm = objectAssign({}, this.props.user);
      changedEnrollForm[event.target.id] = event.target.value;
      this.props.actions.changeLcUser(changedEnrollForm);

    } else if (event.target.id === "confirmPassword") {
      let confirmPasswordError = !formUtilities.formElementHasError(event.target.value) && (formUtilities.validatePassword(event.target.value) ? event.target.value !== this.props.user.password : true);
      this.setState({
        confirmPassword: event.target.value,
        confirmPasswordSuccess: !confirmPasswordError,
        confirmPasswordError: confirmPasswordError,
        passwordError: this.props.user.password !== "" && event.target.value !== this.props.user.password,
        passwordSuccess: this.props.user.password !== "" && event.target.value === this.props.user.password
      });
    }
  }

  changeForm(event) {
    let error = false;
    switch (event.target.id) {
      case "userName":
        error = formUtilities.formElementHasError(event.target.value) || event.target.value.length < 5;
        this.setState({ usernameError: error, usernameSuccess: !error });
        break;
      case "firstName":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ firstNameError: error, firstNameSuccess: !error });
        break;
      case "lastName":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ lastNameError: error, lastNameSuccess: !error });
        break;
      case "addressOne":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ addressOneError: error, addressOneSuccess: !error });
        break;
      case "city":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ cityAdrError: error, cityAdrSuccess: !error });
        break;
      case "zip":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ zipError: error, zipSuccess: !error });
        break;
      case "state":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ stateError: error, stateSuccess: !error });
        break;
      case "phoneNumber":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ phoneNumberError: error, phoneNumberSuccess: !error });
        break;
      case "emailAddress":
        error = formUtilities.formElementHasError(event.target.value) || !formUtilities.validateEmail(event.target.value);
        this.setState({ emailAddressError: error, emailAddressSuccess: !error });
        break;
      case "licenseNumber":
        error = formUtilities.formElementHasError(event.target.value) || !formUtilities.validLicenseNumber(event.target.value);
        // In addition to setting license number errors, clear out state variables for licenseNumberWarning and entityLicenseEntered.
        // These variables will be set to the appropriate values in the onBlur event as needed
        this.setState({ licenseNumberError: error, licenseNumberSuccess: !error, licenseNumberWarning: "", entityLicenseEntered: false });

        // Clear out any errors on license jurisdiction input if it has a value. Errors will be re-applied if necessary in the onBlur event
        var hasLicenseJurisdiction = this.props.user.licenseJurisdiction != "";

        this.setState({ 
          licenseJurisdictionError: !hasLicenseJurisdiction && this.state.licenseJurisdictionError, 
          licenseJurisdictionSuccess: hasLicenseJurisdiction
        });
        break;
      case "licenseJurisdiction":
        error = formUtilities.formElementHasError(event.target.value);
        this.setState({ licenseJurisdictionError: error, 
          licenseJurisdictionSuccess: !error, 
          showJurisdictionConfirm: false,
          showLicenseEffectiveDateConfirm: false });

          var hasLicenseNum = this.props.user.licenseNumber != "";
          var hasInvalidLicenseNumError = this.state.licenseNumberError && !this.state.entityLicenseEntered;
          var hasEntityLicenseNumError = (event.target.value == "CA" || event.target.value == "") && this.state.entityLicenseEntered;

          // Show license num error if there is an entity license number error (entity license # + "CA" or no jurisdiction)
          // Or continue showing current license num error
          var hasLicenseNumError = hasInvalidLicenseNumError || hasEntityLicenseNumError;
          this.setState({ licenseNumberError: hasLicenseNumError, licenseNumberSuccess: !hasLicenseNumError && hasLicenseNum });

          if (!error) {
            // Also show error for jurisdiction if there is an entity license number error
            this.setState({ licenseJurisdictionError: hasEntityLicenseNumError, licenseJurisdictionSuccess: !hasEntityLicenseNumError });
          }
        break;
    }

    let updatedUser = objectAssign({}, this.props.user);
    updatedUser[event.target.id] = event.target.value;

    if (updatedUser.licenseJurisdiction) {
      updatedUser.nonResidentInd = this.props.user.licenseJurisdiction == "CA" ? "N" : "Y";
    }
    else {
      updatedUser.nonResidentInd = "N";
    }
    this.props.actions.changeLcUser(updatedUser);
  }

  handleEffDateChange(date) { // date is a Moment.js date
    let updatedUser = objectAssign({}, this.props.user);
    updatedUser.licenseEffDate = date;
    this.props.actions.changeLcUser(updatedUser);
    this.setState({ showLicenseEffectiveDateConfirm: false, showJurisdictionConfirm: false });
  }

  onCreateClick(user) {
    if (this.props.isGuestForm) {
      let self = this;
      self.props.actions.addGuest(user)
      .then((result) => {

        self.props.actions.registerForCourses(self.props.cart, objectAssign({}, user, result));
        self.props.history.push(self.props.createAccountRedirect);
        self.props.actions.setCreateAccountRedirect(
          initialState.lcUser.createAccountRedirect
        );
      })
      .catch((error) => toast.error(error.message));
    } else {
      //if the user form has an id then it is a guest setting up their account
      let postAction = this.props.user.id ? this.props.actions.setupGuest : this.props.actions.addLcUser;

      // change License Effective Date to UTC
      const editedUser = { ...user };
      editedUser.licenseEffDate = moment.utc(user.licenseEffDate);

      postAction(editedUser).then(() => {
        this.props.actions.login({
          email: editedUser.emailAddress,
          password: editedUser.password
        }).then((user) => {

          this.props.actions.registerForCourses(
            this.props.cart,
            postAction === this.props.actions.setupGuest ? user : undefined
          )
            .catch((error) => toast.error(error.message));

          this.props.history.push(this.props.createAccountRedirect);
          this.props.actions.setCreateAccountRedirect(initialState.lcUser.createAccountRedirect);
        });
      })
        .catch((error) => toast.error(error.message));
    }
  }

  onBlur(event) {
    switch (event.target.id) {
      case "licenseNumber":
        if (event.target.value && !this.state.licenseNumberError) {
          return this.props.actions.validateLicenseNumber(event.target.value, this.props.user.id).then((validationResult) => {
            if (validationResult.success === false) {
              let showLicenseError = this.props.user.licenseJurisdiction == "CA" || !this.props.user.licenseJurisdiction;

              this.setState({ licenseNumberError: showLicenseError, licenseNumberSuccess: !showLicenseError, 
                licenseNumberWarning: validationResult.message, entityLicenseEntered: true, 
                licenseJurisdictionError: showLicenseError, licenseJurisdictionSuccess: !showLicenseError });
            }
            else {
              this.setState({ licenseNumberWarning: "", entityLicenseEntered: false })

              if (validationResult.data &&
                  validationResult.data.isIndividualLicense &&
                  validationResult.data.licenseJurisdiction == "CA") {

                let updatedUser = objectAssign({}, this.props.user);

                let updatedJurisdiction = validationResult.licenseJurisdiction ? validationResult.licenseJurisdiction : "CA";

                updatedUser["licenseJurisdiction"] = updatedJurisdiction;

                this.setState({ licenseJurisdictionSuccess: true, licenseJurisdictionError: false, showJurisdictionConfirm: true });

                if (validationResult.data.effectiveDate) {
                  updatedUser["licenseEffDate"] = validationResult.data.effectiveDate;

                  this.setState({ showLicenseEffectiveDateConfirm: true, licenseEffDateSuccess: true, licenseEffDateError: false });
                }

                this.props.actions.changeLcUser(updatedUser);
              }
            }
          });
        }
    }
  }

  render() {

    let guestFormValid = this.state.firstNameSuccess && this.state.lastNameSuccess && this.state.emailAddressSuccess && this.state.addressOneSuccess
      && this.state.cityAdrSuccess && this.state.zipSuccess && this.state.phoneNumberSuccess && this.state.guestAcknowledgment
      && (this.state.licenseFlag || (this.state.licenseNumberSuccess && this.state.licenseJurisdictionSuccess));
    let accountFormValid = this.state.usernameSuccess && this.state.passwordSuccess && this.state.confirmPasswordSuccess && this.state.firstNameSuccess
      && this.state.lastNameSuccess && this.state.emailAddressSuccess && this.state.addressOneSuccess
      && this.state.cityAdrSuccess && this.state.zipSuccess && this.state.phoneNumberSuccess && this.state.agreedToTerms && (this.state.licenseNumberSuccess || this.state.licenseFlag) && (this.state.licenseJurisdictionSuccess || this.state.licenseFlag);
    let continueDisabled = this.props.isGuestForm ? !guestFormValid : !accountFormValid;
    let shouldShowLicenseJurisdictionWarning = this.props.user.licenseJurisdiction != "CA" && this.props.user.licenseJurisdiction != "";
    let autoPopDataTooltipText = "We have pre-populated this field with information from the California license database. Please take a moment to review this information for accuracy.";

    return (
      <form className="create-account-form">
        {!this.props.isGuestForm &&
          <React.Fragment>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <Textbox id={"userName"} label={"Username *"} errorText={<div className={"field-error-text float-up"}><p>Username must be at least 5 characters long. *</p></div>} isSuccess={this.state.usernameSuccess} isError={this.state.usernameError} value={this.props.user.userName}
                    onChange={this.changeForm} />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <PasswordField change={this.changePassword} id={"password"} passwordLabel={"Password *"} password={this.props.user.password} isConfirmField={true} confirmPassword={this.state.confirmPassword} passwordSuccess={this.state.passwordSuccess} passwordError={this.state.passwordError} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <PasswordField id={"confirmPassword"} passwordError={this.state.confirmPasswordError} passwordSuccess={this.state.confirmPasswordSuccess} passwordLabel={"Confirm Password *"} password={this.state.confirmPassword} isConfirmField={true} confirmPassword={this.props.user.password} change={this.changePassword} />
                </div>
              </div>
            </div>

            <hr />

          </React.Fragment>
        }

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <Textbox id={"firstName"} label={"First Name *"} isSuccess={this.state.firstNameSuccess} isError={this.state.firstNameError} value={this.props.user.firstName}
                onChange={this.changeForm} />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <Textbox id={"middleName"} label={"Middle Name"} value={this.props.user.middleName}
                onChange={this.changeForm} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <Textbox id={"lastName"} label={"Last Name *"} isSuccess={this.state.lastNameSuccess} isError={this.state.lastNameError} value={this.props.user.lastName}
                onChange={this.changeForm} />
            </div>
          </div>
        </div>

        <hr />
        
        <div className=" row mb-4">
          <div className="col-sm-12 col-md-6" >
            <Checkbox id="licenseFlag" checked={this.state.licenseFlag}
                      label="I do not have an individual license"
                      disabled={false}
                      labelClassName="license-flag-label"
                      onChange={() => this.toggleLicenseField()} />
          </div>
          <div className="col-sm-12">
            <small><SvgIcon icon="info" color="green" /> Please note that you don’t need a license to take Learning Center courses. Anyone can take a course!</small>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
             <Textbox id={"licenseNumber"} label={"Individual License #"}
             isSuccess={this.state.licenseNumberSuccess} isError={this.state.licenseNumberError} value={this.props.user.licenseNumber} disabled={this.state.licenseFlag}
                onChange={this.changeForm} onBlur={this.onBlur} />
              <a href="https://pdb.nipr.com/my-nipr/frontend/identify-licensee" className="create-account-link" target="_blank" rel="noopener noreferrer">Don&#39;t know your license #?</a>
              { this.state.licenseNumberError &&
                this.state.licenseNumberWarning &&
                <div className="license-warning">{this.state.licenseNumberWarning}</div>
              }
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
            <Select id={"licenseJurisdiction"} label={"License Jurisdiction"}
              isSuccess={this.state.licenseJurisdictionSuccess} isError={this.state.licenseJurisdictionError}
              value={this.props.user.licenseJurisdiction} defaultOptionText={"License Jurisdiction"} hideLabel options={this.props.states} disabled={this.state.licenseFlag}
              onChange={this.changeForm} />
            { shouldShowLicenseJurisdictionWarning &&
              <span className="license-warning">WARNING: Only California Resident Licensees are eligible for California CE Credits. 
              To receive CE credit for this course, you must submit course materials to your jurisdiction’s department of insurance.
              <NavLink to={ROUTES.CECREDITREFERENCE_PAGE} target="_blank">
               <span > View More</span> </NavLink>
              </span>
            }
            { this.state.showJurisdictionConfirm &&
              <div className="confirm-info-icon-container">
                <a data-tip={autoPopDataTooltipText} data-for="confirm-jurisdiction-tooltip">
                  <span className="svg-icon alert"></span>
                  <span className="confirm-info">Does this look right?</span>
                </a>
                <ReactTooltip id="confirm-jurisdiction-tooltip" className="confirm-info-tooltip" place="bottom" type="dark" effect="solid" />
              </div>
            }
            </div>
          </div>


          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">
              <LicenseDatePicker
                placeholderText={"License Effective Date"} 
                id={"licenseEffDate"}            
                isSuccess={this.state.licenseEffDateSuccess}
                isError={this.state.licenseEffDateError}
                disabled={this.state.licenseFlag}
                selected={this.props.user.licenseEffDate ? moment(this.props.user.licenseEffDate) : undefined}
                onChange={this.handleEffDateChange}
              />
              { this.state.showLicenseEffectiveDateConfirm &&
                <div className="confirm-info-icon-container">
                  <a data-tip={autoPopDataTooltipText} data-for="confirm-eff-date-tooltip">
                    <span className="svg-icon alert"></span>
                    <span className="confirm-info">Does this look right?</span>
                  </a>
                  <ReactTooltip id="confirm-eff-date-tooltip" className="confirm-info-tooltip" place="bottom" type="dark" effect="solid" />
                </div>
              }
            </div>
          </div>
         
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <Textbox id={"addressOne"} isError={this.state.addressOneError} isSuccess={this.state.addressOneSuccess} label={"Address 1 *"} value={this.props.user.addressOne}
                onChange={this.changeForm} />
            </div>
            <div className="form-group">
              <Textbox id={"addressTwo"} label={"Address 2"} value={this.props.user.addressTwo}
                onChange={this.changeForm} />
            </div>
            <div className='row city-state-zip-row'>
              <div className="form-group col-sm-12 col-md-12 col-lg-5 d-inline-block">
                <Textbox isError={this.state.cityAdrError} isSuccess={this.state.cityAdrSuccess} id={"city"} label={"City *"}
                  value={this.props.user.city}
                  onChange={this.changeForm} />
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-3 d-inline-block">
                <Select id={"state"} label={"St *"}
                  value={this.props.user.state} defaultOptionText={"St *"} hideLabel options={this.props.states}
                  onChange={this.changeForm} />
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-4 d-inline-block">
                <Textbox id={"zip"} isSuccess={this.state.zipSuccess} isError={this.state.zipError} label={"Zip *"}
                  value={this.props.user.zip}
                  onChange={this.changeForm} />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <Textbox id={"emailAddress"} isSuccess={this.state.emailAddressSuccess} isError={this.state.emailAddressError} label={"Email *"}
                value={this.props.user.emailAddress}
                onChange={this.changeForm} />
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-7 col-lg-6">
                <div className="form-group">
                  <Textbox id={"phoneNumber"} isSuccess={this.state.phoneNumberSuccess} isError={this.state.phoneNumberError} label={"Telephone *"}
                    value={this.props.user.phoneNumber} maxLength={15}
                    onChange={this.changeForm} />
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-6">
                <div className="form-group">
                  <Textbox id={"phoneExt"} label={"Ext"} value={this.props.user.phoneExt}
                    onChange={this.changeForm} />
                </div>
              </div>
            </div>

          </div>
        </div>

        <hr />

        <div className="form-row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <Textbox id={"companyName"} label={"Company Name"}
                value={this.props.user.companyName}
                onChange={this.changeForm} />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <Select id={"companyType"} label={"Company Type"} hideLabel
                value={this.props.user.companyType} showOther defaultOptionText="Choose a company type"
                options={userCompanyTypeSelectOptions}
                onChange={this.changeForm} />
            </div>
          </div>
        </div>

        {this.props.isGuestForm &&
          <div className="form-row">
            <div className="col-sm-12">
              <div className="confirm-courses-panel guest-acknowledgement col-sm-12">
                <h4><SvgIcon icon="info" color="green"/> Guest Acknowledgement</h4>
                By continuing as a guest, you acknowledge that you will not receive SLA Credit for completion 
                of the CA SL Regulation: Required Training or CE credits for On Demand courses taken unless you create an account.
                <b>
                  <Checkbox id="guestAcknowledgement" checked={this.state.guestAcknowledgment}
                            label="I accept that as a guest I will not receive SLA Credit for completion of the CA SL Regulation: Required Training
                            or CE credits for On Demand courses."
                            disabled={false}
                            onChange={() => this.setState({guestAcknowledgment: !this.state.guestAcknowledgment})}/>
                </b>
              </div>
            </div>
          </div>
        }

        <hr />

        {!this.props.isGuestForm &&
          <div className="form-row">
            <div className="col-sm-12">
              <div className="confirm-courses-panel col-sm-12">
                <h4><SvgIcon icon="info" color="green" /> Create Account Confirmation</h4>
                Thank you for creating an account in the SLA Learning Center. Before your new account can be confirmed,
                please acknowledge that:
                <p className="small-text">
                  <b>1)</b> The personal and professional information you provided is accurate
                  <br /><b>2)</b> That you are creating this account to register for and complete courses on your own behalf
                  <br /><b>3)</b> That you agree not to permit anyone other than yourself to attend or complete any Live or On-Demand courses using this account
                  <br /><b>4)</b> If you have not entered your Broker License number, then you will not receive CE credit for any On-Demand courses you complete in the Learning Center
                </p>
                <b>
                  <Checkbox id="agreedToTerms" checked={this.state.agreedToTerms}
                    label="I agree to the terms listed above."
                    disabled={false}
                    onChange={() => this.setState({ agreedToTerms: !this.state.agreedToTerms })} />
                </b>
              </div>
            </div>
          </div>
        }

        <div className="text-right">
          <Button
            buttonType={buttonTypes.PRIMARY}
            buttonSize={buttonSizes.LARGE}
            className="mt-2 mt-md-4"
            svgIcon
            icon="create-account"
            iconColor={"white"}
            onClick={() => this.onCreateClick(this.props.user)}
            disabled={continueDisabled}
            label={this.props.isGuestForm ? "Continue" : "Create Account"} />
        </div>

      </form>
    );
  }
}

CreateAccountForm.propTypes = {
  user: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object,
  createAccountRedirect: PropTypes.string.isRequired,
  isGuestForm: PropTypes.bool.isRequired,
  cart: PropTypes.array.isRequired
};

CreateAccountForm.defaultProps = {
  isGuestForm: false,
};

function mapStateToProps(state) {
  return {
    user: state.lcUser.user,
    states: state.location.states,
    createAccountRedirect: state.lcUser.createAccountRedirect,
    cart: state.shopping.cart,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    locationActions,
    lcUserActions,
    courseActions,
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm));
