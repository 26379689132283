// import loadingStates from "../constants/loadingStates";
import initialState from "../store/initialState";

const selectDomain = (state) => state.auth || initialState.auth;

export const selectRegisteredCourses = (state) =>
  selectDomain(state).currentUser.registeredCourses;

export const selectIsLoadingRegisteredCourses = (state) =>
  selectDomain(state).isLoadingRegisteredCourses;

export const selectLinkedEventCourses = (state) =>
  selectDomain(state).currentUser.registeredCourses;

export const selectIsLoadingLinkedEventCourses = (state) =>
  selectDomain(state).isLoadingRegisteredCourses;
