import React from "react";
import PropTypes from "prop-types";
import * as buttonTypes from "../../constants/buttonTypes";

class Button extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hover: false
    };

    this.hover = this.hover.bind(this);
  }

  hover(value) {
    this.setState({
      hover: value
    });
  }

  render() {
    let {
      id,
      icon,
      label,
      onClick,
      float,
      buttonType,
      disabled,
      displayBlock,
      buttonSize,
      submitButton,
      className,
      svgIcon,
      iconColor,
      hoverIconColor,
      iconRight,
      title
    } = this.props;

    let LabelSpan = () => (<span className="btn-label">{label}</span>);

	return (
		<button
      title={title}
			id={id}
			type={`${submitButton ? "submit" : "button"}`}
			className={`btn btn-${buttonType} ${buttonSize} ${className ? className : ""} ${displayBlock ? "btn-block" : "" } ${float ? `float-${float}` : ""}`}
			onClick={e => {
				e.preventDefault();
				onClick();
			}}
        onMouseEnter={() => this.hover(true)}
        onMouseLeave={() => this.hover(false)}
        disabled={disabled}
		>
        {iconRight &&
          <LabelSpan/>
        }

        {icon &&
          <React.Fragment>
            {svgIcon ?
              <div className={"svg-icon " + (iconRight ? "ml-2 ml-sm-3 ml-lg-3" : "mr-3") + " " + icon + " " + (this.state.hover && !disabled ? (hoverIconColor ? hoverIconColor : iconColor) : iconColor)}/>
              :
              <i className="material-icons mr-1">{icon}</i>
            }
          </React.Fragment>
        }

        {!iconRight &&
          <LabelSpan/>
        }
		</button>
	);
  }
}

Button.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	float: PropTypes.string,
	label: PropTypes.string.isRequired,
	icon: PropTypes.string,
	buttonType: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	displayBlock: PropTypes.bool,
	buttonSize: PropTypes.string,
  className: PropTypes.string,
  svgIcon: PropTypes.bool,
  submitButton: PropTypes.bool,
  iconColor: PropTypes.string,
  hoverIconColor: PropTypes.string,
  iconRight: PropTypes.bool
};

Button.defaultProps = {
    onClick: () => {},
	disabled: false,
	buttonType: buttonTypes.PRIMARY,
  submitButton: false,
  displayBlock: false,
  title: "",
    buttonSize: "",
    iconRight: false
};

export default Button;
