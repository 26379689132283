import React from 'react'
import SvgIcon from './../../icon/SvgIcon';
import moment from 'moment';
import { PropTypes } from 'prop-types';

function month(date) {
  return moment(date).format('MMM');
}

function day(date) {
  return moment(date).format('D');
}

class CalendarIcon extends React.Component {
  render() {
    let {date} = this.props;

    return (
      <div className='cal'>
        <SvgIcon icon='cal-lg' color='white'/>
        <span className="month">{month(date)}</span>
        <span className="day"> {day(date)}</span>
      </div>
    );
  }
}

CalendarIcon.propTypes = {
  date: PropTypes.string.isRequired,
}

export default CalendarIcon;