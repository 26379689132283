import * as fetchCreators from './fetchCreators';
import {CONFIGURATION} from '../constants/config';

const coursesApiUrl = CONFIGURATION.SERVER_API_URL + "/courseofferings/";

export function getFutureCourses() {
  return fetchCreators.get(coursesApiUrl + 'future');
}

export function getCourse(id, format) {
  return fetchCreators.get(coursesApiUrl, format ? { id, format } : { id });
}

export function getLmsCourseOutlines(courseIds) {
  return fetchCreators.post(coursesApiUrl + "lms-course/outline", courseIds);
}

export function registerForCourses(courses, guestId = null) {
  const body = { courses };
  if (guestId) body.userId = guestId;

  return fetchCreators.post(coursesApiUrl + "register", body);
}

export function dropCourse(courseRegistrationId) {
  return fetchCreators.post(coursesApiUrl + "unregister", courseRegistrationId);
}

export function dropCourseFromEmail(courseRegistrationGuid) {
  return fetchCreators.post(coursesApiUrl + "unenroll", courseRegistrationGuid);
}

export function retakeCourse(user, courseOfferingId) {
  return fetchCreators.post(coursesApiUrl + "retake", {
    userViewModel: user,
    courseOfferingId
  });
}

export function getLmsCourseRegistration(courseRegistrationId) {
  return fetchCreators.get(coursesApiUrl + 'lms-course', {courseRegistrationId});
}

export function getUserCourseRegistration(courseRegistrationId) {
  return fetchCreators.get(coursesApiUrl + 'userCourseRegistration', {courseRegistrationId});
}

export function getUserCourseProgressFromGuid(courseRegistrationGuid) {
  return fetchCreators.get(coursesApiUrl + 'progress-guid', {courseRegistrationGuid});
}

export function getUserRegisteredCourses() {
  return fetchCreators.get(coursesApiUrl + 'registered');
}

export function hasUserCompletedCourseInOfferingsGroup(courseId){
  return fetchCreators.get(coursesApiUrl +'user-completed-course-offering-in-group', {courseId})
}
