import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import objectAssign from "object-assign";
import { toast } from "react-toastify";

import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";
import { ROUTES } from "../../constants/routeConfig";

import * as lcUserActions from "../../actions/lcUserActions";
import * as formUtilities from "../../utilities/formUtilities";

import SimpleContentPageLayout from "../layout/SimpleContentPageLayout";
import Button from '../inputs/Button';
import PasswordField from "./PasswordField";

class ConfirmResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordSuccess: false,
      passwordError: false,
    };
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  onSubmitClick() {
    let changedloginForm = objectAssign({}, this.props.form);
    changedloginForm.token = this.props.match.params.token;
    this.props.actions.changeLoginForm(changedloginForm);

    this.props.actions.confirmResetPassword(changedloginForm)
      .then(() => {
        toast.success("Password successfully changed.")
      })
      .then(() => {
        this.props.history.push(ROUTES.LOGIN);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  changeConfirmPassword(event) {
    this.setState({ password: event.target.value });
  }

  changePassword(event) {
    let changedloginForm = objectAssign({}, this.props.form);
    changedloginForm[event.target.id] = event.target.value;
    switch (event.target.id) {
      case "password":
        if (formUtilities.validatePassword(event.target.value)) {
          if (formUtilities.formElementHasError(event.target.value)) {
            this.setState({ passwordSuccess: false, passwordError: true });
          }
          else {
            this.setState({ passwordSuccess: true, passwordError: false });
          }
        } else {
          this.setState({ passwordSuccess: false, passwordError: true });
        }
        break;
    }
    this.props.actions.changeLoginForm(changedloginForm);
  }

  render() {
    let passwordsDontMatch = this.props.form.password !== this.state.password;
    return (

      <SimpleContentPageLayout
        documentTitle="Save New Password"
        headerTitle="Save New Password"
        headerContent="Please enter your new password on order to reset your password."
        showBackButton={true}
        backButtonRoute={ROUTES.LOGIN}>

        <div className={'reset-form'}>

          <div className="row">
            <div className="col offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">

              <PasswordField
                className='blue'
                wrapperClassName='large-blue'
                id='password'
                passwordLabel='NEW PASSWORD'
                icon="key"
                password={this.props.form.password}
                change={this.changePassword}
                passwordError={this.state.passwordError}
                passwordSuccess={this.state.passwordSuccess} />

              <PasswordField
                className='blue'
                wrapperClassName='large-blue'
                id='password'
                passwordLabel='RE-ENTER NEW PASSWORD'
                icon="key"
                password={this.state.password}
                isConfirmField={true}
                confirmPassword={this.props.form.password}
                passwordError={passwordsDontMatch}
                change={this.changeConfirmPassword} />

              <div className="reset-buttons">
                <Button
                  onClick={this.onSubmitClick}
                  label={"SAVE PASSWORD"}
                  buttonSize={buttonSizes.LARGE}
                  buttonType={buttonTypes.WHITE}
                  className="btn-block"
                  disabled={passwordsDontMatch || this.props.form.password === ""}
                  svgIcon
                  icon="save"
                  iconColor={"blue"}
                  hoverIconColor={"white"} />
              </div>

            </div>
          </div>

        </div>
      </SimpleContentPageLayout>
    );
  }
}

ConfirmResetPasswordPage.propTypes = {
  form: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    form: state.lcUser.loginForm
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    lcUserActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmResetPasswordPage);
