import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';
import {persistStore, persistReducer} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage';
import createExpirationTransform from 'redux-persist-transform-expire';
import {CONFIGURATION} from '../constants/config';

export const history = createHistory();

const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt',
  defaultState: undefined
});
const persistConfig = {
  version: CONFIGURATION.STATE_VERSION,
  key: 'slas',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['brokerGuides', 'shopping', 'auth', 'search', 'ui'],
  transforms: [expireTransform]
};

function configureStoreProd(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    thunk,
    reactRouterMiddleware,
  ];

  const store = createStore(persistReducer(persistConfig, rootReducer), initialState, compose(
    applyMiddleware(...middlewares)
    )
  );

  let persistor = persistStore(store);

  return {store, persistor};
}

function configureStoreDev(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    reduxImmutableStateInvariant(),
    thunk,
    reactRouterMiddleware,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(persistReducer(persistConfig, rootReducer), initialState, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  );


  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  let persistor = persistStore(store);

  return {store, persistor};
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
