export const actionTypes = Object.freeze({
  BLOCK_UI: 'BLOCK_UI',
  SET_ACTIVE_NAV: 'SET_ACTIVE_NAV',
  CLEAR_ACTIVE_NAV: 'CLEAR_ACTIVE_NAV',
  SET_LICENSE_RENEWAL_DATE: 'SET_LICENSE_RENEWAL_DATE'
});

export function blockUI(value) {
  return {type: actionTypes.BLOCK_UI, data: value}
}

export function setActiveNav(value) {
  return {type: actionTypes.SET_ACTIVE_NAV, data: value}
}

export function clearActiveNav() {
  return {type: actionTypes.CLEAR_ACTIVE_NAV}
}

export function setLicenseRenewalDate(value) {
  return {type: actionTypes.SET_LICENSE_RENEWAL_DATE, data: value};
}