import React from "react";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import ReactPlaceholder from 'react-placeholder';

import { ROUTES } from "../../constants/routeConfig";

import faqGroup from "../../proptypes/resource/faqGroup";

import * as resourceActions from "../../actions/resourceActions";

import { userAgentIsBot } from "../../utilities/requestUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import SvgIcon from "../icon/SvgIcon";
import Faq from "./Faq.js";

const maxSortPosition = Number.MAX_SAFE_INTEGER;

class FaqGroupPage extends React.Component {

  componentWillMount() {
    this.getFaqGroup();
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };

    this.goBackToFaqsPage = this.goBackToFaqsPage.bind(this);
    this.getFaqGroup = this.getFaqGroup.bind(this);
  }

  goBackToFaqsPage() {
    this.props.history.push(ROUTES.FAQS_PAGE);
  }

  getFaqGroup() {
    // Load FAQ group if it's not already loaded.
    if (!this.props.faqGroup || this.props.faqGroup.urlName !== this.props.match.params.faqGroupUrlName) {
      this.props.actions.clearFaqGroup();
      this.props.actions.getFaqGroup({ url: this.props.match.params.faqGroupUrlName, shouldThrowError: true })
        .then(() => this.setState({ loading: false }))
    } else {
      this.setState({ loading: false });
    }
  }

  render() {

    let defaultFAQToOpen = userAgentIsBot();

    return (
      <ContentPageLayout
        documentTitle="FAQs"
        headerTitle="FAQs"
        headerContent={
          <React.Fragment>
            The “Frequently Asked Questions” section is a list of recurring questions and corresponding answers on a variety
            of topics about surplus line procedures and regulations.
          </React.Fragment>
        }>

        <div className="container">

          <h1 className="content-heading">
            <span onClick={() => this.goBackToFaqsPage()}><SvgIcon icon={"chevron-left"} color={"white"} />Back to FAQs</span>
            {this.props.faqGroup ?
              this.props.faqGroup.title
              :
              "Loading..."
            }
          </h1>

          <div className="white-body">

            <ReactPlaceholder showLoadingAnimation ready={!this.state.loading} type='text' rows={4}>
              <div className="row">
                <div className="col-12">
                  {this.props.faqGroup && this.props.faqGroup.faQs.sort((a, b) => {
                    return (a.sortPosition || maxSortPosition) - (b.sortPosition || maxSortPosition);
                  }).map((faq) =>
                    <Faq
                      question={faq.question}
                      answer={faq.answer}
                      key={faq.id}
                      open={defaultFAQToOpen}
                      className="collapsible-enhanced"
                      answerClassName="generic-content" />
                  )}
                </div>
              </div>
            </ReactPlaceholder>

          </div>
        </div>
      </ContentPageLayout>
    );
  }
}

FaqGroupPage.propTypes = {
  actions: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  faqGroup: PropTypes.shape(faqGroup),
};

function mapStateToProps(state) {
  return {
    faqGroup: state.resource.faqGroup,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, resourceActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FaqGroupPage));
