import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ROUTES } from "../../constants/routeConfig";

import CreateAccountForm from '../user/CreateAccountForm';
import SvgIcon from "../icon/SvgIcon";

class ShoppingCartCreateGuest extends React.Component {

  componentWillMount() {
    if (this.props.isLoggedIn)
      this.props.history.push(ROUTES.SHOPPING_CART_CONFIRM_INFO)
  }

  render() {
    return (
      <React.Fragment>

        <h1 className="content-heading">
          <span onClick={() => this.props.history.push(ROUTES.SHOPPING_CART_LOGIN)}><SvgIcon icon={"chevron-left"} color={"white"} />Back</span>
          Continue as Guest
        </h1>

        <div className="white-body">
          <CreateAccountForm isGuestForm />
        </div>

      </React.Fragment>
    );
  }
}

ShoppingCartCreateGuest.propTypes = {
  history: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default withRouter(connect(mapStateToProps, null)(ShoppingCartCreateGuest));
