import * as errorApi from "../api/errorApi";
import { stopped } from '../constants/symbols';

export const actionTypes = Object.freeze({
  AVOID_ERROR: 'AVOID_ERROR',
  THROW_ERROR: 'THROW_ERROR',
  POST_ERROR_SUCCESS: 'POST_ERROR_SUCCESS'
});

export function throwError(error, details) {
  // no need to handle intentional aborts
  if (error instanceof DOMException && error.name === 'AbortError' && error[stopped]) {
    return {
      type: actionTypes.AVOID_ERROR,
    };
  }

  if (error == null) {
    return {
      type: actionTypes.THROW_ERROR,
      data: null
    }
  }

  return {
    type: actionTypes.THROW_ERROR,
    data: {
      error,
      details
    }
  }
}

export function postError(error, session, stackTrace) {
  return dispatch => {

    return errorApi.postError(error, session, stackTrace)
      .then(() => {
        dispatch({
          type: actionTypes.POST_ERROR_SUCCESS
        });
      })
      .catch(error => {
        throw error;
      });

  };
}
