import objectAssign from 'object-assign';
import initialState from '../initialState';
import { actionTypes } from "../../actions/completedCourseFiltersActions";

export default function completedCourseFiltersReducer(state = initialState.completedCourseFilters, action) {

  switch (action.type) {

    case actionTypes.CHANGE_FROMDATE_FILTER:
      return objectAssign({}, state, {
        fromDate: action.data
      });

    case actionTypes.CHANGE_TODATE_FILTER:
      return objectAssign({}, state, {
        toDate: action.data
      });

    case actionTypes.CHANGE_CECREDITSONLY_FILTER:
      return objectAssign({}, state, {
        ceCreditsOnly: action.data
      });

    case actionTypes.CHANGE_TITLE_FILTER:
      return objectAssign({}, state, {
        title: action.data
      });

    default:
      return state;
  }
}
