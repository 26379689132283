import React from 'react';
import Button from './Button';
import {CONFIGURATION} from '../../constants/config';
import PropTypes from 'prop-types';
import * as buttonTypes from "../../constants/buttonTypes";

function getICal(id) {
  window.location.href = CONFIGURATION.SERVER_API_URL + '/courseofferings/ical?id=' + id;
}

const ICalButton = ({id, iconOnly}) => {
  return(
    <React.Fragment>
      {iconOnly ?
        <div className={"svg-icon cal-add"} onClick={() => getICal(id)}/>
        :
        <Button onClick={() => getICal(id)} label="ICal"
                disabled={false}
                svgIcon
                icon="cal-add"
                iconColor="white"
                hoverIconColor="blue"
                className="cal-btn"
                buttonType={buttonTypes.PRIMARY}
        />
      }
    </React.Fragment>
  )
};

ICalButton.propTypes = {
  id: PropTypes.number.isRequired,
  iconOnly: PropTypes.bool
};

ICalButton.defaults = {
  iconOnly: false
}

export default ICalButton;
