import React from 'react';
import { RectShape } from 'react-placeholder/lib/placeholders';

const FeaturedCoursePlaceHolder = (
  <div className='row'>
    <div className="col-12 col-sm-6 mb-4">
      <div className="course-card featured place-holder disabled">
        <RectShape color='lightgray' style={{ marginRight: 0 }} />
      </div>
    </div>
    <div className="col-12 col-sm-6 mb-4">
      <div className="course-card featured place-holder disabled">
        <RectShape color='lightgray' style={{ marginRight: 0 }} />
      </div>
    </div>
    <div className="col-12 col-sm-6 mb-4">
      <div className="course-card featured place-holder disabled">
        <RectShape color='lightgray' style={{ marginRight: 0 }} />
      </div>
    </div>
    <div className="col-12 col-sm-6 mb-4">
      <div className="course-card featured place-holder disabled">
        <RectShape color='lightgray' style={{ marginRight: 0 }} />
      </div>
    </div>
  </div>
);

export default FeaturedCoursePlaceHolder;
