import objectAssign from 'object-assign';
import initialState from '../initialState';
import { actionTypes } from '../../actions/UIActions';

export default function authReducer(state = initialState.ui, action) {
  switch (action.type) {

    case actionTypes.BLOCK_UI:
      return objectAssign({}, state, {blockUI: action.data});

    case actionTypes.SET_ACTIVE_NAV:
      return objectAssign({}, state, {activeNav: action.data});

    case actionTypes.CLEAR_ACTIVE_NAV:
      return objectAssign({}, state, {activeNav: initialState.ui.activeNav});

    case actionTypes.SET_LICENSE_RENEWAL_DATE:
      return objectAssign({}, state, { licenseRenewalDate: action.data });

    default:
      return state;
  }

}
