import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "../icon/SvgIcon";

class Textbox extends React.Component {

  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      showLabel: false,
      showError: true
    }
  }

  handleFocus() {
    this.setState(prevState => { return { showLabel: !prevState.showLabel, showError: !prevState.showError } });
  }

  handleBlur(event, callback) {
    this.handleFocus();

    if (callback) {
      callback(event);
    }
  }

  render() {
    let placeholderText = "";
    if (!this.props.displayMode) {
      if (!this.props.placeholder || this.props.placeholder === "")
        placeholderText = this.props.label;
      else
        placeholderText = this.props.placeholder;
    }

    let labelIcon = this.props.isSuccess
      ? <SvgIcon icon={"check"} color={"white"} />
      : this.props.isError
        ? <SvgIcon icon={"alert"} color={"white"} />
        : (null);
    let labelText = this.props.isSuccess
      ? "Success"
      : this.props.isError
        ? "Alert"
        : this.props.label;

    let LabelClassName = this.props.isSuccess
      ? "field-label success"
      : (this.props.isError ? "field-label error" : "field-label");

    let textboxClassName = this.props.isSuccess
      ? "textbox success "
      : (this.props.isError ? "textbox error " : "textbox ");
    return (
      <React.Fragment>
        <div className={"field " + (this.props.wrapperClassName ? this.props.wrapperClassName : '')}>
          {
            this.state.showLabel &&
            <label
              className={LabelClassName}
              htmlFor={this.props.id}
              style={this.props.hideLabel ? { display: 'none' } : {}}>{labelIcon}{labelText}</label>
          }
          <input type={this.props.maskInput ? "password" : "text"}
            id={this.props.id}
            onFocus={this.handleFocus}
            onBlur={(e) => this.handleBlur(e, this.props.onBlur)}
            ref={this.props.inputRef}
            name={this.props.id}
            className={textboxClassName + (this.props.className ? this.props.className : '')}
            readOnly={this.props.displayMode}
            placeholder={placeholderText}
            value={this.props.value}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            maxLength={this.props.maxLength} />
          {this.props.icon &&
            <SvgIcon icon={this.props.icon} />
          }
          {
            this.props.isError && !this.state.showError &&
            this.props.errorText
          }
        </div>
      </React.Fragment>
    );
  }
}

Textbox.propTypes = {
  errorText: PropTypes.element,
  inputRef: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hideLabel: PropTypes.bool.isRequired,
  maskInput: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  displayMode: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  maxLength: PropTypes.number,
};

Textbox.defaultProps = {
  hideLabel: false,
  maskInput: false,
  isError: false,
  isSuccess: false,
  disabled: false,
  displayMode: false,
  dateMode: false,
};

export default Textbox;
