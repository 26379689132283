
export const actionTypes = Object.freeze({
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  UPDATE_SHOPPING_CART: 'UPDATE_SHOPPING_CART'
});

export function addToCart(courseId) {
  return {type: actionTypes.ADD_TO_CART, data: courseId}
}

export function removeFromCart(courseId) {
  return {type: actionTypes.REMOVE_FROM_CART, data: courseId}
}

export function updateShoppingCart(cart) {
  return {type: actionTypes.UPDATE_SHOPPING_CART, data: { cart }};
}
