import React from "react";
import PropTypes from "prop-types";

function LoadingSpinnerOverlay({ children, loading }) {
  return (
    <div className={loading ? "loading-spinner" : undefined}>
      {children}
      {loading && (
        <div
          className="loading-relative"
          style={{
            position: "absolute",
          }}
        >
          <div className="loading-inner loader-small ball-spin-fade-loader blue ">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </div>
  );
}

LoadingSpinnerOverlay.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.any,
};

export default LoadingSpinnerOverlay;
