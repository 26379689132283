import React from "react";
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import objectAssign from "object-assign";
import {connect} from "react-redux";
import {Switch, Route, withRouter} from 'react-router-dom';
import {ROUTES} from "../../constants/routeConfig";
import MyProfilePage from "../user/MyProfilePage";
import MyCoursesPage from "../user/MyCoursesPage";
import * as lcUserActions from '../../actions/lcUserActions';
import * as authSelectors from '../../selectors/authSelectors';
import * as lcUserSelectors from '../../selectors/lcUserSelectors';
import user from '../../proptypes/users/User';
import loadingStates from "../../constants/loadingStates";

class UserContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userDataLoaded: props.registeredCoursesAreLoaded || props.currentUserIsLoaded
    };
  }

  componentWillMount() {
    let onMyProfilePage = this.props.location.pathname === ROUTES.MY_PROFILE_PAGE;

    // Redirect to login screen if accessing a page that requires login and not logged in.
    if((!this.props.isLoggedIn || !this.props.currentUser) && onMyProfilePage)
      this.props.history.push(ROUTES.LOGIN);
    else
    {
      if (this.props.isLoggedIn && this.props.shouldLoadUserData) {
        this.props.actions.getLcUser().then(() => {
          this.setState({userDataLoaded: true})
        });
      }
    }
  }

  componentDidUpdate() {
    if (
      !this.state.userDataLoaded &&
      (this.props.registeredCoursesAreLoaded ||
      this.props.currentUserIsLoaded)
    ) {
      this.setState({ userDataLoaded: true });
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path={ROUTES.MY_PROFILE_PAGE} component={MyProfilePage}/>
        <Route exact path={ROUTES.MY_COURSES_PAGE} render={(props) => <MyCoursesPage {...props} userDataLoaded={this.state.userDataLoaded}/>}/>
      </Switch>
    );
  }
}

UserContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.shape(user).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  registeredCoursesAreLoaded: PropTypes.bool.isRequired,
  currentUserIsLoaded: PropTypes.bool.isRequired,
  shouldLoadUserData: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    isLoggedIn: state.auth.isLoggedIn,
    shouldLoadUserData:
      lcUserSelectors.selectIsLoadingLcUser(state) !==
      loadingStates.LOADING,
    registeredCoursesAreLoaded:
      authSelectors.selectIsLoadingRegisteredCourses(state) ===
      loadingStates.LOADED,
    currentUserIsLoaded:
      lcUserSelectors.selectPreviouslyLoadedLcUser(state),
  };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = objectAssign({}, lcUserActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserContainer));
