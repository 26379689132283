
const courseOfferingStatus = Object.freeze({
  NEW: 'N',
  OPEN: 'O',
  FULL: 'F',
  CANCELLED: 'C'
});

export const CourseOfferingStatuses = [
  courseOfferingStatus.NEW,
  courseOfferingStatus.OPEN,
  courseOfferingStatus.FULL,
  courseOfferingStatus.CANCELLED
];

export const decode = (code) => {
  switch(code) {
    case courseOfferingStatus.NEW:
      return 'New'
    case courseOfferingStatus.OPEN:
      return 'Open'
    case courseOfferingStatus.FULL:
      return 'Full'
    case courseOfferingStatus.CANCELLED:
      return 'Cancelled'
  }
}

export default courseOfferingStatus;
