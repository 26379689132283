import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getCourseDocument } from './../../../constants/routeConfig';
import DocumentIcon from './../../icon/DocumentIcon';
import courseOffering from '../../../proptypes/courses/courseOffering';
import courseDocument from '../../../proptypes/courses/courseDocument';

import * as courseSelectors from "../../../selectors/courseSelectors";

function toFixed(value, precision) {
  var power = Math.pow(10, precision || 0);
  return String(Math.round(value * power) / power);
}

function formatBytes(bytes) {
  if (bytes > 1000000) {
    return toFixed(bytes/1000000.00, 2) + " MB";
  } else if (bytes > 1000) {
    return toFixed(bytes/1000.00 , 2) + " KB";
  } else {
    return toFixed(bytes, 2) + " B";
  }
}

class CourseDocument extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='document'>
          <DocumentIcon type={this.props.document.type} />
          <div className='document-details'>
            <a href={getCourseDocument(this.props.currentOffering.id, this.props.document.id)} target="_blank" >{this.props.document.title}</a>
            <p>{formatBytes(this.props.document.size)}</p>
          </div>
        </div>
        <hr/>
      </React.Fragment>
    );
  }
}

CourseDocument.propTypes = {
  currentOffering: PropTypes.shape(courseOffering).isRequired,
  document: PropTypes.shape(courseDocument).isRequired,
}

function mapStateToProps(state) {
  return {
    currentOffering: courseSelectors.selectCurrentCourseOffering(state),
  };
}

export default connect(mapStateToProps)(CourseDocument);
