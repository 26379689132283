import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { toast } from "react-toastify";

import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";
import { ROUTES } from "../../constants/routeConfig";

import * as lcUserActions from "../../actions/lcUserActions";
import * as shoppingActions from "../../actions/shoppingActions";
import * as courseActions from "../../actions/courseActions";
import initialState from "../../store/initialState";

import Textbox from '../inputs/Textbox';
import Button from '../inputs/Button';
import Checkbox from '../inputs/Checkbox';
import CreateAccountButton from '../inputs/CreateAccountButton';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);

    this.changeLoginForm = this.changeLoginForm.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.changeRememberMe = this.changeRememberMe.bind(this);
  }

  componentWillMount() {
    if (this.props.auth.rememberMe) {
      let loginForm = objectAssign({}, this.props.loginForm);
      loginForm.email = this.props.auth.email;
      loginForm.rememberMe = this.props.auth.rememberMe;
      this.props.actions.changeLoginForm(loginForm);
    }
  }

  loginUser() {
    if (!this.props.loginForm.rememberMe) {
      this.props.actions.clearRememberMeData();
    }

    this.props.actions.login(this.props.loginForm)
      .then((user) => {
        let loginRedirectOverride;

        this.props.actions.clearLoginForm();

        if (this.props.onLoginSuccess) {
          this.props.onLoginSuccess(user, this);
        }

        this.props.history.push(loginRedirectOverride || this.props.loginRedirect || this.props.loginRedirectFromState);
        this.props.actions.setLoginRedirect(initialState.lcUser.loginRedirect);
      })
      .catch(error => toast.error(error.message));
  }

  changeRememberMe(event, rememberMe) {
    let changedRememberMe = objectAssign({}, rememberMe);
    this.props.actions.changeRememberMe(changedRememberMe);
  }

  changeLoginForm(event, form) {
    let changedForm = objectAssign({}, form);

    if (event.target.type === "checkbox") {
      changedForm[event.target.id] = event.target.checked;
    } else {
      changedForm[event.target.id] = event.target.value;
    }

    if (event.target.checked || form.rememberMe)
      this.changeRememberMe(event, { email: changedForm.email, rememberMe: true });

    this.props.actions.changeLoginForm(changedForm);
  }

  render() {
    let { blue, loginForm } = this.props;

    return (
      <form onSubmit={this.loginUser}>
        <div className={`login-form ${blue ? 'blue' : ''}`}>

          <Textbox
            className={blue ? 'blue' : ''}
            wrapperClassName={blue ? 'large-blue' : ''}
            id='email'
            label='Email or Username'
            icon="account"
            value={loginForm.email}
            onChange={(event) => this.changeLoginForm(event, loginForm)} disabled={false} />

          <Textbox
            className={blue ? 'blue' : ''}
            wrapperClassName={blue ? 'large-blue' : ''}
            id='password'
            label='Password'
            icon="key"
            value={loginForm.password}
            maskInput
            onChange={(event) => this.changeLoginForm(event, loginForm)}
            disabled={false} />

          <div className="login-options mb-5">
            <div className="row">
              <div className="col-6">
                <Checkbox
                  id="rememberMe"
                  checked={loginForm.rememberMe}
                  value={loginForm.rememberMe}
                  label="Remember Me"
                  onChange={(event) => this.changeLoginForm(event, loginForm)} />
              </div>
              <div className="col-6 text-right">
                <NavLink to={ROUTES.RESET_PASSWORD}>Forgot Password?</NavLink>
              </div>
            </div>
          </div>

          <div className="login-buttons">

            <div className="row">

              <div className={"col-12" + (this.props.onContinueAsGuest ? "" : " col-md-6")}>
                <Button
                  onClick={() => this.loginUser()}
                  label="Sign In"
                  className={"btn-block mb-4" + (this.props.onContinueAsGuest ? "" : " mb-md-0")}
                  buttonSize={buttonSizes.LARGE}
                  buttonType={blue ? buttonTypes.WHITE : buttonTypes.PRIMARY}
                  svgIcon
                  submitButton
                  icon="signin"
                  iconColor={blue ? "blue" : "white"}
                  hoverIconColor={blue ? "white" : undefined}
                  disabled={false} />
              </div>

              <div className="col-12 col-md-6">
                <CreateAccountButton
                  blue={blue}
                  className="btn-block"
                  createAccountRedirect={this.props.createAccountRedirect}
                  createAccountFormRoute={this.props.createAccountFormRoute} />
              </div>

              {this.props.onContinueAsGuest &&
                <div className="col-12 col-md-6">
                  <Button onClick={this.props.onContinueAsGuest}
                    label="Continue as Guest"
                    className="btn-block mt-4 mt-md-0"
                    buttonSize={buttonSizes.LARGE}
                    buttonType={buttonTypes.PRIMARY_OUTLINE}
                    svgIcon
                    iconRight
                    icon="chevron-right"
                    iconColor="blue"
                    hoverIconColor="white"
                    disabled={false} />
                </div>
              }

            </div>

          </div>
        </div>

      </form>
    );
  }
}

LoginForm.propTypes = {
  blue: PropTypes.bool,
  loginForm: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loginRedirect: PropTypes.string,
  loginRedirectFromState: PropTypes.string,
  cart: PropTypes.array,
  createAccountFormRoute: PropTypes.string,
  createAccountRedirect: PropTypes.string,
  onContinueAsGuest: PropTypes.func,
  onLoginSuccess: PropTypes.func,
  validateCart: PropTypes.bool,
};

LoginForm.defaultProps = {
  blue: false,
  createAccountFormRoute: ROUTES.CREATE_ACCOUNT
};

function mapStateToProps(state) {
  return {
    cart: state.shopping.cart,
    loginForm: state.lcUser.loginForm,
    loggedInUser: state.auth.currentUser,
    auth: state.auth,
    loginRedirectFromState: state.lcUser.loginRedirect
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    shoppingActions,
    lcUserActions,
    courseActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
