import React from 'react';
import PropTypes from "prop-types";
import objectAssign from "object-assign";
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import { CONFIGURATION } from "../../constants/config";
import * as buttonTypes from "../../constants/buttonTypes";

import * as courseUtilities from '../../utilities/courseUtilities';
import * as courseActions from "../../actions/courseActions";
import * as modalActions from "../../actions/modalActions";
import multiLocThumbnail from '../../assets/Multi-Loc-Card.png';

import SvgIcon from "../icon/SvgIcon";
import Button from '../inputs/Button';
import CourseOfferingCard from './CourseOfferingCard';

class CourseOfferingGroupCard extends React.Component {

  constructor(props) {
    super(props);

    this.thumbnailRef = React.createRef();

    this.showCourseSelectionModal = this.showCourseSelectionModal.bind(this);
    this.getCourseSelectionList = this.getCourseSelectionList.bind(this);
  }

  showCourseSelectionModal() {
    this.props.actions.showDefaultModal(this.getCourseSelectionList(), "Pick Your Course Offering", true, true,"padded-stretch");
  }

  getCourseSelectionList() {
    return (
      <div>
          {this.props.courseOfferingGroup.courseOfferings.map((courseOffering, i) =>
            <CourseOfferingCard key={i} courseOffering={courseOffering} featured />
          )}
      </div>)
  }

  render() {
    let { courseOfferingGroup } = this.props;
    let formatsVary = courseUtilities.courseOfferingFormatsVary(courseOfferingGroup.courseOfferings);
    let courseGroupDate = courseUtilities.GetCourseGroupDate(courseOfferingGroup.courseOfferings);
    let iconColor = 'white';
    return (
      <div className="course-card L featured">

        <div className='course-card-content'>

          <div className="course-card-image-container">
            <img src={multiLocThumbnail} />
          </div>
          <div className="top-left-triangle">
            <SvgIcon icon="cal" color='white' />
          </div>

          <div className="course-card-details">
            <div className="course-text">
              <div className="course-badges">

                {formatsVary &&
                  <div className="course-status multi-format" >
                    Multi Format
                  </div>
                }

              </div>
              <p className="course-name" onClick={this.showCourseSelectionModal}>

                <MediaQuery maxWidth={CONFIGURATION.MAX_XS_WIDTH}>
                  {(matches) => { //need to display shorter titles on mobile
                    if (matches)
                      return courseUtilities.truncateCourseName(courseOfferingGroup.course.name, 40);
                    else
                      return courseUtilities.truncateCourseName(courseOfferingGroup.course.name, 70);
                  }}
                </MediaQuery>

              </p>

              <p className="course-subtext">
                <span>
                  {
                    formatsVary ?
                      <React.Fragment>
                        <SvgIcon icon="pin-sm" color={iconColor} />
                        <SvgIcon icon="webinar-sm" color={iconColor} />
                        <span className="detail">Multiple Formats</span>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <SvgIcon icon="pin-sm" color={iconColor} />
                        <span className="detail">Multiple Locations</span>
                      </React.Fragment>
                  }

                </span>
                <span>
                  <SvgIcon icon="cal-sm" color={iconColor} />
                  {
                    courseGroupDate ?
                      <span className="detail">{courseUtilities.formatOfferingDate(null, courseGroupDate)}</span>
                      :
                      <span className="detail">Multiple Dates</span>
                  }
                </span>
                <span>
                  <SvgIcon icon="course-number-sm" color={iconColor} />
                  <span className="detail">Course #{courseOfferingGroup.course.cdiCourseCode}</span>
                </span>
              </p>
            </div>
          </div>

          <ul className="course-card-primary-actions">
            <li className='actions-item'>
              <Button onClick={this.showCourseSelectionModal} label="Register"
                svgIcon
                icon="add"
                iconColor="white"
                hoverIconColor={'green'}
                buttonType={buttonTypes.SUCCESS}
              />
            </li>
          </ul>

          <ul className="course-card-secondary-actions">
            <li className="actions-item">
              <span>
                <span className="credits-number">{courseOfferingGroup.course.ceHours}</span>
                Credits
              </span>
            </li>
            <li className="actions-item clickable" onClick={this.showCourseSelectionModal}>
              <span>
                <SvgIcon icon="info" color={iconColor} /> Details
              </span>
            </li>
          </ul>
        </div >
      </div >
    );
  }
}

CourseOfferingGroupCard.propTypes = {
  history: PropTypes.object.isRequired,
  courseOfferingGroup: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions, modalActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseOfferingGroupCard));
