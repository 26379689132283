import React from 'react';
import { PropTypes } from 'prop-types';
import courseOffering from '../../proptypes/courses/courseOffering';
import courseOfferingMethod from '../../proptypes/courses/courseOfferingMethod';
import { getThumbnailPictureUrl } from '../../constants/routeConfig';
import liveThumbnailDefault from '../../assets/Card-Example2.png';
import onDemandThumbnailDefault from '../../assets/Card-Example.png';

class CourseOfferingImage extends React.Component {
  constructor(props) {
    super(props);

    this.thumbnailRef = React.createRef();

    this.handleThumbnailError = this.handleThumbnailError.bind(this);
  }

  handleThumbnailError() {
    this.thumbnailRef.src = this.props.courseOffering.method === courseOfferingMethod.LIVE ? liveThumbnailDefault : onDemandThumbnailDefault;
  }

  render() {
    return (
      <img ref={el => this.thumbnailRef = el} src={getThumbnailPictureUrl(this.props.courseOffering.id)} onError={this.handleThumbnailError} />
    );
  }
}

CourseOfferingImage.propTypes = {
  courseOffering: PropTypes.shape(courseOffering),
};

export default CourseOfferingImage;
