import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import objectAssign from "object-assign";
import { PropTypes } from 'prop-types';
import * as courseActions from '../../actions/courseActions';
import * as modalActions from '../../actions/modalActions';
import * as lcUserActions from '../../actions/lcUserActions';
import Button from './../inputs/Button';
import CourseOfferingCard from './../courses/CourseOfferingCard';
import Checkbox from './../inputs/Checkbox';
import ModalContent from './ModalContent';
import courseOffering from '../../proptypes/courses/courseOffering';
import user from '../../proptypes/users/User';
import { watchCourse, courseIsUnpublished } from './../../utilities/courseUtilities';
import * as buttonSizes from "../../constants/buttonSizes";

class RetakeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canRetake: false,
    }
    this.props.actions.copyUserDataToForm(this.props.currentUser);
  }
  render() {
    let isUnpublished = courseIsUnpublished(this.props.courseOffering.unpublishDate);
    const retakeCourse = () => {
      const { id, format } = this.props.courseOffering;
      this.props.actions.closeModal();
      this.props.actions.retakeCourse(this.props.user, id, format);
    }
    return (
      <ModalContent
        onClose={() => { this.props.actions.closeModal() }}
        label={isUnpublished ? "Re-watch" : this.props.courseOffering.rewatchEnabled ? "Re-watch or Re-take?" : "Re-take?"}
        fontColor=""
        buttons={
          (<React.Fragment>
            <div className="row">

              {this.props.courseOffering.rewatchEnabled &&
                <div className="col-12 mb-1 mb-sm-0 col-sm-6 col-lg-4 col-xl-4">
                  <Button
                    onClick={() => { this.props.actions.closeModal(); watchCourse(this.props.courseRegistrationId, true); }}
                    label="Re-Watch"
                    disabled={false}
                    svgIcon
                    icon="rewatch-eye"
                    displayBlock={true}
                    title={`Re-Watch Course`}
                    iconColor="white"
                    hoverIconColor="white"
                    buttonSize={buttonSizes.LARGE} />
                </div>
              }

              {!isUnpublished &&
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                  <Button
                    onClick={retakeCourse}
                    label="Re-Take"
                    disabled={!this.state.canRetake}
                    svgIcon
                    icon="rewatch"
                    displayBlock={true}
                    title={`Re-Take Course`}
                    iconColor="white"
                    hoverIconColor="white"
                    buttonSize={buttonSizes.LARGE} />
                </div>
              }
              {!isUnpublished &&
                <div className="col-12 col-sm-6 offset-sm-6 col-lg-8 offset-lg-4 col-xl-4 offset-xl-0">
                  <Checkbox
                    id="verify"
                    className="text-left"
                    label={
                      <span>
                        I certify that I am re-taking this course for license renewal credit.
                        See <a target='_blank' rel="noopener noreferrer" href='https://govt.westlaw.com/calregs/Document/ID9AF7E30622011E4A9828577DD5F1BF2?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)'>California Code of Regulations 10 CCR § 2188.6</a>.
                      </span>
                    }
                    onChange={() => { this.setState({ canRetake: !this.state.canRetake }); }}
                    checked={this.state.canRetake} />
                </div>
              }

            </div>
          </ React.Fragment>)
        }>
        <React.Fragment>
          <p>

            {isUnpublished ?
              `Our records indicate you have already taken this course for CE credit.`
              : `Our records indicate you have already taken this course for CE credit.
                CE credit for this course can only be granted once per 24-month license renewal period.`
            }
          </p>
          <p>
            {
              isUnpublished ?
                `You may re-watch the course for no additional credit by clicking on re-watch below.`
                : this.props.courseOffering.rewatchEnabled ?
                  `You may re-watch the course for no additional credit by clicking on re-watch below.
                  Or, you may re-take this course for CE credit by confirming you are in a new license renewal period via the checkbox below then clicking on re-take.`
                  : `You may re-take this course for CE credit by confirming you are in a new license renewal period via the checkbox below then clicking on re-take.`
            }
          </p>
          <CourseOfferingCard courseOffering={this.props.courseOffering} showButtons={false} />
        </React.Fragment>
      </ModalContent>);
  }
}

RetakeModal.propTypes = {
  courseOffering: PropTypes.shape(courseOffering).isRequired,
  courseRegistrationId: PropTypes.number,
  user: PropTypes.shape(user).isRequired,
  currentUser: PropTypes.shape(user).isRequired,
  actions: PropTypes.any,
}

function mapStateToProps(state) {
  return {
    courseOffering: state.modal.courseOffering,
    courseRegistrationId: state.modal.courseRegistrationId,
    user: state.lcUser.user,
    currentUser: state.auth.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, modalActions, courseActions, lcUserActions);
  return {
    actions: bindActionCreators(combinedActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RetakeModal);
