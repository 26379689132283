import PropTypes from "prop-types";
import React from "react";
import ReactPlaceholder from "react-placeholder";

function TextRowPlaceholder(props) {
  return (
    <ReactPlaceholder
      ready={false}
      showLoadingAnimation
      type="text"
      rows={1}
      {...props}
    >
      {props.children || <div />}
    </ReactPlaceholder>
  );
}

TextRowPlaceholder.propTypes = {
  children: PropTypes.element,
};
export default TextRowPlaceholder;
