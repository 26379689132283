import React from 'react';
import { PropTypes } from 'prop-types';
import navTabs from '../../constants/navTabs';
import Carousel from './Carousel';
import LandingPageMainSlide from './LandingPageMainSlide';
import LandingPageResourcesSlide from './LandingPageResourcesSlide';
import LandingPageCourseSlide from './LandingPageCourseSlide';
import LandingPageMyCoursesSlide from './LandingPageMyCoursesSlide';
import LandingPageComplianceConnectionSlide from './LandingPageComplianceConnectionSlide';

class LandingCarousel extends React.Component {
  constructor() {
    super();
    this.state = {
      currentSlide: 0,
    }
    this.updateActiveNav = this.updateActiveNav.bind(this);
  }

  updateActiveNav(index) {
    switch (index) {
      case 1:
        this.props.changeActiveNav(navTabs.RENEWAL_CALC);
        break;
      case 2:
        this.props.changeActiveNav(navTabs.RESOURCES);
        break;
      case 3:
        this.props.changeActiveNav(navTabs.SEARCH);
        break;
      default:
        this.props.changeActiveNav('');
        break;
    }
    this.setState({
      currentSlide: index
    })
  }
  render() {
    return (
      <Carousel onChange={this.updateActiveNav}>
        <LandingPageMainSlide active={this.state.currentSlide === 0} />
        <LandingPageMyCoursesSlide active={this.state.currentSlide === 1} />
        <LandingPageResourcesSlide active={this.state.currentSlide === 2} />
        <LandingPageCourseSlide active={this.state.currentSlide === 3} />
        <LandingPageComplianceConnectionSlide active={this.state.currentSlide === 4} />
      </Carousel>
    );
  }
}

LandingCarousel.propTypes = {
  changeActiveNav: PropTypes.func.isRequired,
}

export default LandingCarousel;