import React from "react";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';

import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import { ROUTES } from '../../constants/routeConfig';

import courseOffering from "../../proptypes/courses/courseOffering";
import courseOfferingStatus from "../../proptypes/courses/courseOfferingStatus";
import user from "../../proptypes/users/User";
import * as shoppingActions from "../../actions/shoppingActions";
import * as modalActions from "../../actions/modalActions";
import * as courseActions from '../../actions/courseActions';
import courseOfferingMethod from "../../proptypes/courses/courseOfferingMethod";
import Button from '../inputs/Button';
import { CONFIGURATION } from '../../constants/config';

class AddRemoveCartButton extends React.Component {
  constructor() {
    super();
    this.state = {
      today: new moment(),
    };
    this.register = this.register.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }

  register() {
    const course = {
      id: this.props.courseOffering.id,
      format: this.props.courseOffering.format,
    };

    this.props.actions.updateShoppingCart([]);
    this.props.actions.addToCart(course);

    if (!this.props.isLoggedIn) {
      this.props.history.push(ROUTES.SHOPPING_CART_LOGIN);
    } else {
      this.props.actions
        .registerForCourses([course])
        .then(() => this.props.history.push(ROUTES.SHOPPING_CART_COMPLETE))
        .catch((error) => toast.error(error.message));
    }
  }

  addToCart() {
    let difference = new moment(this.props.courseOffering.date).diff(
      this.state.today.startOf("day"),
      "days"
    );
    let shouldHideRegistrationWarningWindow =
      this.props.courseOffering.method === courseOfferingMethod.ON_DEMAND ||
      difference > 5 ||
      this.props.courseOffering.location == null ||
      this.props.courseOffering.webinarUrl != null ||
      this.props.courseOffering.location.name ==
        CONFIGURATION.WEBINAR_LOCATION_NAME;

    if (shouldHideRegistrationWarningWindow) {
      this.props.actions.closeModal();
      this.register();
    } else {
      this.props.actions.showDefaultModal(
        <React.Fragment>
          <p className="text-center">
            Online registration closes <strong>five days</strong> before the
            date of the course.
          </p>
          <p className="text-center">
            If you wish to register for a course after registration has closed,
            please contact The Surplus Line Association&apos;s Education and
            Compliance Department at <strong>(415) 434-4900</strong> or{" "}
            <a href="mailto:Seminars@slacal.com">Seminars@slacal.com</a>.
          </p>
        </React.Fragment>,
        ""
      );
    }
  }
  
  async checkCourseCompletion() {
    const course = {
      id: this.props.courseOffering.id,
      format: this.props.courseOffering.format,
    };

    let isCourseCompleted = await this.props.actions.hasUserCompletedCourseInOfferingsGroup(course.id);

    if (isCourseCompleted) {

      let continueAction = () => {        
        this.props.actions.closeModal();
        this.addToCart();
      };

      let cancelAction = () => {
        this.props.actions.closeModal();
      }

      this.props.actions.showConfirmModal(
        <p>
          Warning! You have already received CE credit for this course. The
          SLA can only offer CE credits for a given course every two years. If
          you continue, YOU WILL NOT RECEIVE CE CREDIT FOR COMPLETING THIS
          COURSE AT THIS TIME. Please reach out to seminars@slacal.com for
          more information
        </p>,
        "Duplicate Registration",
        continueAction,
        false,
        false,
        "Continue",
        "Cancel",
        cancelAction
      );
    } else {
      this.addToCart();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button
          onClick={() => this.checkCourseCompletion()}
          label="Register"
          svgIcon
          icon="add"
          iconColor="white"
          hoverIconColor={this.props.large ? "white" : "green"}
          buttonType={buttonTypes.SUCCESS}
          buttonSize={this.props.large ? buttonSizes.LARGE : ""}
          disabled={
            this.props.disabled ||
            this.props.courseOffering.status !== courseOfferingStatus.OPEN ||
            (this.props.courseOffering.method === "O" &&
              this.props.courseOffering.course.pendingCDIApprovalInd)
          }
        />
      </React.Fragment>
    );
  }
}

AddRemoveCartButton.propTypes = {
  actions: PropTypes.object.isRequired,
  courseOffering: PropTypes.shape(courseOffering),
  cart: PropTypes.arrayOf(PropTypes.shape(courseOffering)),
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  history: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  user: PropTypes.shape(user),
  currentUser: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

AddRemoveCartButton.defaults = {
  large: false,
  disabled: false,
};

function mapStateToProps(state) {
  return {
    cart: state.shopping.cart,
    states: state.location.states,
    user: state.lcUser.user,
    currentUser: state.auth.currentUser,
    isLoggedIn: state.auth.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, shoppingActions, modalActions, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRemoveCartButton));
