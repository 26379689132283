import React from 'react'
import PropTypes from 'prop-types';
import notice from "../../proptypes/resource/notice";
import bulletin from '../../proptypes/resource/bulletin';
import NoticesBulletinControls from "./NoticesBulletinControls";
import CarouselIndicators from "./CarouselIndicators";

class NoticesBulletinMenuDropdownContent extends React.Component {

  render() {
    return (
      <div className={this.props.className}>
        {this.props.active &&
          <div className='notices-bulletin-menu-dropdown-content'>
            <div className='notice-bulletin-dropdown-row'>
              <div className='notices-bulletin-menu-dropdown-type'>
                <span className={(this.props.type == "Notice" ? 'notice-icon info-lg' : 'bulletin-icon pin') + ' svg-icon white'}></span>
                {this.props.type == "Notice" ? "Notice" : "Bulletin"}
              </div>
              <div className='notices-bulletin-menu-dropdown-indicators'>
                <div className='notices-bulletins-nav-items-wrapper'>
                  <NoticesBulletinControls nextSlide={this.props.nextSlide} prevSlide={this.props.prevSlide} />
                  <CarouselIndicators slides={this.props.slides} currentSlide={this.props.currentSlide} setSlide={this.props.setSlide} />
                </div>
              </div>
            </div>
            <div className='notice-bulletin-dropdown-title-row'>
              <div className='notices-bulletin-menu-dropdown-title-container'>
                <div className='notices-bulletin-menu-dropdown-title'>
                    <a href={(this.props.type == "Notice") ? ("/resources/notices/" + this.props.notice.id) : (this.props.bulletin.fileUrl)}>
                      {this.props.type == "Notice" ? this.props.notice.title : this.props.bulletin.title}
                    </a>
                </div>
              </div>
              <a href={(this.props.type == "Notice") ? ("/resources/notices/" + this.props.notice.id) : (this.props.bulletin.fileUrl)}>
                <span className='svg-icon go white' />
              </a>
            </div>
          </div>
        }
      </div>
    );
  }
}

NoticesBulletinMenuDropdownContent.propTypes = {
  className: PropTypes.string,
  notice: PropTypes.shape(notice),
  bulletin: PropTypes.shape(bulletin),
  active: PropTypes.bool,
  prevSlide: PropTypes.func.isRequired,
  nextSlide: PropTypes.func.isRequired,
  slides: PropTypes.array,
  currentSlide: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  setSlide: PropTypes.func.isRequired,
}

export default NoticesBulletinMenuDropdownContent;
