import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";

import PropTypes from "prop-types";

import { ROUTES } from "../../constants/routeConfig";
import { mapWebsiteLinkPathtoLCLinkPath } from "../../utilities/routeUtilities";
import pageNode from "../../proptypes/brokerGuides/pageNode";
import * as brokerGuidesActions from '../../actions/brokerGuidesActions';
import ContentPageLayout from "../layout/ContentPageLayout";

function ResourceSectionCard(props) {
  return (
    <div className="resource-section-card">
      <div className="icon-section">
        <div className={"svg-icon " + props.iconCssName} />
      </div>

      <div className="content-section">
        <Link className="title" to={props.target}>{props.title}</Link>
        <div className={"resource-subtitle"}>{props.subtitle}</div>
      </div>

      <div className="arrow-section">
        <Link to={props.target}><div className='svg-icon chevron-right' /></Link>
      </div>
    </div>
  );
}

ResourceSectionCard.propTypes = {
  iconCssName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
}

class ResourcesPage extends React.Component {

  constructor(props) {
    super(props);
    this.goToResourcePage = this.goToResourcePage.bind(this);

    this.state = {
      filingRequirementsPageRoute: ''
    };
  }

  componentDidMount() {
    if (this.props.pageNodes.length === 0
      || !this.props.sectionPageNode) {
      this.props.actions.getSection("FilingRequirementsAndProcedures")
    }
  }

  goToResourcePage(ROUTE) {
    this.props.history.push(ROUTE);
  }

  render() {
    return (<ContentPageLayout
      documentTitle="Resources"
      headerTitle="Resources"
      headerContent={
        <React.Fragment>
          The resource section contains a variety of reference materials, such as bulletins and tutorials, to assist
          brokers in complying with California surplus line regulations.
        </React.Fragment>
      }>

      <div className={"container"}>
        <div className={"white-body"}>
          <div className={"row"}>

            <div className={"col-12 col-lg-6"}>
              <ResourceSectionCard
                iconCssName="info-lg"
                title="Notices"
                subtitle="SLA Notices are major announcements and information of high importance to the surplus line community in California."
                target={ROUTES.NOTICES_PAGE} />
            </div>

            <div className={"col-12 col-lg-6"}>
              <ResourceSectionCard
                iconCssName="faqs-lg"
                title="FAQs"
                subtitle="The &quot;Frequently Asked Questions&quot; is a list of recurring questions and corresponding answers on a variety of topics about Surplus ..."
                target={ROUTES.FAQS_PAGE} />
            </div>

            <div className={"col-12 col-lg-6"}>
              <ResourceSectionCard
                iconCssName="video-call-lg"
                title="Tutorials"
                subtitle="The basic tutorials provide an overview of California regulations on surplus line filings and the requirements of the Surplus Line ..."
                target={ROUTES.TUTORIALS_PAGE} />
            </div>

            {this.props.pageNodes.length > 0 &&
              this.props.sectionPageNode &&
              <div className={"col-12 col-lg-6"}>
                <ResourceSectionCard
                  iconCssName="file-requirements-lg"
                  title={this.props.sectionPageNode.title}
                  subtitle={this.props.sectionPageNode.subtitle}
                  target={mapWebsiteLinkPathtoLCLinkPath(this.props.pageNodes[0].relativeUrlPath)} />
              </div>
            }

            <div className={"col-12 col-lg-6"}>
              <ResourceSectionCard
                iconCssName="pin-lg"
                title="Bulletins"
                subtitle="SLA Bulletins are updates of news and events affecting the surplus line market in California. To view the title of each bulletin choose one of ..."
                target={ROUTES.BULLETINS_PAGE} />
            </div>

          </div>

          <div className={"announcement-card"}>
            <div className="svg-icon announcement-lg white" />
            <div className={"header"}>
              Coming Soon
            </div>
            <div className={"announcement-content"}>
              Compliance / Regulatory, Education and Learning Licensing, New Members / Broker Updates, & more.
            </div>
          </div>

        </div>
      </div>
    </ContentPageLayout >);
  }
}

ResourcesPage.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageNodes: PropTypes.arrayOf(PropTypes.shape(pageNode)),
  sectionPageNode: PropTypes.shape(pageNode),
};

function mapStateToProps(state) {
  return {
    pageNodes: state.brokerGuides.pageNodes,
    sectionPageNode: state.brokerGuides.sectionPageNode,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({},
    brokerGuidesActions);
  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourcesPage));

