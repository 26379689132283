import React from 'react'
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from './../inputs/Button';
import { ROUTES } from '../../constants/routeConfig';
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";

class ResourcesButton extends React.Component {
  render() {
    return(
      <Button onClick={() => this.props.history.push(ROUTES.RESOURCES_PAGE)}
              label="Resources"
              buttonSize={buttonSizes.LARGE}
              buttonType={buttonTypes.PRIMARY}
              svgIcon
              icon="resources"
              iconColor="white"/>
    );
  }
}
ResourcesButton.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(ResourcesButton);