import PropTypes from 'prop-types';
import faq from './faq';

export default {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  urlName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  faQs: PropTypes.arrayOf(PropTypes.shape(faq)),
}
