import React from 'react'
import { PropTypes } from 'prop-types';

class CourseOfferingDescription extends React.Component {
  constructor() {
    super();
    this.state = {
      descriptionOverflown: false,
    }
    this.descriptionOverflown = this.descriptionOverflown.bind(this);
  }

  descriptionOverflown() {
    if (this.descriptionRef.offsetHeight < this.descriptionDetailRef.offsetHeight) {
      this.setState({
        descriptionOverflown: true,
        descriptionFullHeight: this.descriptionDetailRef.offsetHeight,
      });
      return true;
    } else if (this.props.fullDescription && this.descriptionRef.offsetHeight > this.descriptionDetailRef.offsetHeight) {
      this.setState({
        descriptionFullHeight: this.descriptionDetailRef.offsetHeight,
      });
    }
    return false;
  }

  componentDidMount() {
    setTimeout(this.descriptionOverflown, 1000)

    this.descriptionOverflown();

    window.onresize = this.descriptionOverflown;
  }

  componentWillUnmount() {
    window.onresize = () => { };
  }

  render() {
    let { description, fullDescription, fullDescriptionClick } = this.props;

    return (
      <div className='detail-description-wrap'>
        {this.state.descriptionOverflown ?
          <div className={"detail-description" + (fullDescription ? "" : " cut")}
            ref={ref => this.descriptionRef = ref}
            style={{ height: this.state.descriptionFullHeight }}>
            <div ref={ref => this.descriptionDetailRef = ref}
              className={'detail-description-detail'}
              dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          :
          <div className={"detail-description" + (fullDescription ? "" : " cut")}
            ref={ref => this.descriptionRef = ref}>
            <div ref={ref => this.descriptionDetailRef = ref}
              className={'detail-description-detail'}
              dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        }

        {this.state.descriptionOverflown &&
          <div className={'col-12 detail-description-more' + (fullDescription ? "" : " cut")} onClick={fullDescriptionClick}>
            <div className={fullDescription ? "" : "active"}>View More +</div>
            <div className={fullDescription ? "active" : ""}>View Less -</div>
          </div>
        }
      </div>
    );
  }
}

CourseOfferingDescription.propTypes = {
  description: PropTypes.string,
  fullDescription: PropTypes.bool.isRequired,
  fullDescriptionClick: PropTypes.func.isRequired,
}

export default CourseOfferingDescription;
