import React from 'react'
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from './../inputs/Button';
import { ROUTES } from '../../constants/routeConfig';
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";

class CourseSearchButton extends React.Component {
  render() {
    return(
      <Button onClick={() => this.props.history.push(ROUTES.COURSE_LIST)}
              label="Course Search"
              buttonSize={buttonSizes.LARGE}
              buttonType={buttonTypes.WHITE_OUTLINE}
              svgIcon
              icon="search"
              iconColor="white"
              hoverIconColor="blue" />
    );
  }
}

CourseSearchButton.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(CourseSearchButton);