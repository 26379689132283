import Cookies from 'universal-cookie';
import {CONFIGURATION} from "../src/constants/config";

const cookies = new Cookies();

export default class CookieManager {

  static setCookie(name, identity, params) {
    cookies.set(name, identity, params);
  }

  static removeCookie() {
    cookies.remove(CONFIGURATION.COOKIE_NAME);
  }

  static getAccessToken() {
    let cookie = cookies.get(CONFIGURATION.COOKIE_NAME);
    return !cookie ? "" : cookie;
  }

  static clearLMSCookies() {
    cookies.remove("MoodleSession", {path: '/lms/'});
    cookies.remove(CONFIGURATION.LMS_COOKIE_NAME, { path: '/' });
  }

  static setLMSCookie(courseRegistrationId) {
    cookies.set(CONFIGURATION.LMS_COOKIE_NAME, courseRegistrationId, { path: '/' });
  }

}
