import React from "react";
import PropTypes from "prop-types";
import courseOffering from "../../../proptypes/courses/courseOffering";
import courseOfferingMethod from "../../../proptypes/courses/courseOfferingMethod";
import CourseOfferingDetailsRow from "./CourseOfferingDetailsRow";
import { connect } from "react-redux";
import OnDemandCourseOfferingDetails from "./OnDemandCourseOfferingDetails";
import LiveCourseOfferingDetails from "./LiveCourseOfferingDetails";

import * as courseSelectors from "../../../selectors/courseSelectors";
import TextRowPlaceholder from "../../resources/placeholders/TextRowPlaceholder";

const CourseOfferingDetails = function CourseOfferingDetails({
  courseOffering,
  loading,
  previouslyLoadedCourse,
}) {
  let isOnDemand = courseOffering.method === courseOfferingMethod.ON_DEMAND;
  const pendingCDIApprovalInd =
    courseOffering.course && courseOffering.course.pendingCDIApprovalInd;

  const showPlaceholder =
    loading &&
    !previouslyLoadedCourse &&
    !(
      isOnDemand &&
      courseOffering.lmsOutline &&
      courseOffering.lmsOutline.length
    );

  return (
    <React.Fragment>
      <h1 className="left-block red">Course Details</h1>
      {pendingCDIApprovalInd && (
        <CourseOfferingDetailsRow
          label="Status"
          data="This course is pending CDI approval"
        />
      )}

      {showPlaceholder &&
        new Array(5).fill().map(() => (
          <div className="row" key={Math.random()}>
            <div className='detail-label'>
              <span><TextRowPlaceholder /></span>
            </div>
          <div className='detail-item'>
            <TextRowPlaceholder />
          </div>
          </div>
        ))}

      {!showPlaceholder &&
        (isOnDemand ? (
          <OnDemandCourseOfferingDetails />
        ) : (
          <LiveCourseOfferingDetails />
        ))}
    </React.Fragment>
  );
};

CourseOfferingDetails.propTypes = {
  courseOffering: PropTypes.shape(courseOffering),
  loading: PropTypes.bool,
  previouslyLoadedCourse: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    courseOffering: courseSelectors.selectCurrentCourseOffering(state),
    loading: courseSelectors.selectIsLoadingCourse(state),
    previouslyLoadedCourse:
      courseSelectors.selectWasCurrentCoursePreviouslyLoaded(state)
  };
}

export default connect(mapStateToProps)(CourseOfferingDetails);
