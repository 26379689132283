import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import tagStyles from '../../../constants/tagStyles';

class CourseOfferingTag extends Component {
  render() {
    let {label, title, style} = this.props;

    return (
      <div className={`tag ${style} text-${style}`} 
           aria-label={title}>
           {label}
      </div>
    )
  }
}

CourseOfferingTag.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  style: PropTypes.string,
}

CourseOfferingTag.defaults = {
  style: tagStyles.SECONDARY
}

export default CourseOfferingTag;
