import React from 'react';
import PropTypes from 'prop-types';

class DynamicDocumentTitle extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let newTitle = "SLA LC";
    if (this.props.title) newTitle += ` | ${this.props.title}`;

    document.title = newTitle;

    return null;
  }
}

DynamicDocumentTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

class DocumentTitle extends React.Component {

  constructor(props) {
    super(props);
    this.titleEl = document.getElementsByTagName("title")[0];
  }

  render() {
    return (
      <React.Fragment>
        <DynamicDocumentTitle title={this.props.title} />
        {this.props.children}
      </React.Fragment>
    );
  }
}

DocumentTitle.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
};

export default DocumentTitle;
