import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import * as buttonSizes from "../../constants/buttonSizes";
import * as modalTypes from "../../constants/modalTypes";
import * as modalActions from "../../actions/modalActions";

import Button from "../inputs/Button";
import SvgIcon from "../icon/SvgIcon";
import ModalContent from './ModalContent';
import RetakeModal from './RetakeModal';
import CourseFilterOptions from '../courses/CourseFilterOptions';
import RenewalCalculatorModal from '../user/RenewalCalculatorModal';
import CompletedCourseFilters from '../user/CompletedCourseFilters';


const Modal = ({
  actions,
  buttonType,
  buttonTypeOutline,
  children,
  closeLabel,
  fontColor,
  hideButtons,
  hideIcon,
  icon,
  iconColor,
  label,
  modalAction,
  modalActionLabel,
  modalClassName,
  bodyCssClass,
  onCancel,
  showModal,
  type,
}) => {

  if (!showModal)
    return (<span></span>);

  if (type === modalTypes.RETAKE)
    return (<RetakeModal />)

  if (type === modalTypes.RENEWAL_CALCULATOR)
    return (<RenewalCalculatorModal />)

  return (
    <ModalContent
      className={modalClassName}
      bodyCssClass={bodyCssClass}
      onClose={() => { actions.closeModal(); onCancel(); }}
      fontColor={fontColor}
      label={label}
      icon={!hideIcon && <SvgIcon className={iconColor} icon={icon} />}
      buttons={!hideButtons &&
        <React.Fragment>

          <Button
            buttonSize={buttonSizes.LARGE}
            className="btn-wide"
            buttonType={buttonTypeOutline}
            onClick={() => { actions.closeModal(); onCancel(); }}
            label={closeLabel} />

          {modalAction &&
            <Button
              buttonSize={buttonSizes.LARGE}
              className="btn-wide"
              buttonType={buttonType}
              onClick={() => { modalAction(); }}
              label={modalActionLabel} />
          }

        </React.Fragment>
      } >

      {
        (() => {
          switch (type) {
            case modalTypes.COURSE_LIST_FILTERS:
              return <CourseFilterOptions displayReset={false} />;
            case modalTypes.COMPLETED_COURSES_FILTERS:
              return <CompletedCourseFilters />;
            default:
              return children;
          }
        })()
      }

    </ModalContent>);
};

Modal.propTypes = {
  actions: PropTypes.object,
  buttonType: PropTypes.string,
  buttonTypeOutline: PropTypes.string,
  children: PropTypes.any,
  closeLabel: PropTypes.string,
  fontColor: PropTypes.string,
  hideButtons: PropTypes.bool,
  hideIcon: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  label: PropTypes.string,
  modalAction: PropTypes.func,
  modalActionLabel: PropTypes.string,
  modalClassName: PropTypes.string,
  bodyCssClass: PropTypes.string,
  onCancel: PropTypes.func,
  showModal: PropTypes.bool,
  type: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    buttonType: state.modal.buttonType,
    buttonTypeOutline: state.modal.buttonTypeOutline,
    children: state.modal.children,
    closeLabel: state.modal.closeLabel,
    fontColor: state.modal.fontColor,
    hideButtons: state.modal.hideButtons,
    hideIcon: state.modal.hideIcon,
    icon: state.modal.icon,
    iconColor: state.modal.iconColor,
    label: state.modal.modalLabel,
    modalAction: state.modal.modalAction,
    modalActionLabel: state.modal.modalActionLabel,
    modalClassName: state.modal.modalClassName,
    bodyCssClass: state.modal.bodyCssClass,
    onCancel: state.modal.onCancel,
    showModal: state.modal.showModal,
    type: state.modal.modalType,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(modalActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
