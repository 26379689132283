import React from "react";
import { PropTypes } from "prop-types";
import { getCourse } from '../../api/courseApi';
import courseOfferingFormat from '../../proptypes/courses/courseOfferingFormat';
import SimpleContentPageLayout from "../layout/SimpleContentPageLayout";

class ViewWebinarPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    getCourse(this.props.match.params.courseOfferingId, courseOfferingFormat.WEBINAR).then(
      (courseOffering) => {
        window.location.href = courseOffering.webinarUrl;
      }
    );
  }

  render() {
    return (
      <SimpleContentPageLayout
        documentTitle="View Webinar"
        headerTitle="View Webinar"
      >
        <div className="course-view">
          <h5>Redirecting to webinar...</h5>
        </div>
      </SimpleContentPageLayout>
    );
  }
}

ViewWebinarPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ViewWebinarPage;
