import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";

import * as UIActions from "../../actions/UIActions";

import LandingPage from "../landing/LandingPage";
import TopNavBar from "../layout/TopNavBar";
import Footer from '../layout/Footer';

class LandingPageContainer extends React.Component {

  render() {
    //when there is an error don't show landing page styles
    let initialLoad = this.props.history.length <= 2;
    return (
      <div className={"page-wrapper " + (initialLoad ? "initial-animation" : "")}>

        <div role="main" className="content-wrapper landing-wrapper">

          <TopNavBar showSearchBar={true} activeNav={this.props.activeNav} />

          <LandingPage changeActiveNav={(nav) => { this.props.actions.setActiveNav(nav) }} />

        </div>
        <Footer />
      </div>
    );
  }
}

LandingPageContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nonComponentError: PropTypes.object,
  activeNav: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    nonComponentError: state.error.nonComponentError,
    activeNav: state.ui.activeNav
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, UIActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPageContainer));
