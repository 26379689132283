import * as resourceApi from '../api/resourceApi';
import loadingStates from '../constants/loadingStates';
import {throwError} from './errorActions';

export const actionTypes = Object.freeze({
  GET_FAQ_GROUPS_SUCCESS: 'GET_FAQ_GROUPS_SUCCESS',
  GET_FAQ_GROUP_SUCCESS: 'GET_FAQ_GROUP_SUCCESS',
  CLEAR_FAQ_GROUP: 'CLEAR_FAQ_GROUP',
  GET_FEATURED_FAQS_SUCCESS: 'GET_FEATURED_FAQS_SUCCESS',
  GET_FEATURED_BULLETINS: 'GET_FEATURED_BULLETINS',
  GET_FEATURED_BULLETINS_SUCCESS: 'GET_FEATURED_BULLETINS_SUCCESS',
  GET_FEATURED_BULLETINS_FAILURE: 'GET_FEATURED_BULLETINS_FAILURE',
  GET_SERIES: 'GET_SERIES',
  GET_SERIES_FAILURE: 'GET_SERIES_FAILURE',
  GET_SERIES_SUCCESS: 'GET_SERIES_SUCCESS',
  CLEAR_SERIES_BULLETINS: 'CLEAR_SERIES_BULLETINS',
  GET_SERIES_BULLETINS_SUCCESS: 'GET_SERIES_BULLETINS_SUCCESS',
  GET_FEATURED_NOTICES_SUCCESS: 'GET_FEATURED_NOTICES_SUCCESS',
  GET_NOTICES: 'GET_NOTICES',
  GET_NOTICES_SUCCESS: 'GET_NOTICES_SUCCESS',
  GET_NOTICES_FAILURE: 'GET_NOTICES_FAILURE',
});

export function clearFaqGroup() {
    return {type: actionTypes.CLEAR_FAQ_GROUP}
}

export function getFaqGroups({ shouldThrowError = false } = {}) {
  return dispatch => {

    return resourceApi.getFaqGroups()
      .then((faqGroups) => {
        dispatch({
          type: actionTypes.GET_FAQ_GROUPS_SUCCESS,
          data: faqGroups
        });
      })
      .catch(error => {
        if (shouldThrowError) {
          dispatch(throwError(error, { source: 'resourceActions.getFaqGroups'}))
        }
      });
  };
}

export function getFaqGroup({ url, shouldThrowError = false } = {}) {
  return dispatch => {

    return resourceApi.getFaqGroup(url)
      .then((faqGroup) => {
        dispatch({
          type: actionTypes.GET_FAQ_GROUP_SUCCESS,
          data: faqGroup
        });
      })
      .catch(error => {
        if (shouldThrowError) {
          dispatch(throwError(error, { source: 'resourceActions.getFaqGroup', urlName: url}))
        }
      });
  };
}

export function getFeaturedBulletins({ shouldThrowError = false } = {}) {
  return (dispatch, getState) => {
    const { isLoadingFeaturedBulletins } = getState().resource;

    // If we're already loading, don't fire off another request
    if (
      isLoadingFeaturedBulletins === loadingStates.LOADING ||
      isLoadingFeaturedBulletins === loadingStates.LOADED
    )
      return;

    dispatch({ type: actionTypes.GET_FEATURED_BULLETINS });
    return resourceApi
      .getFeaturedBulletins()
      .then((bulletins) => {
        dispatch({
          type: actionTypes.GET_FEATURED_BULLETINS_SUCCESS,
          data: bulletins,
        });
      })
      .catch(error => {
        dispatch({ type: actionTypes.GET_FEATURED_BULLETINS_FAILURE });
        if (shouldThrowError) {
          dispatch(
            throwError(error, { source: "resourceActions.getFeaturedBulletins" })
          );
        }
      });
  };
}

export function getFeaturedFaqs({ urlName, shouldThrowError = false } = {}) {
  return dispatch => {

    return resourceApi.getFeaturedFaqs()
      .then((featuredFaqs) => {
        dispatch({
          type: actionTypes.GET_FEATURED_FAQS_SUCCESS,
          data: featuredFaqs
        });
      })
      .catch(error => { 
        if (shouldThrowError) {
          dispatch(throwError(error, { source: 'resourceActions.getFeaturedFaqs', urlName }))
        }
      });
  };
}

export function getFeaturedNotices({ shouldThrowError = false } = {}) {
  return dispatch => {

    return resourceApi.getFeaturedNotices()
      .then((notices) => {
        dispatch({
          type: actionTypes.GET_FEATURED_NOTICES_SUCCESS,
          data: notices
        });
      })
      .catch(error => { 
        if (shouldThrowError) {
          dispatch(throwError(error, { source: 'resourceActions.getFeaturedNotices'}))
        }
      });
  };
}

export function getNotices({ shouldThrowError = false } = {}) {
  return (dispatch, getState) => {
    const { isLoadingNotices } = getState().resource;

    // If we're already loading, don't fire off another request
    if (isLoadingNotices === loadingStates.LOADING) return;

    dispatch({ type: actionTypes.GET_NOTICES });
    return resourceApi.getNotices()
      .then((notices) => {
        dispatch({
          type: actionTypes.GET_NOTICES_SUCCESS,
          data: notices
        });
      })
      .catch(error => {
        dispatch({ type: actionTypes.GET_NOTICES_FAILURE });
        if (shouldThrowError) {
           dispatch(throwError(error, { source: 'resourceActions.getNotices' }))
        }
      });
  };
}

export function getSeries({ shouldThrowError = false } = {}) {
  return (dispatch, getState) => {
    const { isLoadingSeries } = getState().resource;

    // If we're already loading, don't fire off another request
    if (isLoadingSeries === loadingStates.LOADING) return;

    dispatch({ type: actionTypes.GET_SERIES });


    return resourceApi.getSeries()
      .then(series => {
        dispatch({
          type: actionTypes.GET_SERIES_SUCCESS,
          data: series
        });
      })
      .catch(error => {
        dispatch({ type: actionTypes.GET_SERIES_FAILURE });
        if (shouldThrowError) {
          dispatch(throwError(error, { source: 'resourceActions.getSeries'}));
        }
      });
  };
}

export function clearSeriesBulletins() {
  return {type: actionTypes.CLEAR_SERIES_BULLETINS};
}

export function getBulletinsBySeries({ seriesId, shouldThrowError = false } = {}) {
  return dispatch => {

    return resourceApi.getBulletinsBySeries(seriesId)
      .then(bulletins => {
        dispatch({
          type: actionTypes.GET_SERIES_BULLETINS_SUCCESS,
          data: bulletins
        });
      })
      .catch(error => {
        if (shouldThrowError) {
          dispatch(throwError(error, { source: 'resourceActions.getBulletinsBySeries', seriesId }))
        }
      });
  };
}
