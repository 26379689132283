import PropTypes from 'prop-types';

export default {
  id: PropTypes.string.isRequired,
  urlName: PropTypes.string.isRequired,
  bulletinNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bulletinDate: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
}



