import courseOfferingMethod from "../proptypes/courses/courseOfferingMethod";
import courseOfferingStatus from "../proptypes/courses/courseOfferingStatus";
import courseOfferingRegisteredStatus from "../proptypes/courses/courseOfferingRegisteredStatus";
import * as routeConfig from "../constants/routeConfig";
import * as buttonTypes from "../constants/buttonTypes";
import loadingStates from "../constants/loadingStates";
import courseOfferingFormat from "../proptypes/courses/courseOfferingFormat";

export const defaultCourseOffering = {
  id: 0,
  uniqueId: '0',
  status: courseOfferingStatus.NEW,
  course: {
    id: 0,
    name: '',
    type: '',
  },
  presenters: [],
  method: courseOfferingMethod.ALL,
  featured: false,
  format: courseOfferingFormat.IN_PERSON,
};

export const loadingCourseKey = "isLoadingCourse";
export const loadingFutureCoursesKey = "isLoadingFutureCourses";
export const loadingLmsOutlinesKey = "isLoadingLmsOutlinesCourses";

export default {
  ui: {
    blockUI: false,
    activeNav: null,
    licenseRenewalDate: null
  },
  modal: {
    acceptCond: false,
    buttonType: buttonTypes.PRIMARY,
    buttonTypeOutline: buttonTypes.PRIMARY_OUTLINE,
    children: [],
    closeLabel: "Close",
    fontColor: "",
    hideButtons: false,
    hideIcon: false,
    icon: "alert-simple",
    iconColor: "",
    modalAction: null,
    modalActionLabel: "Submit",
    modalClassName: null,
    modalLabel: "",
    modalType: "",
    onCancel: function () { },
    showModal: false,
  },
  auth: {
    email: "",
    rememberMe: false,
    currentUser: {},
    isLoggedIn: false,
    isLoadingRegisteredCourses: loadingStates.NOT_LOADED,
    isLoadingLinkedEventCourses:loadingStates.NOT_LOADED
  },
  location: {
    states: []
  },
  error: {
    nonComponentError: null
  },
  search: {
    history: []
  },
  lcUser: {
    user: {}, //user here is for form states
    isEditing: false,
    editItemId: "",
    loginForm: {
      email: "",
      oldPassword: "",
      password: "",
      token: "",
      rememberMe: false
    },
    createAccountRedirect: routeConfig.ROUTES.LANDING_PAGE,
    loginRedirect: routeConfig.ROUTES.LANDING_PAGE,
    isLoadingLcUser: loadingStates.NOT_LOADED,
    previouslyLoadedLcUser: false,
  },
  shopping: {
    cart: [],
    registeredCourses: [], //used to display courses that you just added without keeping them in your cart
    guestUserData: {}
  },
  course: {
    courseOfferings: [],
    courseOfferingsById: {
      0: defaultCourseOffering
    },
    previouslyLoadedCourses: {},
    featuredCourseOfferings: [],
    currentOffering: 0,
    //search/sort
    searchInput: '',
    liveSortBy: 'date',
    liveSortDescending: false,
    onDemandSortBy: 'name',
    onDemandSortDescending: false,
    //filter options
    filterRecentlyPublished: false,
    filterCourseMethod: courseOfferingMethod.ALL,
    filterCECredits: false,
    filterCreditsLow: '',
    filterCreditsHigh: '',
    filterLiveDatesFrom: '',
    filterLiveDatesTo: '',
    filterRegisteredStatus: courseOfferingRegisteredStatus.ALL,
    filterCity: '',
    filterComplianceConnections: false,
    filterSlaUniversity: false,
    // loading indicators
    [loadingCourseKey]: loadingStates.NOT_LOADED,
    [loadingFutureCoursesKey]: loadingStates.NOT_LOADED,
    [loadingLmsOutlinesKey]: loadingStates.NOT_LOADED,
  },
  completedCourseFilters: {
    fromDate: null,
    toDate: null,
    ceCreditsOnly: false,
    title: '',
  },
  resource: {
    faqGroups: null,
    faqGroup: null,
    featuredFaqs: [],
    featuredBulletins: [],
    featuredNotices: [],
    notices: [],
    series: [],
    seriesBulletins: [],
  },
  brokerGuides: {
    sectionName: '',
    sectionPageNode: null,
    pageNodes: [],
    pageContent: {
      url: '',
      originalHTMLContent: '',
      bodyHTMLContent: '',
    },
  },
}
