import objectAssign from 'object-assign';
import moment from "moment/moment";

import initialState from '../initialState';
import { actionTypes } from "../../actions/brokerGuidesActions";

export default function brokerGuidesReducer(state = initialState.brokerGuides, action) {

  switch (action.type) {

    case actionTypes.GET_SECTION:
      return objectAssign({}, state, {
        sectionName: action.data.sectionName,
        sectionPageNode: action.data.sectionPageNode,
        pageNodes: action.data.pageNodes,
        persistExpiresAt: moment()
          .add(1, 'h')
          .format(),
      });

    case actionTypes.GET_PAGE_CONTENT:
      return objectAssign({}, state, {
        pageContent: action.data
      });

    default:
      return state;
  }
}