import React from 'react';
import { PropTypes } from 'prop-types';

class CarouselIndicators extends React.Component {

  render() {
    return (
      <React.Fragment>
        <div className='carousel-indicators-wrapper'>
          <div className='carousel-indicators'>
            {this.props.slides.map((item, index) =>
              <div key={index} className={'indicator' + (this.props.currentSlide === index ? ' active' : '')}
                onClick={() => { this.props.setSlide(index) }}></div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CarouselIndicators.propTypes = {
  slides: PropTypes.array.isRequired,
  currentSlide: PropTypes.number.isRequired,
  setSlide: PropTypes.func.isRequired,
}

export default CarouselIndicators;