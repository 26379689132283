import React from 'react';
import {CONFIGURATION} from "../../constants/config";

const EnvironmentInfo = () => {
  let environmentModifierClass = '';

  if (['prod'].some(x => x === window.LC.APP_MODE)) {
    environmentModifierClass = 'd-none';
  }

  return (
    <div className={`environment-info ${environmentModifierClass}`}>
      <span>
        Version: <b>{CONFIGURATION.STATE_VERSION}</b>
      </span>
      <span>
        Environment: <b>{window.LC.APP_MODE}</b>
      </span>
      <span>
        Viewport: 
        <strong className="d-inline d-sm-none">xs</strong>
        <strong className="d-none d-sm-inline d-md-none">sm</strong>
        <strong className="d-none d-md-inline d-lg-none">md</strong>
        <strong className="d-none d-lg-inline d-xl-none">lg</strong>
        <strong className="d-none d-xl-inline">xl</strong>
      </span>
    </div>
  );
};

export default EnvironmentInfo;
