import loadingStates from "../constants/loadingStates";
import initialState, {
  defaultCourseOffering,
  loadingCourseKey,
  loadingFutureCoursesKey,
  loadingLmsOutlinesKey,
} from "../store/initialState";
import {
  getCurrentRegistration
} from "../utilities/courseUtilities";

import { selectRegisteredCourses } from "./authSelectors";

const selectDomain = (state) => state.course || initialState.course;

export const selectCurrentCourseOffering = (state) => {
  const uniqueId = selectDomain(state).currentOffering;
  return selectDomain(state).courseOfferingsById[uniqueId] || defaultCourseOffering;
};

export const selectCourseOfferings = (state) =>
  Object.values(selectDomain(state).courseOfferingsById).filter(
    (course) => course.id !== 0
  );

export const selectCourseOfferingsById = (state) =>
  selectDomain(state).courseOfferingsById;

export const selectWasCurrentCoursePreviouslyLoaded = (state) =>
  selectDomain(state).previouslyLoadedCourses[
    selectDomain(state).currentOffering
  ];

export const selectShouldLoadFutureCourses = (state) =>
  selectDomain(state)[loadingFutureCoursesKey] === loadingStates.NOT_LOADED ||
  selectDomain(state)[loadingFutureCoursesKey] === loadingStates.FAILED;

export const selectIsLoadingCourse = (state) =>
  selectDomain(state)[loadingCourseKey] === loadingStates.LOADING;

export const selectIsLoadingLmsOutlines = (state) =>
  selectDomain(state)[loadingLmsOutlinesKey] === loadingStates.LOADING;

export const selectIsLoadingFutureCourses = (state) =>
  selectDomain(state)[loadingFutureCoursesKey] === loadingStates.LOADING;

export const selectCourseRegistration = (state) => {
  const registeredCourses = selectRegisteredCourses(state);
  const courseOffering = selectCurrentCourseOffering(state);

  return registeredCourses
    ? getCurrentRegistration(registeredCourses, courseOffering.uniqueId)
    : false;
};
