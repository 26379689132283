import React from 'react'
import { PropTypes } from 'prop-types';
import CarouselIndicators from './CarouselIndicators';
import CarouselControls from './CarouselControls';

class Carousel extends React.Component {
  constructor() {
    super();
    this.state = {
      currentSlide: 0,
      nextSlide: -1,
      prevSlide: -1,
      direction: '',
      timeoutId: 0,
    }

    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
    this.setSlide = this.setSlide.bind(this);
    this.autoPlay = this.autoPlay.bind(this);
  }

  componentDidMount() {
    if (this.props.autoPlay) {
      this.autoPlay()
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.state.timeoutId);
  }

  autoPlay() {
    window.clearTimeout(this.state.timeoutId);
    this.setState({
      timeoutId: 
        window.setTimeout(() => {
          this.nextSlide()
          this.autoPlay()
        }, this.props.delay)
    });
  }

  nextSlide() {
    if (this.props.autoPlay) {
      this.autoPlay()
    }
    
    let prevSlide = this.state.currentSlide;
    let currentSlide = this.state.currentSlide + 1 > this.props.children.length - 1 ? 0 : this.state.currentSlide + 1 ;
    let nextSlide = currentSlide + 1 > this.props.children.length - 1 ? 0 : currentSlide + 1;
    
    this.props.onChange(currentSlide);

    this.setState({
      currentSlide: currentSlide,
      prevSlide: prevSlide,
      nextSlide: nextSlide,
      direction: ' right',
    });
  }

  prevSlide() {
    if (this.props.autoPlay) {
      this.autoPlay()
    }

    let nextSlide = this.state.currentSlide;
    let currentSlide = this.state.currentSlide - 1 < 0 ? this.props.children.length - 1 : this.state.currentSlide - 1;
    let prevSlide = currentSlide - 1 < 0 ? this.props.children.length - 1 : currentSlide - 1;

    this.props.onChange(currentSlide);

    this.setState({
      currentSlide: currentSlide,
      prevSlide: prevSlide,
      nextSlide: nextSlide,
      direction: ' left',
    });
  }
  
  setSlide(index) {
    if (this.props.autoPlay) {
      this.autoPlay()
    }

    if (index !== this.state.currentSlide) {
      let currentSlide = index;
      let prevSlide = currentSlide - 1 < 0 ? this.props.children.length - 1 : currentSlide - 1;
      let nextSlide = currentSlide + 1 > this.props.children.length - 1 ? 0 : currentSlide + 1;
      
      this.props.onChange(currentSlide);
  
      this.setState({
        currentSlide: currentSlide,
        prevSlide: prevSlide,
        nextSlide: nextSlide,
        direction: ' right',
      });
    }
  }

  getSlideClasses(index) {
    let classes = 'container slide';
    switch (index) {
      case this.state.currentSlide:
        classes += ' current';
        break;
      case this.state.prevSlide:
        classes += ' prev';
        break;
      case this.state.nextSlide:
        classes += ' next';
    }
    return classes;
  }

  render() {
    return(
      <div className={'carousel' + this.state.direction}>
        <CarouselControls nextSlide={this.nextSlide} prevSlide={this.prevSlide}/>
        {this.props.children.map(
          (item, index) => <div className={this.getSlideClasses(index)} key={index}>{item}</div>)
        }
        <CarouselIndicators slides={this.props.children} currentSlide={this.state.currentSlide} setSlide={this.setSlide}/>
      </div>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.array,
  onChange: PropTypes.func,
  autoPlay: PropTypes.bool,
  delay: PropTypes.number,
}

Carousel.defaultProps = {
  autoPlay: true,
  delay: 15000,
}

export default Carousel;