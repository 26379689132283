import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

class LoadingScreen extends Component {
  render() {
    if(this.props.blockUI)
      return (
        <div className="loading">
          <div className="loading-spinner">
            <div className="loader-inner ball-triangle-path">
              <div/>
              <div/>
              <div/>
            </div>
          </div>
        </div>
      );
    else
      return(null);
  }
}

LoadingScreen.propTypes = {
  blockUI: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    blockUI: state.ui.blockUI
  }
}

export default connect(mapStateToProps, null)(LoadingScreen);
