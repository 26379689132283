if (process.env.JEST_WORKER_ID)
{
  require("../env.js");
}

export const CONFIGURATION = Object.freeze({
  //site
  LC_HOST: window.LC.LC_HOST,
  
  //api urls
  SERVER_API_URL: window.LC.SERVER_API_URL,
  RAPID_SERVER_URL: window.LC.RAPID_SERVER_URL,
  WEBSITE_URL: window.LC.WEBSITE_URL,

  //google analytics
  GOOGLE_ANALYTICS_KEY: window.LC.GOOGLE_ANALYTICS_KEY,

  //2 hour course title
  INTRO_COURSE_NAME: window.LC.INTRO_COURSE_NAME,

  //webinar location name
  WEBINAR_LOCATION_NAME: window.LC.WEBINAR_LOCATION_NAME,

  //cookie information
  COOKIE_NAME: 'slaLcCookie',
  LMS_COOKIE_NAME: 'slaLmsCookie',

  //redux state persist settings
  STATE_VERSION: 4,
  LOGGED_IN_DATA_EXPIRATION_HOURS: 12,
  SHOPPING_DATA_EXPIRATION_HOURS: 12,
  GOOGLE_MAP_API_KEY: 'AIzaSyCjgFOleRhn6Lffd8VW3y29N99bSgCCAT8',

  //mobile
  MAX_XS_WIDTH: 575,
  MAX_SM_WIDTH: 767,
  MAX_MD_WIDTH: 991,
  isMobileDevice: () => {
    return window.matchMedia("(max-device-width: " + CONFIGURATION.MAX_XS_WIDTH + "px)").matches;
  },
  isMobileOrTablet: () => {
    return window.matchMedia("(max-device-width: " + CONFIGURATION.MAX_MD_WIDTH + "px)").matches;
  },

  //hard coded featured faq groups (these appear first) title: position
  FEATURED_FAQ_GROUPS: {
    "TAXES & FEES": 0,
    "OTHER FEES": 1,
    "FILING PROCEDURES": 2,
    "STATE TAX": 3,
  },

});
