import React from "react";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ReactPlaceholder from 'react-placeholder';

import notice from "../../proptypes/resource/notice";

import * as resourceActions from "../../actions/resourceActions";

import { userAgentIsBot } from "../../utilities/requestUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import Notice from "./Notice";
import FaqPlaceHolder from "./placeholders/FaqPlaceHolder";

const maxSortPosition = Number.MAX_SAFE_INTEGER;

class NoticesPage extends React.Component {

  componentWillMount() {
    this.getNotices();
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.getNotices = this.getNotices.bind(this);
  }

  getNotices() {
    if (this.props.notices.length === 0) {
      this.props.actions.getNotices({ shouldThrowError: true })
        .then(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    let notices = this.props.notices || [];
    notices = [...notices].sort((a, b) => {
      return (a.sortPosition || maxSortPosition) - (b.sortPosition || maxSortPosition);
    });

    let defaultFAQToOpen = userAgentIsBot();

    const noticeId = this.props.match.params.id;

      return (
        <ContentPageLayout
          documentTitle="Notices"
          headerTitle="Notices"
          headerContent={
            <React.Fragment>
              SLA notices are major announcements and information of high importance to the surplus line community in California.
            </React.Fragment>
          }>
  
          <div className={"container"}>
            <div>
              <div className={"white-body"}>
  
                <div className="row">
                  <ReactPlaceholder showLoadingAnimation ready={!this.state.loading} customPlaceholder={FaqPlaceHolder}>
                    {notices.map(notice => <Notice notice={notice} key={notice.id} open={defaultFAQToOpen || noticeId == notice.id} />)}
                  </ReactPlaceholder>
                </div>
  
              </div>
            </div>
          </div>
        </ContentPageLayout>
      )
  }
}

NoticesPage.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object.isRequired,
  notices: PropTypes.arrayOf(PropTypes.shape(notice)),
  match: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    notices: state.resource.notices,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, resourceActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoticesPage));
