import React from 'react';
import PropTypes from 'prop-types';
import presenter from '../../../proptypes/presenter/presenter';
import { getPresenterPictureUrl, getPresenterDocumentUrl } from './../../../constants/routeConfig';

function websiteLink(website) {
  return website.includes("http") ? website : "http://" + website;
}

const PresenterDetail = ({ presenter }) => {
  return (
    <div className='presenter row'>
      <div className='presenter-image col-12 col-sm-4'>
        <div className='image-wrapper'>
          <img src={getPresenterPictureUrl(presenter.id)} />
        </div>
      </div>
      <div className='presenter-details col-12 col-sm-8'>
        <div className='presenter-name mb-3'>
          {presenter.website ?
            <a href={websiteLink(presenter.website)} target='_blank'>
              {presenter.name}
            </a>
            :
            <span>{presenter.name}</span>
          }
        </div>
        {presenter.title && <div className='presenter-title'>{presenter.title}</div>}
        {presenter.company && <div className='presenter-company'>{presenter.company}</div>}
        <p className='presenter-description' dangerouslySetInnerHTML={{ __html: presenter.details }} />

        {presenter.hasExistingDocument == true &&
          <a href={getPresenterDocumentUrl(presenter.id)}
            target="_blank"
            title="View Presenter's document"
            type="button"
            className="btn btn-outline-primary btn-md">View Document</a>
        }
      </div>
    </div>
  );
};

PresenterDetail.propTypes = {
  presenter: PropTypes.shape(presenter)
};

export default PresenterDetail;
