import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import connect from "react-redux/es/connect/connect";
import ReactPlaceholder from 'react-placeholder';

import bulletin from "../../proptypes/resource/bulletin";
import series from "../../proptypes/resource/series";

import * as resourceActions from "../../actions/resourceActions";

import { userAgentIsBot } from "../../utilities/requestUtilities";
import { sortSeries, sortBulletins } from "../../utilities/resourcesUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import ResourcesCard from "./ResourcesCard";
import SeriesCard from "./SeriesCard";
import BulletinsPlaceHolder from "./placeholders/BulletinsPlaceHolder";
import SeriesPlaceHolder from "./placeholders/SeriesPlaceHolder";
import loadingStates from "../../constants/loadingStates";

class BulletinsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      seriesLazyLoader: 6,
      bulletinsLazyLoader: 3,
    };
  }

  componentDidMount() {
        //load series
    if (!this.props.isLoadingSeries) {
      this.props.actions.getSeries({ shouldThrowError: true });
    }

    //load bulletins
    if (!this.props.isLoadingFeaturedBulletins){
      this.props.actions.getFeaturedBulletins({ shouldThrowError: true });
    }
  }


  componentDidUpdate() {

    if (this.props.hasLoadedFeaturedBulletins) {
      // Show all featured bulletins if the request was issued from the Google bot
      if (userAgentIsBot()) {
        this.setState({ bulletinsLazyLoader: this.props.featuredBulletins.length });
      }
    }

    if (this.props.hasLoadedSeries) {
      // Show all series if the request was issued from the Google bot
      if (userAgentIsBot()) {
        this.setState({ seriesLazyLoader: this.props.series.length });
      }
    }
  }

  render() {
    let sortedSeries = sortSeries(this.props.series);
    let featuredBulletins = sortBulletins(this.props.featuredBulletins);

    return (<ContentPageLayout
      documentTitle="Bulletins"
      headerTitle="Bulletins"
      headerContent={
        <React.Fragment>
          SLA bulletins are updates of news and events affecting the surplus line market in California. To view the title of each bulletin choose one of the series listed below for a complete index. Each index lists the title/subject of the bulletin, as well as the date and bulletin number.
        </React.Fragment>
      }>

      <div className={"container"}>
        <div>
          <div className={"white-body"}>
            <div className={"row"}>
              <div className={"col-lg-6"}>
                <h1 className={"left-block red"}>Series</h1>

                <ReactPlaceholder showLoadingAnimation ready={this.props.hasLoadedSeries} customPlaceholder={SeriesPlaceHolder}>
                  {sortedSeries.map((series, index) => {
                    if (index < this.state.seriesLazyLoader)
                      return <SeriesCard key={series.ordinal} series={series} />
                  })}
                  {this.state.seriesLazyLoader < sortedSeries.length ?
                    <span className="view-more" onClick={() => this.setState({ seriesLazyLoader: sortedSeries.length })}>
                      View More +</span> : sortedSeries.length <= 3 ? (<div />) : <span className="view-more" onClick={() => this.setState(() => {
                      return { seriesLazyLoader: 6 }
                    })}>View Less -</span>
                  }
                </ReactPlaceholder>

              </div>

              <div className={"col-lg-6"}>
                <h1 className={"left-block red"}>Featured Bulletins</h1>

                <ReactPlaceholder showLoadingAnimation ready={this.props.hasLoadedFeaturedBulletins} customPlaceholder={BulletinsPlaceHolder}>
                  {featuredBulletins.map((bulletin, index) => {
                    if (index < this.state.bulletinsLazyLoader) {
                      return <ResourcesCard key={bulletin.bulletinNumber} bulletin={bulletin} />
                    }
                  })}

                  {this.state.bulletinsLazyLoader < featuredBulletins.length ?
                    <span className="view-more" onClick={() => this.setState({ bulletinsLazyLoader: featuredBulletins.length })}>
                      View More +</span> : featuredBulletins.length <= 3 ? (<div />) : <span className="view-more" onClick={() => this.setState(() => {
                      return { bulletinsLazyLoader: 3 }
                    })}>View Less -</span>
                  }

                </ReactPlaceholder>

              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentPageLayout>)
  }
}

BulletinsPage.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  featuredBulletins: PropTypes.arrayOf(PropTypes.shape(bulletin)),
  series: PropTypes.arrayOf(PropTypes.shape(series)),
  hasLoadedFeaturedBulletins: PropTypes.bool,
  isLoadingFeaturedBulletins: PropTypes.bool,
  isLoadingSeries: PropTypes.bool,
  hasLoadedSeries: PropTypes.bool,
};


function mapStateToProps(state) {
  return {
    featuredBulletins: state.resource.featuredBulletins,
    series: state.resource.series,
    isLoadingSeries: state.resource.isLoadingSeries === loadingStates.LOADING,
    hasLoadedSeries: state.resource.isLoadingSeries === loadingStates.LOADED,
    hasLoadedFeaturedBulletins: state.resource.isLoadingFeaturedBulletins === loadingStates.LOADED,
    isLoadingFeaturedBulletins:state.resource.isLoadingFeaturedBulletins === loadingStates.LOADING,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, resourceActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulletinsPage));
