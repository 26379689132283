import objectAssign from "object-assign";
import initialState from "../initialState";
import { actionTypes } from "../../actions/lcUserActions";
import { actionTypes as courseActionTypes } from "../../actions/courseActions";
import moment from "moment/moment";
import { CONFIGURATION } from "../../constants/config";
import loadingStates from "../../constants/loadingStates";
import { generateUniqueId } from "../../utilities/courseUtilities";

function mapUniqueIdIntoCourseRegistrations(courseRegistrations) {
  // ensure courseRegistrations is an array of objects
  if (!Array.isArray(courseRegistrations) || courseRegistrations.some(cr => typeof cr !== 'object')) {
    throw new Error('Expected a courseRegistration');
  }
  return courseRegistrations.map((courseRegistration) => ({
    ...courseRegistration,
    courseOffering: {
      ...courseRegistration.courseOffering,
      uniqueId: generateUniqueId(courseRegistration.courseOffering),
    },
  }));
}

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case courseActionTypes.GET_REGISTERED_COURSES:
      return objectAssign({}, state, {
        isLoadingRegisteredCourses: loadingStates.LOADING,
      });

    case courseActionTypes.GET_REGISTERED_COURSES_FAILURE:
      return objectAssign({}, state, {
        isLoadingRegisteredCourses: loadingStates.FAILED,
      });

    case courseActionTypes.GET_REGISTERED_COURSES_SUCCESS:
      return objectAssign({}, state, {
        currentUser: {
          ...state.currentUser,
          registeredCourses: mapUniqueIdIntoCourseRegistrations(action.data),
        },
        isLoadingRegisteredCourses: loadingStates.LOADED,
      });

      case courseActionTypes.GET_LINKED_EVENT_COURSES:
        return objectAssign({},state,{
          isLoadingLinkedEventCourses: loadingStates.LOADING
        })

      case courseActionTypes.GET_LINKED_EVENT_COURSES_FAILURE:
          return objectAssign({},state,{
            isLoadingLinkedEventCourses: loadingStates.FAILED
        })

      case courseActionTypes.GET_LINKED_EVENT_COURSES_SUCCESS:
        return objectAssign ({},state,{
          currentUser:{
            ...state.currentUser,
            courseCompleted: action.data
          }
        })


    case actionTypes.CHANGE_REMEMBER_ME:
      return objectAssign({}, state, action.data);

    case actionTypes.CLEAR_REMEMBER_ME_DATA:
      return objectAssign({}, state, {
        email: initialState.auth.email,
        rememberMe: initialState.auth.rememberMe,
      });

    case actionTypes.LOGIN_SUCCESS:
      return objectAssign({}, state, {
        currentUser: action.data.user,
        isLoggedIn: true,
        //set the state expiration
        persistExpiresAt: moment()
          .add(CONFIGURATION.LOGGED_IN_DATA_EXPIRATION_HOURS, "h")
          .format(),
      });

    case actionTypes.GET_LC_USER_SUCCESS:
      return objectAssign({}, state, {
        currentUser: objectAssign({}, state.currentUser, action.data.user),
        //set the state expiration
        persistExpiresAt: moment()
          .add(CONFIGURATION.LOGGED_IN_DATA_EXPIRATION_HOURS, "h")
          .format(),
      });

    case actionTypes.LOGOUT:
      return objectAssign({}, state, {
        currentUser: initialState.auth.currentUser,
        isLoggedIn: initialState.auth.isLoggedIn,
        //clear the expiration since they are already logged out
        persistExpiresAt: undefined,
      });

    case actionTypes.UPDATE_LC_USER_SUCCESS:
      return objectAssign({}, state, {
        currentUser: action.data,
      });

    case courseActionTypes.REGISTER_FOR_COURSES_SUCCESS:
      return objectAssign({}, state, {
        currentUser: Object.keys(state.currentUser).length //check if current user is not empty
          ? objectAssign({}, state.currentUser, {
              registeredCourses: [
                ...(state.currentUser.registeredCourses || []),
                ...mapUniqueIdIntoCourseRegistrations(action.data.registeredCourses),
              ],
            })
          : initialState.auth.currentUser,
      });

    case courseActionTypes.RETAKE_COURSE_SUCCESS:
      return objectAssign({}, state, {
        currentUser: Object.keys(state.currentUser).length //check if current user is not empty
          ? objectAssign({}, state.currentUser, {
              registeredCourses: [
                ...(state.currentUser.registeredCourses || []),
                {
                  ...action.data.registeredCourse,
                  uniqueId: generateUniqueId(action.data.registeredCourse),
                },
              ],
            })
          : initialState.auth.currentUser,
      });

    case courseActionTypes.DROP_COURSE_SUCCESS:
      return objectAssign({}, state, {
        currentUser: Object.keys(state.currentUser).length //check if current user is not empty
          ? objectAssign({}, state.currentUser, {
              registeredCourses: state.currentUser.registeredCourses.filter(
                (courseRegistration) => courseRegistration.id !== action.data
              ),
            })
          : initialState.auth.currentUser,
      });

    case courseActionTypes.GET_LMS_COURSE_REGISTRATION_SUCCESS:
      return objectAssign({}, state, {
        currentUser: objectAssign({}, state.currentUser, {
          registeredCourses: state.currentUser.registeredCourses.map(
            (courseRegistration) => {
              if (courseRegistration.id === action.data.id) {
                return {
                  ...action.data,
                  courseOffering: {
                    ...action.data.courseOffering,
                    uniqueId: generateUniqueId(action.data.courseOffering),
                  }
                };
              }

              return courseRegistration;
            }
          ),
        }),
      });

    default:
      return state;
  }
}
