import React from 'react';
import Button from './Button';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import objectAssign from "object-assign";
import * as buttonTypes from "../../constants/buttonTypes";
import * as courseActions from "../../actions/courseActions";
import { bindActionCreators } from "redux";
import * as buttonSizes from "../../constants/buttonSizes";
import * as modalActions from "../../actions/modalActions";
import { toast } from "react-toastify";

class DropCourseButton extends React.Component {
  constructor() {
    super();

    this.dropCourse = this.dropCourse.bind(this);
  }

  dropCourse() {
    this.props.actions.showConfirmModal(
      <p className="text-center" key={this.props.courseRegistrationId}>Are you sure that you want to drop this course?</p>,
      "Caution: Dropping Course",
      () => {
        this.props.actions.closeModal();
        this.props.actions.dropCourse(this.props.courseRegistrationId)
          .then(() => toast.success("Course successfully dropped."))
          .catch((error) => toast.error(error.message));
      }
    );
  }

  render() {
    return (
      <Button onClick={this.dropCourse} label="Drop"
        disabled={false}
        svgIcon
        icon="remove"
        iconColor="white"
        hoverIconColor={this.props.large ? "white" : "red"}
        buttonType={buttonTypes.DANGER}
        buttonSize={this.props.large ? buttonSizes.LARGE : ''}
      />
    )
  }
}

DropCourseButton.propTypes = {
  actions: PropTypes.object.isRequired,
  courseRegistrationId: PropTypes.number.isRequired,
  large: PropTypes.bool,
};

DropCourseButton.defaults = {
  large: false,
};

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, modalActions, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(DropCourseButton);
