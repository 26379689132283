import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";

import { DatePickerDateFormats } from '../../constants/datePicker';
import * as completedCourseFiltersActions from '../../actions/completedCourseFiltersActions';

import Checkbox from "../inputs/Checkbox";
import Textbox from "../inputs/Textbox";
import StandardDatePicker from '../inputs/StandardDatePicker';

class CompletedCourseFilters extends React.Component {

  constructor(props) {
    super(props);

    this.handleToChange = this.handleToChange.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleCeCreditsChange = this.handleCeCreditsChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
  }

  handleFromChange(value) {
    this.props.actions.changeFromDate(value);
  }

  handleToChange(value) {
    this.props.actions.changeToDate(value);
  }

  handleCeCreditsChange() {
    this.props.actions.changeCeCreditsOnly(!this.props.ceCreditsOnly);
  }

  handleTitleChange(value) {
    this.props.actions.changeTitle(value);
  }

  render() {

    return (
      <div className={"my-courses-filter-panel no-print"}>
        <div className="row row-compact">
          <div className="col-12 col-md-3 col-xl-2 d-flex align-items-sm-center">
            <Checkbox
              id={"creditsOnly"}
              labelClassName="my-0"
              label={"CE CREDITS ONLY"}
              value={this.props.ceCreditsOnly}
              checked={this.props.ceCreditsOnly}
              onChange={this.handleCeCreditsChange} />
          </div>

          <div className={"col-12 col-md-9 col-xl-3 my-2 my-md-0"}>
            <Textbox
              id={"titleFilter"}
              key={"titleFilter"}
              label={"Title"}
              placeholder={"Title"}
              value={this.props.title}
              hideLabel={true}
              onChange={event => { this.handleTitleChange(event.target.value) }} />
          </div>

          <div className={"col-12 col-xl-7"}>
            <div className="row row-compact">

              <div className="col-12 col-md-3 col-xl-4 my-2 my-xl-0 d-flex justify-content-sm-left align-items-sm-center">
                COMPLETION DATE:
              </div>

              <div className={"col-12 col-sm col-md my-2 my-xl-0 my-courses-filter-date-picker"}>
                <DatePicker
                  onChange={day => { this.handleFromChange(day) }}
                  dateFormat={DatePickerDateFormats}
                  selected={this.props.fromDate}
                  customInput={<StandardDatePicker label="" />}
                  maxDate={this.props.toDate}
                  showYearDropdown />
              </div>

              <div className="col-12 col-sm-2 col-md-1 d-flex justify-content-center align-items-sm-center">
                TO
              </div>

              <div className={"col-12 col-sm col-md my-2 my-xl-0 my-courses-filter-date-picker"}>
                <DatePicker
                  onChange={day => { this.handleToChange(day) }}
                  dateFormat={DatePickerDateFormats}
                  selected={this.props.toDate}
                  customInput={<StandardDatePicker label="" />}
                  minDate={this.props.fromDate}
                  showYearDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CompletedCourseFilters.propTypes = {
  fromDate: PropTypes.object,
  toDate: PropTypes.object,
  ceCreditsOnly: PropTypes.bool,
  title: PropTypes.string,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    fromDate: state.completedCourseFilters.fromDate,
    toDate: state.completedCourseFilters.toDate,
    ceCreditsOnly: state.completedCourseFilters.ceCreditsOnly,
    title: state.completedCourseFilters.title,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, completedCourseFiltersActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompletedCourseFilters));
