import objectAssign from 'object-assign';
import initialState from '../initialState';
import { actionTypes } from '../../actions/lcUserActions';
import { mockUser } from "../../proptypes/users/User";
import { actionTypes as courseActionTypes } from "../../actions/courseActions";
import loadingStates from '../../constants/loadingStates';
import { generateUniqueId } from '../../utilities/courseUtilities';

export default function lcUserReducer(state = initialState.lcUser, action) {

  switch (action.type) {

    case actionTypes.CHANGE_LC_USER_PASSWORD_SUCCESS:
      return objectAssign({}, state, {
        isEditing: false
      });

    case actionTypes.UPDATE_LC_USER_SUCCESS:
      return objectAssign({}, state, {
        isEditing: false
      });

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return objectAssign({}, state, {
        isEditing: false
      });

    case actionTypes.CREATE_LC_USER:
      return objectAssign({}, state, {
        isEditing: true,
        user: objectAssign({}, mockUser)
      });

    case actionTypes.ADD_GUEST_SUCCESS:
      return objectAssign({}, state, {
        user: objectAssign({}, state.user, {
          id: action.data.id,
          guestSetupToken: action.data.token
        })
      });

    case actionTypes.ADD_LC_USER_SUCCESS:
      return objectAssign({}, state, {
        user: objectAssign({}, state.user, {
          id: action.data,
        })
      });


    case actionTypes.LOGOUT:
      return objectAssign({}, state, {
        user: initialState.lcUser.user,
        previouslyLoadedLcUser: false,
      });

    case courseActionTypes.REGISTER_FOR_COURSES_SUCCESS:
      return objectAssign({}, state, {
        user: initialState.lcUser.user
      });

    case actionTypes.LOGIN_SUCCESS:
      return objectAssign({}, state, {
        user: action.data.user,
      });

    case actionTypes.GET_LC_USER:
      return objectAssign({}, state, {
        isLoadingLcUser: loadingStates.LOADING,
      });
    case actionTypes.GET_LC_USER_FAILURE:
      return objectAssign({}, state, {
        isLoadingLcUser: loadingStates.FAILED,
      });
    case actionTypes.GET_LC_USER_SUCCESS:
      return objectAssign({}, state, {
        isLoadingLcUser: loadingStates.LOADED,
        previouslyLoadedLcUser: true,
        isEditing: false,
        user: objectAssign({}, state.user, action.data.user)
      });

    case courseActionTypes.GET_REGISTERED_COURSES_SUCCESS:
      return objectAssign({}, state, {
        user: {
          ...state.user,
          registeredCourses: action.data.map(courseRegistration => ({
            ...courseRegistration,
            courseOffering: {
              ...courseRegistration.courseOffering,
              uniqueId: generateUniqueId(courseRegistration.courseOffering)
            },
          })),
         },
      });

    case actionTypes.EDIT_LC_USER:
      return objectAssign({}, state, {
        isEditing: true
      });

    case actionTypes.CHANGE_LOGIN_FORM:
      return objectAssign({}, state, {
        loginForm: objectAssign({}, action.data)
      });

    case actionTypes.CHANGE_LC_USER:
      return objectAssign({}, state, {
        user: objectAssign({}, action.data)
      });

    case actionTypes.CLEAR_LOGIN_FORM:
      return objectAssign({}, state, {
        loginForm: initialState.lcUser.loginForm,
        isEditing: false,
      });

    case actionTypes.SET_CREATE_ACCOUNT_REDIRECT:
      return objectAssign({}, state, {
        createAccountRedirect: action.data
      });

    case actionTypes.SET_LOGIN_REDIRECT:
      return objectAssign({}, state, {
        loginRedirect: action.data
      });

    case actionTypes.COPY_USER_DATA_TO_FORM:
      return objectAssign({}, state, {
        isEditing: false,
        user: action.data
      });

    default:
      return state;
  }

}

