import React from "react";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import { ROUTES } from "../../constants/routeConfig";

import courseOfferingMethod from "../../proptypes/courses/courseOfferingMethod";
import * as lcUserActions from '../../actions/lcUserActions';
import { generateUniqueId, sortCoursesByMethodAndDate } from "../../utilities/courseUtilities";

import CourseOfferingCard from "../courses/CourseOfferingCard";
import Button from '../inputs/Button';
import SvgIcon from "../icon/SvgIcon";
import { selectCourseOfferingsById } from "../../selectors/courseSelectors";

class ShoppingCartComplete extends React.Component {

  constructor(props) {
    super(props);

    this.createAccountFromGuestData = this.createAccountFromGuestData.bind(this);
  }

  createAccountFromGuestData() {
    this.props.actions.copyUserDataToForm(this.props.guestUserData);
    this.props.history.push(ROUTES.CREATE_ACCOUNT);
  }

  render() {
    let user = !this.props.isLoggedIn ? this.props.guestUserData : this.props.currentUser;

    let sortedCourses = sortCoursesByMethodAndDate(
      this.props.registeredCourses.map(
        course => this.props.courseOfferingsById[generateUniqueId(course)]));

    return (
      <React.Fragment>

        <h1 className="content-heading">Registration Complete</h1>

        <div className="white-body">
          <div>
            <div className="row">
              <div className="col-lg-12 col-xl-5">
                <div className="info-fields">
                  <p className="info"><label>First Name:</label> {user.firstName}</p>
                  <p className="info"><label>Middle Name:</label> {user.middleName}</p>
                  <p className="info"><label>Last Name:</label> {user.lastName}</p>
                  <p className="info"><label>License #:</label> {user.licenseNumber}</p>
                  <p className="info"><label>Company:</label> {user.companyName}</p>
                  <p className="info">
                    <label>Address:</label> <span className="value">{user.addressOne}{user.addressTwo !== '' && user.addressTwo ? `, ${user.addressTwo}` : ''}, {user.city}, {user.state} {user.zip}</span>
                  </p>
                  <p className="info"><label>Phone:</label> {user.phoneNumber} {user.phoneExt}</p>
                  <p className="info"><label>Email:</label> {user.emailAddress}</p>
                  {
                    !this.props.isLoggedIn &&
                    <div className="guest-create-account mb-4">
                      <p>
                        You are currently registered as a guest, but you have the option of creating an account below
                        where you will be able to access the full benefits of the Learning Center.
                      </p>
                      <Button onClick={this.createAccountFromGuestData}
                        label="Create Account"
                        buttonSize={buttonSizes.LARGE}
                        buttonType={buttonTypes.PRIMARY}
                        svgIcon
                        icon="create-account"
                        iconColor="white" />
                    </div>
                  }
                </div>
              </div>

              <div className="col-lg-12 col-xl-7 confirm-courses">

                {!this.props.isLoggedIn ? // if guest then split the list into confirmed and pending

                  <React.Fragment>
                    {sortedCourses.filter(c => c.method === courseOfferingMethod.ON_DEMAND).length > 0 &&
                      <div className="confirm-courses-panel">
                        <h1><SvgIcon icon="check-circle" color="green" /> Registered</h1>
                        <p className="mb-4">
                          You are registered for the following courses.
                          For On-Demand courses, an email with a link to view the course has been sent to your email
                          address.
                      </p>
                        {sortedCourses.filter(c => c.method === courseOfferingMethod.ON_DEMAND).map(courseOffering =>
                          <CourseOfferingCard
                            key={courseOffering.id}
                            courseOffering={courseOffering}
                            showButtons={false}
                            featured />
                        )}
                      </div>
                    }

                    {sortedCourses.filter(c => c.method === courseOfferingMethod.LIVE).length > 0 &&
                      <div className="confirm-courses-panel">
                        <h1><SvgIcon icon="pending" color="yellow" /> Pending Registration</h1>
                        <p className="mb-4">
                          Your request to attend the following course(s) has been recieved. you will recieve
                          a confirmation e-mail once your registration is approved.
                      </p>
                        {sortedCourses.filter(c => c.method === courseOfferingMethod.LIVE).map(courseOffering =>
                          <CourseOfferingCard
                            key={courseOffering.id}
                            courseOffering={courseOffering}
                            showButtons={false}
                            featured />
                        )}
                      </div>
                    }
                  </React.Fragment>

                  :

                  <div className="confirm-courses-panel">
                    <h1><SvgIcon icon="check-circle" color="green" /> Registered</h1>
                    <p className="mb-4">
                      You are registered for the following courses.
                      For On-Demand courses, an email with a link to view the course has been sent to your email address.
                    </p>
                    {sortedCourses.map(courseOffering =>
                      <CourseOfferingCard key={courseOffering.id} courseOffering={courseOffering} showButtons={true} featured />
                    )}
                  </div>

                }

              </div>

            </div>

            <Button onClick={() => this.props.history.push(ROUTES.LANDING_PAGE)}
              label="Return to home"
              className="btn-block-xs"
              buttonSize={buttonSizes.LARGE}
              buttonType={buttonTypes.PRIMARY}
              svgIcon
              icon="chevron-right"
              iconColor="white"
              iconRight />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ShoppingCartComplete.propTypes = {
  actions: PropTypes.object.isRequired,
  registeredCourses: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  guestUserData: PropTypes.object.isRequired,
  courseOfferingsById: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    registeredCourses: state.shopping.registeredCourses,
    states: state.location.states,
    currentUser: state.auth.currentUser,
    isLoggedIn: state.auth.isLoggedIn,
    guestUserData: state.shopping.guestUserData,
    courseOfferingsById: selectCourseOfferingsById(state),
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, lcUserActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCartComplete));
