import React from "react";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import { NavLink, withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import ReactPlaceholder from 'react-placeholder';
import { TextBlock } from 'react-placeholder/lib/placeholders'

import { ROUTES } from "../../constants/routeConfig";
import { 
  mapLCBrokerGuidePathToWebsitePath, 
  mapLCBrokerGuidePathToSection 
} from "../../utilities/routeUtilities";
import * as brokerGuidesActions from "../../actions/brokerGuidesActions";
import pageContent from "../../proptypes/brokerGuides/pageContent";
import pageNode from "../../proptypes/brokerGuides/pageNode";

import ContentPageLayout from "../layout/ContentPageLayout";
import SideNavigation from "./SideNavigation";
import TransformElement from "./TransformElement";

class BrokerGuidesContentPage extends React.Component {

  componentWillMount() {
    this.getPageContent();
  }

  componentDidMount() {
    this.getSection();
  }

  componentDidUpdate(prevProps) {
    const websiteContentPageUrl = mapLCBrokerGuidePathToWebsitePath(this.props.match.url);

    if (this.props.pageContent.url != '') {
      if (this.props.pageContent.url != websiteContentPageUrl) {
        this.setState({
          loadingPageContent: true,
        });

        this.getPageContent();
      }
    }

    if (prevProps.location.pathname != this.props.location.pathname) {
      this.getSection();
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingPageContent: true,
    };

    this.getSection = this.getSection.bind(this);
    this.getPageContent = this.getPageContent.bind(this);
  }

  getSection() {
    if (this.props.location.pathname && !this.props.sectionPageNode) {
      var sectionName = mapLCBrokerGuidePathToSection(this.props.location.pathname);
      this.props.actions.getSection(sectionName);
    }
  }

  getPageContent() {
    // Identify the path to the website resource to request.
    const websiteContentPageUrl = mapLCBrokerGuidePathToWebsitePath(this.props.match.url);

    if (this.props.pageContent.url != websiteContentPageUrl) {

      this.props.actions.clearSelectedPage();

      this.props.actions.getPageContentForUrl({ url: websiteContentPageUrl, shouldThrowError: true })
        .then((res) => {
          if (res.ok)  {
            this.setState({ loadingPageContent: false })
          }
        })
      } else {
      this.setState({ loadingPageContent: false });
    }
  }

  render() {
    const pageContent = this.props.pageContent.bodyHTMLContent || '';

    const websiteContentPageUrl = mapLCBrokerGuidePathToWebsitePath(this.props.match.url);
    const currentPageNode = (this.props.pageNodes && this.props.pageNodes.length > 0)
      ? this.props.pageNodes.find(pageNode => pageNode.relativeUrlPath == websiteContentPageUrl)
      : { title: '' };

    const section = mapLCBrokerGuidePathToSection(this.props.match.url);
    const sectionPageNode = this.props.sectionPageNode || { title: '', subtitle: null };

    let transformedElement = (
      <TransformElement element={pageContent} />
    );

    return (
      <ContentPageLayout
        documentTitle={currentPageNode.title}
        headerTitle={sectionPageNode.title || 'Loading...'}
        headerContent={sectionPageNode.subtitle || ''}>

        <div className={"container"}>
          <div className={"generic-page-content-inner"}>
            <div className="row">
              <div className="col-lg-3">
                <div className="generic-page-sidebar">

                  <div className="section title-companion-section">
                    <NavLink to={ROUTES.RESOURCES_PAGE}>
                      <span className='svg-icon chevron-left'></span> Back to Resources Page
                  </NavLink>
                  </div>

                  <div className="section page-content-section">
                    <SideNavigation selectedPageContent={this.props.pageContent} currentSection={section}></SideNavigation>
                  </div>

                </div>
              </div>
              <div className="col-lg-9">
                <div className="generic-page-article">

                  <div className="section title-section">
                    <h1>{currentPageNode.title || "Loading ..."}</h1>
                  </div>

                  <div className="section content-section">
                    <ReactPlaceholder
                      ready={!this.state.loadingPageContent}
                      customPlaceholder={
                        <div className="show-loading-animation">
                          <TextBlock color='lightgray' rows={5} />
                          <TextBlock className="mt-5" color='lightgray' rows={5} />
                        </div>
                      }>
                      {transformedElement}
                    </ReactPlaceholder>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </ContentPageLayout >
    )
  }
}

BrokerGuidesContentPage.propTypes = {
  actions: PropTypes.object,
  sectionPageNode: PropTypes.shape(pageNode),
  pageContent: PropTypes.shape(pageContent),
  pageNodes: PropTypes.arrayOf(PropTypes.shape(pageNode)),
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    sectionPageNode: state.brokerGuides.sectionPageNode,
    pageContent: state.brokerGuides.pageContent,
    pageNodes: state.brokerGuides.pageNodes,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, brokerGuidesActions);
  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrokerGuidesContentPage));