import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "../icon/SvgIcon";
import * as modalActions from "../../actions/modalActions";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import pptIcon from '../../assets/ppt.png';
import pdfIcon from '../../assets/pdf.png';
import moment from 'moment';
import { trackUserEvent } from "../../utilities/analyticsUtilities";

class ResourcesCard extends React.Component {
  constructor(props) {
    super(props);
    this.playVideo = this.playVideo.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.isDownloadable = this.isDownloadable.bind(this);
    this.downloadLink = this.downloadLink.bind(this);
    this.isVideo = this.isVideo.bind(this);
    this.captureAnalytics = this.captureAnalytics.bind(this);
  }

  isDownloadable() {
    return this.props.bulletin
      || this.props.tutorial && !this.props.tutorial.link.includes("youtube");
  }

  downloadLink() {
    if (this.props.tutorial) {
      return this.props.tutorial.link;
    } else if (this.props.bulletin) {
      return this.props.bulletin.fileUrl;
    }
  }

  isVideo() {
    return this.props.tutorial && this.props.tutorial.link.includes("youtube");
  }

  captureAnalytics(resourceCategory, action, resourceTitle) {
    trackUserEvent({
      category: resourceCategory,
      action,
      label: resourceTitle
    });
  }

  playVideo() {
    this.props.actions.showDefaultModal(
      <div className="embed-responsive embed-responsive-16by9">
        <iframe src={this.props.tutorial.link}
          className="embed-responsive-item"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen />
      </div>,
      this.props.tutorial.title, true, true);
  }

  downloadPdf(link) {
    window.open(link);
  }

  render() {
    let resourceType = this.props.bulletin ? "B" : "T";

    const ResourceCardContent = () => {
      return <React.Fragment>
        <div className={"top-right-triangle"}>
          <SvgIcon icon={this.props.bulletin ? "pin" : "video-call"} color='white' />
        </div>
        <div className={"resource-content"}>
          {
            this.props.tutorial &&
            <div className={"resource-play"}>
              {this.props.tutorial.link.includes("youtube") ?
                <SvgIcon icon={"play-tutorial"} onClick={() => { }} color='white' />
                :
                this.props.tutorial.link.includes("pdf") ?
                  <img src={pdfIcon} />
                  :
                  <img src={pptIcon} />
              }
            </div>
          }
          <div className={"resource-text"}>
            <div className={"title"}>{this.props.bulletin ? "Bulletin #" + this.props.bulletin.bulletinNumber : this.props.tutorial.title}</div>
            {
              this.props.bulletin &&
              (
                <React.Fragment>
                  <div className={"resource-subtitle"}>{this.props.bulletin.title}</div>
                  <div className={"resource-subtext"}>
                    <SvgIcon icon="cal-sm" color={"white"} /> <span
                      className="bulletin_date">{moment(this.props.bulletin.bulletinDate).format('LL')}</span>
                    <SvgIcon icon="course-number-sm" color={"white"} /> <span
                      className="series">{this.props.bulletin.bulletinNumber} Series</span>
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </div>
      </React.Fragment>
    };

    return (
      <React.Fragment>

        { this.isDownloadable() &&
          <Link className={"resources-card " + resourceType} to={this.downloadLink()} target="_blank" >
            <ResourceCardContent />
          </Link>
        }

        { this.isVideo() &&
          <div className={"resources-card " + resourceType} onClick={() => this.playVideo()}>
            <ResourceCardContent />
          </div>
        }

      </React.Fragment>
    );
  }

}

ResourcesCard.propTypes = {
  history: PropTypes.object,
  actions: PropTypes.object,
  bulletin: PropTypes.object,
  tutorial: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(modalActions, dispatch)
  };
}

export default withRouter(connect(null, mapDispatchToProps)(ResourcesCard));
