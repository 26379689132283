import React from "react";
import PropTypes from 'prop-types';

const Checkbox = ({ id, label, onChange, disabled, checked, value, inline, stacked, className, labelClassName }) => {
  return (
    <div className={`checkbox ${className || ""} ${disabled ? "disabled" : ""} ${inline ? "form-check-inline" : ""} ${stacked ? "custom-controls-stacked" : ""}`} >
      <input className="checkbox-input" type="checkbox" id={id} checked={checked} onChange={onChange} value={value} disabled={disabled} />
      <label className={`checkbox-label ${labelClassName || ""}`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  checked: PropTypes.bool,
  stacked: PropTypes.bool
};

Checkbox.defaultProps = {
  checked: false,
  stacked: false,
  disabled: false,
  inline: false
};

export default Checkbox;
