import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as lcUserActions from "../../actions/lcUserActions";
import * as shoppingActions from "../../actions/shoppingActions";
import objectAssign from "object-assign";
import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";
import Button from './Button';

class CreateAccountButton extends Component {

  constructor(props) {
    super(props);

    this.createLcUser = this.createLcUser.bind(this);
  }

  createLcUser() {
    if (this.props.createAccountRedirect)
      this.props.actions.setCreateAccountRedirect(this.props.createAccountRedirect);

    this.props.history.push(this.props.createAccountFormRoute);
  }

  render() {
    return (
      <Button onClick={() => this.createLcUser()}
              label="Create Account"
              className={this.props.className}
              buttonSize={buttonSizes.LARGE}
              buttonType={this.props.blue ? buttonTypes.WHITE_OUTLINE : this.props.primary ? buttonTypes.PRIMARY : buttonTypes.PRIMARY_OUTLINE}
              svgIcon
              icon="create-account"
              iconColor={this.props.blue || this.props.primary ? "white" : "blue"}
              hoverIconColor={this.props.blue ? "blue" : "white"}
              disabled={false}/>
    );
  }
}

CreateAccountButton.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  blue: PropTypes.bool.isRequired,
  createAccountFormRoute: PropTypes.string.isRequired,
  createAccountRedirect: PropTypes.string.isRequired,
  className: PropTypes.string,
  primary: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    shoppingActions,
    lcUserActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(null, mapDispatchToProps)(CreateAccountButton));
