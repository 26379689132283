import React, { Component } from 'react';
import PropTypes from 'prop-types';
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as courseActions from "../../actions/courseActions";
import { ROUTES } from "../../constants/routeConfig";

import CourseSearchField from "./CourseSearchField";
import { trackUserEvent } from '../../utilities/analyticsUtilities';

class CourseSearchBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showHistory: false
    };

    this.submitSearch = this.submitSearch.bind(this);
    this.submitSearchGoogleAnalytics = this.submitSearchGoogleAnalytics.bind(this);
  }

  submitSearchGoogleAnalytics(searchInput) {
    trackUserEvent({
      action: 'Search',
      category: 'Course Search',
      label: searchInput
    });
  }

  submitSearch() {
    this.setState({ showHistory: false });
    if (this.props.searchInput !== '') {
      this.props.actions.saveCourseSearchToHistory(this.props.searchInput);
      this.submitSearchGoogleAnalytics(this.props.searchInput);
    }
    this.props.history.push(ROUTES.COURSE_LIST);
  }

  render() {
    return (
      <form className={"form-inline course-search " + (this.props.className || '')}>

        <CourseSearchField
          onSearchClick={() => {
            this.state.showHistory
              ? this.submitSearch()
              : this.props.searchHistory.length > 0
                ? this.setState({ showHistory: !this.state.showHistory })
                : this.submitSearch()
          }}
          onSubmit={this.submitSearch} />

        {this.state.showHistory && this.props.searchHistory.length > 0 &&
          <ul className="search-history">
            {this.props.searchHistory.slice(0, 5).map((text, i) =>
              <li key={i} className="search-history-item" onClick={() => {
                this.props.actions.changeCourseSearchInput(text);
                this.submitSearch();
              }}>{`"${text}"`}</li>
            )}
          </ul>
        }

      </form>
    );
  }
}

CourseSearchBar.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  searchInput: PropTypes.string.isRequired,
  searchHistory: PropTypes.array.isRequired,
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    searchInput: state.course.searchInput,
    searchHistory: state.search.history,
  }
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseSearchBar));
