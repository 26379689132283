import React from "react";
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from "../../constants/routeConfig";

import ContentPageLayout from "../layout/ContentPageLayout";
import ShoppingCartListPage from "../shopping/ShoppingCartListPage";
import ShoppingCartLogin from '../shopping/ShoppingCartLogin';
import ShoppingCartComplete from "../shopping/ShoppingCartComplete";
import ShoppingCartCreateAccount from "../shopping/ShoppingCartCreateAccount";
import ShoppingCartCreateGuest from "../shopping/ShoppingCartCreateGuest";

class ShoppingCartPageContainer extends React.Component {

  render() {

    return (

      <ContentPageLayout
        documentTitle="Register"
        headerTitle="Register"
        headerContent={
          <React.Fragment>
            <span>
              To register for a course, either sign in, create an account, or sign up as a guest. If you are creating a new account, verify that the information is correct and that it corresponds to the information the CDI has on file for your license.
              <span className="d-none d-md-inline">This ensures that the CE credits earned will be accepted by the CDI. Ensure that your course selections are correct (including location) and remove courses where necessary.</span>
            </span>
          </React.Fragment>
        }>

        <div className="container">

            <Switch>
              <Route exact path={ROUTES.SHOPPING_CART} component={ShoppingCartListPage}/>
              <Route exact path={ROUTES.SHOPPING_CART_LOGIN} component={ShoppingCartLogin}/>
              <Route exact path={ROUTES.SHOPPING_CART_CREATE_ACCOUNT} component={ShoppingCartCreateAccount}/>
              <Route exact path={ROUTES.SHOPPING_CART_CREATE_GUEST} component={ShoppingCartCreateGuest}/>
              <Route exact path={ROUTES.SHOPPING_CART_COMPLETE} component={ShoppingCartComplete}/>
            </Switch>

        </div>

      </ContentPageLayout>
    );
  }
}

export default ShoppingCartPageContainer;
