import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from "moment/moment";
import Collapsible from 'react-collapsible';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';

import { truncateCourseName, sortCoursesCompletionsByDate } from "../../utilities/courseUtilities";
import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";
import courseOfferingMethod from './../../proptypes/courses/courseOfferingMethod';
import certificateStatuses from './../../proptypes/courses/certificateStatuses';
import CertificateButton from './../inputs/CertificateButton';
import { courseDetailRoute, ROUTES } from "../../constants/routeConfig";
import * as courseActions from '../../actions/courseActions';
import * as lcUserActions from "../../actions/lcUserActions";
import * as modalActions from "../../actions/modalActions";

import Button from "../inputs/Button";
import { CONFIGURATION } from "../../constants/config";
import PlayButton from '../inputs/PlayButton';
import CompletedCourseOfferingTags from '../courses/tags/CompletedCourseOfferingTags';
import CompletedCourseFilters from './CompletedCourseFilters';

const PrintButton = function ({ className }) {
  return <Button
    id={"print"}
    onClick={() => { window.print() }}
    label={"Print"}
    className={"no-print btn-blue-outline " + (className || "")}
    disabled={false}
    buttonSize={buttonSizes.MEDIUM}
    buttonType={buttonTypes.PRIMARY_OUTLINE}
    svgIcon
    icon={"print"}>
  </Button>
}

PrintButton.propTypes = {
  className: PropTypes.string,
};

class MyCompletedCourses extends React.Component {
  constructor(props) {
    super(props);

    this.printRef = React.createRef();

    this.forwardToCourse = this.forwardToCourse.bind(this);
    this.showFiltersModal = this.showFiltersModal.bind(this);
  }

  forwardToCourse(id) {
    this.props.history.push(courseDetailRoute(id));
  }

  showFiltersModal() {
    this.props.actions.showCompletedCoursesFiltersModal();
  }

  render() {
    let completedCourses = this.props.completedCourses;
    let sortedCompletedCourses = null;
    if (this.props.isLoggedIn) {
      if (this.props.fromDate) {
        completedCourses = completedCourses.filter((course) => course.courseOffering.method === courseOfferingMethod.ON_DEMAND ?
          moment(course.courseCompletionDate).isSameOrAfter(this.props.fromDate) :
          moment(course.courseOffering.date).isSameOrAfter(this.props.fromDate));
      }

      if (this.props.toDate) {
        completedCourses = completedCourses.filter((course) => course.courseOffering.method === courseOfferingMethod.ON_DEMAND ?
          moment(course.courseCompletionDate).isSameOrBefore(this.props.toDate) :
          moment(course.courseOffering.date).isSameOrBefore(this.props.toDate));
      }

      if (this.props.title !== "") {
        completedCourses = completedCourses.filter((course) => course.courseOffering.course.name.toLowerCase().includes(this.props.title.toLowerCase()));
      }

      if (this.props.ceCreditsOnly) {
        completedCourses = completedCourses.filter((course) => course.courseOffering.course.ceHours > 0);
      }
      sortedCompletedCourses = sortCoursesCompletionsByDate(true, completedCourses);
    }

    function CompletedCoursesTableLayout(props) {
      return <table className={"my-completed-courses-table"}>
        <thead>
          <tr>
            <th width="220px">COURSE</th>
            <th className={"completion-date-col"}>COMPLETION<br />DATE</th>
            <th>LOCATION</th>
            <th className={"ce-credit-col"}>CE<br />CREDIT</th>
            <th className={"no-print"}>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {
            props.completedCourses.length > 0
              ? props.completedCourses.map(courseRegistration => {
                return (
                  <tr key={courseRegistration.id}>

                    <td className={"course-col"}>
                      <div className="course-title"
                        onClick={() => props.forwardToCourseFn(courseRegistration.courseOffering.id)}>
                        {truncateCourseName(courseRegistration.courseOffering.course.name, 31)}
                      </div>
                      <CompletedCourseOfferingTags courseRegistration={courseRegistration} />
                    </td>

                    <td>{courseRegistration.courseOffering.method === courseOfferingMethod.ON_DEMAND ?
                      moment(courseRegistration.courseCompletionDate).format("MM/DD/YY") :
                      moment(courseRegistration.courseOffering.date).format("MM/DD/YY")}</td>

                    <td>{courseRegistration.courseOffering.location !== undefined ? truncateCourseName(courseRegistration.courseOffering.location.address.city, 100) : ""}</td>

                    <td>{courseRegistration.courseOffering.course.ceHours}</td>

                    <td className={"no-print"}>
                      {courseRegistration.certificateStatus == certificateStatuses.ISSUED &&
                        <CertificateButton
                          iconOnly
                          certificateUrl={courseRegistration.certificateUrl} />
                      }
                      {courseRegistration.courseOffering.method === courseOfferingMethod.ON_DEMAND &&
                        <PlayButton className='float-right' courseRegistration={courseRegistration} iconOnly />
                      }
                    </td>
                  </tr>
                );
              })
              : <tr>
                <td className={"mx-auto course-col text-center"} colSpan={5}>Please note that new user accounts must
                be verified before prior history can appear in the LC.
              </td>
              </tr>
          }
        </tbody>
      </table>
    }

    function CompletedCourseCollapsible(props) {
      let courseRegistration = props.courseRegistration;
      return (
        <Collapsible
          className={"collapsible-mycourse collapsible-enhanced"}
          openedClassName={"collapsible-mycourse collapsible-enhanced"}
          trigger={<span className="triggerText">{courseRegistration.courseOffering.course.name}</span>}>

          <div className={"row"}>
            <div className={"col"}>
              <label>COMPLETION DATE:</label>
            </div>
            <div className={"col"}>
              <p>{courseRegistration.courseOffering.method === courseOfferingMethod.ON_DEMAND ?
                moment(courseRegistration.courseCompletionDate).format("MM/DD/YYYY") :
                moment(courseRegistration.courseOffering.date).format("MM/DD/YYYY")}</p>
            </div>
          </div>

          <div className={"row"}>
            <div className={"col"}>
              <label>LOCATION:</label>
            </div>
            <div className={"col"}>
              <p>{courseRegistration.courseOffering.location !== undefined ? truncateCourseName(courseRegistration.courseOffering.location.address.city, 100) : ""}</p>
            </div>
          </div>

          <div className={"row"}>
            <div className={"col"}>
              <label>CE CREDIT:</label>
            </div>
            <div className={"col"}>
              <p>{courseRegistration.courseOffering.course.ceHours}</p>
            </div>
          </div>

          <div className={"row no-print"}>
            <div className={"col"}>
              <label>ACTIONS:</label>
            </div>
            <div className={"col completed-course-actions"}>
              {courseRegistration.certificateStatus == certificateStatuses.ISSUED &&
                <CertificateButton
                  iconOnly
                  certificateUrl={courseRegistration.certificateUrl} />
              }
              {courseRegistration.courseOffering.method === courseOfferingMethod.ON_DEMAND &&
                <PlayButton courseRegistration={courseRegistration} iconOnly />
              }
            </div>
          </div>

          <div>
            <CompletedCourseOfferingTags courseRegistration={courseRegistration} />
          </div>

        </Collapsible>
      )
    }

    function CompletedCoursesCollapsibleLayout(props) {
      return (
        props.completedCourses.length > 0
          ? <div className="collapsible-stack">
            {props.completedCourses.map(courseRegistration => {
              return (
                <CompletedCourseCollapsible
                  key={courseRegistration.id}
                  courseRegistration={courseRegistration} />
              )
            }
            )}
          </div>
          : <div className={"no-course-col"}>
            <p>Please note that new user accounts must be verified before prior history can appear in the LC.</p>
          </div>
      );
    }

    return (
      <div className={"my-completed-courses-panel"}>
        <h1 className="left-block red">My Completed Courses
          {this.props.isLoggedIn &&
            <span className="d-none d-md-inline">
              <PrintButton />
            </span>
          }
        </h1>

        {this.props.isLoggedIn ?
          <React.Fragment>
            {/* Actions for mobile display */}
            <div className="d-block d-md-none my-4">
              <div className="row">
                <div className="col-6 d-sm-none">
                  <Button
                    id={"btnFilters"}
                    onClick={() => this.showFiltersModal()}
                    label={"Filters"}
                    className={"no-print btn-blue-outline d-xs-block d-sm-none"}
                    disabled={false}
                    buttonSize={buttonSizes.MEDIUM}
                    buttonType={buttonTypes.PRIMARY_OUTLINE}
                    displayBlock={true}
                    svgIcon
                    icon={"filters"}>
                  </Button>
                </div>
                <div className="col-6">
                  <PrintButton className="btn-block" />
                </div>
              </div>
            </div>

            <div className="d-none d-sm-block">
              <CompletedCourseFilters />
            </div>

            <MediaQuery maxWidth={CONFIGURATION.MAX_SM_WIDTH}>
              {(matches) => {
                if (!matches) {
                  return (
                    <CompletedCoursesTableLayout
                      ref={el => this.printRef = el}
                      completedCourses={sortedCompletedCourses}
                      forwardToCourseFn={this.forwardToCourse} />
                  );
                } else {
                  return (
                    <CompletedCoursesCollapsibleLayout completedCourses={sortedCompletedCourses} />
                  )
                }
              }}
            </MediaQuery>
          </React.Fragment >
          :
          <React.Fragment>
            <Link to={ROUTES.LOGIN} onClick={() => {
              this.props.actions.setLoginRedirect(ROUTES.MY_COURSES_PAGE);
            }
            }>Log In</Link>&nbsp;or&nbsp;
            <Link to={ROUTES.CREATE_ACCOUNT} onClick={() => {
              this.props.actions.setCreateAccountRedirect(ROUTES.MY_COURSES_PAGE);
            }
            }>Create an Account</Link>
            &nbsp;to access this feature
          </React.Fragment>
        }
      </div>
    );
  }
}

MyCompletedCourses.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  completedCourses: PropTypes.array,
  isLoggedIn: PropTypes.bool.isRequired,
  fromDate: PropTypes.object,
  toDate: PropTypes.object,
  ceCreditsOnly: PropTypes.bool,
  title: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    fromDate: state.completedCourseFilters.fromDate,
    toDate: state.completedCourseFilters.toDate,
    ceCreditsOnly: state.completedCourseFilters.ceCreditsOnly,
    title: state.completedCourseFilters.title,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, lcUserActions, courseActions, modalActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyCompletedCourses));
