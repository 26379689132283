import React from 'react';
import PropTypes from "prop-types";
import objectAssign from "object-assign";
import { bindActionCreators } from 'redux';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import * as courseActions from "../../actions/courseActions";
import SvgIcon from "../icon/SvgIcon";
import { courseDetailRoute } from "../../constants/routeConfig";
import * as courseUtilities from '../../utilities/courseUtilities';
import CourseOfferingImage from './CourseOfferingImage';
import courseOffering from "../../proptypes/courses/courseOffering";
import courseOfferingMethods from '../../proptypes/courses/courseOfferingMethod';
import courseOfferingFormat from '../../proptypes/courses/courseOfferingFormat';
import { COURSE_SERIES } from "../../constants/courseSeriesLookup";
import { CONFIGURATION } from "../../constants/config";
import User from '../../proptypes/users/User';
import ProgressBar from '../inputs/ProgressBar';
import ICalButton from "../inputs/ICalButton";
import DropCourseButton from '../inputs/DropCourseButton';
import PlayButton from '../inputs/PlayButton';
import AddRemoveCartButton from '../shopping/AddRemoveCartButton';
import { selectIsLoadingRegisteredCourses } from '../../selectors/authSelectors';
import LoadingSpinnerOverlay from '../LoadingSpinnerOverlay';
import loadingStates from '../../constants/loadingStates';

class CourseOfferingCard extends React.Component {

  constructor(props) {
    super(props);

    this.thumbnailRef = React.createRef();

    this.courseDetailUrl = courseDetailRoute(this.props.courseOffering.id, this.props.courseOffering.format);
  }

  render() {
    let { featured, reviewing, myCourses, courseOffering, showButtons, currentUser, isLoadingRegistration } = this.props;
    let courseRegistration = currentUser
      && currentUser.registeredCourses
      && courseUtilities.getCurrentRegistration(
        currentUser.registeredCourses,
        courseOffering.uniqueId);
    let isLive = courseOffering.method === courseOfferingMethods.LIVE;
    let onDemand = courseOffering.method === courseOfferingMethods.ON_DEMAND;
    let hasLocation = courseOffering.location ? true : false;
    let isComplianceCourse = courseOffering.course.seriesId === COURSE_SERIES.COMPLIANCE_CONNECTIONS;
    let isSLAUniversityCourse = courseOffering.course.seriesId === COURSE_SERIES.SLA_UNIVERSITY;

    let hasPrimaryButtons = (isLive && courseRegistration) || showButtons;

    let iconColor = featured ? 'white' : 'gray-medium';

    const city = hasLocation && courseOffering.location.address && courseOffering.location.address.city;
    return (
      <div className={`course-card ${courseOffering.method}${featured ? " featured" : ' '}${reviewing ? " reviewing" : ' '}${myCourses ? " my-courses" : ''}`}>

        <div className='course-card-content'>
          <div className="course-card-image-container">
            <CourseOfferingImage courseOffering={courseOffering} />
          </div>
          <div className={featured ? "top-left-triangle" : "course-thumbnail"}>
            <SvgIcon icon={isLive ? (featured ? "cal" : 'cal-lg') : "play-screen"} color='white' />
            {isLive && !featured &&
              <React.Fragment>
                <span className="month">{courseUtilities.month(courseOffering.date)}</span>
                <span className="day"> {courseUtilities.day(courseOffering.date)}</span>
              </React.Fragment>
            }
          </div>

          <div className="course-card-details">
            <div className="course-text">

              <div className="course-badges">

                {isLive &&
                  courseOffering.format == courseOfferingFormat.WEBINAR &&
                  <div className="course-status info">
                    Webinar
                  </div>
                }

                {isLive &&
                  courseOffering.format == courseOfferingFormat.IN_PERSON &&
                  <div className="course-status in-person">
                    In-Person
                  </div>
                }

                {isLive &&
                  <div className={"course-status " + courseOffering.status}>
                    {courseOffering.statusText}
                  </div>
                }
                {onDemand &&
                  <div className="course-status on-demand">
                    On Demand
                  </div>
                }

                {courseOffering.course.pendingCDIApprovalInd &&
                  <React.Fragment>
                    <div className="course-status N">
                      Pending CDI Approval
                    </div>
                  </React.Fragment>
                }

              </div>

              <p className="course-name">
                <NavLink to={this.courseDetailUrl}>
                  <MediaQuery maxWidth={CONFIGURATION.MAX_XS_WIDTH}>
                    {(matches) => { //need to display shorter titles on mobile
                      if (matches)
                        return courseUtilities.truncateCourseName(courseOffering.course.name, 40);
                      else
                        return courseUtilities.truncateCourseName(courseOffering.course.name, 70);
                    }}
                  </MediaQuery>
                </NavLink>
              </p>

              {reviewing && courseOffering.presenters.length > 0 &&
                <p className="course-presenters">
                  <span className="with">with</span>{courseUtilities.formatPresenterString(courseOffering.presenters)}
                </p>
              }

              <p className="course-subtext">
                {isLive && courseOffering.format == courseOfferingFormat.WEBINAR &&
                  <span>
                    <SvgIcon icon="webinar-sm" color={iconColor} /> <span className="detail">Webinar</span>
                  </span>
                }
                {isLive && courseOffering.format == courseOfferingFormat.IN_PERSON && hasLocation && courseOffering.location.address &&
                  <span>
                    <SvgIcon icon="pin-sm" color={iconColor} /> <span className="detail">{myCourses ? courseUtilities.abbreviateOfferingLocation(city) : city}</span>
                  </span>
                }
                {isLive &&
                  <span>
                    <SvgIcon icon="cal-sm" color={iconColor} /> <span className="detail">{myCourses ? courseUtilities.formatMMDDYYYYDate(courseOffering.date) : courseUtilities.formatOfferingDate(featured, courseOffering.date)}</span>
                  </span>
                }

                <span>
                  <SvgIcon icon="course-number-sm" color={iconColor} /> <span className="detail">Course #{courseOffering.course.cdiCourseCode}</span>
                </span>

                {!isLive && courseOffering.duration > 0 &&
                  <span>
                    <SvgIcon icon="clock-sm" color={iconColor} /> <span className="detail">{courseUtilities.formatDuration(courseOffering.duration)}</span>
                  </span>
                }
              </p>
            </div>
          </div>

          <ul className="course-card-primary-actions">

            {showButtons &&
              <React.Fragment>

                {courseRegistration && !isLive &&
                  <li className='actions-item'>
                    <PlayButton courseRegistration={courseRegistration} />
                  </li>
                }

                {courseRegistration && isLive &&
                  <li className='actions-item'>
                    <DropCourseButton courseRegistrationId={courseRegistration.id} />
                  </li>
                }

                {!courseRegistration &&
                  <li className='actions-item'>
                    <LoadingSpinnerOverlay loading={isLoadingRegistration}>
                      <AddRemoveCartButton
                        courseOffering={this.props.courseOffering}
                      />
                    </LoadingSpinnerOverlay>
                  </li>
                }

                {!isLive && courseRegistration && !courseRegistration.attended &&
                  <li className="actions-item">
                    <DropCourseButton courseRegistrationId={courseRegistration.id} />
                  </li>
                }

              </React.Fragment>
            }

            {isLive && courseRegistration &&
              <li className='actions-item'>
                <ICalButton id={courseOffering.id} />
              </li>
            }

          </ul>
          <ul className={"course-card-secondary-actions" + (hasPrimaryButtons ? "" : " hoist-actions")}>
            {(isComplianceCourse || isSLAUniversityCourse) &&
              <li className={"actions-item" + (!showButtons ? " mt-2" : "")}>
                <span>
                  {isComplianceCourse &&
                    <span className={`svg-icon ${iconColor} compliance-connections`}></span>
                  }
                  {isSLAUniversityCourse &&
                    <span className={`svg-icon ${iconColor} sla-university`}></span>
                  }
                </span>
              </li>
            }

            <li className={"actions-item" + (!showButtons ? " mt-2" : "")}>
              <span>
                <span className="credits-number">{courseOffering.course.ceHours}</span>
                Credits
              </span>
            </li>

            <li className="actions-item">
              <NavLink className="clickable" to={this.courseDetailUrl}>
                <SvgIcon icon="info" color={iconColor} />
                Details
              </NavLink>
            </li>
          </ul>
        </div>
        {courseRegistration && courseRegistration.progress && !isLive &&
          <ProgressBar min={0} max={100} current={courseUtilities.calculateProgress(courseRegistration.progress)} />
        }
      </div>
    );
  }
}

CourseOfferingCard.propTypes = {
  history: PropTypes.object.isRequired,
  featured: PropTypes.bool.isRequired,
  reviewing: PropTypes.bool.isRequired,
  myCourses: PropTypes.bool.isRequired,
  isLoadingRegistration: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape(User),
  courseOffering: PropTypes.shape(courseOffering).isRequired,
  showButtons: PropTypes.bool,
  actions: PropTypes.object.isRequired,
};

CourseOfferingCard.defaultProps = {
  featured: false,
  reviewing: false,
  showButtons: true,
  myCourses: false,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    isLoadingRegistration:
      selectIsLoadingRegisteredCourses(state) === loadingStates.LOADING,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseOfferingCard));
