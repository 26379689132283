import React from 'react';
import {TextBlock} from 'react-placeholder/lib/placeholders'

const FaqPlaceHolder = (
  <div className="row w-100">
    <div className="col-6">
      <TextBlock color='lightgray' rows={5}/>
    </div>
    <div className="col-6">
      <TextBlock color='lightgray' rows={5}/>
    </div>
  </div>
);

export default FaqPlaceHolder;
