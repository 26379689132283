import React from 'react';
import DatePicker from "react-datepicker";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import moment from 'moment';
import objectAssign from "object-assign";

import { DatePickerDateFormats } from '../../constants/datePicker';
import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";

import courseOffering from "../../proptypes/courses/courseOffering";
import courseOfferingMethod, { CourseOfferingMethods } from "../../proptypes/courses/courseOfferingMethod";
import courseOfferingRegisteredStatus from "../../proptypes/courses/courseOfferingRegisteredStatus";

import * as courseActions from "../../actions/courseActions";

import { selectCourseOfferings } from "../../selectors/courseSelectors";

import * as courseUtilities from "../../utilities/courseUtilities";

import Button from '../inputs/Button';
import Checkbox from "../inputs/Checkbox";
import Radiobutton from "../inputs/Radiobutton";
import StandardDatePicker from "../inputs/StandardDatePicker";
import Select from '../inputs/Select';
import CourseSearchField from './CourseSearchField';

const CourseFilterOptions = props => {

  var citySelectionList = courseUtilities.getListOfCities(props.courseOfferings);

  return (

    <div className="filter-panel">

      <div className="course-search white in-form d-block d-lg-none"><CourseSearchField /></div>

      <p>Course Type</p>
      <Radiobutton
        name="type"
        label="All"
        onChange={() => props.actions.changeMethodFilter(courseOfferingMethod.ALL)}
        checked={props.filterCourseMethod === courseOfferingMethod.ALL} />

      <Radiobutton
        name="type"
        label="Live"
        onChange={() => props.actions.changeMethodFilter(courseOfferingMethod.LIVE)}
        checked={props.filterCourseMethod === courseOfferingMethod.LIVE} />

      <Radiobutton
        name="type"
        label="On Demand"
        onChange={() => props.actions.changeMethodFilter(courseOfferingMethod.ON_DEMAND)}
        checked={props.filterCourseMethod === courseOfferingMethod.ON_DEMAND} />

      <hr />

      <Checkbox id="CECreditsFilter" checked={props.filterCECredits} label="CE Credits" onChange={props.actions.changeCECreditsFilter} />

      <form className="form-inline ml-4">
        <input
          className="form-control form-control-sm w-25"
          type="number"
          value={props.filterCreditsLow}
          onChange={(event) => props.actions.changeCreditsLowFilter(event.target.value)} />
        <span className="mx-2"> to </span>
        <input
          className="form-control form-control-sm w-25"
          type="number"
          value={props.filterCreditsHigh}
          onChange={(event) => props.actions.changeCreditsHighFilter(event.target.value)} />
      </form>

      {(props.showComplianceConnectionsFilter || props.showSlaUniversityFilter) &&
        <React.Fragment>

          <hr />

          <p>Series</p>
          <div>
            {props.showComplianceConnectionsFilter &&
              <Checkbox id="ComplianceConnectionsFilter" checked={props.filterComplianceConnections} label="Compliance Connections" onChange={props.actions.toggleComplianceConnections} />
            }
            {props.showSlaUniversityFilter &&
              <Checkbox id="SlaUniversityFilter" checked={props.filterSlaUniversity} label="SLA University" onChange={props.actions.toggleSlaUniversity} />
            }
          </div>
        </React.Fragment>
      }

      <hr />

      <p>Live Dates</p>
      <div>
        <DatePicker
          onChange={day => props.actions.changeLiveDatesFromFilter(day)}
          dateFormat={DatePickerDateFormats}
          selected={props.filterLiveDatesFrom === '' ? null : moment(props.filterLiveDatesFrom)}
          customInput={<StandardDatePicker label="From" />}
          minDate={moment()}
          maxDate={props.filterLiveDatesTo === '' ? null : moment(props.filterLiveDatesTo)} />
        <br />
        <DatePicker
          onChange={day => props.actions.changeLiveDatesToFilter(day)}
          dateFormat={DatePickerDateFormats}
          selected={props.filterLiveDatesTo === '' ? null : moment(props.filterLiveDatesTo)}
          customInput={<StandardDatePicker label="To" />}
          minDate={props.filterLiveDatesFrom === '' ? moment() : moment(props.filterLiveDatesFrom)} />
      </div>

      {props.isLoggedIn &&
        <React.Fragment>
          <hr />

          <p> My Status</p>
          <Radiobutton
            name="status"
            label="All"
            onChange={() => props.actions.changeRegisteredStatusFilter(courseOfferingRegisteredStatus.ALL)}
            checked={props.filterRegisteredStatus === courseOfferingRegisteredStatus.ALL} />

          <Radiobutton
            name="status"
            label="Registered"
            onChange={() => props.actions.changeRegisteredStatusFilter(courseOfferingRegisteredStatus.REGISTERED)}
            checked={props.filterRegisteredStatus === courseOfferingRegisteredStatus.REGISTERED} />

          <Radiobutton
            name="status"
            label="Completed"
            onChange={() => props.actions.changeRegisteredStatusFilter(courseOfferingRegisteredStatus.COMPLETED)}
            checked={props.filterRegisteredStatus === courseOfferingRegisteredStatus.COMPLETED} />

        </React.Fragment>
      }

      <hr />

      <p>City</p>
      <div>
        <Select
          id={"filterCity"}
          label={"City"}
          defaultOptionText="Choose a city"
          value={props.filterCity}
          hideLabel
          options={citySelectionList}
          onChange={event => props.actions.changeCityFilter(event.target.value)} />
      </div>

      {props.displayReset &&
        <Button
          className="mt-4"
          onClick={props.actions.resetCourseSearchFilters}
          label="Reset Filters"
          buttonSize={buttonSizes.LARGE}
          buttonType={buttonTypes.PRIMARY}
          displayBlock={true} />
      }
    </div>
  );
};

CourseFilterOptions.propTypes = {
  actions: PropTypes.object,
  courseOfferings: PropTypes.arrayOf(PropTypes.shape(courseOffering)),
  displayReset: PropTypes.bool.isRequired,
  filterCourseMethod: PropTypes.oneOf(CourseOfferingMethods).isRequired,
  filterCECredits: PropTypes.bool.isRequired,
  filterCreditsLow: PropTypes.string.isRequired,
  filterCreditsHigh: PropTypes.string.isRequired,
  filterLiveDatesFrom: PropTypes.string.isRequired,
  filterLiveDatesTo: PropTypes.string.isRequired,
  filterRegisteredStatus: PropTypes.string.isRequired,
  filterCity: PropTypes.string.isRequired,
  filterComplianceConnections: PropTypes.bool.isRequired,
  filterSlaUniversity: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  showComplianceConnectionsFilter: PropTypes.bool.isRequired,
  showSlaUniversityFilter: PropTypes.bool.isRequired,
};

CourseFilterOptions.defaultProps = {
  displayReset: true,
  showComplianceConnectionsFilter: false,
  showSlaUniversityFilter: false,
};

function mapStateToProps(state) {
  return {
    courseOfferings: selectCourseOfferings(state),
    filterCourseMethod: state.course.filterCourseMethod,
    filterCECredits: state.course.filterCECredits,
    filterCreditsLow: state.course.filterCreditsLow,
    filterCreditsHigh: state.course.filterCreditsHigh,
    filterLiveDatesFrom: state.course.filterLiveDatesFrom,
    filterLiveDatesTo: state.course.filterLiveDatesTo,
    filterRegisteredStatus: state.course.filterRegisteredStatus,
    filterCity: state.course.filterCity,
    filterComplianceConnections: state.course.filterComplianceConnections,
    filterSlaUniversity: state.course.filterSlaUniversity,
    isLoggedIn: state.auth.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseFilterOptions);
