import React from "react";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ReactPlaceholder from 'react-placeholder';
import { TextBlock } from 'react-placeholder/lib/placeholders'

import * as brokerGuidesActions from "../../actions/brokerGuidesActions";
import pageNode from "../../proptypes/brokerGuides/pageNode";
import { mapWebsiteLinkPathtoLCLinkPath, isLCPath } from "../../utilities/routeUtilities";

import pageContent from "../../proptypes/brokerGuides/pageContent";

class SideNavigation extends React.Component {

  componentWillMount() {
    this.getChildPages();
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingChildPages: true
    };

    this.getChildPages = this.getChildPages.bind(this);
  }

  getChildPages() {
    if (this.props.sectionNameFromState != this.props.currentSection) {
      this.props.actions.getSection(this.props.currentSection)
        .then((res) => {
          if (res.ok) this.setState({ loadingChildPages: false })
        });
    } else {
      this.setState({ loadingChildPages: false });
    }
  }

  render() {
    let websitePageNodes = this.props.pageNodes || [];
    return (
      <ReactPlaceholder
        showLoadingAnimation
        ready={!this.state.loadingChildPages}
        customPlaceholder={<TextBlock color='lightgray' rows={5} />}>

        {websitePageNodes.map((websitePageNode) => {
          let urlPath = websitePageNode.relativeUrlPath;
          let openInNewWindow = false;

          if (websitePageNode.pageType == "Redirect") {
            urlPath = websitePageNode.redirectUrl;

            if (urlPath.indexOf("?") > -1) {
              urlPath = urlPath.concat("&pageType=Redirect");
            }
            else {
              urlPath = urlPath.concat("?pageType=Redirect");
            }

            if (websitePageNode.redirectPage.nodeId == "00000000-0000-0000-0000-000000000000") {
              openInNewWindow = websitePageNode.redirectPage.openInNewWindow;
            }
          }

          const lcContentPagePath = mapWebsiteLinkPathtoLCLinkPath(urlPath);
          return (
            <React.Fragment key={websitePageNode.id}>
              <p>
                <NavLink
                  activeStyle={{ fontWeight: "bold" }}
                  target={openInNewWindow ? "_blank" : isLCPath(lcContentPagePath) ? undefined : "_blank"}
                  to={lcContentPagePath}>
                  {websitePageNode.title}
                </NavLink>
              </p>
            </React.Fragment>
          )
        })}

      </ReactPlaceholder>)
  }
}

SideNavigation.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object.isRequired,
  sectionNameFromState: PropTypes.string,
  pageNodes: PropTypes.arrayOf(PropTypes.shape(pageNode)),
  selectedPageContent: PropTypes.shape(pageContent),
  currentSection: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    sectionNameFromState: state.brokerGuides.sectionName,
    pageNodes: state.brokerGuides.pageNodes,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, brokerGuidesActions);
  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNavigation));
