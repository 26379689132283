import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from "react-collapsible";

import { trackUserEvent } from '../../utilities/analyticsUtilities';

class Faq extends React.Component {

  constructor(props) {
    super(props);

    this.captureAnalytics = this.captureAnalytics.bind(this);
  }

  captureAnalytics(question) {
    trackUserEvent({
      category: 'FAQs',
      action: 'Expand',
      label: question
    })
  }

  render() {

    return (
      <Collapsible
        onOpening={() => this.captureAnalytics(this.props.question)}
        trigger={"Q: " + this.props.question}
        open={this.props.open}
        className={this.props.className}
        openedClassName={this.props.className}>

        <div className="faq-answer-wrapper">
          <div className={"faq-a d-inline-block"}>
            A:&nbsp;&nbsp;
          </div>

          {typeof this.props.answer === 'string'
            ? <div className={"faq-answer d-inline-block " + (this.props.answerClassName || '')} dangerouslySetInnerHTML={{ __html: this.props.answer }}></div>
            : <div className={"faq-answer d-inline-block " + (this.props.answerClassName || '')}>{this.props.answer}</div>
          }

        </div>
      </Collapsible>
    )
  }
}

Faq.propTypes = {
  question: PropTypes.string.isRequired,
  className: PropTypes.string,
  answer: PropTypes.any.isRequired,
  answerClassName: PropTypes.string,
  open: PropTypes.bool
};

export default Faq;