import React from "react";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import ReactPlaceholder from 'react-placeholder';

import { CONFIGURATION } from '../../constants/config';

import * as resourceActions from "../../actions/resourceActions";

import faqGroup from "../../proptypes/resource/faqGroup";

import { userAgentIsBot } from "../../utilities/requestUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import Faq from "./Faq.js";
import FaqPlaceHolder from "./placeholders/FaqPlaceHolder";

const maxSortPosition = Number.MAX_SAFE_INTEGER;

class FaqsPage extends React.Component {

  componentWillMount() {
    this.getFaqGroups();
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.getFaqGroups = this.getFaqGroups.bind(this);
  }

  getFaqGroups() {
    // Don't load if already loaded.
    if (this.props.faqGroups === null) {
      this.props.actions.getFaqGroups({ shouldThrowError: true })
        .then(() => this.setState({ loading: false }))
    } else {
      this.setState({ loading: false });
    }
  }

  render() {

    let faqGroups = this.props.faqGroups || [];
    let featuredFaqGroups = [];

    let defaultFAQToOpen = userAgentIsBot();

    //pull out 'featured' hard coded faqs
    faqGroups.forEach(faqGroup => {
      let title = faqGroup.title.toUpperCase();
      if (CONFIGURATION.FEATURED_FAQ_GROUPS[title] >= 0) {
        featuredFaqGroups[CONFIGURATION.FEATURED_FAQ_GROUPS[title]] = faqGroup;
        faqGroups = faqGroups.filter(faqGroup => faqGroup.title.toUpperCase() !== title);
      }
    });

    //sort remaining groups
    faqGroups = faqGroups.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });

    //join arrays
    faqGroups = featuredFaqGroups.concat(faqGroups);

      return (
        <ContentPageLayout
          documentTitle="FAQs"
          headerTitle="FAQs"
          headerContent={
            <React.Fragment>
              The “Frequently Asked Questions” section is a list of recurring questions and corresponding answers on a variety
              of topics about surplus line procedures and regulations.
            </React.Fragment>
          }>
  
          <div className={"container"}>
            <div>
              <div className={"white-body"}>
  
                <div className="row">
                  <ReactPlaceholder showLoadingAnimation ready={!this.state.loading} customPlaceholder={FaqPlaceHolder}>
  
                    {faqGroups.map((faqGroup) => {
  
                      if (!faqGroup.faQs || faqGroup.faQs.length === 0) {
                        return null;
                      }
  
                      let featuredFaqs = [];
  
                      // Trim the Faqs to only featured Faqs
                      faqGroup.faQs.forEach(faq => {
                        if (faq.featured)
                          featuredFaqs.push(faq);
                      })
  
                      // Sort the FAQs by sort position and take the top 3.
                      let faqs = featuredFaqs.sort((a, b) => {
                        return (a.sortPosition || maxSortPosition) - (b.sortPosition || maxSortPosition);
                      }).slice(0, 3);
  
                      return (
  
                        // Render FAQ group
                        <div className="col-12 col-md-6" key={faqGroup.id}>
                          <h1 className="left-block blue">{faqGroup.title}</h1>
  
                          {faqs.map((faq) =>
                            <Faq
                              question={faq.question}
                              answer={faq.answer}
                              key={faq.id}
                              open={defaultFAQToOpen}
                              className="collapsible-enhanced"
                              answerClassName="generic-content" />
                          )}
  
                          {(faqGroup.faQs.length > 3 || featuredFaqs.length < faqGroup.faQs.length) &&
                            <span className="view-more">
                              <NavLink to={"faqs/" + faqGroup.urlName}>
                                View More +
                              </NavLink>
                            </span>
                          }
  
                        </div>
                      )
  
                    })}
  
                  </ReactPlaceholder>
                </div>
  
              </div>
            </div>
          </div>
        </ContentPageLayout>
      )
  

  }
}

FaqsPage.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object.isRequired,
  faqGroups: PropTypes.arrayOf(PropTypes.shape(faqGroup)),
};

function mapStateToProps(state) {
  return {
    faqGroups: state.resource.faqGroups,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, resourceActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FaqsPage));
