import * as userApi from '../api/lcUserApi';
import CookieManager from "../../tools/CookieManager";
import {CONFIGURATION} from "../constants/config";
import {throwError} from './errorActions';
import {blockUI} from "./UIActions";
import { getRegisteredCourses } from './courseActions';

export const actionTypes = Object.freeze({
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  CREATE_LC_USER: 'CREATE_LC_USER',
  ADD_LC_USER_SUCCESS: 'ADD_LC_USER_SUCCESS',
  ADD_GUEST_SUCCESS: 'ADD_GUEST_SUCCESS',
  GET_LC_USER: 'GET_LC_USER',
  GET_LC_USER_SUCCESS: 'GET_LC_USER_SUCCESS',
  GET_LC_USER_FAILURE: 'GET_LC_USER_FAILURE',
  CHANGE_LC_USER: 'CHANGE_LC_USER',
  EDIT_LC_USER: 'EDIT_LC_USER',
  CHANGE_LOGIN_FORM: 'CHANGE_LOGIN_FORM',
  CHANGE_REMEMBER_ME: 'CHANGE_REMEMBER_ME',
  CLEAR_LOGIN_FORM: 'CLEAR_LOGIN_FORM',
  CLEAR_REMEMBER_ME_DATA: 'CLEAR_REMEMBER_ME_DATA',
  SET_CREATE_ACCOUNT_REDIRECT: 'SET_CREATE_ACCOUNT_REDIRECT',
  SET_LOGIN_REDIRECT: 'SET_LOGIN_REDIRECT',
  LOGOUT: 'LOGOUT',
  UPDATE_LC_USER_SUCCESS: 'UPDATE_LC_USER_SUCCESS',
  COPY_USER_DATA_TO_FORM: 'COPY_USER_DATA_TO_FORM',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  CONFIRM_RESET_PASSWORD_SUCCESS: 'CONFIRM_RESET_PASSWORD_SUCCESS',
  CHANGE_LC_USER_PASSWORD_SUCCESS: 'CHANGE_LC_USER_PASSWORD_SUCCESS',
  SETUP_GUEST_SUCCESS: 'SETUP_GUEST_SUCCESS',
});

export function changeRememberMe(data) {
  return { type: actionTypes.CHANGE_REMEMBER_ME, data  };
}

export function clearRememberMeData() {
  return { type: actionTypes.CLEAR_REMEMBER_ME_DATA }
}

export function clearLoginForm() {
  return { type: actionTypes.CLEAR_LOGIN_FORM }
}

export function createLcUser() {
  return { type: actionTypes.CREATE_LC_USER };
}

export function setCreateAccountRedirect(route) {
  return {type: actionTypes.SET_CREATE_ACCOUNT_REDIRECT, data: route};
}

export function setLoginRedirect(route) {
  return {type: actionTypes.SET_LOGIN_REDIRECT, data: route};
}

export function changeLcUser(data) {
  return {type: actionTypes.CHANGE_LC_USER, data};
}

export function editLcUser() {
  return {type: actionTypes.EDIT_LC_USER};
}

export function changeLoginForm(data) {
  return {type: actionTypes.CHANGE_LOGIN_FORM, data};
}

export function loginSuccess(isLoggedIn, user) {
  return {type: actionTypes.LOGIN_SUCCESS, data: {isLoggedIn, user}};
}

export function copyUserDataToForm(user) {
  return {type: actionTypes.COPY_USER_DATA_TO_FORM, data: user};
}

export function logout() {
  CookieManager.removeCookie();
  return {type: actionTypes.LOGOUT};
}

export function login(loginForm) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.login(loginForm)
      .then((loginResults) => {

        dispatch(blockUI(false));

        CookieManager.setCookie(CONFIGURATION.COOKIE_NAME, loginResults.access_token, {
          path: '/',
          expires: new Date(loginResults.expiresIn)
        });
        dispatch(loginSuccess(true, loginResults.identity));
        dispatch(getRegisteredCourses());

        return loginResults.identity;
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error
      });

  };
}

export function addLcUser(requestViewModel) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.addLcUser(requestViewModel)
      .then((id) => {
        dispatch(blockUI(false));
        dispatch({ type: actionTypes.ADD_LC_USER_SUCCESS, data: id});
        return id;
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function addGuest(requestViewModel) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.addGuest(requestViewModel)
      .then((result) => {
        dispatch(blockUI(false));
        dispatch({type: actionTypes.ADD_GUEST_SUCCESS, data: result});
        return result;
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function setupGuest(requestViewModel) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.setupGuest(requestViewModel)
      .then(() => {
        dispatch(blockUI(false));
        dispatch({type: actionTypes.SETUP_GUEST_SUCCESS});
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function setupGuestNoData(requestViewModel) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.setupGuestNoData(requestViewModel)
      .then(user => {
        dispatch(blockUI(false));
        dispatch({type: actionTypes.SETUP_GUEST_SUCCESS});
        return user;
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function getLcUser() {
  return (dispatch) => {

    return userApi.getLcUser()
      .then(user => {
        return dispatch(getRegisteredCourses()).then(() => {
          dispatch({ type: actionTypes.GET_LC_USER_SUCCESS, data: { user } })
        });
      })
      .catch(error => {
        dispatch(throwError(error, { source: 'lcUserActions.getLcUser'}))
      });
  };
}

export function resetPassword(email) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.resetPassword(email)
      .then(() => {
        dispatch(blockUI(false));
        dispatch({type: actionTypes.RESET_PASSWORD_SUCCESS});
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function confirmResetPassword(loginUserViewModel) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.confirmResetPassword(loginUserViewModel)
      .then(() => {
        dispatch(blockUI(false));
        dispatch({type: actionTypes.CONFIRM_RESET_PASSWORD_SUCCESS})
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function changePassword(requestViewModel) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.changePassword(requestViewModel)
      .then(() => {
        dispatch(blockUI(false));
        dispatch({type: actionTypes.CHANGE_LC_USER_PASSWORD_SUCCESS})
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function updateLcUser(requestViewModel) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.updateLcUser(requestViewModel)
      .then(() => {
        dispatch(blockUI(false));
        dispatch({type: actionTypes.UPDATE_LC_USER_SUCCESS, data: requestViewModel})
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}

export function validateLicenseNumber(licenseNumber) {
  return dispatch => {

    dispatch(blockUI(true));

    return userApi.validateLicenseNumber(licenseNumber)
      .then((validationResult) => {
        dispatch(blockUI(false));
        return validationResult;
      })
      .catch(error => {
        dispatch(blockUI(false));
        throw error;
      });
  };
}