import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import App from './App';
import {PersistGate} from 'redux-persist/integration/react';
import ScrollToTop from './containers/ScrollToTop';

export default class Root extends Component {
  render() {
    const { store, persistor, history } = this.props;
    return (
      <Provider store={store}>

        <PersistGate loading={null} persistor={persistor}>

          <ConnectedRouter history={history}>

            <ScrollToTop>

              <App/>

            </ScrollToTop>

          </ConnectedRouter>

        </PersistGate>

      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
