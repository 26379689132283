import objectAssign from 'object-assign';
import initialState, { loadingCourseKey, loadingFutureCoursesKey, loadingLmsOutlinesKey } from '../initialState';
import {actionTypes} from "../../actions/courseActions";
import loadingStates from '../../constants/loadingStates';
import { generateUniqueId } from '../../utilities/courseUtilities';

export default function courseReducer(state = initialState.course, action) {

  switch (action.type) {
    case actionTypes.GET_FUTURE_COURSES:
      return objectAssign({}, state, {
        [loadingFutureCoursesKey]: loadingStates.LOADING,
      })

    case actionTypes.GET_FUTURE_COURSES_SUCCESS: {
      const previouslyLoadedCourses = {
        ...state.previouslyLoadedCourses
      };
      const courseOfferingsById = {
        ...state.courseOfferingsById,
        ...action.data.reduce(
          (acc, course) => {
            // we must account for the fact that courses with multiple formats
            // will have the same course id
            const uniqueId = generateUniqueId(course);

            if (action.data.finishedLoading) {
              previouslyLoadedCourses[uniqueId] = true;
            }
            const newAcc = objectAssign(acc, {
              [uniqueId]: {
                ...course,
                uniqueId,
              },
            });
            if (
              state.courseOfferingsById[uniqueId] &&
              state.courseOfferingsById[uniqueId].lmsOutline &&
              state.courseOfferingsById[uniqueId].lmsOutline.length
            ) {
              newAcc[uniqueId].lmsOutline =
                state.courseOfferingsById[uniqueId].lmsOutline;
            } else if (course.lmsCourseId) {
              newAcc[uniqueId].lmsOutline = [];
            }
            return newAcc;
          },
          {}
        ),
      };

      return objectAssign({}, state, {
        [loadingFutureCoursesKey]: action.data.finishedLoading
          ? loadingStates.LOADED
          : loadingStates.LOADING,
        courseOfferingsById,
        previouslyLoadedCourses,
      });
    }

    case actionTypes.GET_FUTURE_COURSES_FAILURE:
      return objectAssign({}, state, {
        [loadingFutureCoursesKey]: loadingStates.FAILED,
      })

    case actionTypes.GET_LMS_OUTLINES:
      return objectAssign({}, state, {
        [loadingLmsOutlinesKey]: loadingStates.LOADING,
      })

    case actionTypes.GET_LMS_OUTLINES_SUCCESS: {
      const lmsOutlineById = action.data.courseOutlines.reduce((acc, course) =>
        objectAssign(acc, { [course.lmsCourseId]: course }),
        { ...state.lmsOutlineById }
      );

      const courseOfferingsById = Object.values(state.courseOfferingsById)
        .map(course => objectAssign({}, course, {
          lmsOutline: lmsOutlineById[course.lmsCourseId]
            ? lmsOutlineById[course.lmsCourseId].lmsOutline
            : undefined,
        }))
        .reduce((acc, course) =>
          objectAssign(acc, { [course.uniqueId]: course }),
          {}
        );

      const newState = objectAssign({}, state, {
        [loadingLmsOutlinesKey]: loadingStates.LOADED,
        lmsOutlineById,
        courseOfferingsById,
        previouslyLoadedCourses: {
          ...state.previouslyLoadedCourses,
          ...action.data.courseIds.reduce(
            (acc, id) => objectAssign(acc, { [id]: true }),
            {}
          ),
        },
      });

      if (action.data.loadingKey) {
        newState[action.data.loadingKey] = loadingStates.LOADED;
      }

      return newState;
    }

    case actionTypes.GET_LMS_OUTLINES_FAILURE:
      return objectAssign({}, state, {
        [loadingLmsOutlinesKey]: loadingStates.FAILED,
      })

    case actionTypes.CHANGE_COURSE_SEARCH_INPUT:
      return objectAssign({}, state, {
        searchInput: action.data
      });


    case actionTypes.TOGGLE_LIVE_SORT:
      return objectAssign({}, state, {
        liveSortBy: action.data,
        liveSortDescending: action.data !== state.liveSortBy ? state.liveSortDescending : !state.liveSortDescending,
      });


    case actionTypes.TOGGLE_ON_DEMAND_SORT:
      return objectAssign({}, state, {
        onDemandSortBy: action.data,
        onDemandSortDescending: action.data !== state.onDemandSortBy ? state.onDemandSortDescending : !state.onDemandSortDescending,
      });


    case actionTypes.GET_COURSE:

      return objectAssign({}, state, {
        currentOffering: action.data, // courseId
        [loadingCourseKey]: loadingStates.LOADING,
      });

    case actionTypes.GET_COURSE_SUCCESS:{
      const courseOffering = action.data.courseOffering;
      const uniqueId = generateUniqueId(courseOffering);

      const courseOfferingsById = {
        ...state.courseOfferingsById,
        [uniqueId]: {
          ...state.courseOfferingsById[uniqueId], // overwrite any known values
          ...action.data.courseOffering, // but not unknown ones
          uniqueId,
        },
      };

      return objectAssign({}, state, {
        courseOfferingsById,
        previouslyLoadedCourses: {
          ...state.previouslyLoadedCourses,
          [uniqueId]:
            state.previouslyLoadedCourses[uniqueId] ||
            action.data.finishedLoading,
        },
        [loadingCourseKey]: action.data.finishedLoading
          ? loadingStates.LOADED
          : loadingStates.LOADING,
      });
    }

    case actionTypes.GET_COURSE_FAILURE:
      return objectAssign({}, state, {
        [loadingCourseKey]: loadingStates.FAILED,
      });


    case actionTypes.CHANGE_RECENTLY_PUBLISHED_FILTER:
      return objectAssign({}, state, {
        filterRecentlyPublished: !state.filterRecentlyPublished
      });

    case actionTypes.CHANGE_METHOD_FILTER:
      return objectAssign({}, state, {
        filterCourseMethod: action.data
      });


    case actionTypes.CHANGE_CE_CREDITS_FILTER:
      return objectAssign({}, state, {
        filterCECredits: !state.filterCECredits
      });


    case actionTypes.CHANGE_CREDITS_LOW_FILTER:
      return objectAssign({}, state, {
        filterCreditsLow: action.data > 0 || !action.data ? action.data : 0
      });


    case actionTypes.CHANGE_CREDITS_HIGH_FILTER:
      return objectAssign({}, state, {
        filterCreditsHigh: action.data > 0 || !action.data ? action.data : 0
      });


    case actionTypes.CHANGE_LIVE_DATES_FROM_FILTER:
      return objectAssign({}, state, {
        filterLiveDatesFrom: action.data
      });

    case actionTypes.CHANGE_LIVE_DATES_TO_FILTER:
      return objectAssign({}, state, {
        filterLiveDatesTo: action.data
      });


    case actionTypes.CHANGE_REGISTERED_STATUS_FILTER:
      return objectAssign({}, state, {
        filterRegisteredStatus: action.data
      });


    case actionTypes.CHANGE_CITY_FILTER:
      return objectAssign({}, state, {
        filterCity: action.data
      });

    case actionTypes.TOGGLE_COMPLIANCE_CONNECTIONS:
      return objectAssign({}, state, {
        filterComplianceConnections: !state.filterComplianceConnections
      });

    case actionTypes.TOGGLE_SLA_UNIVERSITY:
      return objectAssign({}, state, {
        filterSlaUniversity: !state.filterSlaUniversity
      });

    case actionTypes.RESET_COURSE_SEARCH_FILTERS:
      return objectAssign({}, state, {
        searchInput: initialState.course.searchInput,
        filterRecentlyPublished: initialState.course.filterRecentlyPublished,
        filterCourseMethod: initialState.course.filterCourseMethod,
        filterCECredits: initialState.course.filterCECredits,
        filterCreditsLow: initialState.course.filterCreditsLow,
        filterCreditsHigh: initialState.course.filterCreditsHigh,
        filterLiveDatesFrom: initialState.course.filterLiveDatesFrom,
        filterLiveDatesTo: initialState.course.filterLiveDatesTo,
        filterRegisteredStatus: initialState.course.filterRegisteredStatus,
        filterCity: initialState.course.filterCity,
        filterComplianceConnections: initialState.course.filterComplianceConnections,
        filterSlaUniversity: initialState.course.filterSlaUniversity,
      });

    default:
      return state;
  }
}
