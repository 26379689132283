import React from "react";
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import objectAssign from "object-assign";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import * as jsonUtilities from '../../utilities/jsonUtilities';
import * as errorActions from '../../actions/errorActions';
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      componentError: null,
      componentErrorInfo: null
    };

    this.historyListener = this.historyListener.bind(this);
    this.getSessionData = this.getSessionData.bind(this);

    props.history.listen(this.historyListener);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      componentError: error,
      componentErrorInfo: errorInfo
    });
    this.props.actions.postError("JS Error [Component] : " + error.message, this.getSessionData(), errorInfo.componentStack);
  }

  componentWillUpdate(nextProps) {
    if(nextProps.nonComponentError) {
      this.props.actions.postError(
        "JS Error [Non Component] : " + JSON.stringify(nextProps.nonComponentError, jsonUtilities.replaceErrors, '\t'),
        this.getSessionData(nextProps),
        nextProps.nonComponentError.stack);
    }
  }

  getSessionData(nextProps) {
    return {
      user: nextProps ? nextProps.user : this.props.user,
      environment: {
        appCodeName: window.navigator.appCodeName,
        appName: window.navigator.appName,
        appVersion: window.navigator.appVersion,
        cookieEnabled: window.navigator.cookieEnabled,
        language: window.navigator.language,
        platform: window.navigator.platform,
        product: window.navigator.product,
        productSub: window.navigator.productSub,
        userAgent: window.navigator.userAgent,
        vendor: window.navigator.vendor,
        vendorSub: window.navigator.vendorSub,
      },
      route: this.props.location.pathname,
    };
  }

  historyListener() {
    //if there is an error and we navigate somewhere then reset error states
    if (this.state.componentError || this.props.nonComponentError) {

      this.setState({
        componentError: null,
        nonComponentError: null,
        componentErrorInfo: null,
        nonComponentErrorInfo: null
      });

      this.props.actions.throwError(null);
    }
  }

  render() {
    if (this.state.componentError || this.props.nonComponentError) {
      return (
        <ErrorPage componentError={this.state.componentError} componentErrorInfo={this.state.componentErrorInfo} nonComponentError={this.props.nonComponentError}/>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  actions: PropTypes.object.isRequired,
  nonComponentError: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
};


function mapStateToProps(state) {
    return {
      nonComponentError: state.error.nonComponentError,
      user: state.auth.currentUser,
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = objectAssign({}, errorActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary));
