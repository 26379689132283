import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title, hideTitle, children, className }) => {
  return (
    <div className={"jumbotron jumbotron-fluid no-print " + (className ? className : '')}>
      <div className="container">
        <h1 className="header-title">{hideTitle ? "" : title}</h1>
        <p className="body">{children}</p>
      </div>
    </div>
  )
};

Header.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  className: PropTypes.string,
};

export default Header;
