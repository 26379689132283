import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import DocumentTitle from "../containers/DocumentTitle";
import TopNavBar from "../layout/TopNavBar";
import Header from '../layout/Header';
import Footer from '../layout/Footer';

class ContentPageLayout extends React.Component {

    render() {
        let initialLoad = this.props.history.length <= 2;

        return (
            <DocumentTitle title={this.props.documentTitle} >

                <div className={"page-wrapper " + (initialLoad ? "initial-animation" : "")}>

                    <div role="main" className={"content-wrapper"}>

                        <div className="page-header content-page-header">
                            <TopNavBar
                                showSearchItem={this.props.showNavSearchItem}
                                showSearchBar={this.props.showNavSearchField} />

                            <Header title={this.props.headerTitle}>
                                {this.props.headerContent}
                            </Header>
                        </div>

                        <div className="page-content">
                            {this.props.children}
                        </div>
                    </div>

                    <Footer />
                </div>

            </DocumentTitle>
        )
    }
}

ContentPageLayout.propTypes = {
    documentTitle: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    headerTitle: PropTypes.string.isRequired,
    headerContent: PropTypes.any.isRequired,
    showNavSearchItem: PropTypes.bool.isRequired,
    showNavSearchField: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
};

ContentPageLayout.defaultProps = {
    showNavSearchItem: true,
    showNavSearchField: true
};

export default withRouter(connect()(ContentPageLayout));
