import React from 'react';
import { PropTypes } from 'prop-types';
import MediaQuery from 'react-responsive';
import { CONFIGURATION } from '../../constants/config';
import SvgIcon from "../icon/SvgIcon";

class LandingBackground extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.playVideo &&
          <SvgIcon icon="play" color="blue" onClick={this.props.playVideo}/>
        }
        <div className='background'>
          {this.props.mobileBackgroundUrl ?
            <React.Fragment>
              <MediaQuery maxWidth={CONFIGURATION.MAX_XS_WIDTH}>
                <img src={this.props.mobileBackgroundUrl}/>
              </MediaQuery>
              <MediaQuery minWidth={CONFIGURATION.MAX_XS_WIDTH + 1}>
                <img src={this.props.backgroundUrl}/>
              </MediaQuery>
            </React.Fragment>
            :
            <img src={this.props.backgroundUrl}/>
          }
        </div>
      </React.Fragment>
    );
  }
}

LandingBackground.propTypes = {
  backgroundUrl: PropTypes.string.isRequired,
  mobileBackgroundUrl: PropTypes.string,
  playVideo: PropTypes.func,
}

export default LandingBackground;
