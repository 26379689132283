import React from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

class LicenseDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
   
  }

  
  render() {
    let textboxClassName = this.props.isSuccess
      ? "textbox success "
      : (this.props.isError ? "textbox error " : "textbox ");
    return (    
      <div className="field">
        
      <DatePicker
          placeholderText={"License Effective Date"}
          showIcon
          id={this.props.id}
          selected={this.props.selected}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          showYearDropdown
          className={textboxClassName + (this.props.className ? this.props.className : '')}
        />
        </div>
      
    );
  }
}

LicenseDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  selected: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
};

LicenseDatePicker.defaultProps = {
  isError: false,
  isSuccess: false,
  disabled: false,
  value: null,
  selected: null
};

export default LicenseDatePicker;
