import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { connect } from "react-redux";

import * as lcUserActions from '../../actions/lcUserActions';
import * as locationActions from '../../actions/locationActions';

import ContentPageLayout from "../layout/ContentPageLayout";
import CreateAccountForm from './CreateAccountForm';

class CreateAccount extends React.Component {

  componentWillMount() {
    //if the user form is already populated with an id, then this is a guest
    //setting a username and password and we do not need to initiate a blank form
    if (!this.props.user.id || this.props.user.id <= 1) {
      this.props.actions.createLcUser();
    }
  }

  render() {
    return (
      <ContentPageLayout
        documentTitle="Create Account"
        headerTitle="Create Account"
        headerContent={
          <React.Fragment>
            Please enter your account information below. The asterisks identify the required fields. Review your
            information to ensure it is correct before moving forward.
          </React.Fragment>
        }>

        <div className="container">
          <div className="white-body">
            <CreateAccountForm />
          </div>
        </div>

      </ContentPageLayout>
    );
  }
}

CreateAccount.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    states: state.location.states,
    user: state.lcUser.user,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    locationActions,
    lcUserActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
