import React from 'react';
import { PropTypes } from 'prop-types';
import CourseDocument from './CourseDocument';
import courseDocument from '../../../proptypes/courses/courseDocument';

class CourseDocumentList extends React.Component {
  render() {
    return(
      <div className='document-list'>
        <h1 className="left-block red">Course Materials</h1>
        <div className='documents col-sm-12'>
          {this.props.documents.map(document => <CourseDocument key={document.id} document={document} /> )}
        </div>
      </div>
    );
  }
}

CourseDocumentList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape(courseDocument)),
}

export default CourseDocumentList;