import React from 'react';
import PropTypes from 'prop-types';
import Button from "../inputs/Button";
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import Textbox from "../inputs/Textbox";
import LicenseDatePicker from "../inputs/LicenseDatePicker";
import user from "../../proptypes/users/User";
import objectAssign from "object-assign";
import * as formUtilities from "../../utilities/formUtilities";
import * as locationActions from "../../actions/locationActions";
import * as lcUserActions from "../../actions/lcUserActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "../inputs/Select";
import Checkbox from '../inputs/Checkbox';
import moment from "moment";
import { userCompanyTypeSelectOptions } from "../../proptypes/users/userCompanyTypes";

class BasicUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormValid: true,

      usernameSuccess: false,
      firstNameSuccess: false,
      lastNameSuccess: false,
      addressOneSuccess: false,
      emailAddressSuccess: false,
      cityAdrSuccess: false,
      stateSuccess: false,
      zipSuccess: false,
      phoneNumberSuccess: false,
      licenseNumberSuccess: false,
      licenseJurisdictionSuccess: false,
      licenseEffDateSuccess: false,

      usernameError: false,
      firstNameError: false,
      lastNameError: false,
      addressOneError: false,
      cityAdrError: false,
      stateError: false,
      zipError: false,
      emailAddressError: false,
      phoneNumberError: false,
      licenseNumberError: false,
      licenseJurisdictionError: false,
      licenseEffDateError: false,
      licenseFlag: false,
    };

    this.change = this.change.bind(this);
    this.changeFormValid = this.changeFormValid.bind(this);
    this.toggleLicenseField = this.toggleLicenseField.bind(this);
    this.handleEffDateChange = this.handleEffDateChange.bind(this);

    this.props.actions.GetStates();
  }

  toggleLicenseField() {
    this.setState({ licenseFlag: !this.state.licenseFlag });
    
    let updatedUser = objectAssign({}, this.props.user);
    updatedUser.licenseNumber = "";
    updatedUser.licenseJurisdiction = "";
    updatedUser.licenseEffDate = null;
    updatedUser.nonResidentInd = null;
    this.props.actions.changeLcUser(updatedUser);

    if (this.state.licenseFlag) {
      let error = !formUtilities.validLicenseNumber(this.props.user.licenseNumber);
      this.setState({ licenseNumberError: error, licenseNumberSuccess: !error,
                      licenseJurisdictionError: true, licenseJurisdictionSuccess: false,
                      licenseEffDateError: error, licenseEffDateSuccess: !error });

    }
    else {
      this.setState({ licenseNumberError: false, licenseNumberSuccess: true,
                      licenseJurisdictionError: false, licenseJurisdictionSuccess: true,
                      licenseEffDateError: false, licenseEffDateSuccess: true });
    }
  }

  changeFormValid(value) {
    this.setState({ isFormValid: value });
  }

  
 
  change(event) {
    switch (event.target.id) {
      case "userName":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ usernameError: true, usernameSuccess: false })
        }
        else {
          this.setState({ usernameError: false, usernameSuccess: true })
        }
        break;
      case "firstName":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ firstNameError: true, firstNameSuccess: false })
        }
        else {
          this.setState({ firstNameError: false, firstNameSuccess: true })
        }
        break;
      case "lastName":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ lastNameError: true, lastNameSuccess: false })
        }
        else {
          this.setState({ lastNameError: false, lastNameSuccess: true })
        }
        break;
      case "addressOne":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ addressOneError: true, addressOneSuccess: false })
        }
        else {
          this.setState({ addressOneError: false, addressOneSuccess: true })
        }
        break;
      case "city":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ cityAdrError: true, cityAdrSuccess: false })
        }
        else {
          this.setState({ cityAdrError: false, cityAdrSuccess: true })
        }
        break;
      case "zip":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ zipError: true, zipSuccess: false })
        }
        else {
          this.setState({ zipError: false, zipSuccess: true })
        }
        break;
      case "state":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ stateError: true, stateSuccess: false })
        }
        else {
          this.setState({ stateError: false, stateSuccess: true })
        }
        break;
      case "phoneNumber":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ phoneNumberError: true, phoneNumberSuccess: false })
        }
        else {
          this.setState({ phoneNumberError: false, phoneNumberSuccess: true })
        }
        break;
      case "emailAddress":
        if (formUtilities.validateEmail(event.target.value))
          if (formUtilities.formElementHasError(event.target.value)) {
            this.setState({ emailAddressError: true, emailAddressSuccess: false })
          }
          else {
            this.setState({ emailAddressError: false, emailAddressSuccess: true })
          }
        else {
          this.setState({ emailAddressError: true, emailAddressSuccess: false })
        }
        break;
      case "licenseNumber":
        if (formUtilities.formElementHasError(event.target.value) || !formUtilities.validLicenseNumber(event.target.value)) {
          this.setState({ licenseNumberError: true, licenseNumberSuccess: false })
          this.changeFormValid(false);
        }
        else {
          this.setState({ licenseNumberError: false, licenseNumberSuccess: true })
          this.changeFormValid(true);
        }
        break;
      case "licenseJurisdiction":
        if (formUtilities.formElementHasError(event.target.value)) {
          this.setState({ licenseJurisdictionError: true, licenseJurisdictionSuccess: false })
          this.changeFormValid(false);
        }
        else {
          this.setState({ licenseJurisdictionError: false, licenseJurisdictionSuccess: true })
          this.changeFormValid(true);
        }

        break;      
      default:
        this.changeFormValid(true);
        break;
    }

    let changedUser = objectAssign({}, this.props.user);
    changedUser[event.target.id] = event.target.value;

    if (changedUser.licenseJurisdiction) {
      changedUser.nonResidentInd = changedUser.licenseJurisdiction == "CA" ? "N" : "Y";
    }
    else {
      changedUser.nonResidentInd = "N";
    }

    this.props.actions.changeLcUser(changedUser);
  }

  handleEffDateChange(date){ // date is a Moment.js date
    let updatedUser = objectAssign({}, this.props.user);
    updatedUser.licenseEffDate = date;
    this.props.actions.changeLcUser(updatedUser);
  }
 

  render() {
    let shouldShowLicenseJurisdictionWarning =
      this.props.user.licenseJurisdiction != "CA" &&
      this.props.user.licenseJurisdiction != "" &&
      this.props.user.licenseJurisdiction != undefined;

    return (
      <form className="user-profile-form">

        <div className=" row mb-4">
          <div className="col-sm-12 col-md-6" >
            <Checkbox id="licenseFlag" checked={this.state.licenseFlag}
                      label="I do not have an individual license"
                      disabled={false}
                      labelClassName="license-flag-label"
                      onChange={() => this.toggleLicenseField()} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">
             <Textbox id={"licenseNumber"} label={"Individual License #"}
                isSuccess={this.state.licenseNumberSuccess} isError={this.state.licenseNumberError} value={this.props.user.licenseNumber} disabled={this.state.licenseFlag} 
                onChange={this.change} />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">
            <Select id={"licenseJurisdiction"} label={"License Jurisdiction"}
              isSuccess={this.state.licenseJurisdictionSuccess} isError={this.state.licenseJurisdictionError}
              value={this.props.user.licenseJurisdiction} defaultOptionText={"License Jurisdiction"} hideLabel options={this.props.states} disabled={this.state.licenseFlag}
              onChange={this.change} />
            { shouldShowLicenseJurisdictionWarning &&
              <span className="license-warning">WARNING: Only California Resident Licensees are eligible for California CE Credits. To receive CE credit for this course, you must submit course materials to your jurisdiction’s department of insurance.</span>
            }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">           
            <LicenseDatePicker 
              placeholderText={"License Effective Date"} 
              id={"licenseEffDate"}            
              isSuccess={this.state.licenseEffDateSuccess}
              isError={this.state.licenseEffDateError}
              disabled={this.state.licenseFlag}
              selected={this.props.user.licenseEffDate ? moment(this.props.user.licenseEffDate) : null}
              onChange={this.handleEffDateChange}
            />
            </div>
        </div>
         </div>
       
        <hr />
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">
              <Textbox id={"firstName"} isSuccess={this.state.firstNameSuccess} isError={this.state.firstNameError} label={"First Name *"}
                value={this.props.user.firstName}
                onChange={this.change} />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">
              <Textbox id={"middleName"} label={"Middle Name"} value={this.props.user.middleName}
                onChange={this.change} />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">
              <Textbox id={"lastName"} label={"Last Name"}
                isSuccess={this.state.lastNameSuccess} isError={this.state.lastNameError} value={this.props.user.lastName}
                onChange={this.change} />
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">

            <div className="form-group">
              <Textbox id={"addressOne"} label={"Address 1 *"}
                value={this.props.user.addressOne} isSuccess={this.state.addressOneSuccess} isError={this.state.addressOneError}
                onChange={this.change} />
            </div>

            <div className="form-group">
              <Textbox id={"addressTwo"} label={"Address 2"} value={this.props.user.addressTwo}
                onChange={this.change} />
            </div>

            <div className='row city-state-zip-row'>
              <div className="form-group col-sm-12 col-md-12 col-xl-5 d-inline-block">
                <Textbox id={"city"} label={"City *"} isSuccess={this.state.cityAdrSuccess} isError={this.state.cityAdrError}
                  value={this.props.user.city} onChange={this.change} />
              </div>
              <div className="form-group col-12 col-sm-6 col-md-5 col-xl-3 d-inline-block">
                <Select id={"state"} label={"St *"} defaultOptionText={"St *"} hideLabel isSuccess={this.state.stateSuccess} isError={this.state.stateError}
                  value={this.props.user.state} options={this.props.states} onChange={this.change} />
              </div>
              <div className="form-group col-12 col-sm-6 col-md-7 col-xl-4 d-inline-block">
                <Textbox id={"zip"} label={"Zip *"} isSuccess={this.state.zipSuccess} isError={this.state.zipError}
                  value={this.props.user.zip} onChange={this.change} />
              </div>
            </div>

          </div>

          <div className="col-sm-12 col-md-6 col-lg-6">

            <div className="form-group">
              <Textbox id={"emailAddress"} label={"Email *"}
                onChange={this.change}
                value={this.props.user.emailAddress} isSuccess={this.state.emailAddressSuccess} isError={this.state.emailAddressError} />
            </div>

            <div className="row">
              <div className="col-sm-7 col-lg-6">

                <div className="form-group">
                  <Textbox id={"phoneNumber"} label={"Telephone"} maxLength={15}
                    value={this.props.user.phoneNumber} isSuccess={this.state.phoneNumberSuccess} isError={this.state.phoneNumberError}
                    onChange={this.change} />
                </div>
              </div>
              <div className="offset-6 offset-sm-0 col-sm-5 col-md-5 col-lg-6">
                <div className="form-group">
                  <Textbox id={"phoneExt"} label={"Ext"} value={this.props.user.phoneExt}
                    onChange={this.change} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-md-6">
            <div className="form-group">
              <Textbox id={"companyName"} label={"Company Name"}
                value={this.props.user.companyName}
                onChange={this.change} />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-md-6">
            <div className="form-group">
              <Select id={"companyType"} label={"Company Type"} defaultOptionText="Choose a company type"
                value={this.props.user.companyType}
                hideLabel
                showOther
                options={userCompanyTypeSelectOptions}
                onChange={this.change} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-right save-cancel-col">
            <Button onClick={() => this.props.save(this.props.user)}
              label={"Save"}
              className="mr-4"
              disabled={!this.state.isFormValid}
              buttonType={buttonTypes.PRIMARY}
              buttonSize={buttonSizes.LARGE}
              svgIcon
              icon="save"
              iconColor="white" />

            <Button onClick={() => this.props.cancel()}
              label={"Cancel"}
              buttonType={buttonTypes.PRIMARY_OUTLINE}
              buttonSize={buttonSizes.LARGE}
              svgIcon
              icon="circle-x"
              iconColor="blue" />
          </div>
        </div>

      </form>
    );
  }
}

BasicUserForm.propTypes = {
  save: PropTypes.func,
  states: PropTypes.array.isRequired,
  cancel: PropTypes.func,
  user: PropTypes.shape(user).isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.lcUser.user,
    states: state.location.states,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    locationActions,
    lcUserActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicUserForm);
