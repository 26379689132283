import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { toast } from "react-toastify";

import { ROUTES } from "../../constants/routeConfig";

import * as lcUserActions from "../../actions/lcUserActions";
import * as modalActions from "../../actions/modalActions";
import * as courseActions from '../../actions/courseActions';

import LoginForm from '../user/LoginForm';
import SvgIcon from "../icon/SvgIcon";
import { selectCourseOfferingsById } from '../../selectors/courseSelectors';
import { generateUniqueId } from '../../utilities/courseUtilities';

class ShoppingCartLogin extends React.Component {

  constructor(props) {
    super(props);

    this.continueAsGuest = this.continueAsGuest.bind(this);
    this.loginRegisterForCourses = this.loginRegisterForCourses.bind(this);
  }

  continueAsGuest() {
    this.props.actions.createLcUser();
    this.props.actions.setCreateAccountRedirect(ROUTES.SHOPPING_CART_COMPLETE);
    this.props.history.push(ROUTES.SHOPPING_CART_CREATE_GUEST)
  }

  componentWillMount() {
    if (this.props.isLoggedIn)
      this.props.history.push(ROUTES.SHOPPING_CART_CONFIRM_INFO)
  }

  loginRegisterForCourses() {

    const that = this;
    this.props.actions.registerForCourses(this.props.cart)
      .catch((error) => {

        if (error.message.includes('already registered for one of the courses') && that.props.cart.length === 1) {
          const course = that.props.cart[0];
          const uniqueId = generateUniqueId(course);

          if (that.props.courseOfferingsById[uniqueId]) {
            toast.error(`You are already registered for ${that.props.courseOfferingsById[uniqueId].course.name}`);
          }

          this.props.history.push(ROUTES.courseDetailRoute(course.id, course.format));
        } else {
          toast.error(error.message);
        }
      });
  }

  render() {
    return (
      <React.Fragment>

        <h1 className="content-heading">
          <span onClick={() => this.props.history.push(ROUTES.LANDING_PAGE)}><SvgIcon icon={"chevron-left"} color={"white"} />Back</span>
          Sign in
        </h1>

        <div className="white-body">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <LoginForm
                loginRedirect={ROUTES.SHOPPING_CART_COMPLETE}
                createAccountFormRoute={ROUTES.SHOPPING_CART_CREATE_ACCOUNT}
                createAccountRedirect={ROUTES.SHOPPING_CART_COMPLETE}
                onLoginSuccess={this.loginRegisterForCourses}
                onContinueAsGuest={this.continueAsGuest}
                validateCart={true} />
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

ShoppingCartLogin.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  cart: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    cart: state.shopping.cart,
    courseOfferingsById: selectCourseOfferingsById(state),
  }
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    lcUserActions,
    modalActions,
    courseActions,
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCartLogin));
