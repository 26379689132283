import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES } from "../../constants/routeConfig";

import ShipImage from '../../assets/icon-sailingship.png';

import ContentPageLayout from "../layout/ContentPageLayout";

const ErrorPage = (props) => {
  return (
    <ContentPageLayout
      documentTitle="An Error Occurred"
      headerTitle="An Error Occurred"
      headerContent={
        <React.Fragment>
          <span>
            {"The page you are looking for doesn't exist or an error occurred. Go back or click "}
            <NavLink to={ROUTES.LANDING_PAGE}>here</NavLink> {" to return to the home page."}
          </span>
        </React.Fragment>
      }>

      <div className="container">
        <div className="white-body">
          <div className="error-content">
            <img width={288} height={262} src={ShipImage} className="mb-2" />
            <h1 className="my-4">Fun Fact!</h1>
            <p>Did you know the 17th century shipping industry gave birth to the supplemental insurance industry?</p>

            {process.env.NODE_ENV === 'development' && (props.componentError || props.nonComponentError) &&
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <h1>Debug Info:</h1>
                {props.componentError &&
                  <p>
                    {props.componentError.toString()}
                  </p>
                }
                {props.nonComponentError &&
                  <p>
                    {props.nonComponentError.message}
                  </p>
                }
                {props.componentErrorInfo &&
                  <p>
                    {props.componentErrorInfo.componentStack}
                  </p>
                }
                {props.nonComponentError &&
                  <p>
                    {props.nonComponentError.stack}
                  </p>
                }
              </div>
            }

          </div>
        </div>

      </div>

    </ContentPageLayout>
  );
};

ErrorPage.propTypes = {
  componentError: PropTypes.any,
  nonComponentError: PropTypes.any,
  componentErrorInfo: PropTypes.any,
};


export default ErrorPage;
