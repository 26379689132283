import React from "react";

import * as resourceData from "../../resources-page";

import { userAgentIsBot } from "../../utilities/requestUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import ResourcesCard from "./ResourcesCard";
import { trackUserEvent } from "../../utilities/analyticsUtilities";

class SlipTutorialsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = userAgentIsBot()
      ? {
        showMoreBasicFiling: true,
        showMoreSlipProcedures: true,
        showMoreSlipTutorials: true
      }
      : {
        showMoreBasicFiling: false,
        showMoreSlipProcedures: false,
        showMoreSlipTutorials: false
      };

    this.handleViewMoreClick.bind(this);
  }

  handleViewMoreClick(state, viewType) {
    this.setState(state);
    let capture = false;
    switch (viewType) {
      case 'Basic Filing':
        capture = state.showMoreBasicFiling ? true : false;
        break;
      case 'SLIP Procedures':
        capture = state.showMoreSlipProcedures ? true : false;
        break;
      case 'SLIP Tutorials':
        capture = state.showMoreSlipTutorials ? true : false;
        break;
      default:
        break;
    }

    if (capture) {
      this.captureAnalytics("Tutorials", viewType);
    }
  }

  captureAnalytics(resourceCategory, resourceTitle) {
    trackUserEvent({
      action: "View More",
      category: resourceCategory,
      label: resourceTitle
    });
  }

  render() {
    let basicFilingTutorials = this.state.showMoreBasicFiling ? resourceData.basicFilingTutorials : resourceData.basicFilingTutorials.slice(0, 2);
    let slipProcedures = this.state.showMoreSlipProcedures ? resourceData.slipProcedures : resourceData.slipProcedures.slice(0, 2);
    let slipTutorials = this.state.showMoreSlipTutorials ? resourceData.slipTutorials : resourceData.slipTutorials.slice(0, 2);

    return (<ContentPageLayout
      documentTitle="Tutorials"
      headerTitle="Tutorials"
      headerContent={
        <React.Fragment>
          The basic tutorials provide an overview of California regulations on surplus line filings and the
          requirements of the Surplus Line Association of California. The SLIP tutorials introduce you to
          the Association’s electronic filing portal with each tutorial focusing on a specific filing
          procedure to assist with compliance of those regulations.
        </React.Fragment>
      }>

      <div className={"container"}>
        <div className={"white-body"}>
          <div className={"row"}>

            <h1 className="left-block blue col-12">Basic Filing Tutorials</h1>
            {basicFilingTutorials.map((tutorial) => {
              return (
                <div key={tutorial.key} className={"col-12 col-lg-6"}>
                  <ResourcesCard tutorial={tutorial.value} key={tutorial.key} />
                </div>
              );
            })}
            {resourceData.basicFilingTutorials.length > 2 &&
              <span className="view-more" onClick={() => this.handleViewMoreClick({ showMoreBasicFiling: !this.state.showMoreBasicFiling }, "Basic Filing")}>
                {!this.state.showMoreBasicFiling ? "View More +" : "View Less -"}
              </span>
            }

            <h1 className="left-block blue col-12">Slip Procedures</h1>
            {slipProcedures.map((tutorial) => {
              return (
                <div key={tutorial.key} className={"col-12 col-lg-6"}>
                  <ResourcesCard tutorial={tutorial.value} key={tutorial.key} />
                </div>
              );
            })}
            {resourceData.slipProcedures.length > 2 &&
              <span className="view-more" onClick={() => this.handleViewMoreClick({ showMoreSlipProcedures: !this.state.showMoreSlipProcedures }, "SLIP Procedures")}>
                {!this.state.showMoreSlipProcedures ? "View More +" : "View Less -"}
              </span>
            }

            <h1 className="left-block blue col-12">Slip Tutorials</h1>
            {slipTutorials.map((tutorial) => {
              return (
                <div key={tutorial.key} className={"col-12 col-lg-6"}>
                  <ResourcesCard tutorial={tutorial.value} />
                </div>
              );
            })}
            {resourceData.slipTutorials.length > 2 &&
              <span className="view-more" onClick={() => this.handleViewMoreClick({ showMoreSlipTutorials: !this.state.showMoreSlipTutorials }, "SLIP Tutorials")}>
                {!this.state.showMoreSlipTutorials ? "View More +" : "View Less -"}
              </span>
            }

          </div>
        </div>
      </div>
    </ContentPageLayout>)
  }
}

export default SlipTutorialsPage;
