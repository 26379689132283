import * as fetchCreators from './fetchCreators';
import {CONFIGURATION} from '../constants/config';

const resourcesApiUrl = CONFIGURATION.SERVER_API_URL + "/resources";

export function getFaqGroups() {
  return fetchCreators.get(`${resourcesApiUrl}/faqgroups`);
}

export function getFaqGroup(urlName) {
  return fetchCreators.get(`${resourcesApiUrl}/faqgroup/${urlName}`);
}

export function getNotices() {
  return fetchCreators.get(`${resourcesApiUrl}/notices`);
}

export function getFeaturedNotices() {
  return fetchCreators.get(`${resourcesApiUrl}/notices/featured`);
}

export function getFeaturedFaqs() {
  return fetchCreators.get(`${resourcesApiUrl}/faqs/featured`);
}

export function getFeaturedBulletins() {
  return fetchCreators.get(`${resourcesApiUrl}/bulletins/featured`);
}

export function getSeries() {
  return fetchCreators.get(`${resourcesApiUrl}/bulletins/series`);
}

export function getBulletinsBySeries(seriesId) {
  return fetchCreators.get(`${resourcesApiUrl}/bulletins/byseries/${seriesId}`);
}
