import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ROUTES } from "../../constants/routeConfig";
import { connect } from 'react-redux';
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import slideBg from "../../assets/Carousel-5.png";
import slideBgMobile from "../../assets/Carousel-5-mobile.png";
import CarouselSlide from './CarouselSlide';
import Button from '../inputs/Button';
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import * as courseActions from "../../actions/courseActions";


class LandingPageComplianceConnectionSlide extends React.Component {

  constructor(props) {
    super(props);

    this.courseSearchComplianceConnections = this.courseSearchComplianceConnections.bind(this);
  }

  courseSearchComplianceConnections() {
    this.props.actions.resetCourseSearchFilters();
    this.props.actions.toggleComplianceConnections();
    this.props.history.push(ROUTES.COURSE_LIST);
  }

  render() {
    return (
      <CarouselSlide backgroundUrl={slideBg}
        mobileBackgroundUrl={slideBgMobile}
        active={this.props.active}>
        <div className={this.props.active ? '' : 'row'}>
          <div className={this.props.active ? '' : 'col offset-md-4'}>
            <h1><span>Compliance Connections</span></h1>
            <p className="landing-subtitle">
              Compliance Connections is your one-stop shop for all your compliance questions on filing, taxes, and more! Compliance Connections is a monthly webinar tailored to the needs of brokers, filers and compliance staff. Join us for the webinar which includes live Q&amp;A or explore on your own time using our on-demand library.
            </p>
            <div className="slide-buttons">

              <Button
                onClick={this.courseSearchComplianceConnections}
                label="Compliance Connection Courses"
                svgIcon
                icon="compliance-connections"
                iconColor="white"
                hoverIconColor={'white'}
                buttonType={buttonTypes.PRIMARY}
                buttonSize={buttonSizes.LARGE}
                disabled={false}
              />
            </div>
          </div>
        </div>
      </CarouselSlide>
    );
  }
}

LandingPageComplianceConnectionSlide.propTypes = {
  history: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(null, mapDispatchToProps)(LandingPageComplianceConnectionSlide));