import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactPlaceholder from 'react-placeholder';
import objectAssign from "object-assign";

import user from '../../proptypes/users/User';
import { sortCoursesByMethodAndDate, shouldShowAsCompleted, shouldShowAsRegistered } from "../../utilities/courseUtilities";
import * as modalActions from "../../actions/modalActions";
import * as authSelectors from "../../selectors/authSelectors";

import SvgIcon from "../icon/SvgIcon";
import ContentPageLayout from '../layout/ContentPageLayout';
import CourseOfferingCard from '../courses/CourseOfferingCard';
import CourseListPlaceHolder from "../courses/placeholders/CourseListPlaceHolder";
import MyCoursesRenewalCalculator from "./MyCoursesRenewalCalculator";
import MyCompletedCourses from "./MyCompletedCourses";
import CDICheckLicenseStatus from './CDICheckLicenseStatus';
import loadingStates from '../../constants/loadingStates';

class MyCoursesPage extends React.Component {
  render() {
    let myRegisteredCourses = [];
    let myRegisteredCoursesSorted = [];
    let myCompletedCourses = [];

    if (this.props.isLoggedIn && this.props.userDataLoaded && this.props.currentUser.registeredCourses) {
      myRegisteredCourses = this.props.currentUser.registeredCourses.filter((course) => { return shouldShowAsRegistered(course); });
      myRegisteredCoursesSorted = sortCoursesByMethodAndDate(myRegisteredCourses, true);
      myCompletedCourses = this.props.currentUser.registeredCourses.filter((course) => { return shouldShowAsCompleted(course); });
    }

    return (
      <ContentPageLayout
        documentTitle="My Courses"
        headerTitle="My Courses"
        headerContent={
          <React.Fragment>
            The courses you are currently registered for are shown below. This list includes completed and not completed
            courses. The renewal calculator helps you track the number of CEs earned from your registered SLA courses.
          </React.Fragment>
        }>

        <div className="container pr-xs-0 pl-xs-0 pl-sm pr-sm">

          <div className="d-block d-md-bnone">
            <button
              className="btn btn-primary btn-block heading-button"
              onClick={this.props.actions.showRenewalCalculatorModal}>
              <SvgIcon icon="calculator" color="white" />
              Renewal Calculator
            </button>
          </div>

          <div className="row no-gutters">
            <div className="col-12 col-lg my-courses-panel">
              <div className="white-body">

                {(myRegisteredCoursesSorted.length > 0 || !this.props.loadedRegisteredCourses) &&
                  <div className={"no-print"}>
                    <h1 className="left-block red">My Registered Courses</h1>
                    <ReactPlaceholder showLoadingAnimation ready={this.props.userDataLoaded}
                      customPlaceholder={CourseListPlaceHolder}>
                      {myRegisteredCoursesSorted.map(courseRegistration =>
                        <div key={courseRegistration.id} className={"mx-auto mx-lg-0"}>
                          <CourseOfferingCard
                            courseOffering={courseRegistration.courseOffering}
                            myCourses />
                        </div>
                      )}
                    </ReactPlaceholder>
                  </div>
                }

                <MyCompletedCourses completedCourses={myCompletedCourses} />
              </div>
            </div>

            <div className={"col-12 col-lg-35p col-xl-30p d-none d-md-block outside-calculator-panel no-print"}>
              <React.Fragment>
                <div className={"calculator-panel"}>
                  <div className={"renewal-calculator"}>

                    <div className={"renewal-header"}>
                      <h5>
                        <SvgIcon icon="calculator" color="white" />
                        <span className={"ml-2"}>RENEWAL CALCULATOR</span>
                      </h5>
                    </div>

                    <div className="calculator-content">
                      <MyCoursesRenewalCalculator
                        userDataLoaded={this.props.userDataLoaded}
                        registeredCourses={this.props.currentUser.registeredCourses}
                        licenseRenewalNumber={this.props.currentUser.licenseNumber} />
                    </div>
                  </div>
                </div>

                <CDICheckLicenseStatus />

              </React.Fragment>
            </div>

          </div>
        </div>
      </ContentPageLayout>
    )
  }
}

MyCoursesPage.propTypes = {
  currentUser: PropTypes.shape(user).isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loadedRegisteredCourses: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    isLoggedIn: state.auth.isLoggedIn,
    loadedRegisteredCourses:
      authSelectors.selectIsLoadingRegisteredCourses(state) ===
      loadingStates.LOADED,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, modalActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCoursesPage);
