import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import objectAssign from "object-assign";

import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";
import * as courseActions from '../../actions/courseActions';
import * as modalActions from '../../actions/modalActions';
import user from '../../proptypes/users/User';

import Button from "../inputs/Button";
import SvgIcon from "../icon/SvgIcon";
import MyCoursesRenewalCalculator from "./MyCoursesRenewalCalculator";
import CDICheckLicenseStatus from "./CDICheckLicenseStatus";
import ModalContent from '../layout/ModalContent';

class RenewalCalculatorModal extends React.Component {

  render() {
    return (
      <ModalContent
        onClose={() => this.props.actions.closeModal()}
        label="Renewal Calculator"
        className="modal-blue"
        fontColor="white"
        icon={<SvgIcon icon="calculator" color="white" />}
        buttons={
          (<React.Fragment>
            <Button
              buttonSize={buttonSizes.LARGE}
              className="btn-wide"
              buttonType={buttonTypes.WHITE}
              onClick={() => { this.props.actions.closeModal(); }}
              label="Close" />
          </React.Fragment>)
        }>

        <React.Fragment>

          <MyCoursesRenewalCalculator
            registeredCourses={this.props.currentUser.registeredCourses}
            licenseRenewalNumber={this.props.currentUser.licenseNumber} />

          <CDICheckLicenseStatus
            className="invert-colors"
            buttonType={buttonTypes.WHITE_OUTLINE} />

        </React.Fragment>
      </ModalContent>);
  }
}

RenewalCalculatorModal.propTypes = {
  currentUser: PropTypes.shape(user).isRequired,
  actions: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  }
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, modalActions, courseActions);
  return {
    actions: bindActionCreators(combinedActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewalCalculatorModal);