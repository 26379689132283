
const courseOfferingFormat = Object.freeze({
  IN_PERSON: 'IP',
  WEBINAR: 'W',
  ON_DEMAND: 'O'
});

export const CourseOfferingFormats = [
  courseOfferingFormat.IN_PERSON,
  courseOfferingFormat.WEBINAR,
  courseOfferingFormat.ON_DEMAND
];

export default courseOfferingFormat;
