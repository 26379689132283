import moment from "moment/moment";
import courseOfferingMethod from "../proptypes/courses/courseOfferingMethod";
import courseOfferingFormat from "../proptypes/courses/courseOfferingFormat";
import { getCourseViewUrl } from './../constants/routeConfig';
import CookieManager from './../../tools/CookieManager';

export function getUniqueLmsCourseIds(courses) {
  if (!Array.isArray(courses)) {
    throw new Error(`Expected courses to be an array. Got: ${typeof courses}`);
  }

  return Array.from(
    new Set(
      courses
        .filter(
          // we don't want to search for course id 0
          (c) =>
            c.method === courseOfferingMethod.ON_DEMAND &&
            Boolean(c.lmsCourseId)
        )
        .map((c) => c.lmsCourseId)
        .filter(Boolean)
    )
  );
}

export function watchCourse(courseRegistrationId, newTab = false) {
  CookieManager.clearLMSCookies();
  CookieManager.setLMSCookie(courseRegistrationId);
  if (newTab) {
    window.open(getCourseViewUrl(courseRegistrationId), 'LMS');
  } else {
    window.location.href = getCourseViewUrl(courseRegistrationId);
  }
}

export function truncateCourseName(name, characters) {
  return (name.length > characters) ? name.substr(0, characters - 1) + '...' : name;
}

export function sortPresenters(presenters) {
  return [...presenters].sort((a, b) => a.order > b.order ? 1 : -1);
}

export function formatPresenterString(presenters) {
  return sortPresenters(presenters).map((presenter, index, presenters) => {
    if (index === 0) {
      return presenter.name;
    } else if (index === (presenters.length - 1)) {
      return ` & ${presenter.name}`;
    } else {
      return `, ${presenter.name}`;
    }
  }).join('');
}

export function abbreviateOfferingLocation(thing) {
  if (!thing) return thing;
  var split_names = thing.trim().split(" ");
  if (split_names.length > 1) {
    return split_names[0].charAt(0) + split_names[1].charAt(0);
  }
  return split_names[0];
}

export function formatOfferingDate(featured, date) {
  let mdate = moment(date);
  return featured ? mdate.format('ddd, MMM D, YYYY') : mdate.format('dddd, MMMM D, YYYY');
}

export function formatMMDDYYYYDate(date) {
  let mdate = moment(date);
  return mdate.format('MM/DD/YYYY');
}

export function month(date) {
  let mdate = moment(date);
  return mdate.format('MMM');
}

export function day(date) {
  let mdate = moment(date);
  return mdate.format('D');
}

export function sortCourses(sortBy, descending, courseOfferings) {
  switch (sortBy) {
    case 'name':
      return sortCoursesByName(sortBy, descending, courseOfferings);
    case 'date':
      return sortCoursesByDate(sortBy, descending, courseOfferings);
  }
}

export function sortCoursesByName(sortBy, descending, courseOfferings) {
  return courseOfferings.sort((a, b) => {

    let value = 0;

    if (a.course.name < b.course.name)
      value = -1;
    else if (a.course.name > b.course.name)
      value = 1;

    //secondary sort by date
    else if (a.course.name === b.course.name) {
      if (a.date < b.date)
        value = -1;
      else if (a.date > b.date)
        value = 1;
    }

    return descending ? value * -1 : value;
  });
}

export function sortCoursesByDate(sortBy, descending, courseOfferings) {
  return courseOfferings.sort((a, b) => {

    let value = 0;

    if (a.date < b.date)
      value = -1;
    else if (a.date > b.date)
      value = 1;

    return descending ? value * -1 : value;
  });
}

export function sortCoursesCompletionsByDate(descending, courseRegistrations) {
  return [...courseRegistrations].sort((a, b) => {

    let value = 0;

    let aCourseCompletionDate = a.courseCompletionDate ? a.courseCompletionDate : a.courseOffering.date;
    let bCourseCompletionDate = b.courseCompletionDate ? b.courseCompletionDate : b.courseOffering.date;

    if (aCourseCompletionDate < bCourseCompletionDate)
      value = -1;
    else if (aCourseCompletionDate > bCourseCompletionDate)
      value = 1;

    return descending ? value * -1 : value;
  });
}

export function sortCoursesByMethodAndDate(courseOfferings, registrations = false) {
  let coursesCopy = [...courseOfferings];

  return coursesCopy.sort((a, b) => {
    let value = 0;

    let aCourseOffering = registrations ? a.courseOffering : a;
    let bCourseOffering = registrations ? b.courseOffering : b;

    if (aCourseOffering.method === courseOfferingMethod.LIVE && aCourseOffering.method === bCourseOffering.method) {
      if (aCourseOffering.date < bCourseOffering.date)
        value = -1;
      else if (aCourseOffering.date > bCourseOffering.date)
        value = 1;
    }

    if (aCourseOffering.method === courseOfferingMethod.ON_DEMAND)
      value = 1;
    else if (bCourseOffering.method === courseOfferingMethod.ON_DEMAND)
      value = -1;

    return value;
  });
}

export function sortCoursesByFeatured(courseOfferings)
{
  return [...courseOfferings].sort((a, b) => {
    return (a.featured > b.featured) ? -1 : 1;
  })
}

export function sortOnDemandCoursesByDate(courseofferings) {
  return [...courseofferings].sort((a, b) => {
    return moment(a.publishDate).isAfter(b.publishDate) ? -1 : 1;
  })
}

export function sortLiveCoursesByDate(courseofferings) {
  return [...courseofferings].sort((a, b) => {
    return moment(b.date).isAfter(a.date) ? -1 : 1;
  })
}

export function calculateProgress(progress) {
  let numComplete = 0;

  if (progress === null || progress === undefined)
    return 0;

  for (let key in progress) {
    if (progress[key].isComplete) {
      numComplete++;
    }
  }

  return (numComplete / progress.length) * 100;
}

export function getListOfCities(courseOfferings) {
  const cities = new Set();

  courseOfferings.forEach((courseOffering) => {
    if (
      !courseOffering.location ||
      !courseOffering.location.address ||
      !courseOffering.location.address.city
    )
      return;

    if (!cities.has(courseOffering.location.address.city))
      cities.add(courseOffering.location.address.city);
  });

  return [...cities].map((city) => ({
      text: city,
      value: city,
      selected: false,
      disabled: false
    })
  );
}

/// This function will return whether it is hybrid course group or not
/// Hybrid course group should have both Webinar and In-Person events
export function courseOfferingFormatsVary(courseOfferings) {
  return courseOfferings.some(courseOffering => courseOffering.format == courseOfferingFormat.WEBINAR)
    && courseOfferings.some(courseOffering => courseOffering.format == courseOfferingFormat.IN_PERSON);
}

/// This function will return date of Course group
/// If they are all having same dates, then date will be returned,
/// If they are having different dates, then null will be returned
export function GetCourseGroupDate(courseOfferings) {
  let courseDate = null;
  let courseDatesDifferent = false;

  courseOfferings.forEach((courseOffering) => {
    if (courseDate == null)
    {
      courseDate = courseOffering.date;
    }

    if (courseDate != courseOffering.date)
    {
      courseDatesDifferent = true;
      return;
    }
  });

  return courseDatesDifferent ? null : courseDate;
}

export function formatDuration(duration) {
  let hours = Math.trunc(duration / 60).toFixed(0);
  let minutes = duration % 60;

  return (
    (hours != 0 ? hours + "h " : "")
    + (minutes != 0 ? minutes + "m" : "")
  ).trim();
}

// Registered coruse should be listed as completed if the course has been
// completed or (in the case of live events), the date of the event was in the
// past 7 days.
export function shouldShowAsCompleted(registeredCourse) {

  if (registeredCourse.courseOffering.method === courseOfferingMethod.ON_DEMAND) {
    return registeredCourse.attended;
  } else {
    return registeredCourse.attended
      || moment(registeredCourse.courseOffering.date)
        .isBetween(
          moment().subtract(7, 'days'),
          moment(),
          'day',
          '[)');
  }
}

// Registered course should be listed as registered if the course has not been
// completed and (in the case of live events), the date of the even has not passed.
export function shouldShowAsRegistered(course) {
  if (course.courseOffering.method === courseOfferingMethod.ON_DEMAND) {
    return !course.attended;
  } else {
    return !course.attended
      && moment().isSameOrBefore(course.courseOffering.date, 'day');
  }
}

/**
 * Gets the record of registration for a course offering. If the user registered for the
 * course offering multiple times (retake of on-demand course), then return the record for
 * participation that is still in progress. Otherwise, return the first completed participation.
 * @param {*} registeredCourses
 * @param {*} courseOfferingId
 */
export function getCurrentRegistration(registeredCourses, uniqueId) {
  // Get user registrations for the course
  let matchingCourseRegistrations = registeredCourses.filter(registration =>
    registration.courseOffering.uniqueId === uniqueId
  );

  // Default to the first record of participation that is not yet completed.
  let currentRegistration = matchingCourseRegistrations.find(registration => !registration.attended);
  if (currentRegistration == null) {
    // If no in progress participation is found, then return the first (and possibly only) record.
    currentRegistration = matchingCourseRegistrations[0];
  }

  return currentRegistration;
}

export function courseIsUnpublished(unpublishDate) {
  if (unpublishDate != null) {
    if (moment(unpublishDate).isSameOrBefore()) {
      return true;
    }
  }
  return false;
}

export function displayUrl(url) {
  return url.replace(/https:\/\/|http:\/\//, '');
}

export function navigateUrl(url) {
  return url.search(/https:\/\/|http:\/\//) === 0
    ? url
    : `http://${url}`;
}

export function generateUniqueId({ id, format }) {
  if (id === 0) return '0';
  return `${id}-${format}`;
}
