import React from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import objectAssign from "object-assign";
import { toast } from 'react-toastify';

import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";

import user from '../../proptypes/users/User';

import * as lcUserActions from "../../actions/lcUserActions";

import * as formUtilities from "../../utilities/formUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import BasicUserForm from "./BasicUserForm";
import BasicUser from "./BasicUser";
import PasswordField from "./PasswordField";
import Textbox from "../inputs/Textbox";
import Button from "../inputs/Button";
import moment from 'moment';

class MyProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameSuccess: false,
      usernameError: false,
      onChangePasswordClick: false,
      confirmPassword: "",
      oldPasswordFormError: false,
      oldPasswordFormSuccess: false,
      confirmPasswordFormSuccess: false,
      confirmPasswordFormError: false,
      newPasswordFormError: false,
      newPasswordFormSuccess: false,
    };
    this.changeUserInfo = this.changeUserInfo.bind(this);
    this.editUser = this.editUser.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.changePasswordClick = this.changePasswordClick.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeLoginForm = this.changeLoginForm.bind(this);
    this.saveChangePassword = this.saveChangePassword.bind(this);
    this.props.actions.copyUserDataToForm(this.props.currentUser);
  }

  saveChangePassword() {
    this.props.actions.changePassword(this.props.securityModel)
      .then(() => this.setState({ onChangePasswordClick: false, confirmPassword: "" }))
      .then(() => this.props.actions.clearLoginForm())
      .then(() => toast.success("Password successfully changed."))
      .catch(error => toast.error(error.message));
  }

  changeLoginForm(event) {
    let changedLoginForm = objectAssign({}, this.props.securityModel);
    changedLoginForm.id = this.props.currentUser.id;
    changedLoginForm[event.target.id] = event.target.value;
    this.props.actions.changeLoginForm(changedLoginForm);
  }

  changePassword(event) {
    if (event.target.id === "password") {
      if (!formUtilities.formElementHasError(event.target.value)) {
        let formError = formUtilities.validatePassword(event.target.value) && event.target.value === this.state.confirmPassword;
        this.setState({
          newPasswordFormError: !formError,
          newPasswordFormSuccess: formError,
          confirmPasswordFormError: this.state.confirmPassword !== "" && event.target.value !== this.state.confirmPassword,
          confirmPasswordFormSuccess: this.state.confirmPassword !== "" && event.target.value === this.state.confirmPassword
        });
      } else {
        this.setState({
          newPasswordFormError: true,
          newPasswordFormSuccess: false,
        });
      }
      this.changeLoginForm(event)
    } else if (event.target.id === "confirmPassword") {
      let confirmPasswordError = !formUtilities.formElementHasError(event.target.value) && (formUtilities.validatePassword(event.target.value) ? event.target.value !== this.props.securityModel.password : true);
      this.setState({
        confirmPassword: event.target.value,
        confirmPasswordFormSuccess: !confirmPasswordError,
        confirmPasswordFormError: confirmPasswordError,
        newPasswordFormError: this.props.securityModel.password !== "" && event.target.value !== this.props.securityModel.password,
        newPasswordFormSuccess: this.props.securityModel.password !== "" && event.target.value === this.props.securityModel.password
      });
    }
  }

  changePasswordClick() {
    this.setState({ onChangePasswordClick: true });
  }

  cancelEdit() {
    // get user info from db reset editing flag.
    this.setState({ onChangePasswordClick: false, confirmPassword: "" });
    this.props.actions.clearLoginForm();
    this.props.actions.copyUserDataToForm(this.props.currentUser);
  }

  saveEdit(user) {
    // change License Effective Date to UTC
    const editedUser = { ...user };
    editedUser.licenseEffDate = moment.utc(user.licenseEffDate);

    // update user
    this.props.actions.updateLcUser(editedUser)
      .then(() => toast.success("Successfully updated profile."))
      .catch(error => toast.error(error.message));
  }

  changeUserInfo(event) {
    let error = false;
    switch (event.target.id) {
      case "userName":
        error = formUtilities.formElementHasError(event.target.value) || event.target.value.length < 5;
        this.setState({ usernameError: error, usernameSuccess: !error });
        break;
    }
    let changedUser = objectAssign({}, this.props.user);
    changedUser[event.target.id] = event.target.value;
    this.props.actions.changeLcUser(changedUser);
  }

  editUser() {
    this.props.actions.editLcUser();
  }

  render() {
    return (
      <ContentPageLayout
        documentTitle="Profile"
        headerTitle="My Profile"
        headerContent={
          <React.Fragment>
            Please review your information and make any necessary updates. Click the Save button to save your changes.
          </React.Fragment>
        }>

        <div className="container">
          <div className="white-body">
            <div>

              <div className="row mb-5">
                {
                  !this.state.onChangePasswordClick &&
                  <div className="col-12 col-md-6">
                    {this.props.isEditing ?
                      <div className={"w-100"}>
                        <Textbox
                          id={"userName"}
                          label={"Username *"}
                          value={this.props.user.userName}
                          onChange={this.changeUserInfo}
                          errorText={<div className={"field-error-text float-up"}><p>Username must be at least 5 characters long. *</p></div>}
                          isSuccess={this.state.usernameSuccess}
                          isError={this.state.usernameError}
                        />
                      </div>
                      :
                      <React.Fragment>
                        {!this.state.onChangePasswordClick &&
                          <div className="info-fields">
                            <span className="info"><label>Username:</label> {this.props.user.userName}</span>
                          </div>
                        }
                      </React.Fragment>
                    }
                  </div>
                }

                <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
                  <Button className={(this.state.onChangePasswordClick ? "fade-out" : "")}
                    disabled={this.props.isEditing}
                    buttonSize={buttonSizes.LARGE}
                    buttonType={buttonTypes.PRIMARY}
                    displayBlock={true}
                    onClick={this.changePasswordClick}
                    svgIcon
                    icon="change-password"
                    iconColor={"white"}
                    label="Change Password" />
                </div>

                <div className={"col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 change-password-wrapper mt-xs-3 mt-sm-3 mt-md-0"}>

                  <div className={"w-100"} hidden={!this.state.onChangePasswordClick}>
                    <div className={"mb-4"}>
                      <PasswordField
                        change={this.changeLoginForm}
                        id={"oldPassword"}
                        passwordLabel={"Current Password *"}
                        password={this.props.securityModel.oldPassword} />
                    </div>

                    <div className={"mb-4"}>
                      <PasswordField
                        passwordSuccess={this.state.newPasswordFormSuccess}
                        passwordError={this.state.newPasswordFormError}
                        change={this.changePassword}
                        id={"password"}
                        passwordLabel={"New Password *"}
                        isConfirmField
                        confirmPassword={this.state.confirmPassword}
                        password={this.props.securityModel.password} />
                    </div>

                    <div>
                      <PasswordField
                        passwordSuccess={this.state.confirmPasswordFormSuccess}
                        passwordError={this.state.confirmPasswordFormError}
                        id={"confirmPassword"}
                        change={this.changePassword}
                        passwordLabel={"Confirm New Password *"}
                        isConfirmField
                        confirmPassword={this.props.securityModel.password}
                        password={this.state.confirmPassword} />
                    </div>

                    <div className={this.state.onChangePasswordClick ? "mt-5 col-md-12 col-sm-12 text-center change-password-buttons-animated float-up" : "mt-4 col-md-12 col-sm-12 text-center"}>
                      <Button className={"mr-lg-3"} buttonType={buttonTypes.SUCCESS} buttonSize={buttonSizes.LARGE}
                        onClick={this.saveChangePassword} label={"Save"}
                        disabled={this.props.securityModel.password !== this.state.confirmPassword} />

                      <Button className={"ml-sm-5"} buttonType={buttonTypes.DANGER} buttonSize={buttonSizes.LARGE} onClick={this.cancelEdit}
                        label={"Cancel"} />
                    </div>

                  </div>
                </div>
              </div>
              <hr />
              <div className={this.state.onChangePasswordClick ? "my-profile-body collapsed mt-4" : "my-profile-body mt-4"}>
                {this.props.isEditing ?
                  <BasicUserForm user={this.props.user} cancel={this.cancelEdit} save={this.saveEdit} />
                  :
                  <BasicUser user={this.props.user} edit={this.editUser} buttonLabel={"Edit"} />
                }
              </div>
            </div>
          </div>
        </div>
      </ContentPageLayout>
    );
  }
}

MyProfilePage.propTypes = {
  history: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  user: PropTypes.shape(user),
  securityModel: PropTypes.object.isRequired,
  currentUser: PropTypes.shape(user),
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    securityModel: state.lcUser.loginForm,
    currentUser: state.auth.currentUser,
    user: state.lcUser.user,
    isEditing: state.lcUser.isEditing
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    lcUserActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePage);

