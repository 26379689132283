import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from "../containers/DocumentTitle";
import { toast } from "react-toastify";
import BrowserDetection from 'react-browser-detection';
import LandingCarousel from './LandingCarousel';
import NoticesBulletinRibbon from './NoticesBulletinRibbon';
import LandingPageContent from './LandingPageContent';

class LandingPage extends React.Component {

  render() {
    return (
      <DocumentTitle title="Home">
        <BrowserDetection>
          {{
            default: (browser) => {
              if (browser === 'ie') {
                toast.error("Your browser is not officially supported! This website runs best on Google Chrome, FireFox, Safari, and Edge.");
              }
              return <span />
            }
          }}
        </BrowserDetection>

        <LandingCarousel changeActiveNav={this.props.changeActiveNav} />

        <NoticesBulletinRibbon />

        <LandingPageContent />
      </DocumentTitle>
    );
  }
}

LandingPage.propTypes = {
  changeActiveNav: PropTypes.func.isRequired,
}

export default LandingPage;
