import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {ROUTES} from '../../constants/routeConfig';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import objectAssign from "object-assign";
import * as courseActions from "../../actions/courseActions";
import courseOfferingMethod from '../../proptypes/courses/courseOfferingMethod';
import CopyrightFooter from './CopyrightFooter';
import * as lcUserActions from "../../actions/lcUserActions";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.liveSearch = this.liveSearch.bind(this);
    this.onDemandSearch = this.onDemandSearch.bind(this);
    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.actions.logout();
    this.props.history.push(ROUTES.LANDING_PAGE);
  }

  onDemandSearch() {
    this.props.actions.changeMethodFilter(courseOfferingMethod.ON_DEMAND);
  }

  liveSearch() {
     this.props.actions.changeMethodFilter(courseOfferingMethod.LIVE);
  }

   render() {

     return (
       <React.Fragment>

         <footer className="main-footer no-print">
           <div className="container">
             <div className={"row"}>
               <div className={"col-xs-12 col-md-3 my-auto"}>
                 <NavLink to={ROUTES.LANDING_PAGE}>
                   <div className="lc-footer-logo mx-auto"/>
                 </NavLink>
               </div>

               <div className={"col-xs-12 col-md-3"}>
                 <ul>
                 <li>
                   <NavLink to={ROUTES.COURSE_LIST}>
                     Search
                   </NavLink>
                 </li>
                 <li>
                   <NavLink to={ROUTES.COURSE_LIST} onClick={this.onDemandSearch}>
                     On-Demand Courses
                   </NavLink>
                 </li>
                 <li>
                   <NavLink to={ROUTES.COURSE_LIST} onClick={this.liveSearch}>
                     Live Courses
                   </NavLink>
                 </li>
               </ul>

               </div>
               <div className={"col-xs-12 col-md-3"}>
                 <ul>
                   {this.props.isLoggedIn ?
                     <React.Fragment>
                       <li>
                         <a onClick={this.logout}>
                           Sign Out
                         </a>
                       </li>
                       <li>
                         <NavLink to={ROUTES.MY_PROFILE_PAGE}>
                           My Profile
                         </NavLink>
                       </li>
                     </React.Fragment>
                     :
                     <React.Fragment>
                       <li>
                         <NavLink to={ROUTES.LOGIN}>
                           Sign In
                         </NavLink>
                       </li>
                       <li>
                         <NavLink to={ROUTES.CREATE_ACCOUNT}>
                           Create Account
                         </NavLink>
                       </li>
                     </React.Fragment>
                   }
                   <li>
                     <a href="https://www.slacal.com" target="_blank" rel="noopener noreferrer">
                       slacal.com
                     </a>
                   </li>
                 </ul>
               </div>

               <div className={"col-xs-12 col-md-3 my-auto"}>
                 <a href="https://www.slacal.com" target="_blank" rel="noopener noreferrer">
                   <div className="sla-footer-logo mx-auto"/>
                 </a>
               </div>
             </div>
           </div>
         </footer>

         <CopyrightFooter/>

       </React.Fragment>
     );
   }
}

Footer.propTypes = {
  actions: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions, lcUserActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
