import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import objectAssign from "object-assign";
import { toast } from "react-toastify";

import { ROUTES } from "../../constants/routeConfig";
import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";

import * as lcUserActions from "../../actions/lcUserActions";

import * as formUtilities from "../../utilities/formUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import Textbox from '../inputs/Textbox';
import Button from '../inputs/Button';
import PasswordField from './PasswordField';

class SetupGuestForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      usernameError: false,
      userNameSuccess: false,
      password: '',
      passwordError: false,
      passwordSuccess: false,
      confirmPassword: '',
      confirmPasswordError: false,
      confirmPasswordSuccess: false,

      formValid: false,

      id: props.match.params.id,
      guestSetupToken: props.match.params.token,
    };

    this.changeForm = this.changeForm.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.submit = this.submit.bind(this);
  }

  changePassword(event) {
    if (event.target.id === "password") {

      if (!formUtilities.formElementHasError(event.target.value)) {
        let formError = formUtilities.validatePassword(event.target.value) && event.target.value === this.state.confirmPassword;
        this.setState({
          passwordError: !formError,
          passwordSuccess: formError,
          confirmPasswordError: this.state.confirmPassword !== "" && event.target.value !== this.state.confirmPassword,
          confirmPasswordSuccess: this.state.confirmPassword !== "" && event.target.value === this.state.confirmPassword
        });
      } else {
        this.setState({
          passwordError: true,
          passwordSuccess: false,
        });
      }

      this.changeForm(event)

    } else if (event.target.id === "confirmPassword") {
      let confirmPasswordError = !formUtilities.formElementHasError(event.target.value) && (formUtilities.validatePassword(event.target.value) ? event.target.value !== this.state.password : true);
      this.setState({
        confirmPassword: event.target.value,
        confirmPasswordSuccess: !confirmPasswordError,
        confirmPasswordError: confirmPasswordError,
        passwordError: this.state.password !== "" && event.target.value !== this.state.password,
        passwordSuccess: this.state.password !== "" && event.target.value === this.state.password
      });
    }
  }

  changeForm(event) {
    let hasError = false;
    switch (event.target.id) {
      case "userName":
        hasError = formUtilities.formElementHasError(event.target.value) || event.target.value.length < 5;
        this.setState({ usernameError: hasError, usernameSuccess: !hasError });
        break;
    }
    let newState = { formValid: !hasError };
    newState[event.target.id] = event.target.value;
    this.setState(newState);
  }

  submit() {
    this.props.actions.setupGuestNoData(this.state)
      .then(user => {
        this.props.actions.login({
          email: user.emailAddress,
          password: this.state.password
        })
          .then(() => this.props.history.push(ROUTES.LANDING_PAGE));
      })
      .catch((error) => toast.error(error.message));
  }

  render() {
    return (
      <ContentPageLayout
        documentTitle="Setup Account"
        headerTitle="Setup Account"
        headerContent={
          <React.Fragment>
            Enter a username and password to complete your account setup. With a user account, you will be able to access
            the full benefits of the Learning Center such as maintaining the courses that you have registered for,
            tracking the courses that you have taken, and updating your user profile.
          </React.Fragment>
        }>

        <div className="container">
          <div className="white-body">
            <div>
              <form className="create-account-form">
                <div className="form-row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-group">
                      <Textbox id={"userName"} label={"Username *"} errorText={<div className={"field-error-text float-up"}><p>Username must be at least 5 characters long. *</p></div>} isSuccess={this.state.usernameSuccess}
                        isError={this.state.usernameError} value={this.state.userName}
                        onChange={this.changeForm} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-group">
                      <PasswordField change={this.changePassword} id={"password"} passwordLabel={"Password *"} password={this.state.password} isConfirmField={true} confirmPassword={this.state.confirmPassword} passwordSuccess={this.state.passwordSuccess} passwordError={this.state.passwordError} />

                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-lg-6 col-sm-12">
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="form-group">
                      <PasswordField id={"confirmPassword"} passwordError={this.state.confirmPasswordError} passwordSuccess={this.state.confirmPasswordSuccess} passwordLabel={"Confirm Password *"} password={this.state.confirmPassword} isConfirmField={true} confirmPassword={this.state.password} change={this.changePassword} />
                    </div>
                  </div>
                </div>

                <Button onClick={this.submit}
                  disabled={!this.state.formValid || this.state.confirmPassword !== this.state.password}
                  label="Setup Account"
                  buttonSize={buttonSizes.LARGE}
                  buttonType={buttonTypes.PRIMARY} />

              </form>
            </div>
          </div>
        </div>

      </ContentPageLayout>
    );
  }
}

SetupGuestForm.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, lcUserActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(null, mapDispatchToProps)(SetupGuestForm));
