import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  company: PropTypes.string,
  biography: PropTypes.string,
  qualifications: PropTypes.string,
  website: PropTypes.string,
  order: PropTypes.number,
  hasExistingDocument: PropTypes.bool,
}
