/* Contains Google Analytics 4 utilities wrapping GA4 gtag function to send events */

export const trackUserEvent = ({ action, category, label }) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label
    });
  }
}

export const trackTimingEvent = ({ timingVar, timingValue, timingCategory, timingLabel }) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'timing_complete', {
      'name': timingVar,
      'value': timingValue,
      'event_category': timingCategory,
      'event_label': timingLabel
    });
  }
}