import React from 'react'
import CourseOfferingTag from './CourseOfferingTag';
import { PropTypes } from 'prop-types';
import registeredCourse from '../../../proptypes/users/registeredCourse';
import ceCreditStatuses from '../../../proptypes/courses/ceCreditStatuses';
import tagStyles from '../../../constants/tagStyles';

class CreditStatusTag extends React.Component {
  render() {
    let {courseRegistration} = this.props;

    let label = null;
    let style = null;
    let title = null;

    switch(courseRegistration.ceCreditStatus) {

      case ceCreditStatuses.NOT_AWARDED:
        
        label = 'No Credit Awarded';
        style = tagStyles.DANGER
        title = courseRegistration.ceCreditNotAwardedReason || null;

        break;

      case ceCreditStatuses.PENDING:

        label = 'Credit Pending';
        style = tagStyles.WARNING;
        break;
    }

    if (label) {
      return (
        <CourseOfferingTag
          style={style}
          label={label}
          title={title} />
      )
    } else {
      return false;
    }
  }
}

CreditStatusTag.propTypes = {
  courseRegistration: PropTypes.shape(registeredCourse).isRequired,
}

export default CreditStatusTag;
