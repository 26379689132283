import React from 'react';
import Collapsible from 'react-collapsible';

import ContentPageLayout from "../layout/ContentPageLayout";

const TOS = [
  {
    key: "1. Your Use of the SLA Learning Center Web Site Constitutes Acceptance",
    value: (<div className="generic-content">
      <p>
        This Agreement will constitute a binding and enforceable agreement between you (individually and in your individual capacity as an
        employee, officer, agent, or partner of each organization you represent in connection with any use of the SLA Learning Center Web
        Site SLA Learning Center Web Site) and the SLA. By using the SLA Learning Center Web Site SLA Learning Center Web Site, you acknowledge
        and agree that you have fully read and agree to be bound by the provisions of this Agreement, exactly as if it were printed on paper
        and signed by you. If you do not agree to be bound by this Agreement in its entirety, then you must immediately stop using the SLA
        Learning Center Web SiteSLA Learning Center Web Site.
        <br/>
      </p>
      <br/>
      <p>
        Where you use the SLA Learning Center Web Site SLA Learning Center Web Site in the course of your employment or business, you enter
        into this Agreement both on your own behalf and in your individual capacity as an employee, officer, agent, or partner of such organization
        which you represent, and references in this Agreement to &quot;you&quot; shall mean both you as the individual user of the SLA Learning Center
        Web Site SLA Learning Center Web Siteand you in your capacity as a representative of your organization.
        <br/>
      </p>
      <br/>
      Notwithstanding the foregoing, any signed agreement between you and the SLA will supersede any
      conflicting term in this Agreement.
      <br/>
    </div>)
  },
  {
    key: "2. Description of Services",
    value: (
      <div className="generic-content">
      <p>
        The SLA Learning Center Web Site provides you with a variety of resources including: video of SLA presentations and events, presentation materials, 
        links to the California Department of Insurance and other surplus line resources, as well as other materials and information related to the SLA and 
        surplus lines (collectively, the &quot;Services&quot;). The Services, including any updates, enhancements, or new features are provided subject to the
        terms of this Agreement.
      </p>
      </div>)
  },
  {
    key: "3. Privacy and Protection of Personal Information",
    value: (
      <div className="generic-content">
        <p>
          The SLA does not use the SLA Learning Center Web Site to collect personal information and no personal information is available on the SLA Learning 
          Center Web Site SLA Learning Center Web Site (Does this still apply considering users are giving us Broker License, Name, Company, and Address Info?).
          <br/>
        </p>
        <br/>
        <p>
          The SLA may record certain information about your visit, such as: the name of the Internet service provider and the Internet Protocol (&quot;IP&quot;) address
          through which you access the Internet, the date and time you access the SLA Learning Center Web Site , the uniform resource locators (&quot;URLs&quot;) of the
          web sites and pages you visit before, during, and after your visit to the SLA Learning Center Web Site, information about the computer hardware and software 
          you use, and other information that may be available. Also, the SLA may place &quot;cookies&quot; on your computer to recognize you on return visits. This enables
          the SLA to enhance your browsing experience, as well as collect additional information about you. You may adjust your browser settings or use other means 
          to prevent cookies from being placed on your computer, but doing so may result in reduced functionality and a less personalized browsing experience. 
          This information is used by the SLA for several purposes: to monitor use of the SLA Learning Center Web Site, to improve services offered on the SLA Learning 
          Center Web Site, to diagnose problems with our server, to solve technical problems, to collect information about unauthorized access attempts, and to 
          administer the SLA Learning Center Web Site.
          <br/>
        </p>
      </div>)
  },
  {
    key: "4. Intellectual Property",
    value: (
      <div className="generic-content">
        <p>
          All of the Content, including, without limitation, all text, graphics, video, and sounds on the SLA Learning Center Web Site, and all computer code 
          associated therewith, is the valuable proprietary property of the SLA, its licensors and contributors, and is subject to copyright, trademark, and other 
          intellectual property protection. You acknowledge and agree that the SLA has expended substantial time and effort to create the SLA Learning Center Web Site, 
          and the Content and Services provided therewith, and that the SLA either exclusively owns or has been licensed by third parties to use and sub-license all 
          rights, title, and interest therein and all associated information, data, databases, images, and other material.<br/>
        </p>
        <br/>
        If you wish to make any complaint regarding any Content, please contact us at: support@slacal.com. The SLA agent for notice purposes under 17 U.S.C. 512 is:
        <br/>
        <br/>
        Joy Erven
        <br/>
        Director, Stamping Office
        <br/>
        The Surplus Line Association of California
        <br/>
        12667 Alcosta Boulevard, Suite 450
        <br/>
        San Ramon, CA 94583
        <br/>
        415.434.4900
        <br/>
      </div>)
  },
  {
    key: "5. Links to Third Party Sites",
    value: (
      <div className="generic-content">
        <p>
          For your convenience, the SLA Learning Center Web Site links to other sites. The SLA is not responsible for the privacy practices or the content 
          of such other web sites, nor do we provide personal data to such sites or the owners or administrators of such sites. These linked external or 
          third party web sites are not controlled by the SLA and, therefore, are not subject to this Privacy Policy. You should consult the privacy statements 
          or policies posted on third party sites to determine how your personal information, if provided by you, may be utilized by the proprietors of those 
          third party sites. The SLA disclaims any and all responsibility and/or liability for the collection and/or transfer of information collected by such 
          other third party web sites.
        </p>
      </div>)
  },
  {
    key: "6. NO WARRANTIES",
    value: (
      <div className="generic-content">
        <p>
          THE SLA PROVIDES THE SLA LEARNING CENTER WEB SITE, AND ALL CONTENT AND SERVICES RELATED THERETO, STRICTLY ON AN &quot;AS-IS&quot; BASIS. SAVE TO THE EXTENT REQUIRED
          BY ANY MANDATORY APPLICABLE LAW, NEITHER THE SLA LEARNING CENTER WEB SITE NOR ANY CONTENT OR SERVICES PROVIDED ON OR IN CONNECTION WITH THE SLA LEARNING 
          CENTER WEB SITE ARE SUBJECT TO ANY WARRANTY OR CONDITION, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, SATISFACTORY 
          QUALITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, AND NON-INFRINGEMENT. THE SLA DOES NOT GUARANTEE THE ACCURACY OR COMPLETENESS OF THE SLA LEARNING CENTER 
          WEB SITE OR ITS CONTENTS. IN ADDITION, THE SLA DOES NOT GUARANTEE THAT THE SLA LEARNING CENTER WEB SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY 
          SERVICE WILL CONTINUE TO BE AVAILABLE.
        </p>
      </div>)
  },
  {
    key: "7. NO LIABILITY FOR DAMAGES",
    value: (
      <div className="generic-content">
        <p>
          EXCEPT AS REQUIRED BY ANY MANDATORY APPLICABLE LAW, IN NO EVENT WILL THE SLA, OR ANY OF ITS MEMBERS, EMPLOYEES, VENDORS, OR AGENTS, BE LIABLE WITH RESPECT 
          TO THIS AGREEMENT, THE SLA LEARNING CENTER WEB SITE, OR ANY CONTENT FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, RELIANCE, PUNITIVE, OR 
          EXEMPLARY DAMAGES, OR ANY LOSS OF PROFITS, DATA, OPPORTUNITY, SAVINGS, OR INTEREST, OR ANY PENALTIES OR ASSESSMENTS IMPOSED UNDER APPLICABLE TAX LAWS OR 
          OTHERWISE, EVEN IF THE SLA, OR ITS AGENTS, HAS BEEN ADVISED OF THE LIKELIHOOD THEREOF.
        </p>
      </div>)
  },
  {
    key: "8. Reservation of Rights",
    value: (
      <div className="generic-content">
        <p>
          The SLA reserves the right in its sole and absolute discretion (without penalty or liability of any kind) to: (a) suspend or terminate access to 
          or the use of all or any part of the SLA Learning Center Web Site at any time; (b) alter or vary the format and contents of the SLA Learning Center 
          Web Site at any time; or (c) block access to and/or suspend operation of all or part of the SLA Learning Center Web Site, temporarily or permanently, 
          at any time.
        </p>
      </div>)
  },
  {
    key: "9. Indemnification",
    value: (
      <div className="generic-content">
        <p>
          The SLA provides the SLA Learning Center Web Site, Content, and Services solely as an accommodation to you, and the SLA will not accept any risks arising 
          from your use thereof. As such, you will indemnify, defend, and hold harmless the SLA, and its respective members, employees, and agents, from and against 
          any and all claims that arise from or relate to: (a) your use of the SLA Learning Center Web Site, Content, or Services; or (b) your breach of any provision 
          of this Agreement.
        </p>
      </div>)
  },
  {
    key: "10. Miscellaneous",
    value: (
      <div className="generic-content">
      A. Jurisdiction
      <br/>
      <p>
        This Agreement will be governed by the laws of the State of California, without regard to its rules of conflict of laws. The state and federal courts 
        located in San Francisco County, California, will have exclusive jurisdiction over any case or controversy arising from or relating to this Agreement, 
        or the SLA Learning Center Web Site. Each party hereby consents irrevocably to personal jurisdiction in such courts with respect to any and all such matters, 
        and waives any defense of forum non conveniens with respect to such courts. Notwithstanding the foregoing, either party may seek appropriate injunctive 
        relief from any court having jurisdiction.
        <br/>
      </p>
      <br/>
      B. Assignment
      <br/>
      <p>
        Your rights and obligations under this Agreement are personal to you and may not be assigned to any other party.
        <br/>
      </p>
      <br/>
      C. Waiver
      <br/>
      <p>
        The SLA will not be deemed to have waived any breach by you of this Agreement, except by a written waiver expressly so stating, and such waiver 
        will not be construed as a waiver of subsequent or continued breaches.
        <br/>
      </p>
      <br/>
      D. Severability
      <br/>
      <p>
        If any provision of this Agreement is held to be invalid or unenforceable, such invalidity or unenforceability will be limited to the maximum 
        extent permissible, and the other provisions of this Agreement will remain in full force and effect.
        <br/>
      </p>
      <br/>
      E. Complete Understanding
      <br/>
      <p>
        This Agreement constitutes the final and complete agreement between the parties regarding the subject matter hereof, and supersedes any 
        prior or contemporaneous communications, representations, or agreements between the parties relating to the subject matter hereof.
        <br/>
      </p>
    </div>)
  },
  {
    key: "11. Contacting the SLA Learning Center Web Site",
    value: (
    <div className="generic-content">
      If you have any questions about this Agreement, or your dealings with the SLA Learning Center Web Site, you are invited to contact:<br/>
      <br/>
      The Surplus Line Association of California<br/>
      12667 Alcosta Boulevard, Suite 450<br/>
      San Ramon, CA 94583<br/>
      415.434.4900<br/>
      support@slacal.com<br/>
    </div>)
  }
];

const TermsOfUseAndPrivacyPolicy = () => {
  return(<ContentPageLayout
    documentTitle="Terms Of Use &amp; Privacy Policy"
    headerTitle="Terms Of Use &amp; Privacy Policy"
    headerContent={
      <React.Fragment>
      </React.Fragment>
    }>
    <div className={"container"}>
      <div>
        <div className="white-body">
          <p className={"tos-title-text"}>
            These Terms of Use (this &quot;Agreement&quot;) are provided by The Surplus Line Association of California (the &quot;SLA”), a private not-for-profit California association and the duly appointed surplus line advisory organization to the California Department of Insurance, and will govern your use of the publicly available areas of the web site located at learningcenter.slacal.com (the &quot;SLA Learning Center Web Site&quot;), including all content provided on, or through access to the SLA Learning Center Web Site (the &quot;Content&quot;).
            <br/>
            <br/>
            The SLA reserves the right to update these Terms of Use at any time without notice to you. The most recent version of the Terms of Use can be viewed by clicking on the Terms of Use &amp; Privacy Policy hypertext link found at the bottom of the home page to the SLA Learning Center Web Site.
            <br/>
            <br/>
          </p>
            {
            TOS.map((tos,index) => {
              return (
                <React.Fragment key={index}>
                  <Collapsible trigger={tos.key}
                  className={"collapsible-enhanced"}
                  openedClassName={"collapsible-enhanced"}>
                    {tos.value}
                  </Collapsible>
                </React.Fragment>
              );
            })
          }
        </div>
      </div>
    </div>
  </ContentPageLayout>);
};

export default TermsOfUseAndPrivacyPolicy;
