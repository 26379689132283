import objectAssign from 'object-assign';
import initialState from '../initialState';
import {actionTypes} from "../../actions/courseActions";

export default function locationReducer(state = initialState.search, action) {

  switch (action.type) {

    case actionTypes.SAVE_COURSE_SEARCH_TO_HISTORY:
      return objectAssign({}, state, {
        history: [action.data, ...state.history]
      });

    default:
      return state;
  }
}
