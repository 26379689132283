import React from 'react';
import PropTypes from 'prop-types';
import PresenterDetail from './PresenterDetail';
import presenter from '../../../proptypes/presenter/presenter';
import { sortPresenters } from '../../../utilities/courseUtilities';

const PresenterList = ({presenters}) => {
  return (
    <div className="presenter-list">
      <h1 className="left-block red">Presented By</h1>
      <div className="presenters">
        {presenters && presenters.length &&
          sortPresenters(presenters)
            .map((presenter) => (
              <PresenterDetail key={presenter.id} presenter={presenter} />
            ))
            .reduce(
              (prev, curr) => [prev, <hr key={Date.now()} />, curr]
            )}
      </div>
    </div>
  );
};

PresenterList.propTypes = {
    presenters: PropTypes.arrayOf(PropTypes.shape(presenter)).isRequired
};

export default PresenterList;
