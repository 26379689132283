import React from 'react';
import { PropTypes } from 'prop-types';
import SvgIcon from './../icon/SvgIcon';

class CarouselControls extends React.Component {
  
  render() {
    return(
      <div className='carousel-controls'>
        <SvgIcon className='control-prev white' icon='chevron-carousel-right' onClick={this.props.prevSlide}/>
        <SvgIcon className='control-next white' icon='chevron-carousel-right' onClick={this.props.nextSlide}/>
      </div>
    );
  }
}

CarouselControls.propTypes = {
  prevSlide: PropTypes.func.isRequired,
  nextSlide: PropTypes.func.isRequired,
}

export default CarouselControls;