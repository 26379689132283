import React from 'react';
import PropTypes from 'prop-types';
import Button from "../inputs/Button";
import user from "../../proptypes/users/User";
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";

const BasicUser = ({ edit, buttonLabel, user }) => {
  var cts = user.licenseEffDate;
  const objEffectiveDate = (new Date(cts)).toLocaleDateString();
  const licenseEffectiveDate = user.licenseEffDate ? objEffectiveDate : undefined;

  return (
    <React.Fragment>
      <div className="info-fields">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>Individual License #:</label> {user.licenseNumber}</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>License Jurisdiction:</label> {user.licenseJurisdiction}</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>License Effective Date:</label> {licenseEffectiveDate}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>First Name:</label> {user.firstName}</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>Middle Name:</label> {user.middleName}</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>Last Name:</label> {user.lastName}</p>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>Phone:</label> {user.phoneNumber} {user.phoneExt}</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>Email:</label> {user.emailAddress}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className="info">
              <label>Address:</label> {user.addressOne}{user.addressTwo !== '' && user.addressTwo ? `, ${user.addressTwo}` : ''}, {user.city}, {user.state} {user.zip}
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>Company:</label> {user.companyName}</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="info"><label>Company Type:</label> {user.companyType}</p>
          </div>
        </div>

      </div>

      <div className="form-row">
        <div className="form-group col text-right">
          <Button onClick={() => edit()}
            label={buttonLabel}
            buttonType={buttonTypes.PRIMARY}
            buttonSize={buttonSizes.LARGE}
            svgIcon
            icon="edit"
            iconColor="white" />
        </div>
      </div>

    </React.Fragment>
  );
};

BasicUser.propTypes = {
  user: PropTypes.shape(user).isRequired,
  buttonLabel: PropTypes.string.isRequired,
  edit: PropTypes.func.isRequired
};

BasicUser.DefaultProps = {
  buttonLabel: "",
};

export default BasicUser;
