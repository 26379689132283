import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { connect } from "react-redux";
import ReactPlaceholder from 'react-placeholder';

import * as courseActions from "../../actions/courseActions";
import * as shoppingActions from "../../actions/shoppingActions";
import * as modalActions from "../../actions/modalActions";

import * as courseUtilities from "../../utilities/courseUtilities";
import { selectCourseOfferings } from "../../selectors/courseSelectors";

import courseOffering from "../../proptypes/courses/courseOffering";
import courseOfferingMethod, { CourseOfferingMethods } from "../../proptypes/courses/courseOfferingMethod";
import courseOfferingRegisteredStatus from "../../proptypes/courses/courseOfferingRegisteredStatus";

import SvgIcon from "../icon/SvgIcon";
import ContentPageLayout from '../layout/ContentPageLayout';
import ListSorter from "../inputs/ListSorter";
import CourseListPlaceHolder from "./placeholders/CourseListPlaceHolder";
import CourseSearchBar from './CourseSearchBar';
import CourseOfferingCard from "./CourseOfferingCard";
import CourseFilterOptions from "./CourseFilterOptions";
import { COURSE_SERIES } from '../../constants/courseSeriesLookup';

class CourseListPage extends Component {

  componentWillMount() {
    this.getCourses(this.props.searchInput);
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingCourses: true
    };

    this.getCourses = this.getCourses.bind(this);
    this.toggleLiveSort = this.toggleLiveSort.bind(this);
    this.toggleOnDemandSort = this.toggleOnDemandSort.bind(this);
  }

  getCourses() {
    if (this.props.courseOfferings.length > 0) {
      this.setState({ loadingCourses: false });
    }

    this.props.actions.getFutureCourses()
      .then(() => this.setState({ loadingCourses: false }));
  }

  toggleLiveSort(property) {
    this.props.actions.toggleLiveSort(property);
  }

  toggleOnDemandSort(property) {
    this.props.actions.toggleOnDemandSort(property);
  }

  render() {
    // Normally we only show the filter if there are courses to show for that filter. However,
    // we might need to show and apply the filter if the user navigated to the screen with filter
    // applied (homepage carousel tab).
    let showComplianceConnectionsFilter = this.props.courseOfferings.some(c => c.course.seriesId == COURSE_SERIES.COMPLIANCE_CONNECTIONS)
      || this.props.filterComplianceConnections;
    let showSlaUniversityFilter = this.props.courseOfferings.some(c => c.course.seriesId == COURSE_SERIES.SLA_UNIVERSITY)
      || this.props.filterSlaUniversity;

    let results = this.props.courseOfferings
      //apply search
      .filter(c => c.course.name.toLowerCase().includes(this.props.searchInput.toLowerCase()))
      //apply method filter
      .filter(c => c.method === this.props.filterCourseMethod || this.props.filterCourseMethod === courseOfferingMethod.ALL);

    //apply CE credit filters
    if (this.props.filterCECredits) {
      results = results.filter(c => c.course.ceHours >= 1);
      if (this.props.filterCreditsLow !== '' && this.props.filterCreditsHigh !== '')
        results = results.filter(c => c.course.ceHours >= parseInt(this.props.filterCreditsLow, 10) && c.course.ceHours <= parseInt(this.props.filterCreditsHigh, 10));
      else if (this.props.filterCreditsLow !== '')
        results = results.filter(c => c.course.ceHours >= parseInt(this.props.filterCreditsLow, 10));
      else if (this.props.filterCreditsHigh !== '')
        results = results.filter(c => c.course.ceHours <= parseInt(this.props.filterCreditsHigh, 10));
    }

    //apply live dates filter
    if (this.props.filterLiveDatesFrom !== '' && this.props.filterLiveDatesTo !== '')
      results = results.filter(c => !c.date || c.date >= this.props.filterLiveDatesFrom && c.date <= this.props.filterLiveDatesTo, 10);
    else if (this.props.filterLiveDatesFrom !== '')
      results = results.filter(c => !c.date || c.date >= this.props.filterLiveDatesFrom);
    else if (this.props.filterLiveDatesTo !== '')
      results = results.filter(c => !c.date || c.date <= this.props.filterLiveDatesTo);

    //apply registered status filter
    if (this.props.filterRegisteredStatus === courseOfferingRegisteredStatus.REGISTERED)
      results = results.filter(c => this.props.currentUser.registeredCourses.filter(registration => registration.courseOffering.uniqueId === c.uniqueId).length > 0);
    else if (this.props.filterRegisteredStatus === courseOfferingRegisteredStatus.COMPLETED)
      results = results.filter(c => this.props.currentUser.registeredCourses.filter(registration => registration.courseOffering.uniqueId === c.uniqueId && registration.attended).length > 0);

    //apply city filter
    if (this.props.filterCity !== '')
      results = results.filter(c => c.location && c.location.address.city === this.props.filterCity);

    //apply course series filter
    if (this.props.filterComplianceConnections && this.props.filterSlaUniversity)
      results = results.filter(c => c.course.seriesId == COURSE_SERIES.COMPLIANCE_CONNECTIONS || c.course.seriesId == COURSE_SERIES.SLA_UNIVERSITY);
    else if (this.props.filterComplianceConnections)
      results = results.filter(c => c.course.seriesId == COURSE_SERIES.COMPLIANCE_CONNECTIONS);
    else if (this.props.filterSlaUniversity)
      results = results.filter(c => c.course.seriesId == COURSE_SERIES.SLA_UNIVERSITY);

    //split list into two separate arrays and apply sorting when needed
    let displayLiveList = this.props.filterCourseMethod === courseOfferingMethod.ALL || this.props.filterCourseMethod === courseOfferingMethod.LIVE;
    let displayOnDemandList = this.props.filterCourseMethod === courseOfferingMethod.ALL || this.props.filterCourseMethod === courseOfferingMethod.ON_DEMAND;

    //live courses
    let liveResults = [];
    if (displayLiveList) {
      liveResults = results.filter(c => c.method === courseOfferingMethod.LIVE);
      liveResults = courseUtilities.sortCourses(this.props.liveSortBy, this.props.liveSortDescending, [...liveResults]);
    }

    //on demand
    let onDemandResults = [];
    if (displayOnDemandList) {
      onDemandResults = results.filter(c => c.method === courseOfferingMethod.ON_DEMAND);
      onDemandResults = courseUtilities.sortCourses(this.props.onDemandSortBy, this.props.onDemandSortDescending, [...onDemandResults]);
    }
    return (
      <ContentPageLayout
        documentTitle="Course Search"
        headerTitle="Course Search"
        headerContent={
          <React.Fragment>
            Search for courses by course title using the search bar below. Use the sidebar on the left to focus your
            search by Course Type, CE Credit eligibility, Date, Topic, or Location.
          </React.Fragment>
        }
        showNavSearchItem={false}
        showNavSearchField={false}>

        <div className="container-fluid course-search-container">

          <CourseSearchBar className="d-none d-lg-block" />

          <div className="d-block d-lg-none">
            <button
              className="btn btn-primary btn-block heading-button"
              onClick={() => { this.props.actions.showCourseListFiltersModal(this.props.actions.resetCourseSearchFilters) }}>
              <SvgIcon icon="filters" color="white" />
              Filters
            </button>
          </div>

          <div className="row">
            <div className="d-none d-lg-block col-sm-12 col-lg-4 col-xl-3 mb-5 course-filter-options">
              <h1 className="left-block gray pt-0">Filters</h1>

              <CourseFilterOptions
                showComplianceConnectionsFilter={showComplianceConnectionsFilter}
                showSlaUniversityFilter={showSlaUniversityFilter}
              />

            </div>
            <div className={"col-12 col-lg-8 col-xl-9 course-list"}>

              <hr className="d-block d-sm-none" />

              <div className="course-list-live mb-5" >
                {(this.state.loadingCourses || displayLiveList) &&
                  <div className="course-list-header">
                    <h1 className="left-block red">Live Courses</h1>
                    <div className="list-sorters">
                      <ListSorter name="name" sortBy={this.props.liveSortBy}
                        sortDescending={this.props.liveSortDescending}
                        onClick={() => this.toggleLiveSort('name')} />
                      <ListSorter name="date" sortBy={this.props.liveSortBy}
                        sortDescending={this.props.liveSortDescending}
                        onClick={() => this.toggleLiveSort('date')} />
                    </div>
                  </div>
                }
                <ReactPlaceholder showLoadingAnimation ready={!this.state.loadingCourses} customPlaceholder={CourseListPlaceHolder}>
                  {liveResults.length > 0 ?
                    <React.Fragment>
                      {liveResults.map(courseOffering =>
                        <CourseOfferingCard
                          key={courseOffering.uniqueId}
                          courseOffering={courseOffering}
                        />
                      )}
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {displayLiveList &&
                        <p>There are no live courses matching the search criteria.</p>
                      }
                    </React.Fragment>
                  }
                </ReactPlaceholder>
              </div>

              <div className="course-list-ondemand">
                {(this.state.loadingCourses || displayOnDemandList) &&
                  <div className="course-list-header">
                    <h1 className="left-block blue">On Demand Courses</h1>
                    <div className="list-sorters">
                      <ListSorter name="name" sortBy={this.props.onDemandSortBy}
                        sortDescending={this.props.onDemandSortDescending}
                        onClick={() => this.toggleOnDemandSort('name')} />
                    </div>
                  </div>
                }
                <ReactPlaceholder showLoadingAnimation ready={!this.state.loadingCourses} customPlaceholder={CourseListPlaceHolder}>
                  {onDemandResults.length > 0 ?
                    <React.Fragment>
                      {onDemandResults.map((courseOffering )=>
                        <CourseOfferingCard key={courseOffering.uniqueId} courseOffering={courseOffering} 
                         />
                      )}
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {displayOnDemandList &&
                        <p>There are no on demand courses matching the search criteria.</p>
                      }
                    </React.Fragment>
                  }
                </ReactPlaceholder>
              </div>

            </div>

          </div>
        </div>
      </ContentPageLayout>
    );
  }
}

CourseListPage.propTypes = {
  actions: PropTypes.object,
  searchInput: PropTypes.string.isRequired,
  courseOfferings: PropTypes.arrayOf(PropTypes.shape(courseOffering)),
  currentUser: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  liveSortBy: PropTypes.string.isRequired,
  liveSortDescending: PropTypes.bool.isRequired,
  onDemandSortBy: PropTypes.string.isRequired,
  onDemandSortDescending: PropTypes.bool.isRequired,
  filterCourseMethod: PropTypes.oneOf(CourseOfferingMethods).isRequired,
  filterCECredits: PropTypes.bool.isRequired,
  filterCreditsLow: PropTypes.string.isRequired,
  filterCreditsHigh: PropTypes.string.isRequired,
  filterLiveDatesFrom: PropTypes.string.isRequired,
  filterLiveDatesTo: PropTypes.string.isRequired,
  filterRegisteredStatus: PropTypes.string.isRequired,
  filterCity: PropTypes.string.isRequired,
  filterComplianceConnections: PropTypes.bool.isRequired,
  filterSlaUniversity: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    searchInput: state.course.searchInput,
    courseOfferings: selectCourseOfferings(state),
    currentUser: state.auth.currentUser,
    isLoggedIn: state.auth.isLoggedIn,
    liveSortBy: state.course.liveSortBy,
    liveSortDescending: state.course.liveSortDescending,
    onDemandSortBy: state.course.onDemandSortBy,
    onDemandSortDescending: state.course.onDemandSortDescending,
    filterCourseMethod: state.course.filterCourseMethod,
    filterCECredits: state.course.filterCECredits,
    filterCreditsLow: state.course.filterCreditsLow,
    filterCreditsHigh: state.course.filterCreditsHigh,
    filterLiveDatesFrom: state.course.filterLiveDatesFrom,
    filterLiveDatesTo: state.course.filterLiveDatesTo,
    filterRegisteredStatus: state.course.filterRegisteredStatus,
    filterCity: state.course.filterCity,
    filterComplianceConnections: state.course.filterComplianceConnections,
    filterSlaUniversity: state.course.filterSlaUniversity,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, courseActions, shoppingActions, modalActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseListPage);
