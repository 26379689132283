import React from 'react';
import { PropTypes } from 'prop-types';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {ROUTES} from "../../constants/routeConfig";
import slideBg from "../../assets/Carousel-3.png";
import slideBgMobile from "../../assets/Carousel-3-mobile.png";
import CourseSearchButton from '../courses/CourseSearchButton';
import CreateAccountButton from './../inputs/CreateAccountButton';
import CarouselSlide from './CarouselSlide';

class LandingPageCourseSlide extends React.Component {
  render() {
    return(
      <CarouselSlide backgroundUrl={slideBg}
                     mobileBackgroundUrl={slideBgMobile}
                     active={this.props.active}>
        <div className={this.props.active ? '' : 'row'}>
          <div className={this.props.active ? '' : 'col offset-md-4'}>
            <h1><span>Live and On-Demand</span><span>Courses On Your Time</span></h1>
            <p className="landing-subtitle">
              With the option of both live and on-demand courses, you can register for courses and earn continuing 
              education credits based on your busy work schedule.
            </p>
            <div className="slide-buttons">
              {!this.props.isLoggedIn &&
                <CreateAccountButton
                  primary={true}
                  blue={false}
                  createAccountRedirect={ROUTES.LANDING_PAGE}
                  createAccountFormRoute={ROUTES.CREATE_ACCOUNT} />
              }
              <CourseSearchButton />
            </div>
          </div>
        </div>
      </CarouselSlide>
    );
  }
}

LandingPageCourseSlide.propTypes = {
  history: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
}

export default withRouter(connect(mapStateToProps)(LandingPageCourseSlide));