
export function formElementHasError(value) {
  if (value !== null)
    if (value === "")
      return true;
  return false;
}

/**
 * Valid license numbers should be between 4 and 9 characters.
 */
export function validLicenseNumber(licenseNumber) {
  var re = /^.{4,9}$/
  return re.test(licenseNumber);
}

/* eslint-disable no-useless-escape */
export function validateEmail(email) {
  var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return re.test(String(email).toLowerCase());
}

/* eslint-disable no-useless-escape */
export function validatePassword(password) {
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
}
