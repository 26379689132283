import { CONFIGURATION } from './config';
import courseOfferingFormat from '../proptypes/courses/courseOfferingFormat';

export const ROUTES = Object.freeze({
  LANDING_PAGE: "/",
  //courses
  COURSE_LIST: "/courselist",
  COURSE_DETAIL_PAGE: "/course/:courseOfferingId/:format",
  COURSE_DETAIL_PAGE_ARCHIVE_ROUTE: "/course/:courseOfferingId",
  COURSE_VIEW_PAGE: "/watch-course/:courseRegistrationId/",
  COURSE_UNENROLL_PAGE: "/course/drop/:courseRegistrationGuid/",
  VIEW_WEBINAR_REDIRECT: "/view-webinar/:courseOfferingId/",
  //account
  LOGIN: "/account/login",
  CREATE_ACCOUNT: "/account/create",
  SETUP_GUEST: "/account/setup-guest/:id/:token",
  MY_PROFILE_PAGE: "/account/profile",
  MY_COURSES_PAGE: "/account/courses",
  RESET_PASSWORD: "/account/resetPassword",
  CONFIRM_RESET_PASSWORD: "/account/confirmResetPassword/:token",
  //shopping basket
  SHOPPING_CART: "/basket",
  SHOPPING_CART_LOGIN: "/basket/login",
  SHOPPING_CART_CREATE_ACCOUNT: "/basket/create-account",
  SHOPPING_CART_CREATE_GUEST: "/basket/create-guest",
  SHOPPING_CART_CONFIRM_INFO: "/basket/confirm",
  SHOPPING_CART_COMPLETE: "/basket/complete",

  // TERMS OF USE & PRIVACY POLICY
  TERMS_OF_USE_AND_PRIVACY_POLICY: "/tos",

  // RESOURCES
  RESOURCES_PAGE: "/resources",
  TUTORIALS_PAGE: "/resources/tutorials",
  BULLETINS_PAGE: "/resources/bulletins",
  BULLETINS_SERIES_PAGE: "/resources/bulletins/:seriesName/:seriesId",
  FAQS_PAGE: "/resources/faqs",
  FAQS_FAQ_PAGE: "/resources/faqs/:faqGroupUrlName",
  NOTICES_PAGE: "/resources/notices",
  NOTICES_PAGE_EXPANDED: "/resources/notices/:id",
  FILING_REQUIREMENTS_PROCEDURES_PAGE: '/resources/filing-requirements-and-procedures',

  // REFERENCE
  CECREDITREFERENCE_PAGE: "/references/ce-credit-reference",

   // EXTERNAL
  SLACAL_WEBSITE: "https://www.slacal.com",
});

export const EXTERNAL_ROUTES = Object.freeze({
  WEBSITE_FILING_REQUIREMENTS_PROCEDURES_CONTENT_ROOT: '/lc-filing-requirements-and-procedures'
});

export function cdiWebsiteLinkForLicenseRenewalRoute(licenseNumber,isLoggedIn) {
  if (licenseNumber && isLoggedIn)
    return `https://cdicloud.insurance.ca.gov/cal/ContinuingEducation?IndividualLicNbr=${licenseNumber}`;
  else
    return "https://cdicloud.insurance.ca.gov/cal/IndividualNameSearch?handler=Search";
}

const courseOfferingFormatRouteParam = Object.freeze({
  IN_PERSON: 'in-person',
  WEBINAR: 'webinar',
  ON_DEMAND: 'on-demand'
});

const courseOfferingFormatToRouteParam = Object.freeze(function () {
  var mapping = {};
  mapping[courseOfferingFormat.IN_PERSON] = courseOfferingFormatRouteParam.IN_PERSON;
  mapping[courseOfferingFormat.ON_DEMAND] = courseOfferingFormatRouteParam.ON_DEMAND;
  mapping[courseOfferingFormat.WEBINAR] = courseOfferingFormatRouteParam.WEBINAR;
  return mapping;
}());

const courseOfferingFormatFromRouteParam = Object.freeze(function () {
  var mapping = {};
  mapping[courseOfferingFormatRouteParam.IN_PERSON] = courseOfferingFormat.IN_PERSON;
  mapping[courseOfferingFormatRouteParam.ON_DEMAND] = courseOfferingFormat.ON_DEMAND;
  mapping[courseOfferingFormatRouteParam.WEBINAR] = courseOfferingFormat.WEBINAR;
  return mapping;
}());

export function getCourseFormatFromRouteParam(routeParam) {
  return courseOfferingFormatFromRouteParam[routeParam];
}

export function courseDetailRoute(courseId, format) {
  return ROUTES.COURSE_DETAIL_PAGE.replace(":courseOfferingId", courseId).replace(":format", courseOfferingFormatToRouteParam[format]);
}

export function getCourseViewUrl(courseRegistrationId) {
  return CONFIGURATION.SERVER_API_URL + "/courseOfferings/watch/" + courseRegistrationId;
}

export const RAPID_ROUTES = Object.freeze({
  PRESENTER_PHOTO: CONFIGURATION.RAPID_SERVER_URL + "/courses/presenters/",
  PRESENTER_DOCUMENT: CONFIGURATION.RAPID_SERVER_URL + "/courses/presenter/document/",
  COURSE_THUMBNAIL: CONFIGURATION.RAPID_SERVER_URL + "/courses/thumbnail/",
});

export function getFaqsPage(faqGroupUrlName) {
  return ROUTES.FAQS_FAQ_PAGE.replace(":faqGroupUrlName", faqGroupUrlName);
}

export function getSeriesPage(seriesName, seriesId) {
  return ROUTES.BULLETINS_SERIES_PAGE.replace(":seriesName", seriesName).replace(":seriesId", seriesId);
}

export function getPresenterPictureUrl(presenterId) {
  return RAPID_ROUTES.PRESENTER_PHOTO + presenterId;
}

export function getPresenterDocumentUrl(presenterId) {
  return RAPID_ROUTES.PRESENTER_DOCUMENT + presenterId;
}

export function getThumbnailPictureUrl(courseOfferingId) {
  return RAPID_ROUTES.COURSE_THUMBNAIL + courseOfferingId;
}

export function getCourseDocument(courseOfferingId, documentId) {
  return CONFIGURATION.RAPID_SERVER_URL + "/courses/" + courseOfferingId + "/documents/" + documentId;
}
