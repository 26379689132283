import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

class DocumentIcon extends React.Component {
  render() {
    return(
      <div className='document-icon'>
        <SvgIcon icon="file" color='blue'/>
        <span className="type">{this.props.type.slice(0, 3)}</span>
      </div>
    );
  }
}

DocumentIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default DocumentIcon;
