import React from "react";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as resourceActions from "../../actions/resourceActions";
import { sortNotices, sortBulletins } from "../../utilities/resourcesUtilities";

import NoticesBulletinMenuDropdownContent from "./NoticesBulletinMenuDropdownContent";
import bulletin from "../../proptypes/resource/bulletin";
import notice from "../../proptypes/resource/notice";

class NoticesBulletinMenuDropdown extends React.Component {
    componentWillMount() {
          this.getNotices();
          this.getFeaturedBulletins();
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            currentSlide: 0,
            nextSlide: -1,
            prevSlide: -1,
            direction: '',
            timeoutId: 0,
            active: false,
        };

        this.getNotices = this.getNotices.bind(this);
        this.getFeaturedBulletins = this.getFeaturedBulletins.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.setSlide = this.setSlide.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.getSlideClasses = this.getSlideClasses.bind(this);
    }

    getNotices() {
        if (this.props.notices.length === 0) {
            this.props.actions.getNotices();
        } else {
            this.setState({ loading: false });
        }
    }

    getFeaturedBulletins() {
        this.props.actions.getFeaturedBulletins();
    }

    nextSlide() {
        let prevSlide = this.state.currentSlide;
        let currentSlide = this.state.currentSlide + 1 > 5 - 1 ? 0 : this.state.currentSlide + 1;
        let nextSlide = currentSlide + 1 > 5 - 1 ? 0 : currentSlide + 1;

        this.setState({
            currentSlide: currentSlide,
            prevSlide: prevSlide,
            nextSlide: nextSlide,
            direction: ' right',
        });
    }

    prevSlide() {
        let nextSlide = this.state.currentSlide;
        let currentSlide = this.state.currentSlide - 1 < 0 ? 5 - 1 : this.state.currentSlide - 1;
        let prevSlide = currentSlide - 1 < 0 ? 5 - 1 : currentSlide - 1;

        this.setState({
            currentSlide: currentSlide,
            prevSlide: prevSlide,
            nextSlide: nextSlide,
            direction: ' left',
        });
    }

    setSlide(index) {
        if (index !== this.state.currentSlide) {
            let currentSlide = index;
            let prevSlide = currentSlide - 1 < 0 ? 5 - 1 : currentSlide - 1;
            let nextSlide = currentSlide + 1 > 5 - 1 ? 0 : currentSlide + 1;

            this.setState({
                currentSlide: currentSlide,
                prevSlide: prevSlide,
                nextSlide: nextSlide,
                direction: ' right',
            });
        }
    }

    getSlideClasses(index) {
        let classes = 'notices-bulletin-menu-dropdown-slide';
        switch (index) {
            case this.state.currentSlide:
                classes += ' current';
                break;
            case this.state.prevSlide:
                classes += ' prev';
                break;
            case this.state.nextSlide:
                classes += ' next';
        }
        return classes;
    }

    toggleActive() {
        const active = this.state.active;
        this.setState({ active: !active });
    }

    render() {
        var notices = this.props.notices || [];
        var featuredBulletins = this.props.featuredBulletins || [];

        let filteredNotices = sortNotices(notices).slice(0, 3);
        let filteredBulletins = sortBulletins(featuredBulletins).slice(0, 2);

        let noticesAndBulletinsForDisplay = filteredNotices.concat(filteredBulletins);

        return (
            <div className='notices-bulletin-menu-dropdown-container'>
                <div className='notices-bulletin-menu-dropdown' onClick={this.toggleActive}>
                    <span className={'svg-icon white ' + (this.state.active ? 'remove' : 'info')} />
                </div>
                <div className={'dropdown-container ' + (this.state.active ? 'active' : '')}>
                    {noticesAndBulletinsForDisplay.map((item, index) => {
                        return <NoticesBulletinMenuDropdownContent
                            className={this.getSlideClasses(index)}
                            notice={!item.bulletinNumber ? item : undefined}
                            bulletin={item.bulletinNumber ? item : undefined}
                            active={index == this.state.currentSlide}
                            nextSlide={this.nextSlide}
                            prevSlide={this.prevSlide}
                            setSlide={this.setSlide}
                            slides={noticesAndBulletinsForDisplay}
                            currentSlide={this.state.currentSlide}
                            type={item.bulletinNumber ? "Bulletin" : "Notice"}
                            key={index}>
                        </NoticesBulletinMenuDropdownContent>
                    })}
                </div>
            </div>
        );
    }
}

NoticesBulletinMenuDropdown.propTypes = {
    actions: PropTypes.object,
    notices: PropTypes.arrayOf(PropTypes.shape(notice)),
    featuredBulletins: PropTypes.arrayOf(PropTypes.shape(bulletin)),
}

function mapStateToProps(state) {
    return {
        notices: state.resource.notices,
        featuredBulletins: state.resource.featuredBulletins,
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = objectAssign({}, resourceActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoticesBulletinMenuDropdown));
