import objectAssign from 'object-assign';
import initialState from '../initialState';
import { actionTypes } from "../../actions/shoppingActions";
import { actionTypes as courseActionTypes } from "../../actions/courseActions";
import { actionTypes as lcUserActionTypes } from "../../actions/lcUserActions";
import moment from "moment/moment";
import { CONFIGURATION } from "../../constants/config";

export default function shoppingReducer(state = initialState.shopping, action) {

  //merge this object with every action so that every time a shopping
  //action is processed the state expiration is reset
  let expiration = {
    persistExpiresAt: moment()
      .add(CONFIGURATION.LOGGED_IN_DATA_EXPIRATION_HOURS, 'h')
      .format()
  };

  switch (action.type) {

    case actionTypes.ADD_TO_CART:
      return objectAssign({}, state, expiration, {
        cart: [action.data, ...state.cart]
      });

    case actionTypes.UPDATE_SHOPPING_CART:
      return objectAssign({}, state, expiration, {
        cart: [...action.data.cart]
      });

    case actionTypes.REMOVE_FROM_CART:
      return objectAssign({}, state, expiration, {
        cart: state.cart.filter(courseId => courseId !== action.data)
      });

    case courseActionTypes.REGISTER_FOR_COURSES_SUCCESS:
      return objectAssign({}, state, expiration, {
        registeredCourses: [...state.cart],
        cart: initialState.shopping.cart,
        guestUserData: action.data.user,
      });

    case actionTypes.SETUP_GUEST_SUCCESS:
      return objectAssign({}, state, expiration, {
        guestUserData: initialState.shopping.guestUserData,
      });

    case lcUserActionTypes.LOGOUT:
      return objectAssign({}, state, expiration, {
        guestUserData: initialState.shopping.guestUserData,
      });

    case lcUserActionTypes.LOGIN_SUCCESS:
      return objectAssign({}, state, expiration, {
      });

    default:
      return state;
  }
}
