import React from 'react';
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'

const CourseListPlaceHolder = (
  <div className="course-offering-placeholder mb-2">
    <div className='row'>
      <div className="col-4">
        <RectShape color='lightgray' />
      </div>
      <div className="col-8">
        <div className=" pt-4 pb-4 pr-3">
          <TextBlock color='lightgray' rows={5} />
        </div>
      </div>
    </div>

  </div>
);

export default CourseListPlaceHolder;
