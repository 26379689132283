import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from "react-redux";
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";

import { ROUTES } from "../../constants/routeConfig";

import * as courseActions from "../../actions/courseActions";
import * as modalActions from "../../actions/modalActions";
import { getUserCourseProgressFromGuid } from '../../api/courseApi';

import SimpleContentPageLayout from "../layout/SimpleContentPageLayout";

class CourseUnenrollPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      courseCompleted: false,
      courseDropped: false
    };
  }

  componentDidMount() {
    getUserCourseProgressFromGuid(this.props.match.params.courseRegistrationGuid)
      .then((courseRegistration) => {
        if (courseRegistration == null) {
          this.setState({ courseDropped: true });
        }
        else if (courseRegistration.attended == true) {
          this.setState({ courseCompleted: true });
        }
        else {
          this.props.actions.showConfirmModal(
            <p key={this.props.match.params.courseRegistrationGuid}>Are you sure you want to drop <b>{courseRegistration.courseOffering.course.name}</b>? All progress will be lost.</p>,
            "Caution: Dropping Course",
            () => this.props.actions.dropCourseFromEmail(this.props.match.params.courseRegistrationGuid)
              .then(() =>
                this.setState({
                  courseDropped: true
                })
              )
              .then(() => this.props.history.push(ROUTES.LANDING_PAGE))
              .then(() => toast.success("Course successfully dropped."))
              .catch((error) => {
                toast.error(error.message)
              }),
            false,
            false,
            "Drop Course",
            "Cancel",
            () => { this.props.history.push(ROUTES.LANDING_PAGE); }
          );
        }
      });
  }

  render() {
    return (
      <SimpleContentPageLayout
        documentTitle="Drop Course"
        headerTitle="Drop Course">

        <div className='course-view'>
          {!this.state.courseDropped &&
            !this.state.courseCompleted &&
            <h5>Dropping course...</h5>
          }
          {this.state.courseDropped &&
            <h5>You have successfully dropped the course.</h5>
          }
          {this.state.courseCompleted &&
            <h5>You have already completed this course.</h5>
          }
        </div>
      </SimpleContentPageLayout>
    );
  }
}

CourseUnenrollPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, modalActions, courseActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(CourseUnenrollPage);
