import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import errorReducer from './errorReducer';
import lcUserReducer from './lcUserReducer';
import courseReducer from './courseReducer';
import shoppingReducer from './shoppingReducer';
import locationReducer from './locationReducer';
import authReducer from './authReducer';
import modalReducer from './modalReducer';
import UIReducer from './UIReducer';
import searchReducer from './searchReducer';
import resourceReducer from './resourceReducer';
import brokerGuidesReducer from './brokerGuidesReducer';
import completedCourseFiltersReducer from './completedCourseFiltersReducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  ui: UIReducer,
  modal: modalReducer,
  auth: authReducer,
  error: errorReducer,
  lcUser: lcUserReducer,
  course: courseReducer,
  shopping: shoppingReducer,
  location: locationReducer,
  search: searchReducer,
  resource: resourceReducer,
  brokerGuides: brokerGuidesReducer,
  completedCourseFilters: completedCourseFiltersReducer,
});

export default rootReducer;
