import objectAssign from 'object-assign';
import initialState from '../initialState';
import { actionTypes } from "../../actions/resourceActions";
import loadingStates from '../../constants/loadingStates';

export default function resourceReducer(state = initialState.resource, action) {

  switch (action.type) {

    case actionTypes.GET_FAQ_GROUPS_SUCCESS:

      return objectAssign({}, state, {
        faqGroups: action.data
      });

    case actionTypes.GET_FAQ_GROUP_SUCCESS:

      return objectAssign({}, state, {
        faqGroup: action.data
      });

    case actionTypes.CLEAR_FAQ_GROUP:

      return objectAssign({}, state, {
        faqGroup: initialState.resource.faqGroup
      });

    case actionTypes.GET_FEATURED_FAQS_SUCCESS:

      return objectAssign({}, state, {
        featuredFaqs: action.data
      });

    case actionTypes.GET_FEATURED_BULLETINS:
      return objectAssign({}, state, {
        isLoadingFeaturedBulletins: loadingStates.LOADING,
      });

    case actionTypes.GET_FEATURED_BULLETINS_SUCCESS:
      return objectAssign({}, state, {
        featuredBulletins: action.data,
        isLoadingFeaturedBulletins: loadingStates.LOADED
      });

    case actionTypes.GET_FEATURED_BULLETINS_FAILURE:
      return objectAssign({}, state, {
        isLoadingFeaturedBulletins: loadingStates.FAILED
      });

    case actionTypes.GET_SERIES:
      return objectAssign({}, state, {
        isLoadingSeries: loadingStates.LOADING,
      });

    case actionTypes.GET_SERIES_SUCCESS:
      return objectAssign({}, state, {
        series: action.data,
        isLoadingSeries: loadingStates.LOADED,
      });

    case actionTypes.GET_SERIES_FAILURE:
      return objectAssign({}, state, {
        isLoadingSeries: loadingStates.FAILED,
      });

    case actionTypes.CLEAR_SERIES_BULLETINS:

      return objectAssign({}, state, {
        seriesBulletins: initialState.resource.seriesBulletins
      });

    case actionTypes.GET_SERIES_BULLETINS_SUCCESS:

      return objectAssign({}, state, {
        seriesBulletins: action.data
      });

    case actionTypes.GET_NOTICES_SUCCESS:
      return objectAssign({}, state, {
        notices: action.data,
        isLoadingNotices: loadingStates.LOADED
      });

    case actionTypes.GET_NOTICES:
      return objectAssign({}, state, {
        isLoadingNotices: loadingStates.LOADING
      });

    case actionTypes.GET_NOTICES_FAILURE:
      return objectAssign({}, state, {
        isLoadingNotices: loadingStates.FAILED
      });

    case actionTypes.GET_FEATURED_NOTICES_SUCCESS:

      return objectAssign({}, state, {
        featuredNotices: action.data
      });

    default:
      return state;
  }
}
