import React from 'react';
import { PropTypes } from 'prop-types';

import SvgIcon from './../icon/SvgIcon';

const ModalContent = ({ children, buttons, icon, onClose, fontColor, label, className, bodyCssClass  }) => {

  var titleHasMultipleWords = label.split(' ').length > 1;

  return (<div className={"lc-modal-overlay " + (className || '')}>
    <div className={"lc-modal"}>
      <div className={"lc-modal-header"}>
        <SvgIcon className={fontColor} onClick={onClose} icon={"circle-x"} />
        <div className={"lc-modal-title " + fontColor}>
          {icon &&
            <span
              className={titleHasMultipleWords
                ? 'title-icon d-block d-sm-inline-block d-lg-block mx-lg-auto'
                : 'title-icon d-lg-block mx-lg-auto'}
            >{icon}</span>
          }
          {label &&
            <span className={titleHasMultipleWords ? 'title-label d-block d-sm-inline-block' : 'title-label d-inline'}>{label}</span>
          }
        </div>
      </div>
      <div className={"lc-modal-body " + bodyCssClass + " " + fontColor}>
        <div className="lc-modal-content">
          {
            children
          }
        </div>
      </div>
      {buttons &&
        <div className={"lc-modal-footer"}>
          {
            buttons
          }
        </div>
      }
    </div>
  </div>
  );
};

ModalContent.propTypes = {
  children: PropTypes.any,
  buttons: PropTypes.any,
  icon: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  fontColor: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  bodyCssClass: PropTypes.string,
}

export default ModalContent;
