import CookieManager from "../../tools/CookieManager";
import {CONFIGURATION} from '../constants/config';
import { stopped } from '../constants/symbols';
import { trackTimingEvent } from "../utilities/analyticsUtilities";

function rescueAbort(promise, abort) {
  ['then', 'catch'].forEach(method => {
    const original = promise[method];
    promise[method] = function(...args) {
      // IE does not have Reflect.apply
      const innerPromise = typeof Reflect === 'undefined'
        ? Function.prototype.apply.call(original, promise, args)
        : Reflect.apply(original, promise, args);

      innerPromise.abort = abort;
      return rescueAbort(innerPromise, abort);
    }
  })
  promise.abort = abort;

  return promise;
}

function abortablePromise(fetchFn) {
  let signal;
  let controller;
  if (typeof AbortController !== 'undefined') {
    controller = new window.AbortController();
    signal = controller.signal;
  }

  const res = new Promise(fetchFn(signal))
    .catch(function(error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        error[stopped] = true;
      }
      throw error;
    });

    res.abort = controller
      ? () => controller.abort()
      : () => {};


  return rescueAbort(res, res.abort);
}

export function get(url, paramObject) {

  //fetch api standard does not allow body data in a get request
  //so we have to append the params as a query string
  if (paramObject) {
    let query = Object.keys(paramObject)
      .map((k) =>
        Array.isArray(paramObject[k])
          ? paramObject[k]
              .map((v) => encodeURIComponent(k) + "=" + encodeURIComponent(v))
              .join("&")
          : encodeURIComponent(k) + "=" + encodeURIComponent(paramObject[k])
      )

      .join("&");
    url = url + "?" + query;
  }

  return timedFetch("GET", url,
    abortablePromise(signal => (resolve, reject) => {
      fetch(new Request(url, {
        method: 'GET',
        headers: {
          "Authorization": "Bearer " + CookieManager.getAccessToken()
        },
        signal,
      }))
        .then(function (response) {
          const contentType = response.headers.get("content-type");
          let jsonResult = contentType.includes("json");

          switch (response.status) {
            //resolve responses
            case 200:
              return jsonResult ? response.json().then(resolve) : response.text().then(resolve);
            case 204:
              return resolve;

            //reject responses
            case 400:
            case 401:
            case 403:
            case 406:
            case 500:
              if (jsonResult) {
                return response.json().then(jsonResponse => {
                  throw new Error(JSON.stringify(jsonResponse));
                });
              } else {
                return response.text().then(textResponse => {
                  throw new Error(textResponse);
                });
              }

            default:
              throw new Error('Unknown response code ' + response.status);
          }
        })
        .catch(error => {
          error.url = url;
          error.paramObject = paramObject;
          reject(error);
        });
    })
  );
}

export function post(url, data) {

  return timedFetch("POST", url,
    new Promise((resolve, reject) => {
      fetch(new Request(url, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + CookieManager.getAccessToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(data)
      }))
        .then(function (response) {
          const contentType = response.headers.get("content-type");
          let jsonResult = contentType ? contentType.includes("json") : null;

          switch (response.status) {
            //resolve responses
            case 200:
            case 201:
              return jsonResult ? response.json().then(resolve) : response.text().then(resolve);
            case 204:
              return resolve;

            //reject responses
            case 400:
            case 401:
            case 403:
            case 404:
            case 406:
            case 409:
            case 422:
            case 500:
              return jsonResult ? response.json().then(reject) : response.text().then(reject);

            default:
              return reject('Unknown response code ' + response.status);
          }
        })
        .catch(reject);
    })
  );
}

export function put(url, data) {

  return timedFetch("PUT", url,
    new Promise((resolve, reject) => {
      fetch(new Request(url, {
        method: 'PUT',
        headers: {
          "Authorization": "Bearer " + CookieManager.getAccessToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(data)
      }))
        .then(function (response) {
          const contentType = response.headers.get("content-type");
          let jsonResult = contentType ? contentType.includes("json") : null;

          switch (response.status) {
            //resolve responses
            case 200:
            case 201:
              return jsonResult ? response.json().then(resolve) : response.text().then(resolve);
            case 204:
              return resolve;

            //reject responses
            case 400:
            case 401:
            case 403:
            case 404:
            case 406:
            case 409:
            case 422:
            case 500:
              return jsonResult ? response.json().then(reject) : response.text().then(reject);

            default:
              return reject('Unknown response code ' + response.status);
          }
        })
        .catch(reject);
    })
  );
}

function timedFetch(label, variable, promise) {
  if (CONFIGURATION.GOOGLE_ANALYTICS_KEY) {
    let start = new Date();
    const res = new Promise((resolve, reject) => {
      promise
        .then((data) => {
          trackTimingEvent({
            timingVar: variable,
            timingValue: new Date() - start,
            timingLabel: 'LC API Fetch',
            timingCategory: label
          });
          resolve(data);
        })
        .catch(reject);
    });

    return rescueAbort(res, promise.abort);
  } else {
    return promise;
  }
}
