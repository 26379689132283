import PropTypes from 'prop-types';
import course from './course';
import presenter from '../presenter/presenter';
import {CourseOfferingStatuses} from './courseOfferingStatus';
import {CourseOfferingMethods} from './courseOfferingMethod';
import {CourseOfferingFormats} from './courseOfferingFormat';

export default {
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(CourseOfferingStatuses).isRequired,
  statusText: PropTypes.string,
  locationName: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  course: PropTypes.shape(course),
  location: PropTypes.object, //could be changed
  webinarUrl: PropTypes.string,
  presenters: PropTypes.arrayOf(PropTypes.shape(presenter)),
  method: PropTypes.oneOf(CourseOfferingMethods).isRequired,
  format: PropTypes.oneOf(CourseOfferingFormats).isRequired,
  eventAttendee: PropTypes.array,
  featured: PropTypes.bool.isRequired,
  publishDate: PropTypes.string,
  unpublishDate: PropTypes.string,
  rewatchEnabled: PropTypes.bool,
}
