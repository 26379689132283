import PropTypes from 'prop-types';
import registeredCourse from './registeredCourse';

export const mockUser = Object.freeze({
  //id: Math.random(),
  userName: "",
  password: "",
  licenseNumber: "",
  nonResidentInd: null,
  licenseJurisdiction: "",
  firstName: "",
  middleName: "",
  lastName: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  state: "",
  zip: "",
  phoneNumber: "",
  phoneExt: "",
  emailAddress: "",
  companyName: "",
  companyType: "",
  registeredCourses: [],
  licenseEffDate: null,
});

export default {
  id: PropTypes.number,
  userName: PropTypes.string,
  password: PropTypes.string,
  licenseNumber: PropTypes.string,
  nonResidentInd: PropTypes.string,
  licenseJurisdiction: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  addressOne: PropTypes.string,
  addressTwo: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneExt: PropTypes.string,
  emailAddress: PropTypes.string,
  companyName: PropTypes.string,
  companyType: PropTypes.string,
  registeredCourses: PropTypes.arrayOf(PropTypes.shape(registeredCourse)),
  licenseEffDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}
