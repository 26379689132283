import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import { toast } from 'react-toastify';

import * as buttonSizes from "../../constants/buttonSizes";
import * as buttonTypes from "../../constants/buttonTypes";
import { ROUTES } from "../../constants/routeConfig";

import * as lcUserActions from '../../actions/lcUserActions';

import SimpleContentPageLayout from "../layout/SimpleContentPageLayout";
import Textbox from '../inputs/Textbox';
import Button from '../inputs/Button';

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.change = this.change.bind(this);
    this.createUser = this.createUser.bind(this);
    this.SendResetPasswordEmail = this.SendResetPasswordEmail.bind(this);
  }

  SendResetPasswordEmail(email) {
    this.props.actions.resetPassword(email)
      .then(() => {
        toast.success("Password reset email sent!");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  createUser() {
    this.props.actions.createLcUser();
    this.props.history.push(ROUTES.CREATE_ACCOUNT);
  }

  change(event, form) {
    let changedForm = objectAssign({}, form);
    changedForm[event.target.id] = event.target.value;
    this.props.actions.changeLoginForm(changedForm);
  }

  render() {
    return (
      <SimpleContentPageLayout
        documentTitle="Reset Password"
        headerTitle="Reset Password"
        headerContent="Please provide your username or email address that you used when you created your SLA Learning Center account. We will send you an email that will allow you to reset your password."
        showBackButton={true}
        backButtonRoute={ROUTES.LOGIN}>

        <div className={"reset-form"}>
          <div className="row">

            <div className="col offset-sm-1 col-sm-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6">
              <Textbox
                className={'blue'}
                wrapperClassName='large-blue'
                id='email'
                label='Email or Username'
                icon="account"
                value={this.props.form.email}
                onChange={(event) => this.change(event, this.props.form)} disabled={false} />

              <div className="reset-buttons">
                <div className="row">

                  <div className="col col-sm-7 mb-4 mb-sm-0">
                    <Button
                      className={"btn-block reset-password-btn"}
                      onClick={() => this.SendResetPasswordEmail(this.props.form.email)}
                      label={"SEND RESET PASSWORD EMAIL"}
                      buttonSize={buttonSizes.LARGE}
                      buttonType={buttonTypes.WHITE}
                      disabled={this.props.form.email === ""}
                      svgIcon
                      icon="email"
                      iconColor={"blue"}
                      hoverIconColor={"white"} />
                  </div>

                  <div className="col col-sm-5">
                    <Button
                      className={"btn-block create-account-btn"}
                      onClick={() => this.createUser()}
                      label="CREATE ACCOUNT"
                      buttonSize={buttonSizes.LARGE}
                      buttonType={buttonTypes.WHITE_OUTLINE}
                      svgIcon
                      icon="create-account"
                      iconColor={"white"}
                      hoverIconColor={"blue"}
                      disabled={false} />
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>

      </SimpleContentPageLayout>
    );
  }
}

ResetPasswordPage.propTypes = {
  form: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};


function mapStateToProps(state) {
  return {
    form: state.lcUser.loginForm
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign(
    {},
    lcUserActions
  );

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
