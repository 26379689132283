import React from 'react';
import {RectShape} from 'react-placeholder/lib/placeholders'

const SeriesBulletinsPlaceHolder = (
  <React.Fragment>
    <div className="w-50 pr-2">
      <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
      <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
      <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
    </div>
    <div className="w-50 pl-2">
      <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
      <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
      <RectShape color='lightgray' style={{height: 150, marginBottom: 30}}/>
    </div>
  </React.Fragment>
);

export default SeriesBulletinsPlaceHolder;
