import React from 'react';
import SvgIcon from "../icon/SvgIcon";
import PropTypes from 'prop-types';

const ListSorter = ({name, sortBy, sortDescending, onClick}) => {
  let active = sortBy === name;
  return (
    <span className={"sorter " + (active ? 'active' : '')} onClick={onClick}>
      <span className="sort-control">
        <SvgIcon icon={active ? (sortDescending ? 'sort-descending' : 'sort-ascending') : 'sort-default'} color='gray-medium'/>
      </span>
      {name}
    </span>
  );
};

ListSorter.propTypes = {
  name: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDescending: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};


export default ListSorter;
