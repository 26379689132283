import React from 'react'
import CourseOfferingTag from './CourseOfferingTag';
import { PropTypes } from 'prop-types';
import registeredCourse from '../../../proptypes/users/registeredCourse';
import certificateStatuses from '../../../proptypes/courses/certificateStatuses';
import tagStyles from '../../../constants/tagStyles';

class CertificateStatusTag extends React.Component {
  render() {
    let {courseRegistration} = this.props;

    let label = null;
    let style;

    switch(courseRegistration.certificateStatus) {

      case certificateStatuses.NOT_ISSUED:
        
        label = 'No Certificate Awarded';
        style = tagStyles.DANGER
        break;

      case certificateStatuses.PENDING:

        label = 'Certificate Pending';
        style = tagStyles.WARNING;
        break;
    }

    if (label) {
      return (
        <CourseOfferingTag
          style={style}
          label={label} />
      )
    } else {
      return false;
    }
  }
}

CertificateStatusTag.propTypes = {
  courseRegistration: PropTypes.shape(registeredCourse).isRequired,
}

export default CertificateStatusTag;
