import * as fetchCreators from './fetchCreators';
import { CONFIGURATION } from '../constants/config';

const userApiUrl = CONFIGURATION.SERVER_API_URL + "/LcUsers/";

export function confirmResetPassword(requestViewModel) {
  return fetchCreators.post(userApiUrl + "confirmResetPassword", requestViewModel);
}

export function resetPassword(email) {
  return fetchCreators.post(userApiUrl + "resetPassword", email);
}

export function login(requestViewModel) {
  return fetchCreators.post(userApiUrl + "login", requestViewModel);
}

export function addLcUser(requestViewModel) {
  return fetchCreators.post(userApiUrl + "create", requestViewModel);
}

export function addGuest(requestViewModel) {
  return fetchCreators.post(userApiUrl + "create-guest", requestViewModel);
}

export function setupGuest(requestViewModel) {
  return fetchCreators.post(userApiUrl + "setup-guest", requestViewModel);
}

export function setupGuestNoData(requestViewModel) {
  return fetchCreators.post(userApiUrl + "setup-guest-no-data", requestViewModel);
}

export function getLcUser() {
  return fetchCreators.get(userApiUrl + "current-user");
}

export function updateLcUser(requestViewModel) {
  return fetchCreators.put(userApiUrl + "update", requestViewModel);
}

export function changePassword(requestViewModel) {
  return fetchCreators.put(userApiUrl + "changePassword", requestViewModel);
}

export function validateLicenseNumber(licenseNumber) {
  return fetchCreators.post(userApiUrl + "validateLicenseNumber", licenseNumber);
}
