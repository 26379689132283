import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import objectAssign from "object-assign";
import { bindActionCreators } from "redux";
import ReactPlaceholder from 'react-placeholder';

import { ROUTES } from '../../constants/routeConfig';
import { mapWebsiteLinkPathtoLCLinkPath } from '../../utilities/routeUtilities';

import pageNode from "../../proptypes/brokerGuides/pageNode";
import courseOffering from "../../proptypes/courses/courseOffering";
import courseOfferingMethod from "../../proptypes/courses/courseOfferingMethod";
import * as courseActions from "../../actions/courseActions";
import * as brokerGuidesActions from "../../actions/brokerGuidesActions";

import FeaturedCoursePlaceHolder from "./placeholders/FeaturedCoursePlaceHolder";
import CourseOfferingCard from "../courses/CourseOfferingCard";
import CourseOfferingGroupCard from "../courses/CourseOfferingGroupCard";
import {
  sortCoursesByMethodAndDate,
  sortOnDemandCoursesByDate,
  sortLiveCoursesByDate,
  shouldShowAsRegistered,
} from "../../utilities/courseUtilities";
import {
  selectCourseOfferings,
  selectShouldLoadFutureCourses,
} from "../../selectors/courseSelectors";
import { selectIsLoadingRegisteredCourses } from "../../selectors/authSelectors";

import SvgIcon from './../icon/SvgIcon';
import LandingPageCourseFilters from './LandingPageCourseFilters';
import ResourcesCard from '../resources/ResourcesCard';
import * as resourceData from "../../resources-page";
import { COURSE_FILTERS } from "../../constants/courseFilters";
import { COURSE_SERIES } from "../../constants/courseSeriesLookup";
import { CONFIGURATION } from '../../constants/config';
import loadingStates from '../../constants/loadingStates';

class LandingPageContent extends React.Component {

  componentWillMount() {
    this.buildFeaturedTutorialsList()

    if (this.props.shouldLoadRegisteredCourses) {
      this.props.actions.getRegisteredCourses();
    }

    var getCourses = this.props.shouldLoadFutureCourses
      ? this.props.actions.getFutureCourses()
      : Promise.resolve(true);

    getCourses
      .then(() => {
        this.buildFeaturedCourseList();
        this.buildRegisteredCoursesList();

        this.setState({
          loadingCourses: false,
        });

        this.setCoursesForDisplay(this.state.selectedFilter);

        if (this.state.registeredCoursesForDisplay != null
          && this.state.registeredCoursesForDisplay.length > 0) {
          this.setState({
            displayRegisteredCourses: true
          });
        }

        // If still filtering by the default, then switch to registered
        // courses if we have them.
        if (this.state.displayRegisteredCourses === true
          && this.state.selectedFilter === COURSE_FILTERS.ALL_COURSES) {
          this.setCoursesForDisplay(COURSE_FILTERS.REGISTERED_COURSES);
        }
      });
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingCourses: true,
      displayViewMore: false,
      liveCourses: null,
      onDemandCourses: null,
      registeredCoursesForDisplay: null,
      coursesForDisplay: [],
      tutorialsForDisplay: [],
      selectedFilter: COURSE_FILTERS.ALL_COURSES,
      displayRegisteredCourses: false,
    };

    this.buildFeaturedCourseList = this.buildFeaturedCourseList.bind(this);
    this.groupCourses = this.groupCourses.bind(this);
    this.buildFeaturedTutorialsList = this.buildFeaturedTutorialsList.bind(this);
    this.setCoursesForDisplay = this.setCoursesForDisplay.bind(this);
  }

  componentDidMount() {
    if (this.props.pageNodes.length === 0
      || !this.props.sectionPageNode) {
      this.props.actions.getSection("FilingRequirementsAndProcedures");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.courseOfferings !== this.props.courseOfferings) {
      this.buildFeaturedCourseList();
    }
    if (prevProps.currentUser.registeredCourses !== this.props.currentUser.registeredCourses) {
      this.buildRegisteredCoursesList();
    }
  }

  groupCourses(liveCourses) {
    let mappedLiveCourses = [];

    liveCourses.forEach((courseOffering) => {
      var matchingCourse = mappedLiveCourses.find((mappedLiveCourse) => mappedLiveCourse.courseId === courseOffering.course.id);
      if (matchingCourse) {
        matchingCourse.courseOfferings.push(courseOffering);
        if (courseOffering.featured) {
          matchingCourse.isFeatured = courseOffering.featured;
        }
      } else {
        mappedLiveCourses.push({
          course: courseOffering.course,
          courseId: courseOffering.course.id,
          isFeatured: courseOffering.featured,
          courseOfferings: [courseOffering],
        });
      }
    })

    return mappedLiveCourses;
  }

  buildFeaturedCourseList() {
    let onDemandCourses = this.props.courseOfferings.filter(c => c.method === courseOfferingMethod.ON_DEMAND);
    let liveCourses = this.props.courseOfferings.filter(c => c.method === courseOfferingMethod.LIVE);

    onDemandCourses = sortOnDemandCoursesByDate(onDemandCourses);
    liveCourses = sortLiveCoursesByDate(liveCourses);

    onDemandCourses = this.groupCourses(onDemandCourses);
    liveCourses = this.groupCourses(liveCourses);

    this.setState({
      onDemandCourses: onDemandCourses,
      liveCourses: liveCourses,
    });
  }

  buildFeaturedTutorialsList() {
    // This is where we can hardcode which tutorials we want to feature on the homepage

    let slipTutorials = resourceData.slipTutorials.slice(0, 1);
    let slipProcedures = resourceData.slipProcedures.slice(0, 1);
    let basicFilingTutorials = resourceData.basicFilingTutorials.slice(0, 2);

    let tutorialsForDisplay = [...slipTutorials, ...slipProcedures, ...basicFilingTutorials];

    this.setState({
      tutorialsForDisplay: tutorialsForDisplay,
    });
  }

  buildRegisteredCoursesList() {
    if (this.props.isLoggedIn) {

      let registeredCourses = this.props.currentUser.registeredCourses
        ? this.props.currentUser.registeredCourses.filter((course) => shouldShowAsRegistered(course))
        : [];

      registeredCourses = sortCoursesByMethodAndDate(registeredCourses, true);

      let registeredCoursesForDisplay = registeredCourses.map(c => {
        return {
          course: c.courseOffering.course,
          courseId: c.courseOffering.course.id,
          courseOfferings: [c.courseOffering]
        };
      });

      this.setState({
        registeredCoursesForDisplay: registeredCoursesForDisplay,
      });
    }
  }

  setCoursesForDisplay(selectedFilter) {

    this.setState({
      selectedFilter: selectedFilter,
    });

    if (this.state.loadingCourses) {
      return true;
    }

    let complianceConnectionCourses, slaUniversityCourses;
    let filteredOnDemandCourses;
    let featuredOnDemandCourses, featuredLiveCourses;
    let coursesForDisplay, displayViewMore;
    let numLiveCourses, numOnDemandCourses;
    let numRemaining;

    switch (selectedFilter) {

      case COURSE_FILTERS.ALL_COURSES:

        // Filter out SL Regulation course
        filteredOnDemandCourses = this.state.onDemandCourses.filter(onDemandCourse => !onDemandCourse.course.name.includes(CONFIGURATION.INTRO_COURSE_NAME));

        coursesForDisplay = [];

        featuredOnDemandCourses = filteredOnDemandCourses.filter(course => course.isFeatured);
        featuredLiveCourses = this.state.liveCourses.filter(course => course.isFeatured);

        coursesForDisplay.push.apply(coursesForDisplay, featuredOnDemandCourses.slice(0, 2));
        coursesForDisplay.push.apply(coursesForDisplay, featuredLiveCourses.slice(0, 2));

        numRemaining = 4 - coursesForDisplay.length;
        if (numRemaining > 0) {
          coursesForDisplay.push.apply(coursesForDisplay, featuredOnDemandCourses
            .filter(course => !coursesForDisplay.some(courseForDisplay => courseForDisplay.courseId === course.courseId))
            .slice(0, numRemaining))
        }

        numRemaining = 4 - coursesForDisplay.length;
        if (numRemaining > 0) {
          coursesForDisplay.push.apply(coursesForDisplay, featuredLiveCourses
            .filter(course => !coursesForDisplay.some(courseForDisplay => courseForDisplay.courseId === course.courseId))
            .slice(0, numRemaining))
        }

        numRemaining = 4 - coursesForDisplay.length;
        if (numRemaining > 0) {
          coursesForDisplay.push.apply(coursesForDisplay, filteredOnDemandCourses
            .filter(course => !coursesForDisplay.some(courseForDisplay => courseForDisplay.courseId === course.courseId))
            .slice(0, numRemaining))
        }

        numLiveCourses = this.state.liveCourses.length;
        numOnDemandCourses = filteredOnDemandCourses.length;
        displayViewMore = (numOnDemandCourses + numLiveCourses) > 4;
        break;

      case COURSE_FILTERS.LIVE:
        coursesForDisplay = [];

        coursesForDisplay.push.apply(coursesForDisplay, this.state.liveCourses.filter(course => course.isFeatured).slice(0, 4));

        numRemaining = 4 - coursesForDisplay.length;
        if (numRemaining > 0) {
          coursesForDisplay.push.apply(coursesForDisplay, this.state.liveCourses
            .filter(course => !coursesForDisplay.some(courseForDisplay => courseForDisplay.courseId === course.courseId))
            .slice(0, numRemaining));
        }

        displayViewMore = this.state.liveCourses.length > 4;
        break;

      case COURSE_FILTERS.ON_DEMAND:
        // Filter out SL Regulation course
        filteredOnDemandCourses = this.state.onDemandCourses.filter(onDemandCourse => !onDemandCourse.course.name.includes(CONFIGURATION.INTRO_COURSE_NAME));

        coursesForDisplay = [];

        coursesForDisplay.push.apply(coursesForDisplay, filteredOnDemandCourses.filter(course => course.isFeatured).slice(0, 4));

        numRemaining = 4 - coursesForDisplay.length;
        if (numRemaining > 0) {
          coursesForDisplay.push.apply(coursesForDisplay, filteredOnDemandCourses
            .filter(course => !coursesForDisplay.some(courseForDisplay => courseForDisplay.courseId === course.courseId))
            .slice(0, numRemaining));
        }

        displayViewMore = filteredOnDemandCourses.length > 4;
        break;

      case COURSE_FILTERS.TUTORIALS:
        displayViewMore = true;
        break;

      case COURSE_FILTERS.REGISTERED_COURSES:
        coursesForDisplay = this.state.registeredCoursesForDisplay.slice(0, 4);
        displayViewMore = this.state.registeredCoursesForDisplay.length > 4;
        if (!coursesForDisplay.length) this.setState({ selectedFilter: COURSE_FILTERS.ALL_COURSES })
        break;

      case COURSE_FILTERS.INTRO:
        coursesForDisplay = this.state.onDemandCourses.filter(onDemandCourse => onDemandCourse.course.name == CONFIGURATION.INTRO_COURSE_NAME);
        displayViewMore = false;
        break;

      case COURSE_FILTERS.COMPLIANCE_CONNECTIONS:
        complianceConnectionCourses = this.state.liveCourses.filter(course => course.course.seriesId == COURSE_SERIES.COMPLIANCE_CONNECTIONS)
          .concat(this.state.onDemandCourses.filter(course => course.course.seriesId == COURSE_SERIES.COMPLIANCE_CONNECTIONS));
        coursesForDisplay = complianceConnectionCourses.slice(0, 4);
        displayViewMore = complianceConnectionCourses.length > 4;
        break;

      case COURSE_FILTERS.SLA_UNIVERSITY:
        slaUniversityCourses = this.state.liveCourses.filter(course => course.course.seriesId == COURSE_SERIES.SLA_UNIVERSITY)
          .concat(this.state.onDemandCourses.filter(course => course.course.seriesId == COURSE_SERIES.SLA_UNIVERSITY))
        coursesForDisplay = slaUniversityCourses.slice(0, 4);
        displayViewMore = slaUniversityCourses.length > 4;
        break;
    }

    this.setState({
      coursesForDisplay: coursesForDisplay,
      displayViewMore: displayViewMore
    });
  }

  render() {

    let renderTutorialCards = this.state.selectedFilter == COURSE_FILTERS.TUTORIALS;
    let renderCourseCards = !renderTutorialCards;

    return (
      <div className='landing-page-content-container'>

        <LandingPageCourseFilters
          setCoursesForDisplay={this.setCoursesForDisplay}
          selectedFilter={this.state.selectedFilter}
          displayRegisteredCourses={this.state.displayRegisteredCourses}
        />

        <div className='landing-page-course-cards'>

          {this.state.selectedFilter == COURSE_FILTERS.COMPLIANCE_CONNECTIONS &&
            this.state.coursesForDisplay.length == 0 &&
            !this.state.loadingCourses &&
            <div className='text-center'>
              Compliance Connection courses are coming soon.
            </div>
          }

          {this.state.selectedFilter == COURSE_FILTERS.SLA_UNIVERSITY &&
            this.state.coursesForDisplay.length == 0 &&
            !this.state.loadingCourses &&
            <div className='text-center'>
              SLA University courses are coming soon.
            </div>
          }

          {this.state.selectedFilter == COURSE_FILTERS.LIVE &&
            this.state.coursesForDisplay.length == 0 &&
            !this.state.loadingCourses &&
            <div className='text-center'>
              More Live Courses are on the way. Check back soon.
            </div>
          }

          {!renderTutorialCards &&
            <ReactPlaceholder showLoadingAnimation ready={!this.state.loadingCourses}
              customPlaceholder={FeaturedCoursePlaceHolder}>
              <div className='row'>

                {renderCourseCards &&
                  this.state.coursesForDisplay.map(courseListItem =>
                    <div key={courseListItem.courseId} className='col-xs-12 col-sm-6'>
                      {(courseListItem.courseOfferings.length > 1)
                        ? <CourseOfferingGroupCard key={courseListItem.courseId} courseOfferingGroup={courseListItem} />
                        : <CourseOfferingCard key={courseListItem.courseOfferings[0].id} featured courseOffering={courseListItem.courseOfferings[0]} />
                      }
                    </div>
                  )
                }

              </div>
            </ReactPlaceholder>
          }

          {renderTutorialCards &&
            <div className='row'>
              {this.state.tutorialsForDisplay.map((tutorial) => {
                return (
                  <div key={tutorial.key} className={"col-xs-12 col-sm-6"}>
                    <ResourcesCard tutorial={tutorial.value} key={tutorial.key} />
                  </div>
                );
              })}
            </div>
          }
        </div>

        {
          this.state.displayViewMore && this.state.selectedFilter == COURSE_FILTERS.ALL_COURSES &&
          <span className="view-more">
            <NavLink to={ROUTES.COURSE_LIST} onClick={() => {
              this.props.actions.resetCourseSearchFilters();
              this.props.actions.changeMethodFilter(courseOfferingMethod.ALL);
            }}>
              View More +
            </NavLink>
          </span>
        }

        {
          this.state.displayViewMore && this.state.selectedFilter == COURSE_FILTERS.LIVE &&
          <span className="view-more">
            <NavLink to={ROUTES.COURSE_LIST} onClick={() => {
              this.props.actions.resetCourseSearchFilters();
              this.props.actions.changeMethodFilter(courseOfferingMethod.LIVE);
              this.props.actions.changeCECreditsFilter();
            }}>
              View More +
            </NavLink>
          </span>
        }

        {
          this.state.displayViewMore && this.state.selectedFilter == COURSE_FILTERS.ON_DEMAND &&
          <span className="view-more">
            <NavLink to={ROUTES.COURSE_LIST} onClick={() => {
              this.props.actions.resetCourseSearchFilters();
              this.props.actions.changeMethodFilter(courseOfferingMethod.ON_DEMAND);
              this.props.actions.changeCECreditsFilter();
            }}>
              View More +
            </NavLink>
          </span>
        }

        {
          this.state.displayViewMore && this.state.selectedFilter == COURSE_FILTERS.TUTORIALS &&
          <span className="view-more">
            <NavLink to={ROUTES.TUTORIALS_PAGE}>
              View More +
            </NavLink>
          </span>
        }

        {
          this.state.displayViewMore && this.state.selectedFilter == COURSE_FILTERS.REGISTERED_COURSES &&
          <span className="view-more">
            <NavLink to={ROUTES.MY_COURSES_PAGE}>
              View More +
            </NavLink>
          </span>
        }

        {
          this.state.displayViewMore && this.state.selectedFilter == COURSE_FILTERS.COMPLIANCE_CONNECTIONS &&
          <span className="view-more">
            <NavLink to={ROUTES.COURSE_LIST} onClick={() => {
              this.props.actions.resetCourseSearchFilters();
              this.props.actions.toggleComplianceConnections();
            }}>
              View More +
            </NavLink>
          </span>
        }

        {
          this.state.displayViewMore && this.state.selectedFilter == COURSE_FILTERS.SLA_UNIVERSITY &&
          <span className="view-more">
            <NavLink to={ROUTES.COURSE_LIST} onClick={() => {
              this.props.actions.resetCourseSearchFilters();
              this.props.actions.toggleSlaUniversity();
            }}>
              View More +
            </NavLink>
          </span>
        }

        <div className='landing-page-resources-panel-container'>
          <div className='landing-page-resources-panel'>

            <div className='resources-panel-header'>
              <div className="header">
                <SvgIcon icon="resources" color="white" />
                <h1 className="left-block white large d-inline-block">
                  Resources
                </h1>
              </div>
              <div className="sub-header">
                View filing tutorials, bulletins, and other helpful information.
              </div>
            </div>

            <div className='resources-panel-links'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <NavLink to={ROUTES.NOTICES_PAGE}>
                    <div className='svg-icon-container'>
                      <SvgIcon icon="info" color="white" />
                    </div>
                    <div className='btn-text notices'>
                      Notices
                    </div>
                    <SvgIcon icon="chevron-right" color="white" />
                  </NavLink>
                </div>

                <div className='col-xs-12 col-sm-6'>
                  <NavLink to={ROUTES.BULLETINS_PAGE}>
                    <div className='svg-icon-container'>
                      <SvgIcon icon="pin" color="white" />
                    </div>
                    <div className='btn-text'>
                      Bulletins
                    </div>
                    <SvgIcon icon="chevron-right" color="white" />
                  </NavLink>
                </div>

                <div className='col-xs-12 col-sm-6'>
                  <NavLink to={ROUTES.TUTORIALS_PAGE}>
                    <div className='svg-icon-container'>
                      <SvgIcon icon="video-call" color="white" />
                    </div>
                    <div className='btn-text tutorials'>
                      Tutorials
                    </div>
                    <SvgIcon icon="chevron-right" color="white" />
                  </NavLink>
                </div>

                <div className='col-xs-12 col-sm-6'>
                  <NavLink to={ROUTES.FAQS_PAGE}>
                    <div className='svg-icon-container'>
                      <SvgIcon icon="faqs" color="white" />
                    </div>
                    <div className='btn-text'>
                      FAQS
                    </div>
                    <SvgIcon icon="chevron-right" color="white" />
                  </NavLink>
                </div>

                <div className='col-12'>
                  {this.props.pageNodes.length > 0 &&
                    this.props.sectionPageNode &&
                    <NavLink to={mapWebsiteLinkPathtoLCLinkPath(this.props.pageNodes[0].relativeUrlPath)}>
                      <div className='svg-icon-container'>
                        <SvgIcon icon="file-requirements" color="white" />
                      </div>
                      <div className='btn-text'>
                        {this.props.sectionPageNode.title}
                      </div>
                      <SvgIcon icon="chevron-right" color="white" />
                    </NavLink>
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

LandingPageContent.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  courseOfferings: PropTypes.arrayOf(PropTypes.shape(courseOffering)),
  isLoggedIn: PropTypes.bool.isRequired,
  shouldLoadFutureCourses: PropTypes.bool.isRequired,
  shouldLoadRegisteredCourses: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  pageNodes: PropTypes.arrayOf(PropTypes.shape(pageNode)),
  sectionPageNode: PropTypes.shape(pageNode),
};

function mapStateToProps(state) {
  return {
    courseOfferings: selectCourseOfferings(state),
    shouldLoadFutureCourses: selectShouldLoadFutureCourses(state),
    shouldLoadRegisteredCourses:
      state.auth.isLoggedIn &&
      selectIsLoadingRegisteredCourses(state) === loadingStates.NOT_LOADED,
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser,
    pageNodes: state.brokerGuides.pageNodes,
    sectionPageNode: state.brokerGuides.sectionPageNode,
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({},
    courseActions,
    brokerGuidesActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPageContent));
