import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import ReactPlaceholder from 'react-placeholder';

import * as resourceActions from "../../actions/resourceActions";

import bulletin from "../../proptypes/resource/bulletin";

import { sortBulletins } from "../../utilities/resourcesUtilities";

import ContentPageLayout from "../layout/ContentPageLayout";
import ResourcesCard from "./ResourcesCard";
import SeriesBulletinsPlaceHolder from "./placeholders/SeriesBulletinsPlaceHolder";

class BulletinsSeriesPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    let seriesId = this.props.match.params.seriesId;
    this.props.actions.clearSeriesBulletins();
    this.props.actions.getBulletinsBySeries({ seriesId, shouldThrowError: true })
      .then(() => this.setState({ loading: false }))
  }

  render() {
    let bulletins = sortBulletins(this.props.seriesBulletins);

    return (<ContentPageLayout
      documentTitle={this.props.match.params.seriesName + " Series"}
      headerTitle={this.props.match.params.seriesName + " Series"}
      headerContent={
        <React.Fragment>
          SLA bulletins are updates of news and events affecting the surplus line market in California. To view the title of each bulletin choose one of the series listed below for a complete index. Each index lists the title/subject of the bulletin, as well as the date and bulletin number.
        </React.Fragment>
      }>

      <div className={"container"}>
        <div>
          <div className={"white-body"}>
            <div className={"row"}>
              <ReactPlaceholder showLoadingAnimation ready={!this.state.loading} customPlaceholder={SeriesBulletinsPlaceHolder}>
                {bulletins.map((bulletin) =>
                  <div key={bulletin.id} className={"col-md-6 col-12"}>
                    <ResourcesCard bulletin={bulletin} />
                  </div>
                )}
              </ReactPlaceholder>
            </div>
          </div>
        </div>
      </div>
    </ContentPageLayout>);
  }
}

BulletinsSeriesPage.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  seriesBulletins: PropTypes.arrayOf(PropTypes.shape(bulletin)),
};

function mapStateToProps(state) {
  return {
    seriesBulletins: state.resource.seriesBulletins
  };
}

function mapDispatchToProps(dispatch) {
  const combinedActions = objectAssign({}, resourceActions);

  return {
    actions: bindActionCreators(combinedActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulletinsSeriesPage));
