import PropTypes from 'prop-types';
import redirectPage from './redirectPage';

export default {
  id: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  parentid: PropTypes.string,
  haschildren: PropTypes.string,
  relativeurlpath: PropTypes.string,
  pageType: PropTypes.string,
  redirectUrl: PropTypes.string,
  redirectPage:  PropTypes.shape(redirectPage),
}