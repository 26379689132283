import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { ROUTES } from '../../constants/routeConfig';
import landingBg from "../../assets/Carousel-4.png";
import landingBgMobile from "../../assets/Carousel-4-mobile.png";
import * as buttonTypes from "../../constants/buttonTypes";
import * as buttonSizes from "../../constants/buttonSizes";
import user from '../../proptypes/users/User';

import CarouselSlide from './CarouselSlide';
import Button from './../inputs/Button';

class LandingPageMyCoursesSlide extends React.Component {

  render() {
    return (
      <CarouselSlide backgroundUrl={landingBg}
        mobileBackgroundUrl={landingBgMobile}
        active={this.props.active}>
        <div className={this.props.active ? '' : 'row'} >
          <div className={this.props.active ? '' : 'col offset-md-5'}>
            <h1><span>Monitor Your Progress</span><span>Towards License Renewal</span></h1>
            <p className="landing-subtitle">
              <React.Fragment>
                View your SLA Course History, access your CE certificates, and track the number
                <br className="d-none d-sm-block" />
                &nbsp;of CE credits earned towards your license renewal.
              </React.Fragment>
            </p>

            <div className="slide-buttons">

              <Button onClick={() => this.props.history.push(ROUTES.MY_COURSES_PAGE)}
                label="My Courses"
                buttonSize={buttonSizes.LARGE}
                buttonType={buttonTypes.PRIMARY}
                svgIcon
                icon="my-courses"
                iconColor="white"
                hoverIconColor="white" />

              <Button onClick={() => this.props.history.push(ROUTES.MY_COURSES_PAGE)}
                label="Renewal Calculator"
                buttonSize={buttonSizes.LARGE}
                buttonType={buttonTypes.WHITE_OUTLINE}
                svgIcon
                icon="calculator"
                iconColor="white"
                hoverIconColor="blue" />

              <Button className="mt-md-3 mt-lg-0 mt-0" onClick={() => window.open("https://cdicloud.insurance.ca.gov/cal/IndividualNameSearch?handler=Search", '_blank')}
                label="CDI Credits (External)"
                buttonSize={buttonSizes.LARGE}
                buttonType={buttonTypes.WHITE_OUTLINE}
                svgIcon
                icon="capital"
                iconColor="white"
                hoverIconColor="blue" />                
            </div>
            
          </div>
        </div>
      </CarouselSlide>
    );
  }
}

LandingPageMyCoursesSlide.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape(user),
  active: PropTypes.bool,
  history: PropTypes.object.isRequired,
}

LandingPageMyCoursesSlide.defaults = {
  active: false,
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(LandingPageMyCoursesSlide));