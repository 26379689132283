import PropTypes from 'prop-types';

export default {
  id: PropTypes.string.isRequired,
  urlName: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  sortPosition: PropTypes.number,
}



