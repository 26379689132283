export const DOCUMENT_PUBLISH_OPTIONS = Object.freeze({
    IMMEDIATE: "I",
    UPON_REGISTRATION: "R",
    UPON_COMPLETION: "C",
});

export const COURSE_TYPES = Object.freeze({
    CE: "C",
    NON_CE: "N",
});
